import classNames from 'classnames';
import React from 'react';
import { Form, FormCheckProps } from 'react-bootstrap';
import styles from '../style.module.scss';

type CheckboxProps = FormCheckProps;

export const Checkbox = React.forwardRef(function Checkbox(props: CheckboxProps, ref: React.Ref<HTMLInputElement>) {
    const { checked, value, id, name, onChange, label, className } = props;
    return (
        <Form.Check
            ref={ref}
            type='checkbox'
            className={classNames('design-system', className, styles['checkbox'])}
            checked={checked}
            id={id}
            name={name}
            value={value}
            onChange={onChange}
            label={label}
        />
    );
});
