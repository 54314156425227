import React from 'react';
import { useTranslation } from 'next-i18next';
import { TFunction } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import { Button } from '@/components/common';

export const LoadMoreButton = ({ onClick }: { onClick: () => void }) => {
    const { t } = useTranslation(['myOwnr', 'properties']) as unknown as { t: TFunction<string, undefined> };
    return (
        <Container>
            <Row className='justify-content-center mb-6'>
                <Col xs='auto' md='auto'>
                    <Button className='float-end' onClick={onClick} color='velvet' type='submit'>
                        {t('load_more', { ns: 'properties' })}
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};
