import { toast, ToastContainer, ToastContent } from 'react-toastify';
import { Account, EmploymentContractTypeEnum, LoadAccountFunction, UpdateAccountFunction } from '../types';
import useSWR from 'swr';
import { Button, colorVariant, Loader, lockSymbols, patternVariant } from '@/components/common';
import { FieldValues, Path, useForm, UseFormSetError } from 'react-hook-form';
import { useRouter } from 'next/router';
import React, { useId, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { TFunction } from 'react-i18next';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import isEmail from 'validator/lib/isEmail';
import { ErrorMessage } from '../util';
import moment from 'moment';
import 'moment/locale/de';
import { TOAST_OPTIONS } from '@/styles/toasts';
import styles from '../seldservice.module.scss';
import { notify } from '@/lib/utilities/logger';
import { useAccountId } from '../../../users';

export type UpdateAccountFromProps = {
    updateFunction: UpdateAccountFunction;
    account: Account;
};

export type UpdateAccountProps = Pick<UpdateAccountFromProps, 'updateFunction'> & {
    loadFunction: LoadAccountFunction;
};

export type UpdateAccountLoaderProps = UpdateAccountProps & {
    accountId: string;
};

export function UpdateAccount(props: UpdateAccountProps) {
    const accountId = useAccountId();

    if (status === 'unauthenticated' || !accountId) {
        // router.push(`/my`);
        return;
    }
    return <UpdateAccountLoader accountId={accountId} {...props} />;
}

export function UpdateAccountLoader(props: UpdateAccountLoaderProps) {
    const { loadFunction, accountId } = props || {};
    const router = useRouter();

    const {
        data: account,
        error: loadError,
        isLoading,
    } = useSWR(['account', accountId], ([, accountId]) =>
        !accountId ? Promise.resolve(null) : loadFunction(accountId)
    );

    if (loadError) {
        notify(loadError, 'selfService/updateAccount');
        router.push('/my/lease-requests?where=two');

        return null;
    }

    if (isLoading || !account) return <Loader />;
    return <UpdateAccountForm account={account} {...props} />;
}

function UpdateAccountForm(props: UpdateAccountFromProps) {
    const componentId = useId();
    const symbols = useMemo(() => {
        const date = new Date();
        return lockSymbols(2, 2, {
            pattern: patternVariant(date.getDay() + 2),
            colors: colorVariant(date.getDate() + 1),
        });
    }, []);
    const formId = (name: string) => `${componentId}-${name}`;
    const { account, updateFunction } = props || {};
    const router = useRouter();
    const { locale, defaultLocale } = router;
    const formControl = useForm({
        mode: 'onChange',
        defaultValues: {
            ...account,
            dateOfBirth: account?.dateOfBirth || '',
            employmentContractEnd: account?.employmentContractEnd
                ? moment(account?.employmentContractEnd).format('L')
                : '',
        },
    });

    const {
        register,
        handleSubmit,
        watch,
        setError,
        formState: { errors, isSubmitting },
    } = formControl;
    const { t } = useTranslation(['account', 'common']) as unknown as {
        t: TFunction<string, undefined>;
    };

    return (
        <Row>
            <Col>
                <div className='p-0'>
                    <h3>{t('h_update_account')}</h3>
                    <p className='text-footnote'>{t('required_fields')}</p>
                </div>
                <Form
                    onSubmit={event =>
                        handleSubmit(onSubmit(locale || defaultLocale || 'de_DE', updateFunction, setError, t))(event)
                    }
                    className='p-0'
                >
                    <FloatingLabel
                        controlId={formId('firstName')}
                        label={`${t('firstName')} *`}
                        className='mb-4 d-grid w-100'
                    >
                        <Form.Control
                            type='text'
                            placeholder='Max'
                            isInvalid={!!errors.firstName}
                            {...register('firstName', {
                                minLength: 2,
                                required: true,
                            })}
                        />
                        <ErrorMessage t={t} errors={errors} name='firstName' />
                    </FloatingLabel>
                    <FloatingLabel
                        controlId={formId('lastName')}
                        label={`${t('lastName')} *`}
                        className='mb-4 d-grid w-100'
                    >
                        <Form.Control
                            type='text'
                            placeholder='Mustermann'
                            isInvalid={!!errors.lastName}
                            {...register('lastName', {
                                minLength: 2,
                                required: true,
                            })}
                        />
                        <ErrorMessage t={t} errors={errors} name='lastName' />
                    </FloatingLabel>
                    <FloatingLabel
                        controlId={formId('dateOfBirth')}
                        label={t('dateOfBirth')}
                        className='mb-4 d-grid w-100'
                    >
                        <Form.Control type='date' {...register('dateOfBirth')} />
                        <ErrorMessage t={t} errors={errors} name='dateOfBirth' />
                    </FloatingLabel>
                    <FloatingLabel controlId={formId('email')} label={`${t('email')} *`} className='mb-4 d-grid w-100'>
                        <Form.Control
                            type='email'
                            disabled={true}
                            placeholder='name@example.com'
                            isInvalid={!!errors.email}
                            {...register('email', {
                                minLength: 8,
                                required: true,
                                validate: { format: v => v && isEmail(v) },
                            })}
                        />
                    </FloatingLabel>

                    <FloatingLabel controlId={formId('phone')} label={t('phone')} className='mb-4 d-grid w-100'>
                        <Form.Control
                            type='tel'
                            placeholder='+04000000000'
                            isInvalid={!!errors.phone}
                            {...register('phone', {
                                minLength: 2,
                                maxLength: 16,
                                required: false,
                            })}
                        />
                        <ErrorMessage t={t} errors={errors} name='phone' />
                    </FloatingLabel>
                    <FloatingLabel controlId={formId('mobile')} label={t('mobile')} className='mb-4 d-grid w-100'>
                        <Form.Control
                            type='tel'
                            placeholder='+017200000000'
                            isInvalid={!!errors.mobile}
                            {...register('mobile', {
                                minLength: 2,
                                maxLength: 16,
                                required: false,
                            })}
                        />
                        <ErrorMessage t={t} errors={errors} name='mobile' />
                    </FloatingLabel>
                    <div className='pt-2'>
                        <h3>{t('h_update_address')}</h3>
                    </div>

                    <FloatingLabel controlId={formId('street')} label={t('street')} className='mb-4 d-grid w-100'>
                        <Form.Control
                            type='text'
                            placeholder='Musterstraße'
                            isInvalid={!!errors.street}
                            {...register('street', {
                                minLength: 8,
                                required: false,
                            })}
                        />
                        <ErrorMessage t={t} errors={errors} name='street' />
                    </FloatingLabel>
                    <FloatingLabel
                        controlId={formId('houseNumber')}
                        label={t('houseNumber')}
                        className='mb-4 d-grid w-100'
                    >
                        <Form.Control
                            type='text'
                            placeholder='3'
                            isInvalid={!!errors.street}
                            {...register('houseNumber', {
                                required: false,
                                deps: ['street'],
                            })}
                        />
                        <ErrorMessage t={t} errors={errors} name='houseNumber' />
                    </FloatingLabel>
                    <FloatingLabel controlId={formId('zipCode')} label={t('zipCode')} className='mb-4 d-grid w-100'>
                        <Form.Control
                            type='text'
                            placeholder='20355'
                            isInvalid={!!errors.zipCode}
                            {...register('zipCode', {
                                required: false,
                                minLength: 5,
                                maxLength: 5,
                                validate: {
                                    format: v => {
                                        try {
                                            if (!v || !v.length) return true;
                                            return !isNaN(parseInt(v)) && v.slice(0, 2) !== '00';
                                        } catch (e) {
                                            return false;
                                        }
                                    },
                                },
                            })}
                        />
                        <ErrorMessage t={t} errors={errors} name='zipCode' />
                    </FloatingLabel>
                    <FloatingLabel controlId={formId('city')} label={t('city')} className='mb-4 d-grid w-100'>
                        <Form.Control
                            type='text'
                            placeholder='Hamburg'
                            isInvalid={!!errors.city}
                            {...register('city', {
                                required: false,
                            })}
                        />
                        <ErrorMessage t={t} errors={errors} name='city' />
                    </FloatingLabel>
                    <div className='pt-2'>
                        <h3>{t('h_update_misc')}</h3>
                    </div>
                    <Form.Select
                        className='mb-4 d-grid w-100'
                        id={formId('employmentContract')}
                        isInvalid={!!errors.employmentContract}
                        {...register('employmentContract', {
                            required: false,
                        })}
                    >
                        <option value=''> {t('employmentContract')}</option>
                        {EMPLOYMENT_TYPES.map(name => (
                            <option key={name} value={name}>
                                {t(`employment_types.${name}`)}
                            </option>
                        ))}
                    </Form.Select>
                    <ErrorMessage t={t} errors={errors} name='employmentContract' />
                    {watch('employmentContract') === 'FIXED_TERM' && (
                        <FloatingLabel
                            controlId={formId('employmentContractEnd')}
                            label={t('employmentContractEnd')}
                            className='mb-4 d-grid w-100'
                        >
                            <Form.Control
                                type='text'
                                placeholder={moment().format('L')}
                                isInvalid={!!errors.employmentContractEnd}
                                {...register('employmentContractEnd', {
                                    minLength: 2,
                                    required: watch('employmentContract') === 'FIXED_TERM',
                                    validate: { format: v => !!v && v.length > 0 && moment(v, 'L', true).isValid() },
                                })}
                            />
                            <ErrorMessage t={t} errors={errors} name='employmentContractEnd' />
                        </FloatingLabel>
                    )}
                    <FloatingLabel
                        controlId={formId('householdSize')}
                        label={t('householdSize')}
                        className='mb-4 d-grid w-100'
                    >
                        <Form.Control
                            type='text'
                            placeholder='Hamburg'
                            isInvalid={!!errors.householdSize}
                            {...register('householdSize', {
                                required: false,
                            })}
                        />
                        <ErrorMessage t={t} errors={errors} name='householdSize' />
                    </FloatingLabel>
                    <FloatingLabel controlId={formId('netIncome')} label={t('netIncome')} className='mb-4 d-grid w-100'>
                        <Form.Control
                            type='number'
                            placeholder='100'
                            isInvalid={!!errors.netIncome}
                            {...register('netIncome', {
                                required: false,
                                valueAsNumber: true,
                            })}
                        />
                        <ErrorMessage t={t} errors={errors} name='netIncome' />
                    </FloatingLabel>
                    <FloatingLabel
                        controlId={formId('monthlyBudget')}
                        label={t('monthlyBudget')}
                        className='mb-4 d-grid w-100'
                    >
                        <Form.Control
                            type='number'
                            placeholder='Hamburg'
                            isInvalid={!!errors.monthlyBudget}
                            {...register('monthlyBudget', {
                                required: false,
                                valueAsNumber: true,
                            })}
                        />
                        <ErrorMessage t={t} errors={errors} name='monthlyBudget' />
                    </FloatingLabel>

                    <div className='my-5'>
                        {isSubmitting ? (
                            <Button buttonType='submit' disabled={true}>
                                <span className='me-1'>{t('btn_saving')}</span>
                                <span
                                    className='spinner-border spinner-border-sm me-2'
                                    role='status'
                                    aria-hidden='true'
                                />
                            </Button>
                        ) : (
                            <Button buttonType='submit'>
                                <span>{t('btn_save')}</span>
                            </Button>
                        )}
                    </div>
                    <ToastContainer />
                </Form>
            </Col>
            <Col
                sm={4}
                xs={0}
                style={{ backgroundImage: `url("data:image/svg+xml,${encodeURIComponent(symbols)}")` }}
                className={styles['decoration']}
            ></Col>
        </Row>
    );
}

export default UpdateAccount;

function onSubmit<T>(
    locale: string,
    updateFunction: UpdateAccountFunction,
    setError: UseFormSetError<T>,
    t: TFunction
) {
    return async (values: FieldValues) => {
        const {
            dateOfBirth = null,
            employmentContractEnd = null,
            employmentContract = null,
            householdSize = null,
            netIncome = null,
            monthlyBudget = null,
            ...otherValues
        } = values;
        const specialValues: Partial<Account> = {
            dateOfBirth: dateOfBirth?.length > 0 ? moment(dateOfBirth).format('YYYY-MM-DD') : null,
            employmentContractEnd:
                employmentContractEnd && employmentContract === 'FIXED_TERM'
                    ? moment(employmentContractEnd, 'L').format('YYYY-MM-DD')
                    : null,
            employmentContract: employmentContract?.length > 0 ? employmentContract : null,
            householdSize: householdSize ? parseInt(householdSize) : null,
            netIncome: netIncome ? parseInt(netIncome) : null,
            monthlyBudget: monthlyBudget ? parseInt(monthlyBudget) : null,
        };
        const filteredValues = Object.entries(otherValues).reduce((acc, [k, v]) => {
            acc[k] = !v ? null : v;
            return acc;
        }, specialValues as Record<string, unknown>);

        const { success, errors } = await updateFunction({ ...filteredValues, email: undefined } as Account);
        if (success) {
            toast.success(t('update_success') as ToastContent, TOAST_OPTIONS);
        } else {
            const displayedKeys = new Set<string>();
            errors.forEach(({ code, propertyName, message }) => {
                const c = code.toLowerCase();
                if (!propertyName) {
                    const cd = EXPRECTED_ERROR_CODES.has(c) ? c : 'unknown';
                    const key = `errors.${cd}`;
                    if (!displayedKeys.has(key)) {
                        toast.error(t(key) as ToastContent, TOAST_OPTIONS);
                    }
                } else {
                    setError(propertyName as Path<T>, { type: c, message: message ?? 'unknown' });
                }
            });
        }
    };
}

const EMPLOYMENT_TYPES = Object.keys(EmploymentContractTypeEnum).filter(v => isNaN(Number(v)));
const EXPRECTED_ERROR_CODES = new Set<string>();
