import React, { useId, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { ErrorMessage } from '../error-message';
import { pascalCase } from '@/lib/utilities';
import { useTranslation } from 'next-i18next';
import { TFunction } from 'react-i18next';
import { createUsernameValidation } from '@/components/auth-ui/lib/util';

export type ResendCodeComponentProps = {
    username?: string | null;
    resendConfirmationCode: (email: string) => Promise<{ success: boolean; attempts?: number; error?: string }>;
};

export function ResendCodeComponent(props: ResendCodeComponentProps) {
    const componentId = useId();
    const formId = (...params: string[]) => pascalCase(componentId, ...params);
    const [submitted, setSubmitted] = useState<boolean>(false);

    const { t } = useTranslation('common') as unknown as {
        t: TFunction<string, undefined>;
    };

    const tNamespacePrefix = 'resend_code_form';

    return (
        <div>
            <h5>{t(`${tNamespacePrefix}.headline`)}</h5>
            {submitted ? (
                <div>{t(`${tNamespacePrefix}.success`)}</div>
            ) : (
                <ResendCodeForm
                    formId={formId}
                    t={t}
                    setSubmitted={setSubmitted}
                    {...props}
                    tNamespacePrefix={tNamespacePrefix}
                />
            )}
        </div>
    );
}

export default ResendCodeComponent;

function ResendCodeForm(
    props: ResendCodeComponentProps & {
        formId: (...params: string[]) => string;
        t: TFunction;
        resendConfirmationCode: (username: string) => Promise<{ success: boolean; attempts?: number; error?: string }>;
        setSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
        tNamespacePrefix: string;
    }
) {
    const {
        formId,
        username = queryParameter('username'),
        resendConfirmationCode,
        setSubmitted,
        t,
        tNamespacePrefix,
    } = props || {};
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors, isSubmitting, isValid },
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            username,
        },
    });

    const usernameValidation = createUsernameValidation(t, tNamespacePrefix);

    const submit = async ({ username }: { username?: string | null }) => {
        if (!username) {
            setError('username', { message: t(`${tNamespacePrefix}.validation.username.required`) });
            return;
        }
        const { success, error } = await resendConfirmationCode(username);
        if (!success || !!error) {
            setError('username', {
                message: t(`${tNamespacePrefix}.error.${error || 'unexpected_error'}`, { username }),
            });
        } else {
            setSubmitted(true);
        }
    };

    return (
        <div>
            <p>{t(`${tNamespacePrefix}.into`)}</p>
            <Form id={formId()} onSubmit={handleSubmit(submit)}>
                <div className='input-group'>
                    <div className='mb-3 d-grid w-100 form-floating'>
                        <Form.Control
                            type='email'
                            id={formId('Username')}
                            placeholder='name@example.com'
                            isInvalid={!!errors['username']}
                            {...register('username', {
                                minLength: 8,
                                maxLength: 254,
                                required: true,
                                validate: { username: usernameValidation },
                            })}
                        />
                        <Form.Label htmlFor={formId('email')}>{t(`${tNamespacePrefix}.username`)}</Form.Label>
                        <ErrorMessage name='username' errors={errors} t={t} tNamespacePrefix={tNamespacePrefix} />
                    </div>
                    <div className='mt-2 d-grid w-100 d-flex justify-content-end'>
                        {isSubmitting ? (
                            <button type='submit' className='btn btn-primary text-white mb-3' disabled={true}>
                                <span
                                    className='spinner-border spinner-border-sm me-2'
                                    role='status'
                                    aria-hidden='true'
                                />
                                <span>
                                    {' '}
                                    {t(`${tNamespacePrefix}.button.sending`)} <i className='icon icon-angle-right' />
                                </span>
                            </button>
                        ) : (
                            <button type='submit' className='btn btn-ternary text-white mb-3' disabled={!isValid}>
                                <span>
                                    {t(`${tNamespacePrefix}.button.send`)} <i className='icon icon-angle-right' />
                                </span>
                            </button>
                        )}
                    </div>
                </div>
            </Form>
        </div>
    );
}

function queryParameter(name: string): string | null {
    if (typeof window === 'undefined' || !name) return null; //probably server side
    const queryParameters = new URLSearchParams(window.location.search);
    return queryParameters.get(name);
}
