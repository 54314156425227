import { pascalCase } from '@/lib/utilities';
import isEmail from 'validator/lib/isEmail';
import { TFunction } from 'react-i18next';
import { FieldErrors, Validate } from 'react-hook-form';
import { ParsedUrlQuery } from 'querystring';

export function queryParameter(name: string, query?: ParsedUrlQuery): string | null {
    if ((typeof window === 'undefined' && !query) || !name) return null; //probably server side
    if (query) {
        const v = query[name];
        if (Array.isArray(v)) return v[0];
        else return v || null;
    } else {
        const queryParameters = new URLSearchParams(window.location.search);
        return queryParameters.get(name);
    }
}

export function createFormIdFn(componentId: string) {
    return (...params: string[]) => pascalCase(componentId, ...params);
}

export function createUsernameValidation(
    t: TFunction,
    tNamespacePrefix: string
): Validate<string | null | undefined, unknown> {
    return (username: string | null | undefined) => {
        if (!username?.length) return t(`${tNamespacePrefix}.validation.username.required`);
        if (!isEmail(username)) return t(`${tNamespacePrefix}.validation.username.format`);
        return true;
    };
}

export function createCodeValidation(t: TFunction): Validate<string | null, unknown> {
    return (code: string | null) => {
        if (!code?.length) return t(`validation.code.required`);
        try {
            parseInt(code, 10);
            return true;
        } catch (e) {
            return t(`validation.code.pattern`);
        }
    };
}

export function createPasswordValidation(
    t: TFunction,
    fieldName: 'one' | 'two'
): Validate<string | null, string | boolean> {
    return (password: string | null) => {
        if (!password?.length) return t(`validation.password_${fieldName}.required`);
        return true;
    };
}

export const getErrorMessageByCode = (t: TFunction<'common'>, success: boolean, code?: string | null) => {
    if (code) {
        return t(`login_form.errors.${code}`);
    }
    if (!success) {
        return t('login_form.errors.something_went_wrong');
    }

    return null;
};

export const getErrorMessageByName = (
    t: TFunction<'common'>,
    tNamespacePrefix: string,
    errors: FieldErrors,
    name: string
) => {
    const error = errors[name];
    if (!error) return null;
    if (error.message) return error.message.toString();
    return (
        t(`${tNamespacePrefix}.validation.${name}.${error.type}`) || t(`${tNamespacePrefix}.validation.${error.type}`)
    );
};
