export const PROPERTY_FEATURES = {
    balcony: 'balcony',
    terrace: 'terrace',
    elevator: 'elevator',
    garden: 'garden',
    parking: 'parking',
};

export enum ExternalPortals {
    IS24 = 'IS24',
    KLEINANZEIGEN = 'KLEINANZEIGEN',
    IMMOWELT = 'IMMOWELT',
}

export const externalPortalsPublishSupportList = [
    ExternalPortals.IMMOWELT,
    ExternalPortals.IS24,
    // ExternalPortals.KLEINANZEIGEN
];
