import { shuffle } from '@/lib/utilities';
import { BASE_PATTERN, BaseConfiguration, COLORS_VARIATIONS, PATTERN_VARIATIONS } from './types';

export function idToVariant(id?: string): Pick<BaseConfiguration, 'pattern' | 'colors'> {
    if (!id?.length) {
        const date = new Date();
        return { pattern: patternVariant(date.getDate()), colors: colorVariant(date.getDay()) };
    }
    try {
        const start = parseInt(id.slice(0, Math.floor(id.length / 2)), 36);
        const end = parseInt(id.slice(Math.floor(id.length / 2)), 36);

        return { pattern: patternVariant(end), colors: colorVariant(start) };
    } catch (e) {
        const date = new Date();
        return { pattern: patternVariant(date.getDate()), colors: colorVariant(date.getDay()) };
    }
}
export function colorVariant(variant = 0) {
    return COLORS_VARIATIONS[variant % COLORS_VARIATIONS.length];
}

export function patternVariant(variant = 0) {
    return PATTERN_VARIATIONS[variant % PATTERN_VARIATIONS.length];
}

export function flip<T>(base: T[][]): (value: unknown, index: number) => T[][] {
    return (value, index) => {
        const front: T[][] = base.slice(0, index + 1);
        const back: T[][] = base.slice(index + 1, BASE_PATTERN.length);
        return [...back, ...front];
    };
}

export function createList(animate: number | 'all', width: number, height: number): [number, number][] {
    const list = Array.from({ length: height }).flatMap((_, y) => {
        return Array.from({ length: width }, (_, x) => [x, y] as [number, number]);
    });

    return shuffle(animate === 'all' ? list : list.slice(0, animate));
}
