import { Button } from '@/components/common';
import { Step, StepProps } from '../types';
import { TFunction } from 'react-i18next';
import { RatingType } from '@/lib/api/bff';
import { FieldValues } from 'react-hook-form';
import React from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { Trans } from 'next-i18next';
import { useRouter } from 'next/router';
import classNames from 'classnames';

import styles from '../registration.module.scss';

export function SuccessPage(props: StepProps) {
    const { otherValues } = props || {};
    const { contact } = otherValues || {};
    const { qualified, rating = 'C' } = contact || {};

    if (!qualified) return <Unrated {...props} />;
    if (rating === RatingType.C) return <Rated {...props} />;

    return <TopRated {...props} />;
}

SuccessPage.headlineFunction = () => {
    return '';
};

function isQualified(rating?: string | RatingType | null) {
    return !!rating;
}

const showMetaSearch = isQualified;

function showCalenly(rating?: string | RatingType | null) {
    return rating === RatingType.A || rating === RatingType.B;
}

function calendlyLink(locale: string, region?: string | null) {
    const r = region?.toLowerCase() === 'external' ? 'property' : region?.toLowerCase();

    return `https://www.ownr.eu/${locale.startsWith('en') ? 'appointment' : 'termin'}-${r}`;
}

function Rated({ t, otherValues }: { t: TFunction; searchAgent?: boolean; otherValues?: Record<Step, FieldValues> }) {
    const { city, contact } = otherValues || {};
    const region = city?.region || 'Hamburg';
    const { locale = 'de' } = useRouter();
    const { rating = 'C' } = contact || {};

    const searchPage = `/${locale}/searches`;
    return (
        <div className='mt-3 mb-5 p-0'>
            {showMetaSearch(rating) && (
                <>
                    <div className='mb-4'>
                        <p className='text-caption-bold'> {t('success.next_step_meta_search_caption')}</p>
                        <p className='mb-4'>{t('success.next_step_meta_search')}</p>
                    </div>
                    <div className='mb-6'>
                        <Button type='outline' external={searchPage} glyph='arrow-right'>
                            {t('success.next_step_meta_search_button', { city: region })}
                        </Button>
                    </div>
                </>
            )}
            {showCalenly(rating) && (
                <>
                    <div className='mb-4'>
                        <p className='text-caption-bold'> {t('success.next_step_calendly_caption')}</p>
                        <p>{t('success.next_step_calendly')}</p>
                    </div>
                    <div className='mb-6'>
                        <Button type='outline' external={calendlyLink(locale, region)} glyph='arrow-right'>
                            {t('success.next_step_calendly_button')}
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
}

function TopRated({
    t,
    otherValues,
}: {
    t: TFunction;
    searchAgent?: boolean;
    otherValues?: Record<Step, FieldValues>;
}) {
    const { contact } = otherValues || {};
    const { purchasePriceMax = null } = contact || {};
    const { locale = 'de' } = useRouter();
    const decimalFormatter = new Intl.NumberFormat(locale, { style: 'decimal' });

    return (
        <div className='mt-3 mb-5 p-0'>
            <div className='mb-6'>
                <p className='text-copy-l' style={{ fontWeight: 700, fontFamily: 'Source Serif Pro' }}>
                    {t('success.headline_qualified')}{' '}
                </p>
                {purchasePriceMax !== null && purchasePriceMax > 200000 && (
                    <Trans
                        i18nKey='success.headline_qulified_subtext'
                        ns='register'
                        values={{ price: decimalFormatter.format(purchasePriceMax) }}
                    />
                )}
            </div>

            <div className='mb-6'>
                <Alert className={classNames('container p-3', styles['alert'])}>
                    <Row>
                        <Col xs='auto' className='pe-0 ps-4'>
                            <i className={classNames('icon icon-envelope', styles['alert__icon'])} />
                        </Col>
                        <Col className='me-2'>{t(`success.confirm_email`)}</Col>
                    </Row>
                </Alert>
            </div>
        </div>
    );
}

function Unrated({ t, otherValues }: { t: TFunction; otherValues?: Record<Step, FieldValues> }) {
    const { contact } = otherValues || {};
    const { newsletter = false } = contact || {};
    return (
        <div className='mt-3 mb-5 p-0'>
            <div className='mb-4'>
                <p className='text-caption-bold'> {t('success.low_rating_caption')}</p>
                <p>{t(`success.low_rating_${newsletter ? 'with_' : 'without_'}newsletter`)}</p>
            </div>
            <div className='mb-6'>
                <Button type='outline' external='/' glyph='arrow-right'>
                    {t('success.homepage')}
                </Button>
            </div>
        </div>
    );
}
