import { useEffect, useState } from 'react';
import { usePropertiesClient } from '@/lib/api';
import useSWR from 'swr';
import { METADATA } from '@/lib/constants/paths';
import { notify } from '@/lib/utilities/logger';

export const useEnhancedDescriptionStatus = (
    propertyId: string,
    shouldEnhance: boolean
): { status: 'DONE' | 'IN PROGRESS'; isLoading: boolean; error?: Error | null } => {
    const DESCRIPTION_STATUS_FETCH_INTERVAL_MS = 10000;
    const RETRY_LIMIT = 3;
    const api = usePropertiesClient();
    const [status, setStatus] = useState<'DONE' | 'IN PROGRESS'>(shouldEnhance ? 'IN PROGRESS' : 'DONE');
    const [refreshInterval, setRefreshInterval] = useState<number>(DESCRIPTION_STATUS_FETCH_INTERVAL_MS);
    const [retryCount, setRetryCount] = useState<number>(RETRY_LIMIT);
    const [shouldFetch, setShouldFetch] = useState(false);

    if (!propertyId) {
        notify('Property Id was null!', 'Enhance property description');
    }

    const { data, error, isLoading } = useSWR(
        shouldFetch && propertyId && shouldEnhance && status !== 'DONE' ? [METADATA, propertyId] : null,
        async () => {
            const { data } = await api.GET(METADATA, {
                params: { path: { propertyID: propertyId.toString() } },
            });

            return data;
        },
        {
            refreshInterval,
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            refreshWhenHidden: false,
            shouldRetryOnError: false,
            onSuccess: () => {
                setRetryCount(retryCount - 1);
                if (retryCount === 0) {
                    setRefreshInterval(0);
                }
            },
        }
    );

    useEffect(() => {
        const timer = setTimeout(() => {
            setShouldFetch(true);
        }, DESCRIPTION_STATUS_FETCH_INTERVAL_MS);

        return () => {
            clearTimeout(timer);
        };
    }, []);

    useEffect(() => {
        if (data && data.enhancementsDone) {
            setStatus('DONE');
        }
    }, [data]);

    return {
        status,
        isLoading,
        error,
    };
};
