import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { TFunction } from 'react-i18next';

import { PropertySearchOrderBy, WithRegions } from '@/lib/api/bff';

import {
    areSearchQueryParamsEmpty,
    asQuery,
    getDefaultSearch,
    getSearchParamsFromFields,
    mapSearchFilterToOffMarketFilter,
    setSearchFields,
} from './utils';

import { FormValuesType } from './types';
import { SearchFilters } from './components/SearchFilters';
import { useOffMarketProperties, useProperties, useSavedSearch } from './hooks';
import { PropertiesGrid } from '../../properties-grid';
import { notify } from '@/lib/utilities/logger';
import { Loader } from '@/components/common';
import { LoadMoreButton } from './components/LoadMoreButton';
import { gtm } from '@/lib/utilities';
import { useAccountId } from '../../users';
import { Properties } from '@/components/types';
import { SearchPropertyFilter } from '@/lib/api/properties/types';

export function SearchPage(props: WithRegions) {
    const router = useRouter();
    const [displayedProperties, setDisplayedProperties] = useState<Properties>([]);
    const { savedSearch, isLoading: isSavedSearchLoading } = useSavedSearch();
    const { regions = [] } = props;
    const [touched, setTouched] = useState<Set<string>>(new Set());
    const accountId = useAccountId();

    const onFieldTouched = (fieldName: string) => {
        if (!touched.has(fieldName)) {
            setTouched(current => current.add(fieldName));
            gtm.event(gtm.EVENTS.SEARCH_FIELD_TOUCHED, { category: gtm.CATEGORIES.SEARCH, label: fieldName });
        }
    };

    const {
        register,
        control,
        handleSubmit,
        formState: { isValid, isSubmitting, errors, isDirty },
        watch,
        setValue,
        getValues,
    } = useForm<FormValuesType>({
        mode: 'onChange',
        defaultValues: getDefaultSearch(router),
    });

    useEffect(() => {
        if (areSearchQueryParamsEmpty(router)) {
            if (isSavedSearchLoading) {
                return;
            }

            if (savedSearch) {
                setSearchFields(setValue, {
                    ...savedSearch,
                    maxLeaseRate: Math.min(savedSearch.maxLeaseRate, 1000000),
                    minLeaseRate: Math.min(savedSearch.minLeaseRate, 1000000),
                    minSpace: Math.min(savedSearch.minSpace, 1000),
                });
            }
        }

        handleSubmit(onSubmit)();
    }, [savedSearch, isSavedSearchLoading]);

    const {
        properties,
        error,
        isLoading: isLoadingPartnersProperties,
        nextOffset,
        trigger: partnersSearchTrigger,
    } = useProperties();
    const { offMarket, error: offMarketError, isLoading: isLoadingOffMarket, trigger } = useOffMarketProperties();

    if (offMarketError) {
        notify(offMarketError, 'off-market/search');
    }

    useEffect(() => {
        if (!properties || !properties?.length) {
            return;
        }
        setDisplayedProperties((prev: Properties) => [...(prev || []), ...properties]);
    }, [properties]);

    useEffect(() => {
        if (!offMarket || !offMarket?.length) {
            return;
        }
        setDisplayedProperties((prev: Properties) => [...offMarket, ...(prev || [])]);
    }, [offMarket]);

    const onSubmit = (fields: FormValuesType) => {
        try {
            const searchParams = getSearchParamsFromFields(fields);
            const currentOffset = searchParams.offset;
            gtm.event(gtm.EVENTS.SEARCH_INITIATED, { category: gtm.CATEGORIES.SEARCH, value: searchParams });
            router.push({ query: asQuery(fields) }, undefined, { shallow: true });
            if (isLoadingPartnersProperties || isLoadingOffMarket) return;

            const offMarketFilter = mapSearchFilterToOffMarketFilter(searchParams, accountId);
            partnersSearchTrigger({ ...(offMarketFilter as SearchPropertyFilter), offset: currentOffset });

            // Triggers offMarket search only one time
            // Since we don't have pagination there!
            if (!currentOffset) trigger(offMarketFilter);
        } catch (e) {
            notify(e);
        }
    };

    const handleSearchBtnClick = () => {
        setValue('offset', 0);
        setDisplayedProperties([]);
        handleSubmit(onSubmit)();
    };

    const handleGetMoreProperties = () => {
        if (!nextOffset) {
            return;
        }
        setValue('offset', nextOffset);
        gtm.event(gtm.EVENTS.SEARCH_LOAD_MORE_CLICKED, { category: gtm.CATEGORIES.SEARCH, value: nextOffset });
        handleSubmit(onSubmit)();
    };

    const handleOrderSelect = (option: PropertySearchOrderBy) => {
        setDisplayedProperties([]);
        setValue('offset', 0);
        setValue('orderBy', option);
        gtm.event(gtm.EVENTS.SEARCH_ORDER_CHANGED, { category: gtm.CATEGORIES.SEARCH, value: option });
        handleSubmit(onSubmit)();
    };

    return (
        <div>
            <SearchFilters
                register={register}
                watch={watch}
                setValue={setValue}
                control={control}
                onSearchBtnClick={handleSearchBtnClick}
                onOrderOptionClick={handleOrderSelect}
                isSubmitting={isSubmitting}
                isValid={isValid && isDirty}
                getValues={getValues}
                onFieldTouched={onFieldTouched}
                errors={errors}
                regions={regions}
            />
            <Results
                displayedProperties={displayedProperties}
                isLoading={isLoadingPartnersProperties || isSavedSearchLoading || isLoadingOffMarket}
                error={error && offMarketError}
                onGetMorePropertiesClick={handleGetMoreProperties}
                nextOffset={nextOffset}
            />
        </div>
    );
}

const Results = ({
    displayedProperties,
    isLoading,
    onGetMorePropertiesClick,
    nextOffset,
    error,
}: {
    displayedProperties: Properties | null;
    isLoading: boolean;
    onGetMorePropertiesClick: () => void;
    nextOffset?: number | null;
    error: any;
}) => {
    const { t } = useTranslation(['myOwnr', 'properties']) as unknown as { t: TFunction<string, undefined> };

    if (error) {
        return (
            <Container>
                <Row className='justify-content-md-center m-6'>
                    <Col md='auto'>{t('error.something_went_wrong', { ns: 'properties' })}</Col>
                </Row>
            </Container>
        );
    }

    if (!isLoading && displayedProperties?.length === 0) {
        return (
            <Container>
                <Row className='justify-content-md-center m-6'>
                    <Col md='auto'>{t('nothing_was_found', { ns: 'properties' })}</Col>
                </Row>
            </Container>
        );
    }

    return (
        <>
            {displayedProperties && (
                <Container>
                    <Row>
                        <PropertiesGrid properties={displayedProperties} />
                    </Row>
                </Container>
            )}
            {isLoading && <Loader />}
            {!isLoading && (nextOffset || 0 > 0) && <LoadMoreButton onClick={onGetMorePropertiesClick} />}
        </>
    );
};
