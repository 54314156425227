import { BaseConfiguration, CELL_SIZE, DELAY, DURATION, GREY, HEIGHT, ORIENTATIONS, WIDTH } from './types';
import { colorVariant, createList, patternVariant } from './util';

export type Orientation = 'north' | 'east' | 'south' | 'west';
export type Position = { x: number; y: number };

function animation(
    x: number,
    y: number,
    animationOrder: number,
    numberAnimated: number,
    rotation: number,
    opposite: number,
    rotationCenter: string,
    initialDelay = DELAY,
    interval = DELAY,
    duration = DURATION
): string {
    if (animationOrder < 0) return '';
    const startA =
        animationOrder < 1
            ? `${initialDelay}s; animation-${numberAnimated - 1}-b.end+${interval}s`
            : `animation-${animationOrder - 1}-a.end+${interval}s`;
    const startB =
        animationOrder < 1
            ? `animation-${numberAnimated - 1}-a.end+${interval}s`
            : `animation-${animationOrder - 1}-b.end+${interval}s`;
    return `<animateTransform id="animation-${animationOrder}-a" begin="${startA}"
                dur="${duration}s"
                attributeName='transform'
                type='rotate'
                from="${rotation} ${rotationCenter}"
                to="${opposite} ${rotationCenter}"
                repeatCount='1'
                fill='freeze'
            />
            <animateTransform id="animation-${animationOrder}-b" begin="${startB}"
                dur="${duration}s"
                attributeName='transform'
                type='rotate'
                from="${opposite} ${rotationCenter}"
                to="${rotation} ${rotationCenter}"
                repeatCount='1'
                fill='freeze'
            />`;
}

function lockSymbolUse(
    id: string,
    orientation: Orientation,
    x: number,
    y: number,
    fill: string,
    animationOrder: number,
    numberAnimated: number,
    initialDelay: number,
    interval: number,
    duration: number
) {
    const rotation = ORIENTATIONS[orientation];
    const opposite = (animationOrder % 2 ? rotation + 180 : rotation + 180) % 360;
    const xOrigin = x * CELL_SIZE + CELL_SIZE / 2;
    const yOrigin = y * CELL_SIZE + CELL_SIZE / 2;
    const rotationCenter = `${xOrigin} ${yOrigin}`;

    return `<use id="${id}" href="#symbol" x="${x * CELL_SIZE + (CELL_SIZE - WIDTH) / 2}"  y="${
        y * CELL_SIZE + (CELL_SIZE - HEIGHT) / 2
    }" transform="rotate(${rotation} ${rotationCenter})" fill="${fill}">${animation(
        x,
        y,
        animationOrder,
        numberAnimated,
        rotation,
        opposite,
        rotationCenter,
        initialDelay,
        interval,
        duration
    )}</use>`;
}

export function lockSymbols(width: number, height: number, configuration: BaseConfiguration) {
    const {
        pattern = patternVariant(),
        colors = colorVariant(),
        animate = [],
        interval = DELAY,
        initialDelay = DELAY,
        duration = DURATION,
        cellSizeMultiplier = 1,
    } = configuration || {};
    const uses = [];
    const animateList: [number, number][] = (
        animate === undefined ? [] : Array.isArray(animate) ? animate : createList(animate, width, height)
    ).filter(([x, y]) => x >= 0 && y >= 0 && x < width && y < height);

    for (let y = 0; y < height; y++) {
        const orientations = pattern[y % pattern.length] || [];
        const rowColors = colors[y % colors.length] || [];
        for (let x = 0; x < width; x++) {
            const orientation = orientations[x % orientations.length];
            const color = rowColors[x % rowColors.length] || GREY;
            const animationOrder = animateList.findIndex(([x1, y1]) => x1 === x && y1 === y);

            const use = lockSymbolUse(
                `symbol_${x + 1}_${y + 1}`,
                orientation,
                x,
                y,
                color,
                animationOrder,
                animateList.length,
                initialDelay,
                interval,
                duration
            );
            if (use) uses.push(use);
        }
    }
    return `<svg width="${width * CELL_SIZE * cellSizeMultiplier}" height="${
        height * CELL_SIZE * cellSizeMultiplier
    }" viewBox="0 0 ${width * CELL_SIZE} ${
        height * CELL_SIZE
    }" fill="none" xmlns="http://www.w3.org/2000/svg"><defs><path id="symbol" d="M63.9055 0.847992C27.6041 0.847992 0 26.7129 0 60.4497C0 102.433 40.839 117.802 46.7001 117.802V86.8769C37.6248 81.0667 32.1418 71.133 32.3309 60.4497C32.1418 43.7687 45.7548 30.274 62.3929 30.0866C62.9601 30.0866 63.3383 30.0866 63.9055 30.0866C80.7326 29.3369 94.9128 42.4567 95.6691 59.1377C95.6691 59.5126 95.6691 60.0749 95.6691 60.4497C96.0473 71.3205 90.3752 81.4415 81.1108 86.8769V117.802C86.7829 117.802 128 102.433 128 60.4497C128 26.9003 100.018 0.847992 63.9055 0.847992Z"/></defs>
    ${uses.join('\n')}</svg>`;
}
