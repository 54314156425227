import { useRouter } from 'next/router';
import classNames from 'classnames';
import styles from './language-selector.module.scss';
import { LanguageSelectorProps } from './types';
import Link from './link';
import { ParsedUrlQueryInput } from 'node:querystring';
import { Dropdown } from 'react-bootstrap';
import React from 'react';

const LANGUAGES = ['de', 'en'];

export function LanguageSelector(props: LanguageSelectorProps) {
    const { className, localizations = {}, whiteBackground } = props || {};
    const { locale: currentLocale, asPath, pathname, query } = useRouter();

    const params = asPath.split('?')[1];

    const queryParams = Object.keys(query);
    const dynamicParams: ParsedUrlQueryInput = {};
    queryParams.forEach(p => {
        if (p === 'slug') return;
        if (!params || !params.includes(p)) dynamicParams[p] = query[p];
    });

    return (
        <div className={classNames(className, 'd-flex', styles['language-selector'])}>
            <Dropdown>
                <Dropdown.Toggle
                    variant='warning'
                    color='white'
                    size='sm'
                    className={classNames('d-flex align-items-center gap-1 px-2', whiteBackground && 'bg-white')}
                >
                    <i className={classNames(styles[currentLocale], 'rounded-circle')}></i>
                    <p className='m-0'>
                        {typeof currentLocale === 'string' ? currentLocale.toUpperCase() : currentLocale}
                    </p>
                </Dropdown.Toggle>

                <Dropdown.Menu variant='velvet' color='velvet' className={styles['menu-overrides']}>
                    {LANGUAGES.map(locale => (
                        <Dropdown.Item
                            key={locale}
                            className='d-flex gap-2 p-0'
                            as='span'
                            data-cy={`ownr-'side-'${locale}`}
                        >
                            <Link
                                target={{ slug: localizations[locale] || pathname }}
                                parameter={params}
                                locale={locale}
                                dynamicParams={dynamicParams}
                                className='text-decoration-none text-black text-form d-flex align-items-center gap-1 px-3 py-1'
                            >
                                <i className={classNames(styles[locale], 'rounded-circle')}></i>
                                {locale?.toUpperCase()}
                            </Link>
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}
