import { Col, Container, Row } from 'react-bootstrap';
import styles from './styles.module.scss';
import { useTranslation } from 'next-i18next';
import { TFunction } from 'react-i18next';
import { PropertyCard } from './PropertyCard';
import { WithContextMenu } from './context-menu';
import { EyeSlash } from 'react-bootstrap-icons';
import { Properties as PropertiesType } from '@/components/types';

type PropertiesGridProps = {
    properties?: PropertiesType;
    withLeasingRequest?: boolean;
    withContextMenu?: boolean;
    handleHideProperty?: (id: string) => void;
};

export const Properties = ({
    properties,
    withLeasingRequest,
    withContextMenu,
    handleHideProperty,
}: PropertiesGridProps) => {
    const { t } = useTranslation('properties') as unknown as {
        t: TFunction<string>;
    };

    if (!properties) {
        return null;
    }

    return (
        <>
            {properties.map((item, index) => {
                if (!item) {
                    return null;
                }

                return (
                    <Col md='4' id={styles['property_card']} className='mb-6 mr-6' key={`${item.id}-${index}`}>
                        <div style={{ cursor: 'pointer' }} id={'' + item?.id}>
                            {withContextMenu ? (
                                <WithContextMenu
                                    options={[
                                        {
                                            icon: <EyeSlash className='text-velvet' />,
                                            text: t('hide_property'),
                                            disabled: 'isPrivate' in item && item.isPrivate,
                                            onClick: () => (handleHideProperty ? handleHideProperty(item.id) : {}),
                                        },
                                    ]}
                                >
                                    <PropertyCard property={item} withLeasingRequest={withLeasingRequest} />
                                </WithContextMenu>
                            ) : (
                                <PropertyCard property={item} withLeasingRequest={withLeasingRequest} />
                            )}
                        </div>
                    </Col>
                );
            })}
        </>
    );
};

export function PropertiesGrid(props: PropertiesGridProps) {
    return (
        <Container>
            <Row>
                <Properties {...props} />
            </Row>
        </Container>
    );
}
