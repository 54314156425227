import React, { ReactNode } from 'react';
import { Divider } from '../Divider';
import { useTranslation } from 'next-i18next';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { DescriptionSpinner } from '../InfoSection';

export const WithReadMore = ({
    isOpened,
    children,
    callback,
    showLoader,
}: {
    isOpened: boolean;
    children: ReactNode;
    callback: () => void;
    showLoader?: boolean;
}) => {
    const { t } = useTranslation('common');
    const BLOCK_HEIGHT_PX = 150;

    return (
        <div className='d-flex flex-column'>
            <div className='overflow-hidden' style={{ height: isOpened ? 'auto' : `${BLOCK_HEIGHT_PX}px` }}>
                {children}
            </div>
            <div className={classNames(styles.readMore, 'd-flex align-items-center gap-3')}>
                <div className='d-flex align-items-center link-velvet pb-1'>
                    <i className={`fs-3 icon icon-angle-${isOpened ? 'up' : 'down'}`} />
                    <p
                        className='text-nowrap m-0'
                        onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            callback();
                        }}
                    >
                        {t(isOpened ? 'read_less' : 'read_more')}
                    </p>
                    {showLoader && <DescriptionSpinner />}
                </div>
                <Divider />
            </div>
        </div>
    );
};
