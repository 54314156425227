import usePartnerLogo from './usePartnerLogo';
import { PartnersLogoType } from '@/lib/api/graphcms';
import { CSSProperties } from 'react';

export type LogoProps = {
    partnersLogo?: PartnersLogoType;
    light?: boolean;
    shadow?: boolean;
    style?: React.CSSProperties;
};

export function Logo({ partnersLogo = [], light = false, shadow = false, style }: LogoProps) {
    const partnerLogo = usePartnerLogo(partnersLogo);

    if (partnerLogo) {
        return (
            <div
                data-cy='partner-logo'
                style={{
                    width: '100%',
                    height: '100%',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'left',
                    backgroundImage: `url(${partnerLogo})`,
                }}
            ></div>
        );
    }

    const textFill = light ? '#32384B' : '#FFFFFF';
    const filterStyle: CSSProperties = shadow
        ? light
            ? { filter: 'drop-shadow(0px 0px 3px rgb(255 255 255 / 0.4))' }
            : { filter: 'drop-shadow(0px 0px 3px rgb(0 0 0 / 0.4))' }
        : {};

    return (
        <svg
            width='100%'
            viewBox='0 0 160 33'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={style ? { ...filterStyle, ...style } : filterStyle}
        >
            <path
                d='M150.797 23.9185V32.2382C153.813 32.9662 156.985 32.9662 160.001 32.2382V23.9185C156.985 24.5944 153.813 24.5944 150.797 23.9185Z'
                fill='#FA695A'
            />
            <path
                d='M45.0826 32.239L35.5149 0.987969H43.8346L49.1905 17.9914L53.8183 0.987969H60.6821L65.31 17.9914L70.6658 0.987969H78.9855L69.3658 32.239H61.7741L57.1462 16.2235L52.5704 32.239H45.0826ZM82.2094 32.239V0.987969H89.8532L102.957 17.7314V0.987969H111.121V32.239H104.153L90.3732 14.8195V32.239H82.2094ZM116.632 32.239V0.987969H132.596C135.56 0.935971 138.42 2.07994 140.552 4.10787C142.684 6.08381 143.88 8.83973 143.827 11.6996C143.931 15.4435 141.852 18.9274 138.472 20.5914L146.271 32.135H136.496L130.152 22.4113H125.16V32.135L116.632 32.239ZM125.16 15.7035H131.66C133.532 15.8595 135.144 14.5075 135.352 12.6876C135.352 12.4796 135.352 12.2716 135.352 12.0636C135.508 10.3997 134.26 8.89172 132.596 8.73573C132.544 8.73573 132.492 8.73573 132.44 8.73573H125.212L125.16 15.7035ZM17.5754 0C7.59176 0 0 7.17578 0 16.5355C0 28.1831 11.2316 32.447 12.8436 32.447V23.8673C10.3477 22.2553 8.83972 19.4994 8.89172 16.5355C8.83972 11.9076 12.5836 8.16375 17.1595 8.11175C17.3155 8.11175 17.4195 8.11175 17.5754 8.11175C22.2033 7.90375 26.1032 11.5436 26.3112 16.1715C26.3112 16.2755 26.3112 16.4315 26.3112 16.5355C26.4152 19.5514 24.8552 22.3593 22.3073 23.8673V32.447C23.8673 32.447 35.2029 28.1831 35.2029 16.5355C35.2029 7.22778 27.5071 0 17.5754 0Z'
                fill={textFill}
            />
        </svg>
    );
}
