import React from 'react';

export default function Plus(props: object) {
    return (
        <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M10.0814 11.9188L10.0814 21.3949L11.919 21.3949L11.919 11.9188L21.3952 11.9188L21.3951 10.0812L11.919 10.0812L11.919 0.605086L10.0814 0.605085L10.0814 10.0812L0.605318 10.0812L0.605318 11.9188L10.0814 11.9188ZM10.0814 11.9188L10.0814 10.0812L11.919 10.0812L11.919 11.9188L10.0814 11.9188Z'
                fill='currentColor'
            />
            <path
                d='M10.0814 10.0812L10.0814 11.9188L11.919 11.9188L11.919 10.0812L10.0814 10.0812Z'
                fill='currentColor'
            />
        </svg>
    );
}
