import { useRouter } from 'next/router';
import { Locale, Stage, useGraphCms } from '@/lib/api/graphcms';
import useSWR from 'swr';
import { notify } from '@/lib/utilities/logger';

export const useRefurbishmentGroups = () => {
    const { locale = 'de', isPreview } = useRouter();

    const graphCms = useGraphCms();
    const {
        data: { refurbishmentGroups } = {},
        error,
        isLoading,
    } = useSWR(
        () => ['cms/refurbishment', locale],
        () =>
            graphCms.RefurbishmentGroups({
                locale: locale as Locale,
                stage: isPreview ? Stage.DRAFT : Stage.PUBLISHED,
            })
    );
    if (error) {
        notify(error, 'cms/refurbishment');
    }

    return {
        refurbishmentGroups,
        isLoading,
        error,
    };
};
