import useSWR from 'swr';
import { notify } from '@/lib/utilities/logger';
import { Attendee } from '@/lib/api/lease-requests/types';
import { useLeaseRequestsClient } from '@/lib/api';
import { ATTENDEES } from '@/lib/constants/paths';
import { logErrorResponse } from '@/lib/api/utils';
import { useAccountId } from '@/components/users';

const fetchAttendees = async (slotIds: string[], api, accountId) => {
    const attendees = new Map<string, Attendee[]>();

    const promises = slotIds.map(async slotId => {
        const requestData = { body: { slotId } };
        const response = await api.POST(ATTENDEES, requestData);
        if (response?.status >= 400) logErrorResponse(response, accountId, requestData, response.error);
        return { slotId, response: response.data as Attendee[] };
    });

    const results = await Promise.all(promises);

    results.forEach(({ slotId, response }) => {
        attendees.set(slotId, response);
    });

    return attendees;
};

export const useAttendees = (slotIds: string[], propertyId: string) => {
    const api = useLeaseRequestsClient();
    const accountId = useAccountId();

    const { data, error, isLoading } = useSWR(
        slotIds?.filter(Boolean)?.length > 0 ? [ATTENDEES, propertyId] : null,
        () => fetchAttendees(slotIds, api, accountId)
    );

    if (error) {
        notify(error, ATTENDEES);
    }

    return {
        data,
        isLoading,
        error,
    };
};
