import { Property } from '@/lib/api/lease-requests/types';
import { useTranslation } from 'next-i18next';
import { getFullAddress } from '@/lib/utilities/lib/properties';
import { Row } from 'react-bootstrap';
import { SignTurnLeft } from 'react-bootstrap-icons';

interface AddressProps {
    property: Property;
    className?: string;
    withIcon?: boolean;
}

export const Address = ({ property, className, withIcon }: AddressProps) => {
    const { t } = useTranslation(['properties', 'common']);
    const address = getFullAddress(property?.location);

    return (
        <Row className={className} data-cy='address'>
            {withIcon && (
                <div className='d-flex gap-2 align-items-baseline w-auto'>
                    <SignTurnLeft className='' />
                    <span>{t('address')}:</span>
                </div>
            )}
            <div className='d-flex flex-wrap w-auto align-self-end gap-2'>{address}</div>
        </Row>
    );
};
