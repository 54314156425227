import classNames from 'classnames';
import { QuickNavigationProps } from './types';
import { RegisterButton } from '@/components/navigation/components/RegisterButton';
import { LoginButton } from '@/components/navigation/components/LoginButton';
import { MyAccountButton } from '@/components/navigation/components/MyAccountButton';
import { useSession } from 'next-auth/react';
import { Link } from '@/components/design-system';
import { useRouter } from 'next/router';
import { getPartner, getPublicLinks, isCurrentPage } from '../utils';
import styles from './quick-nav.module.scss';
import { hasRole, UserRoles } from '@/lib/security';

export function QuickNavigation({ navigationItems = [], locale, light = false }: QuickNavigationProps) {
    const router = useRouter();
    const { asPath } = router;
    const { status, data } = useSession();
    const partner = getPartner(data, asPath);
    const isBroker = hasRole(data, UserRoles.Broker);
    const links = getPublicLinks(navigationItems, data, partner, isBroker).map(link => ({
        ...link,
        selected: isCurrentPage(router, link.href),
    }));

    return (
        <nav id='quicknav'>
            <ul className='navbar-nav me-auto mb-lg-0'>
                {links.map(item => {
                    return (
                        <li data-cy={`ownr-${item.text}`} key={item.text} className='nav-item d-none d-xl-block'>
                            <Link
                                className={classNames(
                                    item.selected ? styles['selected'] : 'text-gray-900',
                                    styles['nav-link'],
                                    'mx-3 text-decoration-none text-nowrap'
                                )}
                                href={item.href}
                                locale={locale}
                            >
                                {item.text}
                            </Link>
                        </li>
                    );
                })}
                {status === 'loading' ? null : status === 'authenticated' ? (
                    <span className='d-none d-xl-block align-content-center'>
                        <MyAccountButton />
                    </span>
                ) : (
                    <>
                        <span className='align-content-center'>
                            <RegisterButton />
                        </span>
                        <span className='d-none d-xl-block align-content-center'>
                            <LoginButton light={light} />
                        </span>
                    </>
                )}
            </ul>
        </nav>
    );
}
