import { useAppointmentSlots } from '@/hooks/useAppointmentSlots';
import { Loader } from '@/components/common';
import React, { useRef, useState } from 'react';
import { useAttendees } from '@/hooks/useAttendees';
import { Slot } from '@/components/slots/list/components/Slot';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { WidgetContainer } from '@/components/design-system/widget-container';
import moment from 'moment';
import { AppointmentSlot } from '@/lib/api/lease-requests/types';
import { Collapse } from 'react-bootstrap';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';

export const SlotsList = ({ propertyId }: { propertyId: string }) => {
    const { t } = useTranslation('metasearch');
    const { appointmentSlots, isLoading } = useAppointmentSlots(propertyId);
    const [passedAppointmentsOpened, setPassedAppointmentsOpened] = useState(false);
    const { data, isLoading: isAttendeesLoading } = useAttendees(
        (appointmentSlots || []).map(slot => slot.id),
        propertyId
    );

    const container = useRef<HTMLDivElement>();

    if (isLoading || isAttendeesLoading) {
        return <Loader includeMargins={false} />;
    }

    if (!appointmentSlots?.length) {
        return null;
    }

    const firstPastAppointmentIndex = appointmentSlots
        .sort((a, b) => (a.startDate >= b.startDate ? -1 : 1))
        .findIndex(app => moment.tz(app.startDate, 'Europe/Berlin').isBefore(moment()));

    const passedAppointments = firstPastAppointmentIndex > -1 ? appointmentSlots.slice(firstPastAppointmentIndex) : [];
    const futureAppointments =
        firstPastAppointmentIndex > -1 ? appointmentSlots.slice(0, firstPastAppointmentIndex) : appointmentSlots;

    const appointmentMapFunctionCreator = (isPast: boolean) =>
        function mapAppointment(slot: AppointmentSlot) {
            const { id } = slot;
            const attendees = data.get(id) || [];
            return <Slot isPast={isPast} key={id} slot={slot} attendees={attendees} propertyId={propertyId} />;
        };

    return (
        <WidgetContainer>
            <h4 className='mb-4'>{t('appointments')}</h4>
            <div ref={container} className={classNames(styles['slots-container'])}>
                {futureAppointments.map(appointmentMapFunctionCreator(false))}
                {passedAppointments.length > 0 && (
                    <div className='mt-6'>
                        <div
                            className='body-m mb-4 cursor-pointer user-select-none'
                            onClick={() => {
                                if (container.current && !passedAppointmentsOpened) {
                                    setTimeout(() => {
                                        container.current.scrollTo({
                                            top: container.current.scrollHeight,
                                            behavior: 'smooth',
                                        });
                                    }, 100);
                                }
                                setPassedAppointmentsOpened(prev => !prev);
                            }}
                            aria-expanded={passedAppointmentsOpened}
                        >
                            {t('slot_form.show_past')}
                            {passedAppointmentsOpened ? (
                                <ChevronUp className='ms-3'></ChevronUp>
                            ) : (
                                <ChevronDown className='ms-3'></ChevronDown>
                            )}
                        </div>
                        <Collapse in={passedAppointmentsOpened}>
                            <div>{passedAppointments.map(appointmentMapFunctionCreator(true))}</div>
                        </Collapse>
                    </div>
                )}
            </div>
        </WidgetContainer>
    );
};
