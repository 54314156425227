/* eslint-disable jsx-a11y/anchor-is-valid */
import { advancedLinkFragmentFragment as Record } from '@/lib/api/graphcms';

type AdvancedLinkProps = Pick<Record, 'AdvancedLink_linkText'>;
export function AdvancedLink(props: AdvancedLinkProps) {
    const { AdvancedLink_linkText: linkText } = props;

    const onServer = typeof window === 'undefined';
    if (!onServer) {
        type extendWindow = typeof window & { CCM: { openWidget: () => void } };
        const CCM = (window.window as extendWindow).CCM;
        if (!CCM) return <a href=''>{linkText}</a>;

        return (
            <a
                href='#'
                onClick={() => {
                    CCM.openWidget();
                    return false;
                }}
            >
                {linkText}
            </a>
        );
    }

    return <a href=''>{linkText}</a>;
}
