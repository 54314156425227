import React, { ReactNode } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { InfoCircle } from 'react-bootstrap-icons';
import styles from './styles.module.scss';

export const InfoIcon = ({ content }: { content: ReactNode }) => {
    return (
        <OverlayTrigger
            placement='auto'
            overlay={
                <Tooltip className={styles['info-tooltip']} id='tooltip-top'>
                    {content}
                </Tooltip>
            }
        >
            <InfoCircle className='text-gray-500' style={{ scale: '1.3' }} />
        </OverlayTrigger>
    );
};
