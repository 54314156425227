import { TFunction } from 'react-i18next';
import { FieldValues } from 'react-hook-form';
import { Grid } from '@/lib/api/graphcms';
import { CityForm } from './SubPages/CityForm';
import { DistrictForm } from './SubPages/DistrictForm';
// import { UrgencyForm } from './SubPages/UrgencyForm';
import { PropertyFeaturesForm } from './SubPages/PropertyFeaturesForm';
import { ContactForm } from './SubPages/ContactForm';
import { SuccessPage } from './SubPages/SuccessPage';
import { BrokerRegistrationData, BrokerRegistrationResponse } from '@/lib/api/bff';

export enum PreferenceType {
    DISLIKE = 'DISLIKE',
    LIKE = 'LIKE',
}

export type Preference = {
    key: string;
    type: PreferenceType;
};

export type Location = {
    id: number;
    name: string;
};

export enum PropertyType {
    APARTMENT = 'APARTMENT',
    HOUSE = 'HOUSE',
}

export type CustomerSearchData = {
    region: string;
    otherRegion: string | null;
    locations: Preference[];
    locationNames: Location[];
    propertyTypes: PropertyType[];
    propertySubTypes: Preference[];
    propertyAges: Preference[];
    livingArea: [number, number] | [number];
    rooms: [number, number] | [number];
    features: Preference[];
    timing: string | null;
    monthlyBudget: number | null;
    isBudgetMax: boolean | null;
    propertyURL: string | null;
    referrer: string | null;
};

export type CustomerUserData = {
    email: string;
    salutation: string;
    title?: string;
    firstName: string;
    lastName: string;
    phone?: string;
    password?: string;
    locale: string;
    netIncome?: number | null;
    isNetIncomeMax?: boolean | null;
    newsletter: boolean;
    searchAgent: boolean;
    referrer: string;
};

export type CustomerApplicationData = CustomerSearchData & CustomerUserData;

export type CustomerApplicationError = {
    code: string;
    message?: string | null;
    propertyName?: string | null;
};

export type CustomerApplicationResult = {
    success: boolean;
    level?: string;
    rating?: string | null;
    qualified: boolean;
    purchasePriceMax?: number | null;
    errors?: CustomerApplicationError[];
};

export type RegisterResult = {
    success: boolean;
    errors?: CustomerApplicationError[];
};

export type CustomerApplicationUserFunction = (data: CustomerApplicationData) => Promise<CustomerApplicationResult>;
export type BrokerApplicationFunction = (data: BrokerRegistrationData) => Promise<BrokerRegistrationResponse>;
export type RegisterFunction = (data: CustomerUserData) => Promise<RegisterResult>;

export type FormProps = {
    id?: string;
    highlightColour?: string;
};
export type FetchDistrictsFunction = (url: string, region: string) => Promise<string[]>;

export type StepProps = FormPartProps & {
    maxRooms?: number;
    maxLivingArea?: number;
    selectDislikedDistricts?: boolean;
    customerApplication: CustomerApplicationUserFunction;
};

export type Step = 'city' | 'districts' | 'urgency' | 'features' | 'contact' | 'success';

export type FormPartProps = SubFormProps & {
    navigateForward: (data: FieldValues) => void;
    defaultValues?: FieldValues;
    otherValues?: Record<Step, FieldValues>;
    content: Record<string, Grid>;
    activeRegions?: string[];
    suggestedLocationsByRegion: { [key: string]: { name: string; casafariId: number }[] };
};

export type BrokerContactFormProps = SubFormProps & {
    brokerApplication: BrokerApplicationFunction;
    content: Record<string, Grid>;
};

export type ContactFormQuickProps = SubFormProps & {
    register: RegisterFunction;
    content: Record<string, Grid>;
};

export type SubFormProps = {
    mandatoryFields: string[];
    fieldsToHide: string[];
    formId: (...params: string[]) => string;
    t: TFunction;
};
export type RegistrationState = { [step: string]: { valid: boolean; values: FieldValues } };
export type HeadlineFunction = (values: Record<Step, FieldValues>, step: Step, t: TFunction) => string;
export type StepComponent = (
    | typeof CityForm
    | typeof DistrictForm
    // | typeof UrgencyForm
    | typeof PropertyFeaturesForm
    | typeof ContactForm
    | typeof SuccessPage
) & { headlineFunction?: HeadlineFunction };
export type RegistrationFormProps = {
    customerApplication: CustomerApplicationUserFunction;
    id: string;
    fieldsToHide?: string[];
    mandatoryFields?: string[];
    selectDislikedDistricts?: boolean;
    maxRooms?: number;
    maxLivingArea?: number;
    additionalContent?: Record<string, Grid>;
    trendingDistricts?: Record<string, string[]>;
    maxNetIncome?: number;
    maxMonthlyBudget?: number;
    activeRegions?: string[];
    suggestedLocationsByRegion: { [key: string]: { name: string; casafariId: number }[] };
} & FormProps;

export type BrokerRegistrationFormProps = {
    brokerApplication: BrokerApplicationFunction;
    id: string;
    fieldsToHide?: string[];
    mandatoryFields?: string[];
    additionalContent?: Record<string, Grid>;
} & FormProps;

export type RegistrationFormQuickProps = {
    register: RegisterFunction;
    id: string;
    fieldsToHide?: string[];
    mandatoryFields?: string[];
    additionalContent?: Record<string, Grid>;
} & FormProps;
