import React from 'react';

export default function ChevronRight(props: object) {
    return (
        <svg width='11' height='20' viewBox='0 0 11 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M1.24239 20L5.56556e-08 18.7267L8.51522 10L8.18572e-07 1.27325L1.24239 -1.85304e-06L11 10L1.24239 20Z'
                fill='currentColor'
            />
        </svg>
    );
}
