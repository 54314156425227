import { GetServerSidePropsContext } from 'next';
import { getServerSession, User } from 'next-auth';
import { authOptions } from '@/pages/api/auth/[...nextauth]';
import { Session } from 'next-auth';

type SessionUser = {
    accountId?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    locale?: string | null;
};

export enum UserRoles {
    'Impersonator' = 'Impersonator',
    'Broker' = 'Brokers',
    'LeasingClient' = 'LeasingClients',
    'Microsoft' = 'microsoft',
    'Vobahome' = 'vobahome',
}

export async function checkPermitted(
    context: GetServerSidePropsContext,
    userRole?: UserRoles
): Promise<{
    permitted: boolean;
    redirect?: { redirect: { destination: string; permanent: false } };
    session?: Session | null;
    user?: SessionUser | null;
}> {
    const session = await getServerSession(context.req, context.res, authOptions);

    if (!session || (session && userRole && !hasRole(session, userRole))) {
        const locale = context.locale ?? 'de';
        return {
            permitted: false,
            redirect: {
                redirect: {
                    destination: `/${locale}/login?returnUrl=${locale}${context.resolvedUrl}`,
                    permanent: false,
                },
            },
        };
    }
    return { permitted: true, session, user: session.user as SessionUser };
}

export function hasRole(session: Session | User, role: UserRoles): boolean {
    const { groups = [], impersonating = null } = session
        ? 'user' in session
            ? session?.user
            : (session as User) ?? {}
        : {};
    const g = (impersonating ? [...impersonating?.groups, 'Impersonator'] : groups ?? []).map(g => g.toLowerCase());
    return g.includes(role.toLowerCase()) ?? false;
}

export function checkIsBroker(session?: Session | User) {
    return !!session && hasRole(session, UserRoles.Broker);
}

export function checkIsAdmin(session?: Session | User) {
    return !!session && hasRole(session, UserRoles.Impersonator);
}

export function getUserRating(session: Session): string {
    const { user } = session;
    const { rating, impersonating = null } = user;
    if (impersonating) return impersonating.rating;
    else return rating;
}
