import { Link } from '@/components/design-system';
import { useTranslation } from 'next-i18next';
import { Offcanvas } from 'react-bootstrap';
import { LanguageSelector } from './LanguageSelector';
import styles from './side-nav.module.scss';
import classNames from 'classnames';
import { SidebarNavigationProps } from './types';
import { getPartner, getPrivateLinks, getPublicLinks, isCurrentPage } from '@/components/navigation/utils';
import React from 'react';
import { useSession } from 'next-auth/react';
import { LogoutButton } from '@/components/navigation/components/LogoutButton';
import { LoginButton } from '@/components/navigation/components/LoginButton';
import { useRouter } from 'next/router';
import { hasRole, UserRoles } from '@/lib/security';

export function SidebarNavigation({
    show = false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    handleClose = () => {},
    navigationItems = [],
    localizations = {},
}: SidebarNavigationProps) {
    const { t } = useTranslation('common');
    const { data, status } = useSession();
    const router = useRouter();
    const { asPath } = router;
    const partner = getPartner(data, asPath);
    const isBroker = hasRole(data, UserRoles.Broker);

    const privateLinks =
        status === 'authenticated'
            ? getPrivateLinks(data, t).map(link => ({ ...link, selected: isCurrentPage(router, link.href) }))
            : null;
    const publicLinks = getPublicLinks(navigationItems, data, partner, isBroker).map(link => ({
        ...link,
        selected: isCurrentPage(router, link.href),
    }));

    return (
        <Offcanvas
            show={show}
            onHide={handleClose}
            placement='end'
            backdrop={true}
            className={classNames('bg-white text-black rounded-12-top-left rounded-12-bottom-left', styles['side-nav'])}
        >
            <Offcanvas.Header className='ms-auto pt-9 pe-6' closeButton></Offcanvas.Header>
            <Offcanvas.Body>
                {status !== 'authenticated' && (
                    <div className={classNames('d-flex justify-content-start w-100 mb-5')}>
                        <div onClick={handleClose}>
                            <LoginButton light />
                        </div>
                    </div>
                )}

                <ul className='navbar-nav me-auto mb-2 mb-lg-0 ps-3' onClick={handleClose}>
                    {status === 'authenticated' &&
                        privateLinks?.map(link => {
                            return (
                                <Link
                                    href={link.href}
                                    key={link.href}
                                    className={classNames(
                                        'd-block py-1',
                                        styles['link'],
                                        link.selected ? styles['selected'] : 'text-dark'
                                    )}
                                >
                                    {link.text}
                                </Link>
                            );
                        })}
                    {status === 'authenticated' && <hr className='text-gray-100' />}
                    {publicLinks.map(item => {
                        return (
                            <li key={item.text} className='nav-item' data-cy={`ownr-side-${item.text}`}>
                                <Link
                                    href={item.href}
                                    className={classNames(
                                        'd-block py-1',
                                        styles['link'],
                                        item.selected ? styles['selected'] : 'text-dark'
                                    )}
                                >
                                    {item.text}
                                </Link>
                            </li>
                        );
                    })}
                </ul>
                <div className='ms-3 mt-3'>
                    <LanguageSelector localizations={localizations} />
                </div>
                {status === 'authenticated' ? (
                    <div className='ms-3 mt-8'>
                        <LogoutButton />
                    </div>
                ) : null}
            </Offcanvas.Body>
        </Offcanvas>
    );
}
