import React, { KeyboardEvent, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useRouter } from 'next/router';
import { Trans, useTranslation } from 'next-i18next';
import { FieldValues, useForm } from 'react-hook-form';
import classNames from 'classnames';
import isEmail from 'validator/lib/isEmail';

import { notify } from '@/lib/utilities/logger';

import styles from './login-form.module.scss';
import { NotifiableError } from '@bugsnag/js';
import { ClientSafeProvider, getProviders, getSession, signIn } from 'next-auth/react';
import { Button, Form, FormInput, Link, ErrorAlert } from '@/components/design-system';
import Image from 'next/image';

import vobahome from './Vobahome_final.png';
import { queryParameterAsString } from '@/lib/utilities';
import { getDefaultPageAfterLogin } from '@/components/navigation/utils';

const FORGOT_PASSWORD_PAGE = 'login/forgot-password';

const getProviderIconByName = (name: string) => {
    if (name.toLocaleLowerCase().includes('voba')) {
        return vobahome;
    }
    return null;
};

export const LoginForm = ({
    returnUrl,
    absoluteUrl,
    resendConfirmationCode,
}: {
    returnUrl: string;
    absoluteUrl: (pathname: string) => string;
    resendConfirmationCode: (email: string) => Promise<{ success: boolean; attempts?: number; error?: string }>;
    providers: Record<string, ClientSafeProvider>;
    // do we need it here?
    csrfToken: string;
}) => {
    const router = useRouter();
    const { locale = 'de' } = router;

    const { t } = useTranslation('common');
    const [errorCode, setErrorCode] = useState<string | null>(null);
    const [info, setInfo] = useState<'confirmation_sent' | null>(null);

    const defaultUsernameValue = router.query.username ? router.query.username + '' : '';
    const ssoError = queryParameterAsString(router.query, 'error');

    const [providers, setProviders] = useState(null);
    const ssoProvidersEnabled = providers && Object.keys(providers).some(p => !!getProviderIconByName(p));

    useEffect(() => {
        getProviders().then(providers => setProviders(providers));
    }, []);

    useEffect(() => {
        if (errorCode) window?.scrollTo({ top: 0, behavior: 'smooth' });
    }, [errorCode]);

    useEffect(() => {
        if (ssoError) setErrorCode('unexpected_error');
    }, [ssoError]);

    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors, isValid, isSubmitting },
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            username: defaultUsernameValue,
            password: '',
        },
    });

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.code === 'Enter') {
            handleSubmit(onSubmit)();
        }
    };

    const handleOnClickResendConfirmation = async () => {
        const email = getValues('username');

        if (!email?.length || Array.isArray(email) || !isEmail(email)) {
            setErrorCode('incorrect_email');
            return;
        }

        const { success, error } = await resendConfirmationCode(email);
        if (!success || !!error) {
            setErrorCode('unexpected_error');
        } else {
            setErrorCode(null);
            setInfo('confirmation_sent');
        }
    };

    const onSubmit = async (fields: FieldValues) => {
        if (isSubmitting) return;

        setErrorCode(null);
        setInfo(null);
        try {
            console.time('login');
            const { ok, error } =
                (await signIn('credentials', {
                    username: fields.username,
                    password: fields.password,
                    redirect: false,
                })) ?? {};
            console.timeEnd('login');

            const session = await getSession();
            const defaultPage = getDefaultPageAfterLogin(session);

            if (ok) {
                let target = returnUrl ?? absoluteUrl(`${locale}/${defaultPage}`);
                if (target.indexOf('?') === -1) target += '?al=true';
                else target += '&al=true';
                router.push(target);
            } else {
                setErrorCode(error ?? 'unexpected_error');
            }
        } catch (e: unknown) {
            setErrorCode('unexpected_error');
            notify(e as NotifiableError);
        }
    };

    const getErrorBody = (errorCode: string | undefined) => {
        if (!errorCode) return null;

        if (errorCode === 'user_not_confirmed') {
            return (
                <div>
                    {t('login_form.errors.user_not_confirmed')}
                    <Link
                        href=''
                        onClick={handleOnClickResendConfirmation}
                        className='d-block text-white fw-bold text-decoration-underline'
                    >
                        {t('login_form.resend_confirmation')}
                    </Link>
                </div>
            );
        }

        if (errorCode === 'password_reset_required') {
            return (
                <Trans i18nKey='login_form.errors.password_reset_required' ns='common'>
                    xxx
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <Link
                        className='text-white fw-bold text-decoration-underline'
                        href={`/${locale ?? 'de'}/${FORGOT_PASSWORD_PAGE}`}
                    >
                        Terms
                    </Link>
                    .
                </Trans>
            );
        }

        if (!errorCode.includes('_')) return errorCode;

        return t(`login_form.errors.${errorCode}`);
    };

    return (
        <Container
            className={classNames(
                'px-6 pb-6 d-flex gap-7 flex-column align-items-center design-system',
                styles['login-form']
            )}
            fluid='md'
        >
            <Form className='gap-7 w-100'>
                {errorCode && <ErrorAlert>{getErrorBody(errorCode)}</ErrorAlert>}
                {info && <ErrorAlert>{t(`login_form.info.${info}`)}</ErrorAlert>}
                <div className='text-center'>
                    <h1 className='text-center text-velvet'>{t('login_form.header_welcome')}</h1>
                    <span className='body-l bold'>{t('login_form.header_text')}</span>
                </div>
                <div>
                    <div className='mb-5'>
                        <FormInput
                            id='email'
                            type='email'
                            data-cy='email'
                            placeholder={t('login_form.email')}
                            inputClassName='mb-3'
                            autoFocus={true}
                            label={t('login_form.email') + '*'}
                            tabIndex={1}
                            isInvalid={!!errors['username']}
                            error={errors['username']?.message}
                            {...register('username', {
                                required: true,
                                validate: value => isEmail(value) || t('login_form.errors.incorrect_email'),
                            })}
                        />
                    </div>
                    <div>
                        <FormInput
                            id='password'
                            type='password'
                            data-cy='password'
                            placeholder={t('login_form.password')}
                            label={t('login_form.password') + '*'}
                            tabIndex={2}
                            onKeyDown={handleKeyDown}
                            isInvalid={!!errors['password']}
                            error={errors['password']?.message}
                            {...register('password', {
                                required: true,
                            })}
                        />
                    </div>
                    <div className='text-end'>
                        <Link
                            href={FORGOT_PASSWORD_PAGE}
                            className='body-s bold'
                            onClick={e => {
                                e.preventDefault();
                                router.push(
                                    {
                                        pathname: FORGOT_PASSWORD_PAGE,
                                        query: { ...router.query, username: getValues('username') },
                                    },
                                    undefined,
                                    {
                                        shallow: true,
                                    }
                                );
                            }}
                        >
                            {t('login_form.forgot_password')}
                        </Link>
                    </div>
                </div>
            </Form>
            <Button
                className='w-100'
                onClick={handleSubmit(onSubmit)}
                type='submit'
                data-cy='login'
                tabIndex={3}
                disabled={!isValid || isSubmitting}
                isSubmitting={isSubmitting}
            >
                {t('login')}
            </Button>

            {ssoProvidersEnabled && (
                <div className='w-100 text-center d-flex flex-column gap-5'>
                    <span
                        className={classNames([
                            styles['sso-header'],
                            'body-m text-gray-200 text-uppercase align-items-center gap-5 text-nowrap',
                        ])}
                    >
                        {t('login_form.sso_header')}
                    </span>
                    {Object.keys(providers).map(
                        key =>
                            getProviderIconByName(key) && (
                                <button
                                    key={key}
                                    onClick={() => signIn(providers[key].id)}
                                    className={classNames([styles['sso-button'], 'w-100 rounded-8'])}
                                >
                                    <Image
                                        className={classNames([
                                            key?.toLocaleLowerCase().includes('voba') && styles['vobahome'],
                                            'p-2',
                                        ])}
                                        alt={key}
                                        src={getProviderIconByName(key)}
                                    />
                                </button>
                            )
                    )}
                </div>
            )}

            <div className='text-center body-m'>
                {t('login_form.no_account')}{' '}
                <Link className='body-m bold' href='/account/create'>
                    {t('apply')}
                </Link>
            </div>
        </Container>
    );
};
