export default function Elevator({ fillColor }) {
    return (
        <svg fill='none' width='17' height='16' viewBox='0 0 17 16' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_506_3117)'>
                <path
                    fill={fillColor}
                    d='M1.0001 7.99325C1.0001 5.93299 1.0001 3.87253 1.0001 1.81188C1.0001 0.998339 1.49951 0.5 2.31465 0.5H14.7343C15.3927 0.5 15.8816 0.906185 15.9854 1.5407C15.9967 1.62296 16.0012 1.70599 15.9989 1.78899C15.9989 5.92947 15.9989 10.0699 15.9989 14.2104C15.9989 14.994 15.4913 15.5018 14.7078 15.5018H2.28649C1.51066 15.5018 1.0001 14.9934 1.0001 14.2204C0.999316 12.1445 0.999316 10.0688 1.0001 7.99325ZM8.92263 1.3335V14.6512C8.96371 14.6554 8.99658 14.6624 9.03003 14.6624C10.9338 14.6624 12.8373 14.6624 14.7407 14.6624C15.0294 14.6624 15.1585 14.525 15.1585 14.2368V1.80131C15.1585 1.45265 15.0353 1.33174 14.6762 1.33174H8.92498L8.92263 1.3335ZM8.07815 1.34758C8.02885 1.34289 7.9954 1.33761 7.96078 1.33761C6.06172 1.33761 4.16305 1.33761 2.26477 1.33761C1.96372 1.33761 1.83695 1.47378 1.83695 1.78605C1.83695 5.93084 1.83695 10.0754 1.83695 14.2198C1.83695 14.5338 1.96841 14.6653 2.28355 14.6653C4.16735 14.6653 6.05135 14.6653 7.93555 14.6653C7.98299 14.6625 8.03023 14.6569 8.07698 14.6483L8.07815 1.34758Z'
                />
                <path
                    fill={fillColor}
                    d='M12.4531 7.1175V9.12142C12.4531 9.37499 12.4531 9.62856 12.4531 9.88448C12.4531 10.165 12.2858 10.3488 12.0382 10.3494C11.7905 10.3499 11.6227 10.1733 11.6215 9.88683C11.6215 9.0187 11.6215 8.14998 11.6215 7.28127V7.09285C11.5681 7.13805 11.5352 7.16212 11.5071 7.19029C11.3369 7.35816 11.1726 7.53308 10.9965 7.69391C10.9192 7.77174 10.8142 7.81569 10.7045 7.81608C10.5948 7.81646 10.4895 7.77326 10.4117 7.69596C10.3339 7.61867 10.2899 7.51362 10.2896 7.40393C10.2892 7.29424 10.3324 7.18888 10.4096 7.11105C10.8521 6.65634 11.3003 6.2077 11.7541 5.76513C11.9237 5.60019 12.1649 5.61134 12.3339 5.77921C12.7717 6.20966 13.2064 6.64441 13.6379 7.08346C13.6784 7.12091 13.711 7.16613 13.7337 7.21644C13.7563 7.26674 13.7686 7.32109 13.7698 7.37626C13.771 7.43142 13.7611 7.48626 13.7407 7.53751C13.7202 7.58876 13.6897 7.63536 13.6508 7.67454C13.4859 7.84359 13.2336 7.84417 13.0481 7.67102C12.9478 7.57769 12.8533 7.47966 12.7547 7.38164C12.6561 7.28361 12.574 7.19322 12.4836 7.09931L12.4531 7.1175Z'
                />
                <path
                    fill={fillColor}
                    d='M5.40603 8.89658C5.58209 8.72049 5.75052 8.53912 5.9342 8.37301C6.01462 8.29714 6.112 8.2416 6.21824 8.211C6.30036 8.19304 6.38613 8.20311 6.46187 8.2396C6.53761 8.27609 6.59894 8.3369 6.63608 8.41233C6.6798 8.4839 6.69961 8.56753 6.69264 8.65111C6.68568 8.73468 6.6523 8.81387 6.59734 8.87722C6.5146 8.97876 6.4166 9.06857 6.32387 9.16189C5.98937 9.49647 5.65466 9.83104 5.31976 10.1656C5.06917 10.4157 4.85028 10.4151 4.60028 10.1656C4.18948 9.75943 3.78338 9.35442 3.37845 8.9453C3.12434 8.6888 3.2024 8.31901 3.5281 8.22157C3.70005 8.16992 3.84324 8.22803 3.96589 8.3507C4.14195 8.52679 4.318 8.70288 4.49406 8.87897C4.50919 8.88784 4.5251 8.89531 4.54159 8.90128V8.74104C4.54159 7.87702 4.54159 7.01339 4.54159 6.15016C4.53946 6.07747 4.54817 6.00486 4.56742 5.93474C4.59769 5.84555 4.65767 5.76947 4.73733 5.71925C4.81699 5.66902 4.91149 5.64769 5.005 5.65883C5.09851 5.66997 5.18535 5.71291 5.25098 5.78044C5.31662 5.84798 5.35706 5.93602 5.36554 6.02983C5.37434 6.14722 5.37082 6.26461 5.37082 6.38201V8.8778L5.40603 8.89658Z'
                />
            </g>
            <rect x='1' y='0.5' width='15' height='15' stroke={fillColor} />
            <defs>
                <clipPath id='clip0_506_3117'>
                    <rect x='1' y='0.5' width='15' height='15' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
}
