import classNames from 'classnames';
import { useTranslation } from 'next-i18next';

export const Description = ({ description, className }: { description: string; className?: string }) => {
    const { t } = useTranslation('metasearch');

    if (!description) {
        return null;
    }

    return (
        <>
            {' '}
            <h3 className='text-copy-s-bold'>{t('description')}</h3>
            <div style={{ flex: 1 }}>
                <p
                    style={{ whiteSpace: 'pre-line', wordBreak: 'break-word' }}
                    className={classNames(className, 'ms-5 mb-0')}
                    data-cy='property-description'
                >
                    {description}
                </p>
            </div>
        </>
    );
};
