import React from 'react';

export default function Minus(props: object) {
    return (
        <svg width='22' height='2' viewBox='0 0 22 2' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M10.0814 1.91879L11.919 1.91879L21.3952 1.91879L21.3951 0.0812088L11.919 0.0812078L10.0814 0.0812068L0.605318 0.0812058L0.605318 1.91878L10.0814 1.91879ZM10.0814 1.91879L10.0814 0.0812068L11.919 0.0812078L11.919 1.91879L10.0814 1.91879Z'
                fill='currentColor'
            />
            <path
                d='M10.0814 0.0812068L10.0814 1.91879L11.919 1.91879L11.919 0.0812078L10.0814 0.0812068Z'
                fill='currentColor'
            />
        </svg>
    );
}
