import React from 'react';
import { FormInputProps } from '../FormInput';
import { Form } from 'react-bootstrap';
import classNames from 'classnames';
import styles from '../style.module.scss';

export const DefaultInput = React.forwardRef(function DefaultImport<T>(props: FormInputProps<T>, ref) {
    const { placeholder, inputClassName, type, id, ...rest } = props;
    return (
        <Form.Control
            ref={ref}
            placeholder={placeholder}
            id={id}
            className={classNames(inputClassName, styles['form-input'])}
            type={type}
            {...rest}
        ></Form.Control>
    );
});
