import { usePropertiesClient } from '@/lib/api';
import { useState } from 'react';
import { PropertyDetails } from '@/lib/api/properties/types';
import { HIDE_PROPERTY } from '@/lib/constants/paths';
import { notify } from '@/lib/utilities/logger';

export const useHideProperty = () => {
    const propertyServiceApi = usePropertiesClient();
    if (!propertyServiceApi) throw new Error('propertyServiceApi is undefined');

    const [isLoading, setIsLoading] = useState(false);

    return {
        isLoading,
        hideProperty: async (
            id: string
        ): Promise<{
            success: boolean;
            error?: Record<string, string> | undefined;
            data?: PropertyDetails | null;
        }> => {
            setIsLoading(true);

            try {
                const { response, error } = await propertyServiceApi.POST(HIDE_PROPERTY, {
                    params: { path: { id } },
                });

                if (!response.ok && error) {
                    notify({ message: error.message, name: error.path }, HIDE_PROPERTY);
                    return { success: false, error };
                }

                return { success: true };
            } catch (error) {
                notify(error, HIDE_PROPERTY);
                return {
                    success: false,
                    error: { message: '' },
                };
            } finally {
                setIsLoading(false);
            }
        },
    };
};
