import React from 'react';
import Slider from 'react-slick';
import classNames from 'classnames';
import { Container, Row } from 'react-bootstrap';
import { enumToBackground, enumToTextColor } from '@/lib/utilities';
import { QuoteCarousel as Record } from '@/lib/api/graphcms';
import { RichText } from '@graphcms/rich-text-react-renderer';
import styles from './QuoteCarousel.module.scss';

export type QuoteCarouselProps = Partial<Record> & { className?: string };

export const QuoteCarousel = ({ className, items = [] }: QuoteCarouselProps) => {
    const sliderConfig = {
        dots: true,
        slidesToShow: 1,
        infinite: true,
        arrows: false,
    };

    return (
        <Row className={className}>
            <Slider {...sliderConfig} className={styles.carousel}>
                {items?.map(item => (
                    <div key={item.id} className={enumToBackground(item.background)}>
                        <div className={classNames(styles.content, enumToTextColor(item.colour))}>
                            {item.text && <RichText content={item.text?.raw} />}
                        </div>
                    </div>
                ))}
            </Slider>
        </Row>
    );
};

QuoteCarousel.pageLayoutContainer = (element: JSX.Element, key: string) => (
    <Container fluid key={key}>
        {element}
    </Container>
);
