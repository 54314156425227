import isEmail from 'validator/lib/isEmail';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { Button, Logo } from '@/components/common';
import { ErrorMessage } from '@hookform/error-message';
import { ErrorText, isMandatory } from '../User/util';
import { FieldValues, useForm } from 'react-hook-form';
import { addStarWhenMandatory } from '../User/util';
import { ServiceCheckboxes } from '../User/SubPages/SubComponents/service-checkboxes';
import { onVobahomeSubmit, SubmissionError } from '../User/functions';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { getSavedQueryParam, gtm, pascalCase, QUERY_PARAMS } from '@/lib/utilities';
import classNames from 'classnames';
import styles from '../User/registration.module.scss';
import { ContactFormQuickProps } from '../User/types';
import { RevealButton } from '../User/SubPages/SubComponents/reveal-button';
import { SuccessPage } from './SuccessPage';
import Image from 'next/image';
import { Trans } from 'next-i18next';

const FormComponent = (
    props: ContactFormQuickProps & { onSubmit: (values: FieldValues) => void; submissionErrors: string[] }
) => {
    const { formId, t, mandatoryFields = [], onSubmit, submissionErrors } = props || {};
    const passwordFieldId = formId('password');

    const [touched, setTouched] = useState(new Set());

    const formControl = useForm({
        mode: 'onChange',
    });
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors, isValid, touchedFields },
    } = formControl;
    const [hidePassword, setHidePassword] = useState<boolean>(true);

    const touchedFieldNames = Object.entries(touchedFields).map(([name]) => name);

    useEffect(() => {
        const newTouched = touchedFieldNames.filter(name => !touched.has(name));
        if (!newTouched?.length) return;

        newTouched.forEach(name => {
            gtm.event(gtm.EVENTS.FIELD_TOUCHED, { category: gtm.CATEGORIES.VOBAHOME_APPLICATION, label: name });
        });

        setTouched(new Set(touchedFieldNames));
    }, [touched, touchedFieldNames]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)} className='p-0'>
            <FloatingLabel
                controlId={formId('first_name')}
                label={addStarWhenMandatory(mandatoryFields, 'firstName', t('contact.first_name'))}
                className='mb-3 d-grid w-100'
            >
                <Form.Control
                    type='text'
                    placeholder='Max'
                    isInvalid={!!errors.firstName}
                    {...register('firstName', {
                        minLength: 2,
                        required: true,
                    })}
                />
                <ErrorMessage
                    errors={errors}
                    name='firstName'
                    render={e => <ErrorText t={t} name='first_name' prefix='person' {...e} />}
                />
            </FloatingLabel>
            <FloatingLabel
                controlId={formId('last_name')}
                label={addStarWhenMandatory(mandatoryFields, 'lastName', t('contact.last_name'))}
                className='mb-3 d-grid w-100'
            >
                <Form.Control
                    type='text'
                    placeholder='Mustermann'
                    isInvalid={!!errors.lastName}
                    {...register('lastName', {
                        minLength: 2,
                        required: true,
                    })}
                />
                <ErrorMessage
                    errors={errors}
                    name='lastName'
                    render={e => <ErrorText t={t} name='last_name' prefix='person' {...e} />}
                />
            </FloatingLabel>
            <FloatingLabel
                controlId={formId('email')}
                label={addStarWhenMandatory(mandatoryFields, 'email', t('contact.email'))}
                className='mb-3 d-grid w-100'
            >
                <Form.Control
                    type='email'
                    autoComplete={'username'}
                    placeholder='name@example.com'
                    isInvalid={!!errors.email}
                    {...register('email', {
                        minLength: 8,
                        required: true,
                        validate: { format: v => isEmail(v) },
                    })}
                />
                <ErrorMessage
                    errors={errors}
                    name='email'
                    render={e => <ErrorText t={t} name='email' prefix='person' {...e} />}
                />
            </FloatingLabel>
            <div className={classNames('mb-3 w-100', styles['password'])}>
                <div className='input-group mb-3'>
                    <div className='form-floating form-floating-group flex-grow-1'>
                        <input
                            id={passwordFieldId}
                            autoComplete={'current-password'}
                            className={classNames('form-control', { 'is-invalid': errors.password })}
                            type={hidePassword ? 'password' : 'text'}
                            aria-describedby={pascalCase('passwordFieldId', 'eye')}
                            placeholder='test123'
                            {...register('password', {
                                minLength: 8,
                                required: true,
                                pattern:
                                    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/,
                            })}
                        />
                        <label htmlFor={passwordFieldId}>
                            {addStarWhenMandatory(mandatoryFields, 'password', t('password.subline'))}
                        </label>
                    </div>
                    <span
                        id={pascalCase(passwordFieldId, 'eye')}
                        role='button'
                        className={classNames('input-group-text bg-white align-bottom', {
                            'is-invalid': errors.password,
                        })}
                        style={{ height: '59px' }}
                        onClick={() => setHidePassword(!hidePassword)}
                    >
                        <RevealButton isRevealed={!hidePassword} />
                    </span>
                </div>
                <ErrorMessage
                    errors={errors}
                    name='password'
                    render={e => <ErrorText t={t} name='password' prefix='person' {...e} />}
                />
                <span className='description text-form'>{t('password.explain')}</span>
            </div>
            <FloatingLabel
                controlId={formId('phone')}
                label={addStarWhenMandatory(mandatoryFields, 'phone', t('contact.phone'))}
                className='mb-6 d-grid w-100'
            >
                <Form.Control
                    type='tel'
                    placeholder='+494000000000'
                    isInvalid={!!errors.phone}
                    {...register('phone', {
                        minLength: 2,
                        maxLength: 16,
                        required: isMandatory(mandatoryFields, 'phone'),
                    })}
                />
                <ErrorMessage
                    errors={errors}
                    name='phone'
                    render={e => <ErrorText t={t} name='phone' prefix='person' {...e} />}
                />
            </FloatingLabel>
            {!!submissionErrors?.length && (
                <SubmissionError errors={submissionErrors} t={t} values={{ email: watch('email') }} />
            )}
            <ServiceCheckboxes form={formControl} {...props} />
            <div className='mt-5'>
                <Button buttonType='submit' disabled={!isValid}>
                    {t('contact.btn_submit')}
                </Button>
            </div>
        </Form>
    );
};

export const ContactForm = (props: ContactFormQuickProps) => {
    const router = useRouter();
    const { locale = 'de', defaultLocale } = router;
    const { formId, t, fieldsToHide = [], mandatoryFields = [], register } = props || {};
    const [submissionSuccess, setSubmissionSuccess] = useState<boolean>(false);
    const [submissionErrors, setSubmissionErrors] = useState<string[]>([]);

    const referrer = getSavedQueryParam(QUERY_PARAMS.REFERRER) || '';

    return (
        <>
            {submissionSuccess ? (
                <SuccessPage t={t} />
            ) : (
                <Row className='w-100 p-0 mb-6'>
                    <Col xs={12} sm={8}>
                        <Row>
                            <Col style={{ position: 'relative', width: '400px', height: '150px' }} xs={'auto'}>
                                <Image
                                    src='https://media.graphassets.com/4xfuqXuTTgiaH1UEzrq4'
                                    alt='vobahome-logo'
                                    fill
                                    sizes='100vw'
                                    style={{
                                        objectFit: 'contain',
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <div className='p-0 mt-6'>
                                <p className='text-copy-s-bold'>{t('contact.vobahome.intro')}</p>
                                <p>
                                    <Trans i18nKey='contact.vobahome.body' ns='register' />
                                </p>
                                <p>{t('contact.vobahome.outro')}</p>
                            </div>
                        </Row>
                        <Row>
                            <span className='w-100 mb-2 text-copy-l p-0'>{t('contact.vobahome.headline')}</span>
                            <FormComponent
                                {...props}
                                formId={formId}
                                mandatoryFields={mandatoryFields}
                                fieldsToHide={fieldsToHide}
                                t={t}
                                submissionErrors={submissionErrors}
                                onSubmit={onVobahomeSubmit(
                                    register,
                                    locale || defaultLocale || 'de_DE',
                                    referrer,
                                    setSubmissionSuccess,
                                    setSubmissionErrors
                                )}
                            />
                        </Row>
                    </Col>
                    <Col sm={4} className='pt-13 d-none d-sm-block'>
                        <Logo light={true} style={{ transform: 'rotate(90deg) translateY(-70px)' }} />
                    </Col>
                </Row>
            )}
        </>
    );
};
