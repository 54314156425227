import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import { useSession } from 'next-auth/react';
import { checkIsBroker } from '@/lib/security';
import { Button } from '@/components/design-system';

export const AttendViewingRequestButton = ({
    onClick,
    selectedSlotId,
    className,
}: {
    onClick: () => Promise<void>;
    selectedSlotId?: string;
    className?: string;
}) => {
    const { t } = useTranslation('metasearch');
    const [savingLeasingRequest, setSavingLeasingRequest] = useState(false);

    const handleViewingRequestClick = () => {
        setSavingLeasingRequest(true);
        onClick().finally(() => setSavingLeasingRequest(false));
    };

    const { data: session } = useSession();
    const isBroker = checkIsBroker(session);
    if (isBroker) return null;

    return (
        <Button
            disabled={!selectedSlotId}
            isSubmitting={savingLeasingRequest}
            onClick={handleViewingRequestClick}
            size='md'
            className={className}
        >
            {t('send_attend_viewing_request')}
        </Button>
    );
};
