import React from 'react';
import classNames from 'classnames';
import styles from './sticky-label.module.scss';
import { Button } from '../button';
import { ButtonProps } from '../button/types';

type StickyLabelProps = {
    title?: string | null;
    className?: string;
    ctaButton?: ButtonProps | null;
    columnLayout?: boolean;
    body?: React.ReactNode | null;
};

export const StickyLabel = ({ title, className, columnLayout, ctaButton, body }: StickyLabelProps): JSX.Element => {
    return (
        <div
            className={classNames(
                !body && styles.mainContainer,
                className,
                columnLayout && styles.columnLayout,
                body && styles.secondaryContainer
            )}
        >
            <h4 className={styles.title}>{title}</h4>
            {body}
            {ctaButton && <Button {...ctaButton} />}
        </div>
    );
};
