import React, { useEffect, useId, useMemo, useState } from 'react';
import { APIProvider, Map, AdvancedMarker, useMapsLibrary } from '@vis.gl/react-google-maps';
import { googleMapsApiKey } from '@/lib/env';

interface MapComponentProps {
    position?: google.maps.LatLngLiteral;
    address?: string | null;
    zoom: number;
    className?: string;
}

const MapComponent = ({ position, address, zoom, className }: MapComponentProps) => {
    const id = useId();

    if (!position && !address && !googleMapsApiKey) {
        return null;
    }

    let map;

    if (position) {
        map = (
            <Map mapId={id} defaultCenter={position} defaultZoom={zoom} className={className}>
                <AdvancedMarker clickable={false} draggable={false} position={position} />
            </Map>
        );
    }

    if (address) {
        map = <MapWithGeocoder id={id} address={address} zoom={zoom} className={className} />;
    }

    return <APIProvider apiKey={googleMapsApiKey}>{map}</APIProvider>;
};

export default MapComponent;

const MapWithGeocoder = ({ id, address, zoom, className }: MapComponentProps & { id: string }) => {
    const [position, setPosition] = useState<google.maps.LatLngLiteral | null>(null);

    const geocodingLib = useMapsLibrary('geocoding');
    const geocoder = useMemo(() => geocodingLib && new geocodingLib.Geocoder(), [geocodingLib]);

    useEffect(() => {
        if (!address || !geocoder) {
            return;
        }

        async function getPosition() {
            const response = await geocoder.geocode({
                address,
                componentRestrictions: {
                    country: 'DE',
                },
            });

            const position = {
                lat: response.results?.[0]?.geometry?.location.lat(),
                lng: response.results?.[0]?.geometry?.location.lng(),
            };

            setPosition(position);
        }

        getPosition();
    }, [address, geocoder]);

    if (!position) {
        return null;
    }

    return (
        <Map mapId={id} defaultCenter={position} defaultZoom={zoom} className={className}>
            <AdvancedMarker clickable={false} draggable={false} position={position} />
        </Map>
    );
};
