import React from 'react';
import { useRouter } from 'next/router';

type CtaButtonProps = { configuration: Record<string, { topText: string; buttonText: string; buttonUrl: string }> };

export function CTAButton(props: CtaButtonProps) {
    const router = useRouter();
    const locale: string = router.locale || router.defaultLocale || 'de';
    if (!props?.configuration[locale]) {
        return null;
    }
    const { topText = 'top text ', buttonText = 'bottom text', buttonUrl } = props.configuration[locale];

    //TODO move css to css-module file
    //TODO add checks to now show if missing properties
    //TODO make it work fully from CMS to finish (add content)
    return (
        <div className={'text-start'} style={{ marginBottom: '2rem' }}>
            <div
                style={{
                    width: '90%',
                    height: '132px',
                    backgroundColor: '#FA695A',
                    padding: '15px 20px 45px',
                    margin: 'auto',
                    position: 'relative',
                }}
            >
                <h2 style={{ color: 'white', textAlign: 'left' }}>{topText}</h2>
                <a
                    href={buttonUrl}
                    style={{
                        color: '#FFF',
                        backgroundColor: '#32374B',
                        right: '15px',
                        transform: 'translateY(50%)',
                        position: 'absolute',
                        bottom: '0',
                        padding: '20px',
                        fontSize: '18px',
                        textTransform: 'none',
                    }}
                >
                    {buttonText}
                </a>
            </div>
        </div>
    );
}
