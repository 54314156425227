import React from 'react';
import { Modal as BootstrapModal, ModalProps as BootstrapModalProps } from 'react-bootstrap';
import styles from './style.module.scss';
import classNames from 'classnames';

type ModalProps = {
    show: boolean;
    handleClose: () => void;
    title?: string;
    children: React.ReactNode;
    footer?: React.ReactNode;
    contentClassName?: string;
} & BootstrapModalProps;

const Modal = ({ show, handleClose, title, children, footer, contentClassName, ...rest }: ModalProps) => {
    return (
        <BootstrapModal
            show={show}
            onHide={handleClose}
            className={classNames(styles['modal'], 'design-system')}
            contentClassName={contentClassName}
            {...rest}
        >
            <BootstrapModal.Header closeButton>
                <BootstrapModal.Title className='text-gray-900'>{title}</BootstrapModal.Title>
            </BootstrapModal.Header>
            <BootstrapModal.Body>{children}</BootstrapModal.Body>
            <BootstrapModal.Footer>{footer}</BootstrapModal.Footer>
        </BootstrapModal>
    );
};

export default Modal;
