import { Badge, DangerAlert, Link } from '@/components/design-system';
import Modal from '@/components/design-system/modal';
import classNames from 'classnames';
import { Trans, useTranslation } from 'next-i18next';
import React from 'react';
import { TelephoneFill } from 'react-bootstrap-icons';
import styles from '../styles.module.scss';
import { Attendee } from '@/lib/api/lease-requests/types';
import { gtm } from '@/lib/utilities';

type AttendeeDetailsModalProps = {
    isAttendeesModalShown: boolean;
    setIsAttendeesModalShown: (shown: boolean) => void;
    attendees: Attendee[];
    // propertyAddress: string;
};

export const AttendeesModal = (props: AttendeeDetailsModalProps) => {
    const { t } = useTranslation(['metasearch']);
    // todo: fix BE data fetching
    const propertyAddress = '';
    const { isAttendeesModalShown, setIsAttendeesModalShown, attendees } = props;

    const onTelLinkClick = () => {
        gtm.event(gtm.EVENTS.ATTENDEE_TEL_CLICKED, { category: gtm.CATEGORIES.APPOINTMENT_SLOT });
    };

    return (
        <Modal
            title={t('slot_form.scheduling_details')}
            show={isAttendeesModalShown}
            handleClose={() => setIsAttendeesModalShown(false)}
            size='lg'
        >
            <DangerAlert className='body-m'>
                <Trans i18nKey='slot_form.gdpr_warning' ns='metasearch' tOptions={{ address: propertyAddress }} />
            </DangerAlert>

            <div className={classNames(styles['attendee-container'], 'gap-3 w-100')}>
                {attendees
                    .sort((a, b) => a.firstName.localeCompare(b.firstName))
                    .sort((a, b) => {
                        return a.status === 'CONFIRMED' && b.status === 'CONFIRMED'
                            ? 0
                            : a.status === 'CONFIRMED'
                            ? -1
                            : 1;
                    })
                    .map(attendee => {
                        const confirmed = attendee.status === 'CONFIRMED';
                        const oneLineView = attendees.length === 1;
                        const phone = attendee.mobilePhone ?? attendee.phone;
                        return (
                            <div
                                key={attendee.accountId}
                                className={classNames(
                                    'd-flex flex-wrap gap-2 p-4 align-items-center border border-velvet-500 rounded-4',
                                    confirmed && 'bg-velvet-50',
                                    oneLineView
                                        ? 'flex-row justify-content-between'
                                        : 'flex-column justify-content-start'
                                )}
                            >
                                <div className={classNames('d-flex', !oneLineView && 'mb-5')}>
                                    <Badge
                                        containerClassName={classNames(
                                            'border border-velvet-500',
                                            confirmed ? 'bg-velvet-500' : 'bg-white'
                                        )}
                                        textClassName={confirmed ? 'text-white' : 'text-velvet-500'}
                                        text={confirmed ? t('slot_form.confirmed') : t('slot_form.unconfirmed')}
                                        size='xs'
                                    />
                                </div>

                                <span className='text-center bold'>
                                    {attendee.firstName} {attendee.lastName}
                                </span>

                                <Link
                                    className={classNames(
                                        'bold text-decoration-none d-inline-flex',
                                        confirmed ? 'text-velvet-500' : 'text-black'
                                    )}
                                    href={`tel:${phone}`}
                                    onClick={onTelLinkClick}
                                >
                                    {phone ? phone.replace(' ', '') : ' '}
                                </Link>

                                {phone && (
                                    <Link
                                        className={classNames(
                                            'd-inline-flex py-2',
                                            oneLineView ? 'px-3' : 'mx-auto',
                                            confirmed ? 'text-velvet-500' : 'text-black'
                                        )}
                                        href={`tel:${phone}`}
                                        onClick={onTelLinkClick}
                                    >
                                        <TelephoneFill size={16}></TelephoneFill>
                                    </Link>
                                )}
                            </div>
                        );
                    })}
            </div>
        </Modal>
    );
};
