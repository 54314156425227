import moment from 'moment-timezone';

export const getLocalDate = (date: string | null, locale: string, fullMonth?: boolean) => {
    if (!date) {
        return '';
    }
    const localLocale = moment(date);
    moment.locale(locale);
    localLocale.locale(locale);
    return localLocale.format(`ddd, DD ${fullMonth ? 'MMMM' : 'MMM'}`);
};
