import { TFunction } from 'react-i18next';
import styles from './contact.module.scss';
import { Form } from 'react-bootstrap';
import { MultipleFieldErrors } from 'react-hook-form';

export type OptionalMarkerProps = {
    t: TFunction;
    mandatoryFields?: string[];
    name: string;
};

export function OptionalMarker(props: OptionalMarkerProps) {
    const { mandatoryFields = [], name, t } = props;
    if (mandatoryFields.includes(name)) return null;
    return (
        <>
            {' '}
            <span className={styles['optional']}>({t('optional')})</span>
        </>
    );
}

export function isOptional(mandatoryFields: string[], name: string) {
    return !mandatoryFields.includes(name);
}

export function isMandatory(mandatoryFields: string[], name: string) {
    return mandatoryFields.includes(name);
}

export function addStarWhenMandatory(mandatoryFields: string[], name: string, value: string) {
    return isMandatory(mandatoryFields, name) ? `${value} *` : value;
}

export function isHidden(fieldsToHide: string[], name: string) {
    return fieldsToHide.includes(name);
}

export function isVisible(fieldsToHide: string[], name: string) {
    return !fieldsToHide.includes(name);
}

export function ErrorText(props: { messages?: MultipleFieldErrors; t: TFunction; name: string; prefix?: string }) {
    const { messages, t, name, prefix } = props;
    if (!messages) return null;
    const [message] = Object.keys(messages).sort();
    let key = prefix ? `${prefix}.${name}` : name;
    const fieldName = t(`${key}`, { defaultValue: t(`${key}.label`) });
    key = `${key}.${message}`;
    const defaultValue = t(`errors.default.${message}`, { fieldName });
    return <Form.Text className='invalid-feedback'>{t(key, { fieldName, defaultValue })}</Form.Text>;
}
