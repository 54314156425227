import classNames from 'classnames';
import React, { HTMLInputTypeAttribute, InputHTMLAttributes, forwardRef } from 'react';
import { FormControlProps } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import styles from './style.module.scss';
import { DefaultInput } from './inputs/DefaultInput';
import { PasswordInput } from './inputs/PasswordInput';

export type FormInputProps<T> = InputHTMLAttributes<T> &
    FormControlProps & {
        id: string;
        containerClassName?: string;
        labelClassName?: string;
        inputClassName?: string;
        errorClassName?: string;
        type: HTMLInputTypeAttribute;
        label?: string;
        placeholder?: string;
        required?: boolean;
        error?: string;
        hint?: string;
    };

export const FormInput = forwardRef(function FormInput<T>(props: FormInputProps<T>, ref) {
    const { id, containerClassName, labelClassName, errorClassName, type, label, error, hint } = props;

    const getInputComponent = () => {
        switch (type) {
            case 'password':
                return <PasswordInput ref={ref} {...props} />;
            default:
                return <DefaultInput ref={ref} {...props} />;
        }
    };

    return (
        <div className={classNames(containerClassName, 'position-relative', 'design-system')}>
            <label htmlFor={id} className={classNames('body-s', labelClassName, styles['form-label'])}>
                {label}
            </label>
            {getInputComponent()}
            <Form.Control.Feedback
                id={id}
                className={classNames(
                    'body-s',
                    errorClassName,
                    styles['form-input-feedback'],
                    error ? styles['error'] : styles['hint']
                )}
                type={error ? 'invalid' : 'valid'}
            >
                {error || hint}
            </Form.Control.Feedback>
        </div>
    );
});
