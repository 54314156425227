import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { Col, Row } from 'react-bootstrap';
import classNames from 'classnames';
import styles from './styles.module.scss';

import { formatCurrency, useBootstrapBreakpointValue } from '@/lib/utilities';
import { useMediaQuery } from '@mui/material';

export const LeaseRate = ({ leaseRate }: { leaseRate: number }) => {
    const { t } = useTranslation('metasearch');
    const { locale = 'de' } = useRouter();

    return (
        <div className={classNames('px-6 py-5 border border-gray-100', styles['lease-rate__container'])}>
            <Row>
                <Col xs={2} className='d-flex justify-content-center align-items-center'>
                    <i className='icon icon-menu-overview text-velvet-500 fs-3' />
                </Col>
                <Col xs={10} className='pe-0 text-gray-400'>
                    <p className='text-footnote m-0 fw-light'>{`${t('lease_rate')} (${t('excluding_charges')})`}</p>
                    <LeaseRateFormatted locale={locale} leaseRate={leaseRate} />
                </Col>
            </Row>
        </div>
    );
};

export const LeaseRateFormatted = ({
    leaseRate,
    locale,
    className,
}: {
    leaseRate: number;
    locale: string;
    className?: string;
}) => {
    const { t } = useTranslation('metasearch');
    const MD_BOOTSTRAP_BREAKPOINT = useBootstrapBreakpointValue('md', []);
    const isMobile = useMediaQuery(`(max-width: ${MD_BOOTSTRAP_BREAKPOINT})`);
    const renderHeader = props => (isMobile ? <h5 {...props} /> : <h4 {...props} />);
    return renderHeader({
        ['data-cy']: 'lease-rate',
        className: classNames('m-0 fw-bold', className),
        children: `${t('Leasing rate')}:${formatCurrency(locale, leaseRate)}`,
    });
};
