import { Controller, UseFormReturn } from 'react-hook-form';
import { FormPartProps } from '../../types';
import { notEmpty, formatCurrency } from '@/lib/utilities';
import Slider, { createSliderWithTooltip } from 'rc-slider';
import { useRouter } from 'next/router';
import { TFunction } from 'next-i18next';
import classNames from 'classnames';

const SliderWithTooltip = createSliderWithTooltip(Slider);

const NET_INCOME_TOTAL_STEP = 500;
const NET_INCOME_MARK_STEPS = 2000;
const NET_INCOME_DEFAULT = 0;
const MONTHLY_BUDGET_DEFAULT = 1000;

export function PersonalIncomePart(
    props: {
        maxNetIncome?: number;
        maxMonthlyBudget?: number;
        netIncomeStep?: number;
        form: UseFormReturn;
    } & FormPartProps
) {
    const {
        form,
        t,
        fieldsToHide,
        maxNetIncome = 12000,
        maxMonthlyBudget = 6000,
        netIncomeStep = NET_INCOME_TOTAL_STEP,
    } = props || {};
    const { control, watch } = form || {};
    const { locale = 'de' } = useRouter();
    const decimalFormatter = new Intl.NumberFormat(locale, { style: 'decimal' });

    const netIncome = watch('netIncome') || NET_INCOME_DEFAULT;
    const monthlyBudget = watch('monthlyBudget');
    const maxValidBudget = netIncome >= maxNetIncome ? maxMonthlyBudget : netIncome * 0.4;
    const maxBudgetTextColor = netIncome < maxNetIncome && monthlyBudget >= maxValidBudget ? 'text-error' : '';

    return (
        <>
            {!fieldsToHide.includes('netIncome') && (
                <div className='mb-8 w-100'>
                    <div className='text-form mb-2'>{t('contact.net_income')}</div>
                    <div className='mx-5'>
                        <Controller
                            name='netIncome'
                            control={control}
                            defaultValue={NET_INCOME_DEFAULT}
                            render={({ field: { value, onChange, onBlur } }) => (
                                <SliderWithTooltip
                                    min={0}
                                    max={maxNetIncome}
                                    className='pb-5'
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    marks={netIncomeMarks(maxNetIncome, t, decimalFormatter)}
                                    tipFormatter={value =>
                                        value >= maxNetIncome
                                            ? t('more', { ns: 'common' })
                                            : formatCurrency(locale, value)
                                    }
                                    step={netIncomeStep}
                                    trackStyle={{ backgroundColor: `var(--bs-velvet-700)` }}
                                    handleStyle={{
                                        backgroundColor: `var(--bs-velvet-500)`,
                                        borderColor: `var(--bs-velvet-500)`,
                                    }}
                                    activeDotStyle={{
                                        borderColor: `var(--bs-velvet-700)`,
                                        backgroundColor: `var(--bs-velvet-700)`,
                                    }}
                                />
                            )}
                        />
                    </div>
                </div>
            )}

            {!fieldsToHide.includes('monthlyBudget') && (
                <div className='mb-2 w-100'>
                    <div className='text-form mb-2'>{t('contact.monthly_budget')}</div>
                    <div className='mx-5 mb-8'>
                        <Controller
                            name='monthlyBudget'
                            control={control}
                            defaultValue={MONTHLY_BUDGET_DEFAULT}
                            render={({ field: { value, onChange, onBlur } }) => (
                                <SliderWithTooltip
                                    min={MONTHLY_BUDGET_DEFAULT}
                                    max={Math.max(1000, maxValidBudget)}
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    marks={monthlyBudgetMarks(
                                        Math.max(1000, maxValidBudget),
                                        MONTHLY_BUDGET_DEFAULT,
                                        t,
                                        decimalFormatter
                                    )}
                                    tipFormatter={value =>
                                        value >= maxMonthlyBudget
                                            ? t('more', { ns: 'common' })
                                            : formatCurrency(locale, value)
                                    }
                                    step={50}
                                    trackStyle={{ backgroundColor: `var(--bs-velvet-700)` }}
                                    handleStyle={{
                                        backgroundColor: `var(--bs-velvet-500)`,
                                        borderColor: `var(--bs-velvet-500)`,
                                    }}
                                    activeDotStyle={{
                                        borderColor: `var(--bs-velvet-700)`,
                                        backgroundColor: `var(--bs-velvet-700)`,
                                    }}
                                />
                            )}
                        />
                    </div>
                    <div className={classNames('text-form mb-2', maxBudgetTextColor)}>{t('contact.budget_max')}</div>
                </div>
            )}
        </>
    );
}

function netIncomeMarks(maxNetIncome: number, t: TFunction, decimalFormatter: Intl.NumberFormat) {
    const netIncomeMarks = Object.fromEntries(
        Array.from({ length: Math.max(Math.ceil(maxNetIncome / NET_INCOME_MARK_STEPS), 0) }).map((v, idx) => {
            const val = idx * NET_INCOME_MARK_STEPS;
            const value = decimalFormatter.format(val);
            return [val, value];
        })
    );
    netIncomeMarks[`${maxNetIncome}`] = t('more', { ns: 'common' });
    return netIncomeMarks;
}

function monthlyBudgetMarks(budgetMax: number, budgetMin: number, t: TFunction, decimalFormatter: Intl.NumberFormat) {
    const stepSize = budgetMax <= 1250 ? 250 : budgetMax < 2100 ? 250 : budgetMax < 3500 ? 500 : 1000;
    const monthlyBudgetMarks = Object.fromEntries(
        Array.from({ length: Math.max(Math.ceil(budgetMax / stepSize), 0) })
            .map((v, idx) => {
                const val = idx * stepSize + budgetMin;
                if (budgetMax - val < stepSize / 4) return null;
                const value = decimalFormatter.format(val);
                return [val, value];
            })
            .filter(notEmpty)
    );
    // monthlyBudgetMarks[`500`] = t('less', { ns: 'common' });
    monthlyBudgetMarks[`${budgetMax}`] = t('more', { ns: 'common' });
    return monthlyBudgetMarks;
}
