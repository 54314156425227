import { useState } from 'react';
import { mutate } from 'swr';
import { useAccountId } from '@/components/users';
import { useLeaseRequestsClient } from '@/lib/api';
import { LEASE_REQUEST_BY_PROPERTY, LEASE_REQUESTS_OLD } from '@/lib/constants/paths';
import { notify } from '@/lib/utilities/logger';
import { Availability } from '@/lib/api/lease-requests/types';
import { logErrorResponse } from '@/lib/api/utils';

export const useCreateLeaseRequest = () => {
    const accountId = useAccountId();
    const leaseRequestsClient = useLeaseRequestsClient();
    const [isLoading, setIsLoading] = useState(false);

    const createLeaseRequest = async (
        propertyId: string,
        params: {
            slotId: string;
            leaseRate: number;
            leaseDuration: number;
            refurbishmentKey: string;
            refurbishmentAdjustment: number;
            availability: Availability;
        }
    ) => {
        setIsLoading(true);
        const { slotId, leaseRate, leaseDuration, refurbishmentKey, refurbishmentAdjustment, availability } = params;
        let data, error, status;

        const requestData = {
            body: {
                propertyId,
                leaseRate: {
                    duration: leaseDuration,
                    refurbishmentKey,
                    refurbishmentAdjustment,
                    value: leaseRate,
                },
                accountId: accountId,
                slotId,
                availability,
            },
        };

        try {
            const response = await leaseRequestsClient.POST(LEASE_REQUESTS_OLD, requestData);

            if (response.response?.status >= 400)
                logErrorResponse(response.response, accountId, requestData, response.error);

            data = response.data;
            status = response.response.status;
            error = response.error;

            if (data) mutate([LEASE_REQUEST_BY_PROPERTY, accountId, propertyId]);
        } catch (err) {
            error = err;
            notify(typeof error === 'object' ? JSON.stringify(error) : error, LEASE_REQUESTS_OLD, accountId);
        }

        setIsLoading(false);
        return { data, status, error };
    };

    return {
        createLeaseRequest,
        isLoading,
    };
};
