import dynamic from 'next/dynamic';
import { FinancingExample as Record } from '@/lib/api/graphcms';

const Financing = dynamic(() => import('../../../financing'), {
    ssr: false,
});

export function FinancingExample(props: Partial<Record>) {
    return <Financing {...props} />;
}
