import { useTranslation } from 'next-i18next';
import Map from './Map';
import { Location } from '@/lib/api/lease-requests/types';
import { getFullAddress, getShortAddress } from '@/lib/utilities/lib/properties';

export const PropertyLocationMap = ({
    location,
    className,
    showFullAddress,
}: {
    location: Location;
    showFullAddress?: boolean;
    className?: string;
}) => {
    const { t } = useTranslation('common');

    const { geom } = location;
    const address = showFullAddress ? getFullAddress(location) : getShortAddress(location);

    const [lng, lat] = geom.coordinates as [number, number];

    const zoom = 18;
    return (
        <div>
            <div>
                <h3 className='text-copy-s-bold'>{t('Location')}</h3>
                {address && <div>{address}</div>}
            </div>

            <Map address={showFullAddress ? address : null} position={{ lat, lng }} zoom={zoom} className={className} />
        </div>
    );
};
