export type Address = {
    street?: string | null;
    houseNumber?: string | null;
    zipCode?: string | null;
    city?: string | null;
};
export type Account = Address & {
    accountId: string;
    firstName: string;
    lastName: string;
    dateOfBirth?: string | null;
    email: string;
    phone?: string | null;
    mobile?: string | null;
    landline?: string | null;
    employmentContract?: EmploymentContractTypeEnum | null;
    employmentContractEnd?: string | null;
    householdSize?: number | null;
    netIncome?: number | null;
    monthlyBudget?: number | null;
};
export enum EmploymentContractTypeEnum {
    OPEN_ENDED = 'OPEN_ENDED',
    FIXED_TERM = 'FIXED_TERM',
    SELF_EMPLOYED = 'SELF_EMPLOYED',
}

export type LoadAccountFunction = (accountId: string) => Promise<Account | null>;
type UpdateAccountError = { message?: string | null; code: string; propertyName?: string | null };
export type UpdateAccountFunction = (account: Account) => Promise<{ success: boolean; errors: UpdateAccountError[] }>;
