import { ForgotPasswordComponent } from '../../../auth-ui';
import React from 'react';
import { forgotPassword, confirmForgotPassword } from '@/lib/env';
export function ForgotPasswordForm() {
    return (
        <div className='container'>
            <ForgotPasswordComponent forgotPassword={forgotPassword} confirmForgotPassword={confirmForgotPassword} />
        </div>
    );
}
