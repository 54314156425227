import { useTranslation } from 'next-i18next';
import { PropertyCoordinates } from '@/lib/api/bff';
import Map from './Map';

export const PropertyLocationMapOld = (props: {
    city?: string | null;
    zip?: number | string | null;
    location?: PropertyCoordinates;
    address?: string | null;
    className?: string;
}) => {
    const { t } = useTranslation('common');

    const { city, zip, location, address, className } = props;

    const position =
        location?.latitude && location?.longitude ? { lat: location.latitude, lng: location.longitude } : undefined;
    const zoom = 18;
    return (
        <div>
            <div>
                <h3 className='text-copy-s-bold'>{t('Location')}</h3>
                {city && zip && <div>{`${city} ${zip}`}</div>}
            </div>

            <Map address={address} position={position} zoom={zoom} className={className} />
        </div>
    );
};
