import React from 'react';
import Script from 'next/script';

type RVCalculatorProps = { configuration: Record<string, string> };

export function RVCalculator(props: RVCalculatorProps) {
    const { configuration } = props || {};
    const { tagName = 'app-ktv-m', wlid = '9962ab1d0545cb9', product = 'private.mkb' } = configuration || {};
    const Component = tagName as React.ElementType;
    return (
        <>
            <Script id='kreditconnect-script' src='https://www.kreditconnect.de/app/ktv-m/dist/js/integrate.js' />
            <Component wlid={wlid} product={product}></Component>
        </>
    );
}
