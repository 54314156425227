import React, { useRef, useState, useEffect } from 'react';
import styles from './styles.module.scss';
import classnames from 'classnames';
import { useElementVisibility } from '@/lib/utilities';
import { gtm } from '@/lib/utilities';
import { useRouter } from 'next/router';

type VideoProps = React.ComponentPropsWithRef<'video'> & {
    url: string;
    mimeType: string;
    previewImageUrl: string;
    title?: string;
    containerClassName?: string;
};

export function Video(props: VideoProps) {
    const { url, mimeType, previewImageUrl, title, containerClassName, ...rest } = props;

    const video = useRef<HTMLVideoElement>(null);
    const [videoPlaying, setVideoPlaying] = useState(false);
    const [videoHasBeenInView, setVideoHasBeenInView] = useState(false);
    const router = useRouter();
    const [target, isVisible] = useElementVisibility();

    const onVideoPlay = () => {
        setVideoPlaying(!videoPlaying);
        video?.current?.play();
        gtm.event(gtm.EVENTS.VIDEO_PLAYED, { title, page: router.asPath });
    };

    const onVideoPause = e => {
        gtm.event(gtm.EVENTS.VIDEO_PAUSED, {
            title,
            duration: Math.round(e.currentTarget.currentTime),
            page: router.asPath,
        });
        setVideoPlaying(false);
    };

    useEffect(() => {
        if (!videoHasBeenInView && isVisible) {
            gtm.event(gtm.EVENTS.VIDEO_IN_VIEW, { title, page: router.asPath });
            setVideoHasBeenInView(true);
        }
    }, [router.asPath, isVisible]);

    return (
        <div
            className={classnames(styles.videoContainer, containerClassName, 'design-system')}
            onClick={() => {
                if (!videoPlaying) onVideoPlay();
            }}
            ref={target}
        >
            <video
                ref={video}
                id={url}
                className={classnames(styles.video)}
                onEnded={() => {
                    setVideoPlaying(false);
                }}
                poster={previewImageUrl}
                controls={videoPlaying}
                onPause={e => onVideoPause(e)}
                {...rest}
            >
                <source src={url} type={mimeType} />
                Your browser does not support the video tag.
            </video>
            {!videoPlaying && (
                <div className={styles.videoButtonAligner}>
                    <div>
                        <div
                            className={styles.videoIconWrapper}
                            onClick={e => {
                                e.stopPropagation();
                                onVideoPlay();
                            }}
                        >
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='16vw'
                                height='16vh'
                                fill='currentColor'
                                className='bi bi-play'
                                viewBox='0 0 14 16'
                                style={{ height: '100%', width: '100%' }}
                            >
                                <path d='M10.804 8 5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z' />
                            </svg>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
