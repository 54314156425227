/* eslint-disable-next-line */
import classNames from 'classnames';
import { useState } from 'react';
import styles from './dropdown.module.scss';
import { CardDropdownProps, OptionItem } from './types';

export function CardDropdown(props: CardDropdownProps) {
    const { className, options = [], defaultValue = '', cardRenders = {}, startCollapsed = true } = props || {};
    const [collapsed, setCollapsed] = useState(startCollapsed);
    const [selected, setSelected] = useState<string>(defaultValue);

    const updateSelection = (item: OptionItem) => {
        setSelected(item.key);
        setCollapsed(true);
    };

    const CardComponent = cardRenders[selected];
    const title = options.find(i => i.key === selected)?.label;
    return (
        <>
            <div className={classNames(styles['dropdown'], 'w-100 shadow', className)}>
                <div
                    role='button'
                    className={classNames(
                        styles['dropdown-button'],
                        'w-100 m-0 d-flex justify-content-between align-items-middle'
                    )}
                    onClick={() => setCollapsed(!collapsed)}
                >
                    <div className='text-caption-bold pt-3'>{title}</div>
                    <div
                        className={classNames(styles['icon'], collapsed ? styles['icon-closed'] : styles['icon-open'])}
                    >
                        <svg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <rect x='0.75' y='0.75' width='40.5' height='40.5' rx='20.25' fill='#725BFF' />
                            <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M31 17.2424L29.7267 16L21 24.5152L12.2733 16L11 17.2424L21 27L31 17.2424Z'
                                fill='white'
                            />
                            <rect
                                x='0.75'
                                y='0.75'
                                width='40.5'
                                height='40.5'
                                rx='20.25'
                                stroke='#725BFF'
                                strokeWidth='1.5'
                            />
                        </svg>
                    </div>
                </div>
                <div className={classNames(styles['dropdown-body'], 'collapse', { show: !collapsed })}>
                    <div>
                        <div className={''}>
                            <ul className={classNames(styles['item-list'])}>
                                {options.map(item => {
                                    return (
                                        <li
                                            key={item.key}
                                            role='button'
                                            className={'text-caption-bold'}
                                            onClick={() => updateSelection(item)}
                                        >
                                            {item.label}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            {CardComponent && (
                <div className={classNames(styles['card'], 'w-100 shadow mt-4')}>
                    <CardComponent />
                </div>
            )}
        </>
    );
}
