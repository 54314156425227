import React, { ReactElement, useState } from 'react';
import { useRouter } from 'next/router';
import styles from '../styles.module.scss';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';
import { Description } from '../../Description';
import { WithReadMore } from '../../ReadMore';
import { Address } from './Address';
import { Divider } from '../../Divider';
import { Badge, Spinner } from 'react-bootstrap';
import { gtm, pascalCase } from '@/lib/utilities';
import { PROPERTY_FEATURES } from '@/lib/constants';
import { Property } from '@/lib/api/lease-requests/types';

export const InfoSection = ({ isActive = true, property }: { isActive?: boolean; property: Property }) => {
    const { metadata } = property;
    const { description } = metadata;

    const { locale = 'de' } = useRouter();
    const [isDescriptionOpened, setIsDescriptionOpened] = useState(false);

    const onReadMoreClick = () => {
        gtm.event(gtm.EVENTS.READ_MORE_CLICKED, { category: gtm.CATEGORIES.PROPERTY, expanded: !isDescriptionOpened });
        setIsDescriptionOpened(!isDescriptionOpened);
    };

    const nonNullDescription = formatDescription(description, locale);

    const shouldShowReadMore = () => {
        if (!nonNullDescription || ('ownerId' in property && property.ownerId !== null)) {
            return false;
        }
        if (nonNullDescription.length > 300) {
            return true;
        }
        if ('enhancementsDone' in property && !property.enhancementsDone) {
            return true;
        }
        return 'enhancementsDone' in property && property.enhancementsDone && nonNullDescription.length > 300;
    };

    const showReadMore = shouldShowReadMore();

    return (
        <UnevenCut
            blurText={!isActive}
            side={true}
            bigger={
                <div className={styles.biggerWrapper}>
                    <div className='d-flex flex-column flex-fill gap-5'>
                        <Address withIcon property={property} className={classnames('fw-bold', styles.addressGrid)} />
                        <Divider />
                        <ObjectInformation property={property} />
                        <Divider />
                        {showReadMore ? (
                            <WithReadMore isOpened={isDescriptionOpened} callback={onReadMoreClick}>
                                <Description description={nonNullDescription} />
                            </WithReadMore>
                        ) : (
                            <>
                                <Description description={nonNullDescription} />
                                <Divider />
                            </>
                        )}
                        {/*<Contacts contactInfo={contactInfo} propertyId={property.id.toString()} />*/}
                        {/*{contactInfo && Object.values(contactInfo).some(info => info) && <Divider />}*/}
                        {/*{address?.coordinates && isActive && (*/}
                        {/*    <PropertyLocationMapOld*/}
                        {/*        city={address?.city || ''}*/}
                        {/*        zip={address.postalCode}*/}
                        {/*        location={address.coordinates}*/}
                        {/*        className={styles.mapContainer}*/}
                        {/*    />*/}
                        {/*)}*/}
                        {/*<PropertyLocationMap property={property} />*/}
                    </div>
                </div>
            }
        />
    );
};

export const DescriptionSpinner = () => {
    const { t } = useTranslation('properties');
    return (
        <div className='d-flex ms-4 gap-1'>
            <Spinner variant='coral' animation={'border'} size='sm' />
            <p className='text-footnote m-0 text-nowrap'>{t('improving_the_description')}</p>
        </div>
    );
};

function ObjectInformation({ property }: { property: Property }) {
    const { t } = useTranslation(['metasearch', 'properties']);
    const CASAFARI_TOP_FLOOR_VALUE = 'top';

    const propertyType = property.propertyType;
    const { constructionYear, area, rooms, bedRooms, bathRooms, energyCertificate, floor } = property;

    const characteristics = property.characteristics?.filter(
        characteristic => characteristic && Object.values(PROPERTY_FEATURES).includes(characteristic.toLowerCase())
    );

    if (floor === CASAFARI_TOP_FLOOR_VALUE) characteristics?.push('top_floor');

    return (
        <div style={{ flex: 1 }}>
            <h3 className='text-copy-s-bold'>{t('Object Information')}</h3>
            {!!characteristics && (
                <div data-cy='features' className='mt-5 mb-5'>
                    {characteristics?.map(characteristic => (
                        <Badge
                            key={characteristic}
                            data-cy={`feature-${characteristic}`}
                            pill
                            bg='velvet-100'
                            className='d-inline-block px-4 mb-2 me-2 py-1'
                        >
                            {t(`features.${characteristic}`, { ns: 'common' })}
                        </Badge>
                    ))}
                </div>
            )}
            <ul className={styles.leaders}>
                {propertyType ? (
                    <li>
                        <span>{t('property_type', { ns: 'properties' })}</span>
                        <Divider />
                        <span>{pascalCase(propertyType.toLowerCase())}</span>
                    </li>
                ) : (
                    ''
                )}

                {area ? (
                    <li>
                        <span>{t('Living area')}</span>
                        <Divider />
                        <span>{area + '  m²'}</span>
                    </li>
                ) : (
                    ''
                )}
                {!!rooms && rooms > 0 && (
                    <li>
                        <span>{t('Rooms')}</span>
                        <Divider />
                        <span>{rooms}</span>
                    </li>
                )}
                {!!bedRooms && bedRooms !== 0 && (
                    <li>
                        <span>{t('bedrooms')}</span>
                        <Divider />
                        <span>{bedRooms}</span>
                    </li>
                )}
                {!!bathRooms && bathRooms !== 0 && (
                    <li>
                        <span>{t('bathrooms')}</span>
                        <Divider />
                        <span>{bathRooms}</span>
                    </li>
                )}
                {typeof constructionYear === 'number' && (
                    <li>
                        <span>{t('year_of_construction')}</span>
                        <Divider />
                        <span>{constructionYear}</span>
                    </li>
                )}
                {energyCertificate && (
                    <li>
                        <span>{t('energy_certificate')}</span>
                        <Divider />
                        <span>{energyCertificate}</span>
                    </li>
                )}
            </ul>
        </div>
    );
}

export const UnevenCut = ({
    bigger,
    smaller,
    side = true,
    swapWidths = false,
    blurText = false,
}: {
    bigger: ReactElement;
    smaller?: ReactElement | null;
    side?: boolean;
    swapWidths?: boolean;
    blurText?: boolean;
}) => {
    return (
        <div
            className={classnames(
                styles.marginAligner,
                side ? styles.padRight : styles.padLeft,
                blurText && styles.blurText
            )}
        >
            <div className={classnames(styles.contentContainer)}>
                <div
                    className={classnames(styles.textContainer, side && styles.orderOne)}
                    style={{ width: swapWidths && 'var(--property-page-smaller)' }}
                >
                    {smaller}
                </div>
                {bigger}
            </div>
        </div>
    );
};

function formatDescription(description: string | object, locale = 'de') {
    if (!description) return '';
    const language = locale === 'de' ? 'german' : 'english';
    let parsedDescription;
    try {
        if (typeof description === 'string') {
            parsedDescription = JSON.parse(description);
            parsedDescription = parsedDescription[language];
        } else if (typeof description === 'object') {
            parsedDescription = description[language];
        }
    } catch (e) {
        return description;
    } finally {
        return parsedDescription;
    }
}
