import { CustomerUserData, RegisterFunction, RegisterResult, RegistrationVobahome } from '../../../registration';
import { RegisterMutation, Sdk, useBff } from '@/lib/api/bff';
import { DynamicComponentElementProps } from '../blocks/DynamicComponent';
import React, { useContext } from 'react';
import { RegistrationFormContext } from './registration-form';

export function RegistrationFormVobahome(props: DynamicComponentElementProps) {
    const { id = '', configuration } = props || {};
    const { mandatoryFields = [] } = configuration || {};
    const api = useBff();
    const additionalContent = useContext(RegistrationFormContext);

    return (
        <RegistrationVobahome
            id={id}
            register={register(api)}
            mandatoryFields={mandatoryFields}
            additionalContent={additionalContent.content}
        />
    );
}

function register(api: Pick<Sdk, 'Register'>): RegisterFunction {
    return (data: CustomerUserData) => {
        const { firstName, lastName, email, phone, password, locale, newsletter, searchAgent = true, referrer } = data;
        const user = {
            firstName,
            lastName,
            email,
            phone: phone || null,
            password: password || null,
            locale,
            newsletter,
            searchAgent,
            referrer,
            isNetIncomeMax: null,
            netIncome: null,
            salutation: null,
            title: null,
        };

        return api.Register({ data: { user } }).then(
            ({ register }: RegisterMutation) => {
                return register as RegisterResult;
            },
            err => {
                return {
                    success: false,
                    errors: [{ code: 'invocation_failed', message: err.message }],
                } as RegisterResult;
            }
        );
    };
}
