import React from 'react';

export default function Location(props: object) {
    return (
        <svg width='16' height='20' viewBox='0 0 16 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M8.71059 19.1819L8 20L7.28941 19.1819C2.77263 13.9821 0.5 10.1223 0.5 7.43044C0.5 3.32672 3.85786 0 8 0C12.1421 0 15.5 3.32672 15.5 7.43044C15.5 10.1223 13.2274 13.9821 8.71059 19.1819ZM2.375 7.43044C2.375 9.40545 4.24206 12.6902 8 17.1408C11.7579 12.6902 13.625 9.40545 13.625 7.43044C13.625 4.35265 11.1066 1.85761 8 1.85761C4.8934 1.85761 2.375 4.35265 2.375 7.43044ZM8 11.1457C5.92893 11.1457 4.25 9.4823 4.25 7.43044C4.25 5.37858 5.92893 3.71522 8 3.71522C10.0711 3.71522 11.75 5.37858 11.75 7.43044C11.75 9.4823 10.0711 11.1457 8 11.1457ZM8 9.28805C9.03553 9.28805 9.875 8.45637 9.875 7.43044C9.875 6.40451 9.03553 5.57283 8 5.57283C6.96447 5.57283 6.125 6.40451 6.125 7.43044C6.125 8.45637 6.96447 9.28805 8 9.28805Z'
                fill='currentColor'
            />
        </svg>
    );
}
