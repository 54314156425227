import React, { useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { TFunction } from 'react-i18next';
import { Col, Container, FloatingLabel, Form, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { PropertySearchOrderBy } from '@/lib/api/bff';
import { Button } from '@/components/common';
import LocationSelect from './LocationSelect';
import OrderByComponent from './OrderByComponent';
import { FormPropertyType, SearchFiltersProps } from '../types';
import styles from '../styles.module.scss';
import SaveDefaultSearchButton from './SaveDefaultSearchButton';
import { FieldValues, UseFormSetValue, UseFormGetValues } from 'react-hook-form';
export function SearchFilters({
    register,
    watch,
    setValue,
    control,
    onSearchBtnClick,
    onOrderOptionClick,
    isValid,
    isSubmitting,
    getValues,
    onFieldTouched,
    errors,
    regions = [],
}: SearchFiltersProps) {
    const { t } = useTranslation(['myOwnr', 'properties']) as unknown as { t: TFunction<string, undefined> };

    watch((data: unknown, { name }: Record<string, string>) => {
        if (name === 'region') {
            setValue('locationsIds', null);
            setValue('offset', 0);
        }
    });
    const watchRegion = watch('region');
    const orderByValue = watch('orderBy') as PropertySearchOrderBy;

    useEffect(() => {
        register('orderBy');
    }, [register]);

    return (
        <Container className={classNames(styles['search-filter'], 'mb-6')}>
            <Form>
                <Row>
                    <Col md={3} className='mb-3'>
                        <Form.Group controlId='formGridCity'>
                            <Form.Select
                                data-cy='search-filters-region'
                                {...register('region')}
                                defaultValue={watchRegion}
                                name='region'
                                onFocus={() => onFieldTouched('region')}
                            >
                                {regions.map(region => {
                                    return (
                                        <option key={region} value={region}>
                                            {t(`region.${region}`)}
                                        </option>
                                    );
                                })}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md={9} className='mb-3'>
                        <Form.Group controlId='formGridDistrict'>
                            <LocationSelect
                                region={watchRegion}
                                control={control}
                                setValue={setValue as unknown as UseFormSetValue<FieldValues>}
                                getValues={getValues as unknown as UseFormGetValues<FieldValues>}
                                watch={watch as unknown as UseFormGetValues<FieldValues>}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={4} lg={2} sm={12} xs={12} className='mb-3'>
                        <Form.Group as={Col} controlId='formGridPrice'>
                            <FloatingLabel
                                controlId='minLeaseRate'
                                label={`${t('min_lease_rate', { ns: 'properties' })}`}
                                className={`mb-lg-6 mb-md-2 d-grid mb-2 mt-4 ${errors.minLeaseRate && 'text-danger'}`}
                            >
                                <Form.Control
                                    className={errors?.minLeaseRate && classNames(styles['text-danger'])}
                                    placeholder={t('min_lease_rate', { ns: 'properties' })}
                                    onFocus={() => onFieldTouched('minLeaseRate')}
                                    {...register('minLeaseRate', { pattern: /^[0-9]*$/ })}
                                />
                            </FloatingLabel>
                        </Form.Group>
                    </Col>
                    <Col md={4} lg={2} sm={12} xs={12} className='mb-3'>
                        <Form.Group as={Col} controlId='formGridPrice'>
                            <FloatingLabel
                                controlId='maxLeaseRate'
                                label={`${t('max_lease_rate', { ns: 'properties' })}`}
                                className={`mb-lg-6 mb-md-2 d-grid mb-2 mt-4 ${errors.maxLeaseRate && 'text-danger'}`}
                            >
                                <Form.Control
                                    className={errors?.maxLeaseRate && classNames(styles['text-danger'])}
                                    placeholder={t('max_lease_rate', { ns: 'properties' })}
                                    onFocus={() => onFieldTouched('maxLeaseRate')}
                                    {...register('maxLeaseRate', { pattern: /^[0-9]*$/ })}
                                />
                            </FloatingLabel>
                        </Form.Group>
                    </Col>
                    <Col md={4} lg={2} sm={3} xs={6} className='mb-3'>
                        <Form.Group as={Col} controlId='formGridSpace'>
                            <FloatingLabel
                                controlId='minSpace'
                                label={`${t('min_space', { ns: 'properties' })}`}
                                className={`mb-6 d-grid mb-xs-1 mb-sm-1 mt-4 ${errors.minSpace && 'text-danger'}`}
                            >
                                <Form.Control
                                    className={errors?.minSpace && classNames(styles['text-danger'])}
                                    placeholder={t('min_space', { ns: 'properties' })}
                                    onFocus={() => onFieldTouched('minSpace')}
                                    {...register('minSpace', { pattern: /^[0-9]*$/ })}
                                />
                            </FloatingLabel>
                        </Form.Group>
                    </Col>
                    <Col md={3} lg={2} sm={3} xs={6} className='mb-3 mt-4'>
                        <Form.Group as={Col} controlId='formGridPropertyRooms'>
                            <Form.Select
                                aria-label={'Floating label select example'}
                                {...register('minRooms')}
                                defaultValue={0}
                                onFocus={() => onFieldTouched('minRooms')}
                            >
                                <option disabled value='0'>
                                    {t('rooms', { ns: 'properties' })}
                                </option>
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md={3} lg={2} sm={3} xs={6} className='mb-3 mt-4 '>
                        <Form.Group controlId='formGridPropertyType'>
                            <Form.Select {...register('propertyType')} onFocus={() => onFieldTouched('propertyType')}>
                                <option disabled value='DEFAULT'>
                                    {t('property_type', { ns: 'properties' })}
                                </option>
                                <option value={FormPropertyType.HOUSE}>{t('HOUSE', { ns: 'properties' })}</option>
                                <option value={FormPropertyType.APARTMENT}>
                                    {t('APARTMENT', { ns: 'properties' })}
                                </option>
                                <option value={FormPropertyType.ALL}>
                                    {t('HOUSE', { ns: 'properties' }) + ' & ' + t('APARTMENT', { ns: 'properties' })}
                                </option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col className='mb-3 mt-4'>
                        <div className={'d-flex justify-content-end'}>
                            <Form.Group controlId='searchBtn'>
                                <Button
                                    className='float-end'
                                    onClick={onSearchBtnClick}
                                    color='velvet'
                                    type='submit'
                                    disabled={!isValid || isSubmitting}
                                >
                                    {t('search', { ns: 'properties' })}
                                    {isSubmitting ? <i className='icon icon-ownr-o loader' /> : ''}
                                </Button>
                            </Form.Group>
                        </div>
                    </Col>
                </Row>
                <Row xs='auto' className='justify-content-between'>
                    <Col>
                        <OrderByComponent selectedOrderOption={orderByValue} onOrderOptionClick={onOrderOptionClick} />
                    </Col>
                    <Col>
                        <SaveDefaultSearchButton getValues={getValues} />
                    </Col>
                </Row>
            </Form>
        </Container>
    );
}
