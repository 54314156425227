import { default as Search } from './search';
import { default as ChevronDown } from './chevron-down';
import { default as ChevronUp } from './chevron-up';
import { default as ChevronLeft } from './chevron-left';
import { default as ChevronRight } from './chevron-right';
import { default as Play } from './play';
import { default as Minus } from './minus';
import { default as Plus } from './plus';
import { default as Close } from './close';
import { default as Info } from './info';
import { default as Menu } from './menu';
import { default as ArrowRight } from './arrow-right';
import { default as ArrowLeft } from './arrow-left';
import { default as ArrowCircleRight } from './arrow-circle-right';
import { default as ArrowCircleLeft } from './arrow-circle-left';
import { default as Profile } from './profile';
import { default as Share } from './share';
import { default as Location } from './location';
import { default as Attention } from './attention';
import { default as Show } from './show';
import { default as Hide } from './hide';
import { default as Linkedin } from './linkedin';
import { default as Facebook } from './facebook';
import { default as Twitter } from './twitter';
import { default as Instagram } from './instagram';

const glyphs = {
    search: Search,
    'chevron-down': ChevronDown,
    'chevron-up': ChevronUp,
    'chevron-left': ChevronLeft,
    'chevron-right': ChevronRight,
    play: Play,
    minus: Minus,
    plus: Plus,
    close: Close,
    info: Info,
    menu: Menu,
    'arrow-right': ArrowRight,
    'arrow-left': ArrowLeft,
    'arrow-circle-right': ArrowCircleRight,
    'arrow-circle-left': ArrowCircleLeft,
    profile: Profile,
    share: Share,
    location: Location,
    attention: Attention,
    show: Show,
    hide: Hide,
    linkedin: Linkedin,
    facebook: Facebook,
    twitter: Twitter,
    instagram: Instagram,
};

export default glyphs;
