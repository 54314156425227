import { Icon as Record } from '@/lib/api/graphcms';
import classNames from 'classnames';
import { contrastTextClass, constructURL, getWidths, resizeImage, srcSet } from '@/lib/utilities';
import styles from './Icon.module.scss';

export type IconProps = Partial<Record> &
    Partial<Pick<React.HTMLAttributes<HTMLDivElement>, 'className'>> & { noPadBot?: boolean; videoIcon?: boolean };

export function Icon(props: IconProps = {}) {
    const { asset, background, border, word, noPadBot = false, videoIcon = false } = props || {};
    return (
        <div className={classNames(!noPadBot && styles['icon'], props.className)}>
            <div
                className={classNames(
                    `bg-${background}`,
                    !noPadBot && styles['iconWrap'],
                    !noPadBot && styles['padBot'],
                    noPadBot && styles['noPadBot']
                )}
                style={{ borderRadius: border, ...(!noPadBot && { paddingBottom: '100%' }) }}
            >
                {icon(asset, { noPadBot, videoIcon })}
                {word && word?.length > 0 && (
                    <div className={classNames(styles['iconText'], contrastTextClass(background))}>{word}</div>
                )}
            </div>
        </div>
    );
}

function icon(
    asset: IconProps['asset'],
    props: { noPadBot: boolean; videoIcon: boolean | undefined }
): JSX.Element | null {
    const { noPadBot, videoIcon } = props;
    if (!asset || asset?.handle.length <= 0) return null;
    const base = constructURL(asset.handle, false);

    if (asset?.mimeType?.startsWith('image/svg')) {
        return (
            /* eslint-disable-next-line @next/next/no-img-element */
            <img
                src={base()()}
                className={classNames(!noPadBot && styles['iconImage'], videoIcon && styles.videoIcon)}
                alt={'Icon'}
            />
        );
    }

    const imageSrcSet = srcSet(base, getWidths(asset.width || 100));
    const finalSrc = base(resizeImage({ ...asset }))();

    return (
        /* eslint-disable-next-line @next/next/no-img-element */
        <img
            src={finalSrc}
            srcSet={imageSrcSet}
            className={classNames(!noPadBot && styles['iconImage'], videoIcon && styles.videoIcon)}
            alt={'Icon'}
        />
    );
}
