import { Stack } from 'react-bootstrap';
import styles from './contact.module.scss';
import classNames from 'classnames';
import { TFunction } from 'react-i18next';
import { ContactData } from './contact';

export type SuccessScreenProps = {
    t: TFunction;
    values: Omit<ContactData, 'locale' | 'token' | 'userId'> & { caseNumber: string | null };
};

export function SuccessScreen({ values, t }: SuccessScreenProps) {
    return (
        <div className='d-flex justify-content-center align-middle h-100'>
            <Stack direction='vertical' gap={2}>
                <h6>
                    {t('success.headline')}&nbsp;
                    <i className={classNames('icon icon-check-mark', styles['icon'])} />{' '}
                </h6>
                <p>{t('success.text_explain', values)} </p>
                <p>{t('success.text_explain_more', values)} </p>
            </Stack>
        </div>
    );
}
