import { ElementType, MouseEventHandler, ReactNode } from 'react';
import { Dropdown as DropdownBS } from 'react-bootstrap';
import styles from './styles.module.scss';
import classNames from 'classnames';

type DropdownProps = {
    children: ReactNode | string;
    items: { text: string; onClick: MouseEventHandler<HTMLElement> }[];
    containerClassName?: string;
    buttonClassName?: string;
    size?: 'xs';
    pill?: boolean;
    variant: 'primary' | 'secondary';
    itemsAs?: ElementType;
    disabled?: boolean;
};

const Dropdown = ({
    children,
    items,
    containerClassName,
    buttonClassName,
    size = 'xs',
    pill,
    variant,
    itemsAs,
    disabled,
}: DropdownProps) => {
    return (
        <DropdownBS className={classNames('design-system', containerClassName)}>
            <DropdownBS.Toggle
                disabled={disabled}
                variant='_'
                className={classNames(
                    buttonClassName,
                    size === 'xs' && 'body-s p-1',
                    pill ? 'rounded-32' : 'rounded-4',
                    styles[variant]
                )}
            >
                {children}
            </DropdownBS.Toggle>
            <DropdownBS.Menu renderOnMount className={classNames(styles['menu'])} popperConfig={{ strategy: 'fixed' }}>
                {items.map((item, index) => (
                    <DropdownBS.Item
                        as={itemsAs}
                        onClick={item.onClick}
                        className={classNames(styles['menu-item'], 'py-2', size === 'xs' && 'body-s')}
                        key={index}
                    >
                        {item.text}
                    </DropdownBS.Item>
                ))}
            </DropdownBS.Menu>
        </DropdownBS>
    );
};

export default Dropdown;
