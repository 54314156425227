import { FieldValues, useForm } from 'react-hook-form';
import { Dispatch, SetStateAction } from 'react';
import { TFunction } from 'react-i18next';
import { createOpenEndInterval, createPreferences } from './util';
import {
    BrokerApplicationFunction,
    CustomerApplicationData,
    CustomerApplicationUserFunction,
    FormPartProps,
    PreferenceType,
    RegisterFunction,
} from './types';
import { Alert } from 'react-bootstrap';
import { gtm } from '@/lib/utilities';
import { Step, RegistrationState, Location } from './types';
import { BrokerRegistrationData } from '@/lib/api/bff';

export type FormSubmitProps = FormPartProps & {
    customerApplication: CustomerApplicationUserFunction;
    maxRooms?: number;
    maxLivingArea?: number;
    maxNetIncome?: number;
    maxMonthlyBudget?: number;
};

export function onSubmit(
    props: FormSubmitProps & { maxNetIncome?: number; maxMonthlyBudget?: number },
    locale: string,
    referrer: string,
    setError: ReturnType<typeof useForm>['setError'],
    setSubmissionErrors: Dispatch<SetStateAction<string[]>>
) {
    const {
        otherValues,
        maxLivingArea = 140,
        maxNetIncome,
        maxMonthlyBudget,
        maxRooms = 5,
        customerApplication,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        navigateForward = () => {},
    } = props || {};
    const { city, districts, features } = otherValues || {};

    return async (values: FieldValues) => {
        const featurePreferences = createPreferences(features?.propertyFeatures);

        const locations = districts?.districts?.map((value: string) => ({
            key: value,
            type: PreferenceType.LIKE,
        }));

        const locationNames: Location[] =
            districts?.locationsIds?.map((location: { value: number; label: string }) => ({
                id: location.value,
                name: location.label,
            })) || [];

        const propertyTypes: string[] | null = features?.propertyType
            ? (Array.isArray(features?.propertyType)
                  ? (features?.propertyType as string[])
                  : [features?.propertyType]
              ).map(t => t.toUpperCase())
            : null;

        const {
            success,
            errors = [],
            rating,
            qualified,
            purchasePriceMax,
        } = await customerApplication({
            locale,
            region: city?.region,
            otherRegion: city?.otherRegion,
            propertyTypes,
            livingArea: createOpenEndInterval(features?.livingArea, maxLivingArea),
            rooms: createOpenEndInterval(features?.roomsTotal, maxRooms),
            // timing: urgency?.timing,
            features: featurePreferences,
            isNetIncomeMax: maxNetIncome && values?.netIncome >= maxNetIncome,
            isBudgetMax: maxMonthlyBudget && values?.monthlyBudget >= maxMonthlyBudget,
            locations,
            locationNames,
            propertyURL: city?.propertyURL,
            ...values,
            referrer,
        } as CustomerApplicationData);

        if (success) {
            setSubmissionErrors([]);
            gtm.event(gtm.EVENTS.APPLICATION_SUCCESSFUL, {
                category: gtm.CATEGORIES.APPLICATION,
                region: city?.region,
                propertyTypes,
                rating,
                qualified,
                label: `Region: ${city?.region} | property types: ${propertyTypes?.join(
                    ','
                )} | Rating: ${rating} | Qualified: ${qualified ? 'yes' : 'no'}`,
            });

            navigateForward({ ...values, rating, qualified, purchasePriceMax });
        } else {
            const submissionErrors = errors.map(({ propertyName, code }) => {
                return propertyName?.length ? `${propertyName}_${code}` : code;
            });
            setSubmissionErrors(submissionErrors);
        }
    };
}

export function onBrokerSubmit(
    brokerApplication: BrokerApplicationFunction,
    locale: string,
    referrer: string,
    setSuccess: Dispatch<SetStateAction<boolean>>,
    setSubmissionErrors: Dispatch<SetStateAction<string[]>>
) {
    return async (values: FieldValues) => {
        const { success, errors = [] } = await brokerApplication({
            ...values,
            organization: values?.organization?.toUpperCase(),
            locale,
            referrer,
        } as BrokerRegistrationData);

        if (success) {
            setSubmissionErrors([]);

            gtm.event(gtm.EVENTS.APPLICATION_SUCCESSFUL, {
                category: gtm.CATEGORIES.BROKER_APPLICATION,
            });

            setSuccess(true);
        } else {
            const submissionErrors = errors?.map(({ propertyName, code }) => {
                return propertyName?.length ? `${propertyName}_${code}` : code;
            });
            setSubmissionErrors(submissionErrors ?? []);
        }
    };
}

export function onVobahomeSubmit(
    register: RegisterFunction,
    locale: string,
    referrer: string,
    setSuccess: Dispatch<SetStateAction<boolean>>,
    setSubmissionErrors: Dispatch<SetStateAction<string[]>>
) {
    return async (values: FieldValues) => {
        const { success, errors = [] } = await register({
            ...values,
            locale,
            referrer: referrer ? `VOBAHOME_${referrer.toUpperCase()}` : 'VOBAHOME',
        } as CustomerApplicationData);

        if (success) {
            setSubmissionErrors([]);

            gtm.event(gtm.EVENTS.APPLICATION_SUCCESSFUL, {
                category: gtm.CATEGORIES.VOBAHOME_APPLICATION,
            });

            setSuccess(true);
        } else {
            const submissionErrors = errors?.map(({ propertyName, code }) => {
                return propertyName?.length ? `${propertyName}_${code}` : code;
            });
            setSubmissionErrors(submissionErrors ?? []);
        }
    };
}

export function SubmissionError({
    errors = [],
    t,
    values,
}: {
    errors: string[];
    t: TFunction;
    values?: Record<string, string>;
}) {
    if (!errors?.length) return null;
    const errorToDisplay = errors[0];
    return <Alert>{t(`errors.${errorToDisplay}`, { ...values })}</Alert>;
}

export function calculateNextStep(data: FieldValues, target: Step): Step {
    return target;
}

export function calculatePreviousStep(data: RegistrationState, current: Step, target: Step): Step {
    const { region } = data.city.values;
    if (current === 'contact' && region !== 'external') return 'features';

    return target;
}
