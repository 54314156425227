import React from 'react';

export default function Menu(props: object) {
    return (
        <svg width='22' height='14' viewBox='0 0 22 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path fillRule='evenodd' clipRule='evenodd' d='M22 2H0V0H22V2Z' fill='currentColor' />
            <path fillRule='evenodd' clipRule='evenodd' d='M22 8H0V6H22V8Z' fill='currentColor' />
            <path fillRule='evenodd' clipRule='evenodd' d='M22 14H0V12H22V14Z' fill='currentColor' />
        </svg>
    );
}
