import NextLink from 'next/link';
import React from 'react';
import { LinkProps } from './types';
import { useRouter } from 'next/router';
import { getPartnerPathFrom, targetUrl } from '@/components/navigation/utils';

const VALID_ATTRIBUTES = new Set([
    'accessKey',
    'className',
    'contextMenu',
    'dir',
    'id',
    'lang',
    'style',
    'role',
    'onClick',
]);

export function Link(props: LinkProps = {}) {
    const {
        external,
        children = [],
        target,
        useLink = true,
        anchor,
        parameter: query,
        dynamicParams,
        locale,
        newWindow = false,
        ...o
    } = props || {};
    const optional = Object.fromEntries(Object.entries(o).filter(([key]) => VALID_ATTRIBUTES.has(key)));
    if (newWindow) optional['target'] = '_blank';

    const router = useRouter();

    const partnerUrlPath = getPartnerPathFrom(router.pathname.replace(query ?? '', ''));

    if (external?.length)
        return (
            <a href={external} {...optional}>
                {children}
            </a>
        );
    if (!target) return <a {...optional}>{children}</a>;

    let pathname = targetUrl(target, partnerUrlPath);
    if (anchor?.length) {
        pathname = pathname + '#' + anchor;
    }

    if (useLink)
        return (
            <NextLink
                href={{ pathname, query: dynamicParams && Object.keys(dynamicParams).length ? dynamicParams : query }}
                passHref={true}
                locale={locale}
                {...optional}
            >
                {children}
            </NextLink>
        );
    return (
        <a href={locale?.length ? `/${locale}${pathname}` : pathname} {...optional}>
            {children}
        </a>
    );
}

export default Link;
