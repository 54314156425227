import { FormCheck, Modal, Row } from 'react-bootstrap';
import React, { useMemo, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { FormPartProps } from '../../types';
import { Trans } from 'next-i18next';
import { Wrapper } from '@/components/graph-cms';
import { Button, colorVariant, lockSymbols, patternVariant } from '@/components/common';
import classNames from 'classnames';
import styles from '../../registration.module.scss';

type ServiceCheckboxesProps = Pick<FormPartProps, 't' | 'content'> & {
    form: UseFormReturn;
};

export function ServiceCheckboxes(props: ServiceCheckboxesProps) {
    const { form, t, content } = props;
    const { terms, privacyPolicy } = content;
    const {
        register,
        formState: { isSubmitting },
    } = form || {};
    const [showTermsModal, setShowTermsModal] = useState<boolean>(false);
    const [showPrivacyModal, setShowPrivacyModal] = useState<boolean>(false);

    const symbols = useMemo(() => {
        const date = new Date();
        return lockSymbols(5, 3, {
            pattern: patternVariant(date.getDate()),
            colors: colorVariant(date.getDay()),
            animate: [
                [0, 0],
                [1, 1],
                [0, 2],
                [2, 2],
                [1, 0],
                [0, 1],
                [1, 2],
                [2, 1],
                [3, 1],
            ],
        });
    }, []);

    return (
        <>
            <div className='mb-3 mt-6'>
                <FormCheck
                    type='checkbox'
                    label={t('checkboxes.newsletter')}
                    {...register('newsletter', {
                        required: false,
                    })}
                    disabled={isSubmitting}
                />
            </div>
            <Row>
                <span>
                    <Trans i18nKey='checkboxes.terms' ns='register'>
                        xxx
                        <a className={`link-ternary`} onClick={() => setShowTermsModal(true)}>
                            Terms
                        </a>
                        xxx
                        <a className={`link-ternary`} onClick={() => setShowPrivacyModal(true)}>
                            Privacy
                        </a>
                        .
                    </Trans>
                </span>
            </Row>
            <Modal show={showTermsModal} centered={true} onHide={() => setShowTermsModal(false)}>
                <Modal.Header closeButton={true}>{terms?.title}</Modal.Header>
                <Modal.Body>
                    <Wrapper elements={terms?.columns} styleContext={{ background: 'bg-white' }} />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setShowTermsModal(false)}>{t('checkboxes.modal_close')}</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showPrivacyModal} centered={true} onHide={() => setShowPrivacyModal(false)}>
                <Modal.Header closeButton={true}>{privacyPolicy?.title}</Modal.Header>
                <Modal.Body>
                    <Wrapper elements={privacyPolicy?.columns} styleContext={{ background: 'bg-white' }} />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setShowPrivacyModal(false)}>{t('checkboxes.modal_close')}</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={isSubmitting} fullscreen={true}>
                <Modal.Body className={classNames('bg-velvet-50', styles['transition-screen'])}>
                    <div className={classNames('container text-title', styles['claim'])}>{t('transition.claim')}</div>
                    <div className={classNames('container text-caption')}>{t('transition.message')}</div>
                    <div
                        style={{ backgroundImage: `url("data:image/svg+xml,${encodeURIComponent(symbols)}")` }}
                        className={classNames('container', styles['decoration'])}
                    >
                        &nbsp;
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
