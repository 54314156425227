import { AppointmentSlot, Location } from '@/lib/api/lease-requests/types';
import { notEmpty } from '@/lib/utilities';
import moment from 'moment';

export const getShortAddress = (location: Location) => {
    if (!location) {
        return '';
    }
    const { zipCodes, city } = location;

    return [zipCodes?.[0], city].filter(Boolean).join(', ');
};

export const getImageUrl = (urls?: (string | null)[] | null): string => {
    if (!urls || urls.length < 1) return 'https://static.ownr.eu/img/placeholder/property.svg';

    return urls.filter(notEmpty)[0];
};

export const getFullAddress = (location: Location) => {
    if (!location) {
        return '';
    }
    const { zipCodes, city, street, houseNumber } = location;

    const zipLine = [zipCodes?.[0], city].filter(Boolean).join(' ');
    return [zipLine, street, houseNumber].filter(Boolean).join(', ');
};

export const getActiveSlots = (slots: AppointmentSlot[], maxLength = 4) => {
    const now = moment.utc();
    return slots?.filter(slot => slot.status === 'ACTIVE' && moment(slot.startDate).isAfter(now))?.slice(0, maxLength);
};
