import React, { useState } from 'react';
import classNames from 'classnames';
import { Trans, useTranslation } from 'next-i18next';

import { Button, Form } from '@/components/design-system';
import { Checkbox } from '@/components/design-system/form/inputs/Checkbox';
import { Textarea } from '@/components/design-system/form/inputs/Textarea';
import { Group, Item } from '@/components/design-system/list-group';

const TIME_SLOTS = ['08:00-12:00', '12:00-15:00', '15:00-18:00'];

export const AvailabilityPicker = ({
    onSubmit,
    className,
}: {
    onSubmit: ({ comment, timeSlots }: { comment: string; timeSlots: string[] | null }) => Promise<void>;
    className?: string;
}) => {
    const { t } = useTranslation('metasearch');
    const [selectedTimeSlots, setSelectedTimeSlots] = useState<string[]>(TIME_SLOTS);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [comment, setComment] = useState('');

    const handleTimeSlotChange = interval => {
        const checked = selectedTimeSlots.includes(interval);
        if (checked) {
            setSelectedTimeSlots(selectedTimeSlots.filter(s => s !== interval));
        } else {
            setSelectedTimeSlots([...selectedTimeSlots, interval]);
        }
    };

    const handleAvailabilitySubmit = () => {
        if (isSubmitting) return;

        setIsSubmitting(true);

        const sortedTimeSlots = selectedTimeSlots.sort((a, b) => {
            const aStart = a.split('-')[0];
            const bStart = b.split('-')[0];

            return aStart.localeCompare(bStart);
        });

        onSubmit({ comment, timeSlots: sortedTimeSlots }).finally(() => setIsSubmitting(false));
    };

    return (
        <Form className={classNames(className, 'd-flex gap-5')} data-cy='availability-picker'>
            <Group className='body-m'>
                <p className='body-m mb-3'>{t('availability_form.time_slots_header')}</p>
                {TIME_SLOTS.map((interval, index) => {
                    const id = `days[${index}]`;
                    const checked = selectedTimeSlots.includes(interval);
                    return (
                        <Item
                            onClick={() => handleTimeSlotChange(interval)}
                            key={id}
                            selected={checked}
                            className={'d-flex align-items-baseline'}
                        >
                            <Checkbox
                                type='checkbox'
                                className='m-0'
                                checked={checked}
                                id={id}
                                name='availability-dates'
                                value={interval}
                                onChange={e => {
                                    handleTimeSlotChange(interval);
                                    e.stopPropagation();
                                }}
                                label={interval}
                            />
                        </Item>
                    );
                })}
            </Group>
            <p className='body-s m-0 line-height-18'>
                <Trans i18nKey='availability_form.description' ns='metasearch' />
            </p>
            <div className='d-flex flex-column justify-content-between'>
                <label htmlFor='comment' className='body-m bold mb-1'>
                    {t('availability_form.comment_label')}
                </label>
                <Textarea
                    id='comment'
                    value={comment}
                    className='body-s'
                    onChange={e => setComment(e.target.value)}
                    placeholder={t('availability_form.comment_placeholder')}
                    rows={5}
                />
            </div>

            <Button
                type='button'
                disabled={selectedTimeSlots.length === 0}
                size='md'
                onClick={handleAvailabilitySubmit}
                isSubmitting={isSubmitting}
            >
                {t('send_viewing_request')}
            </Button>
        </Form>
    );
};
