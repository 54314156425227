import React from 'react';

export default function Info(props: object) {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <circle cx='12' cy='12' r='8' stroke='currentColor' strokeWidth='2' />
            <path
                d='M12.7236 8V7.25H11.2236V8H12.7236ZM11.2236 13V13.75H12.7236V13H11.2236ZM11.9954 14.9502C11.5812 14.9502 11.2454 15.286 11.2454 15.7002C11.2454 16.1144 11.5812 16.4502 11.9954 16.4502V14.9502ZM12.0044 16.4502C12.4187 16.4502 12.7544 16.1144 12.7544 15.7002C12.7544 15.286 12.4187 14.9502 12.0044 14.9502V16.4502ZM11.2236 8V13H12.7236V8H11.2236ZM11.9954 16.4502H12.0044V14.9502H11.9954V16.4502Z'
                fill='currentColor'
            />
        </svg>
    );
}
