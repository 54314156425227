import isEmail from 'validator/lib/isEmail';
import { FloatingLabel, Form } from 'react-bootstrap';
import { Button } from '@/components/common';
import { ErrorMessage } from '@hookform/error-message';
import { ErrorText, isMandatory } from '../User/util';
import { FieldValues, useForm } from 'react-hook-form';
import { addStarWhenMandatory, isVisible } from '../User/util';
import { ServiceCheckboxes } from '../User/SubPages/SubComponents/service-checkboxes';
import { onBrokerSubmit, SubmissionError } from '../User/functions';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { getSavedQueryParam, gtm, pascalCase, QUERY_PARAMS } from '@/lib/utilities';
import classNames from 'classnames';
import styles from '../User/registration.module.scss';
import { BrokerContactFormProps } from '../User/types';
import { RevealButton } from '../User/SubPages/SubComponents/reveal-button';
import { SuccessPage } from './SuccessPage';

const FormComponent = (
    props: BrokerContactFormProps & { onSubmit: (values: FieldValues) => void; submissionErrors: string[] }
) => {
    const { formId, t, fieldsToHide = [], mandatoryFields = [], onSubmit, submissionErrors } = props || {};
    const passwordFieldId = formId('password');

    const [touched, setTouched] = useState(new Set());

    const formControl = useForm({
        mode: 'onChange',
    });
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors, isValid, touchedFields },
    } = formControl;
    const [hidePassword, setHidePassword] = useState<boolean>(true);

    const touchedFieldNames = Object.entries(touchedFields).map(([name]) => name);

    useEffect(() => {
        const newTouched = touchedFieldNames.filter(name => !touched.has(name));
        if (!newTouched?.length) return;

        newTouched.forEach(name => {
            gtm.event(gtm.EVENTS.FIELD_TOUCHED, { category: gtm.CATEGORIES.BROKER_APPLICATION, label: name });
        });

        setTouched(new Set(touchedFieldNames));
    }, [touched, touchedFieldNames]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)} className='p-0'>
            {isVisible(fieldsToHide, 'title') && (
                <Form.Select {...register('title')}>
                    <option>Title</option>
                    <option value='Dr.'>Dr.</option>
                    <option value='Prof.'>Prof.</option>
                    <option value='Prof. Dr.'>Prof. Dr.</option>
                </Form.Select>
            )}
            <FloatingLabel
                controlId={formId('first_name')}
                label={addStarWhenMandatory(mandatoryFields, 'firstName', t('contact.first_name'))}
                className='mb-3 d-grid w-100'
            >
                <Form.Control
                    type='text'
                    placeholder='Max'
                    isInvalid={!!errors.firstName}
                    {...register('firstName', {
                        minLength: 2,
                        required: true,
                    })}
                />
                <ErrorMessage
                    errors={errors}
                    name='firstName'
                    render={e => <ErrorText t={t} name='first_name' prefix='person' {...e} />}
                />
            </FloatingLabel>
            <FloatingLabel
                controlId={formId('last_name')}
                label={addStarWhenMandatory(mandatoryFields, 'lastName', t('contact.last_name'))}
                className='mb-3 d-grid w-100'
            >
                <Form.Control
                    type='text'
                    placeholder='Mustermann'
                    isInvalid={!!errors.lastName}
                    {...register('lastName', {
                        minLength: 2,
                        required: true,
                    })}
                />
                <ErrorMessage
                    errors={errors}
                    name='lastName'
                    render={e => <ErrorText t={t} name='last_name' prefix='person' {...e} />}
                />
            </FloatingLabel>
            <FloatingLabel
                controlId={formId('email')}
                label={addStarWhenMandatory(mandatoryFields, 'email', t('contact.email'))}
                className='mb-3 d-grid w-100'
            >
                <Form.Control
                    type='email'
                    autoComplete={'username'}
                    placeholder='name@example.com'
                    isInvalid={!!errors.email}
                    {...register('email', {
                        minLength: 8,
                        required: true,
                        validate: { format: v => isEmail(v) },
                    })}
                />
                <ErrorMessage
                    errors={errors}
                    name='email'
                    render={e => <ErrorText t={t} name='email' prefix='person' {...e} />}
                />
            </FloatingLabel>
            <div className={classNames('mb-3 w-100', styles['password'])}>
                <div className='input-group mb-3'>
                    <div className='form-floating form-floating-group flex-grow-1'>
                        <input
                            id={passwordFieldId}
                            autoComplete={'current-password'}
                            className={classNames('form-control', { 'is-invalid': errors.password })}
                            type={hidePassword ? 'password' : 'text'}
                            aria-describedby={pascalCase('passwordFieldId', 'eye')}
                            placeholder='test123'
                            {...register('password', {
                                minLength: 8,
                                required: true,
                                pattern:
                                    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/,
                            })}
                        />
                        <label htmlFor={passwordFieldId}>
                            {addStarWhenMandatory(mandatoryFields, 'password', t('password.subline'))}
                        </label>
                    </div>
                    <span
                        id={pascalCase(passwordFieldId, 'eye')}
                        role='button'
                        className={classNames('input-group-text bg-white align-bottom', {
                            'is-invalid': errors.password,
                        })}
                        style={{ height: '59px' }}
                        onClick={() => setHidePassword(!hidePassword)}
                    >
                        <RevealButton isRevealed={!hidePassword} />
                    </span>
                </div>
                <ErrorMessage
                    errors={errors}
                    name='password'
                    render={e => <ErrorText t={t} name='password' prefix='person' {...e} />}
                />
                <span className='description text-form'>{t('password.explain')}</span>
            </div>
            <FloatingLabel
                controlId={formId('phone')}
                label={addStarWhenMandatory(mandatoryFields, 'phone', t('contact.phone'))}
                className='mb-6 d-grid w-100'
            >
                <Form.Control
                    type='tel'
                    placeholder='+494000000000'
                    isInvalid={!!errors.phone}
                    {...register('phone', {
                        minLength: 2,
                        maxLength: 16,
                        required: isMandatory(mandatoryFields, 'phone'),
                    })}
                />
                <ErrorMessage
                    errors={errors}
                    name='phone'
                    render={e => <ErrorText t={t} name='phone' prefix='person' {...e} />}
                />
            </FloatingLabel>
            <FloatingLabel
                controlId={formId('organization')}
                label={addStarWhenMandatory(mandatoryFields, 'organization', t('contact.organization'))}
                className='mb-6 d-grid w-100'
            >
                <Form.Control
                    type='text'
                    placeholder='organization'
                    isInvalid={!!errors.organization}
                    {...register('organization', {
                        maxLength: 100,
                        required: isMandatory(mandatoryFields, 'organization'),
                    })}
                />
                <ErrorMessage
                    errors={errors}
                    name='organization'
                    render={e => <ErrorText t={t} name='organization' {...e} />}
                />
            </FloatingLabel>
            {!!submissionErrors?.length && (
                <SubmissionError errors={submissionErrors} t={t} values={{ email: watch('email') }} />
            )}
            <ServiceCheckboxes form={formControl} {...props} />
            <div className='mt-5'>
                <Button buttonType='submit' disabled={!isValid}>
                    {t('contact.btn_submit')}
                </Button>
            </div>
        </Form>
    );
};

export const ContactForm = (props: BrokerContactFormProps) => {
    const router = useRouter();
    const { locale = 'de' } = router;
    const { formId, t, fieldsToHide = [], mandatoryFields = [], brokerApplication } = props || {};
    const [submissionSuccess, setSubmissionSuccess] = useState<boolean>(false);
    const [submissionErrors, setSubmissionErrors] = useState<string[]>([]);

    const referrer = getSavedQueryParam(QUERY_PARAMS.REFERRER) || '';

    return (
        <>
            {submissionSuccess ? (
                <SuccessPage t={t} />
            ) : (
                <>
                    <span className='w-100 mb-4 text-copy-l p-0'>{t('contact.headline')}</span>
                    <div className='p-0'>
                        <p>{t('contact.intro')}</p>
                        <p>{t('contact.required_fields')}</p>
                    </div>
                    <FormComponent
                        {...props}
                        formId={formId}
                        mandatoryFields={mandatoryFields}
                        fieldsToHide={fieldsToHide}
                        t={t}
                        submissionErrors={submissionErrors}
                        onSubmit={onBrokerSubmit(
                            brokerApplication,
                            locale,
                            referrer,
                            setSubmissionSuccess,
                            setSubmissionErrors
                        )}
                    />
                </>
            )}
        </>
    );
};
