import React from 'react';

export default function ChevronDown(props: object) {
    return (
        <svg width='20' height='11' viewBox='0 0 20 11' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M20 1.24239L18.7267 1.11311e-07L10 8.51522L1.27325 1.63714e-06L-1.79874e-06 1.24239L10 11L20 1.24239Z'
                fill='currentColor'
            />
        </svg>
    );
}
