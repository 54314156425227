import classNames from 'classnames';
import React from 'react';
import styles from './style.module.scss';

type ButtonProps = React.ComponentPropsWithRef<'button'> & {
    isSubmitting?: boolean;
    size?: 'xs' | 'sm' | 'md';
    variant?: 'primary' | 'secondary';
};

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({ isSubmitting, children, className, size = 'md', variant = 'primary', disabled, ...rest }, ref) => {
        return (
            <button
                ref={ref}
                disabled={isSubmitting || disabled}
                className={classNames(
                    'btn',
                    styles['btn'],
                    styles[size],
                    styles[variant],
                    isSubmitting && styles['loading'],
                    className
                )}
                {...rest}
            >
                {children}
            </button>
        );
    }
);

Button.displayName = 'Button';

export { Button };
