import React from 'react';
import { Parallax, Background } from 'react-parallax';
import Dict = NodeJS.Dict;
import Image from '@graphcms/react-image';
import { Asset } from '@/lib/api/graphcms';
import { baseMediaUrl } from '@/lib/utilities';
import styles from './GraphCmsImage.module.scss';
import classnames from 'classnames';

function GraphCmsImage({
    image,
    style,
    className,
    alt = '',
    fit,
    withWebp,
    maxWidth,
    withParallax = false,
}: {
    className?: string;
    style?: Dict<string>;
    image?: Asset | null;
    alt?: string;
    maxWidth?: number;
    fit?: 'scale' | 'max' | 'crop' | 'clip';
    withWebp?: boolean;
    withParallax?: boolean;
}) {
    if (!image) return null;
    const { handle, height = 0, width = 0 } = image || {};
    if (!handle) return null;

    const isSvg = image?.mimeType === 'image/svg+xml';

    if (isSvg) {
        /* eslint-disable-next-line @next/next/no-img-element */
        return <img className={className} src={baseMediaUrl + '/' + handle} alt={alt} />;
    }

    if ((height || 0) <= 0 || (width || 0) <= 0) return null;

    if (withParallax && image?.url) {
        return (
            <div className={styles['graphcms-image-container']} style={{ margin: 'auto' }}>
                <Parallax strength={200}>
                    <Background className={styles.parallaxBackground}>
                        <Image
                            alt={alt}
                            className={classnames(className || '', styles.parallaxInnerImageHeight)}
                            style={style || {}}
                            image={{ handle, height: height || 0, width: width || 0 }}
                            fit={fit}
                            withWebp={withWebp}
                            maxWidth={maxWidth}
                        />
                    </Background>
                </Parallax>
            </div>
        );
    }

    return (
        <div className='graphcms-image-container' style={{ margin: 'auto' }}>
            <Image
                alt={alt}
                className={className || ''}
                style={style || {}}
                image={{ handle, height: height || 0, width: width || 0 }}
                fit={fit}
                withWebp={withWebp}
                maxWidth={maxWidth}
            />
        </div>
    );
}

export default GraphCmsImage;
