import React from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import classNames from 'classnames';

export function Loader({ includeMargins = true }) {
    return (
        <Container>
            <Row className={classNames('justify-content-center', includeMargins && 'm-10 p-10')}>
                <Col className='col-auto'>
                    <Spinner variant='coral' animation={'border'} />
                </Col>
            </Row>
        </Container>
    );
}
