import {
    CustomerApplicationData,
    CustomerApplicationResult,
    CustomerApplicationUserFunction,
    Registration,
} from '../../../registration';
import React, { useContext } from 'react';
import { CustomerApplicationV2Mutation, SalutationEnum, Sdk, useBff } from '@/lib/api/bff';
import { DynamicComponentElementProps } from '../blocks/DynamicComponent';
import { Grid } from '@/lib/api/graphcms';

export type RegistrationFormContent = {
    content: Record<string, Grid>;
    regions: string[];
    suggestedLocationsByRegion: { [key: string]: { name: string; casafariId: number }[] };
};
export const RegistrationFormContext = React.createContext({} as RegistrationFormContent);
export type RegistrationFormProps = DynamicComponentElementProps;

export function RegistrationForm(props: RegistrationFormProps) {
    const { id = '', configuration } = props || {};
    const {
        mandatoryFields = [],
        fieldsToHide = [],
        maxRooms,
        maxLivingArea,
        trendingDistricts = {},
    } = configuration || {};
    const api = useBff();
    const additionalContent = useContext(RegistrationFormContext);

    return (
        <Registration
            id={id}
            customerApplication={customerApplication(api)}
            fieldsToHide={fieldsToHide}
            mandatoryFields={mandatoryFields}
            maxRooms={maxRooms}
            maxLivingArea={maxLivingArea}
            additionalContent={additionalContent.content}
            activeRegions={additionalContent.regions ?? []}
            trendingDistricts={trendingDistricts}
            suggestedLocationsByRegion={additionalContent.suggestedLocationsByRegion}
        />
    );
}

function customerApplication(api: Pick<Sdk, 'CustomerApplicationV2'>): CustomerApplicationUserFunction {
    return (data: CustomerApplicationData) => {
        const {
            salutation,
            title,
            firstName,
            lastName,
            email,
            phone,
            password,
            locale,
            newsletter,
            searchAgent = true,
            netIncome,
            isNetIncomeMax,
            monthlyBudget,
            isBudgetMax,
            otherRegion,
            // search
            features,
            livingArea,
            locations,
            locationNames,
            propertyAges,
            propertySubTypes,
            propertyTypes,
            region,
            rooms,
            // timing,
            propertyURL,
            referrer,
        } = data;
        const user = {
            salutation: salutation?.toUpperCase() as SalutationEnum,
            title: title || null,
            firstName,
            lastName,
            email,
            phone: phone || null,
            password: password || null,
            locale,
            netIncome: netIncome || null,
            isNetIncomeMax: isNetIncomeMax || null,
            newsletter,
            searchAgent,
            referrer,
        };
        const search = {
            monthlyBudget: monthlyBudget || null,
            isBudgetMax: isBudgetMax || null,
            otherRegion: otherRegion || null,
            features,
            livingArea,
            locations,
            locationNames,
            propertyAges,
            propertySubTypes,
            propertyTypes,
            region,
            rooms,
            timing: null,
            propertyURL,
        };

        return api.CustomerApplicationV2({ data: { user, search }, events: [] }).then(
            ({ customerApplicationV2 }: CustomerApplicationV2Mutation) => {
                return customerApplicationV2 as CustomerApplicationResult;
            },
            err => {
                return {
                    success: false,
                    errors: [{ code: 'invocation_failed', message: err.message }],
                } as CustomerApplicationResult;
            }
        );
    };
}
