import { useTranslation } from 'next-i18next';
import { InfoSegment } from './InfoSegment';
import { ContactInfo } from '@/lib/api/bff';
import { gtm } from '@/lib/utilities';

export const Contacts = ({ contactInfo, propertyId }: { contactInfo: ContactInfo | null; propertyId: string }) => {
    const { t } = useTranslation('properties');

    if (!contactInfo) {
        return null;
    }

    const { email, name, phone, propertyURL } = contactInfo;

    if (!email && !name && !phone && !propertyURL) {
        return null;
    }

    const handleProviderLinkClick = () => {
        gtm.event(gtm.EVENTS.PROPERTY_PROVIDER_LINK_CLICKED, {
            category: gtm.CATEGORIES.PROPERTY,
            propertyId,
            propertyURL,
        });
        window.open(propertyURL + '', '_blank');
    };

    return (
        <>
            {' '}
            <h3 className='text-copy-s-bold'>{t('contacts')}</h3>
            <div style={{ flex: 1 }}>
                {name && <InfoSegment text={t('name')} value={name} />}
                {email && <InfoSegment text={t('email')} value={email} />}
                {phone && <InfoSegment text={t('phone')} value={phone} />}
                {propertyURL && (
                    <div className='d-flex justify-content-end'>
                        <div>
                            <a href={propertyURL} onClick={handleProviderLinkClick} target='_blank'>
                                {t('provider_url')}
                            </a>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
