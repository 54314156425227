import classNames from 'classnames';
import React from 'react';
import { ListGroup, ListGroupItemProps, ListGroupProps } from 'react-bootstrap';

type GroupProps = ListGroupProps;

type ItemProps = ListGroupItemProps & { selected?: boolean };

export const Group = ({ className, children, ...props }: GroupProps) => {
    return (
        <ListGroup className={classNames('design-system', className)} {...props}>
            {children}
        </ListGroup>
    );
};

export const Item = ({ className, children, selected, ...props }: ItemProps) => {
    return (
        <ListGroup.Item className={classNames(selected && 'bg-velvet-100', className)} {...props}>
            {children}
        </ListGroup.Item>
    );
};
