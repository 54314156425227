import type { Middleware } from 'openapi-fetch';
import { notify } from '@/lib/utilities/logger';

export const logOnError: Middleware = {
    async onResponse(res) {
        if (res.status >= 400) {
            const body = res.headers.get('content-type')?.includes('json')
                ? await res.clone().json()
                : await res.clone().text();

            const message = `${res.status} ${res.statusText} ${body?.message || body}`;

            notify(message, res.url);
        }

        return undefined;
    },
};

export const logErrorResponse = async (
    res: Response,
    accountId: string,
    requestData?: Record<string, any>,
    error?: Record<string, any> | string
) => {
    const message = `${res.status} ${res.statusText} ${typeof error === 'object' ? JSON.stringify(error) : error}`;

    notify(message, res.url, accountId, requestData);
};
