import React, { useMemo, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { FieldValues, useForm } from 'react-hook-form';
import { TFunction } from 'react-i18next';
import { Grid, Wrapper } from '../../types';
import { FormPart } from './form-part';
import { SuccessScreen } from './success-screen';
import { useRouter } from 'next/router';
import { colorVariant, lockSymbols, patternVariant } from '@/components/common';
import { Col, Row } from 'react-bootstrap';
import styles from './contact.module.scss';
import { gtm } from '@/lib/utilities';
import { useSession } from 'next-auth/react';

export type ContactData = {
    userId: string;
    firstName: string;
    lastName: string;
    email: string;
    phone?: string | null;
    message: string;
    locale: string;
    reason?: string | null;
    type?: string | null;
    priority?: string | null;
};

export type ContactError = {
    code: string;
    message?: string | null;
    propertyName?: string | null;
};

export type ContactResult = {
    success: boolean;
    caseNumber?: string | null;
    errors?: ContactError[];
};
export type ContactFunction = (data: ContactData) => Promise<ContactResult>;

/* eslint-disable-next-line */
export interface ContactFormProps {
    contact: ContactFunction;
    wrapperComponent: Wrapper;
    additionalContent?: Record<string, Grid>;
    fieldsToHide?: string[];
    mandatoryFields?: string[];
    invalidReasons?: [];
    selectedReason?: string;
}

const MANDATORY_BASE = ['firstName', 'lastName', 'email', 'message'];
const REASONS = [
    'COMPANY',
    'LEASE_RATE',
    'VIEWING',
    'PROPERTY_REQUEST',
    'TECHNICAL',
    'PRIVACY_POLICY',
    'CLOSE_ACCOUNT',
    'PRESS',
    'BAUSPAREN_LIGHT',
    'OTHER',
];

export function ContactForm(props: ContactFormProps) {
    const { contact, selectedReason } = props || {};

    const { data: session } = useSession();
    const { user } = session || {};
    const symbols = useMemo(() => {
        const date = new Date();
        return lockSymbols(2, 2, { pattern: patternVariant(date.getDate()), colors: colorVariant(date.getDay()) });
    }, []);

    const defaultValues: FieldValues = {};
    const form = useForm({
        mode: 'onTouched',
        criteriaMode: 'all',
        defaultValues,
    });
    const {
        formState: { isSubmitSuccessful },
        watch,
        setError,
    } = form;
    const mandatoryFields: string[] = [...new Set([...(props.mandatoryFields || []), ...MANDATORY_BASE])];
    const fieldsToHide: string[] = (props.fieldsToHide || []).filter(f => !mandatoryFields.includes(f));
    const reasons: string[] =
        selectedReason && REASONS.includes(selectedReason)
            ? [selectedReason]
            : REASONS.filter(
                  r => !(props?.invalidReasons || []).find((i: string) => i.toUpperCase() === r.toUpperCase())
              );

    const { t } = useTranslation(['contact', 'common']) as unknown as { t: TFunction<string, undefined> };
    const { locale } = useRouter();

    const [generalError, setGeneralError] = useState<string | null>(null);
    const [caseNumber, setCaseNumber] = useState<string | null>(null);
    const onSubmit = async (data: FieldValues) => {
        const {
            success,
            caseNumber = null,
            errors = [],
        } = await contact({
            ...data,
            userId: user?.accountId,
            locale,
        } as ContactData);
        if (!success) {
            errors.forEach(error => {
                if (error.propertyName) setError(error.propertyName, { type: error.code });
                else {
                    setGeneralError(error.code);
                }
            });
            throw new Error();
        } else {
            setCaseNumber(caseNumber);
            gtm.event(gtm.EVENTS.CONTACT_SUCCESSFUL, { registered: !!user });
        }
    };

    return (
        <Row className='mb-9'>
            <Col xs={12} sm={8}>
                {isSubmitSuccessful ? (
                    <SuccessScreen
                        t={t}
                        values={{
                            firstName: watch('firstName'),
                            lastName: watch('lastName'),
                            email: watch('email'),
                            phone: watch('phone'),
                            message: watch('message'),
                            reason: watch('reason'),
                            caseNumber,
                        }}
                    />
                ) : (
                    <FormPart
                        {...props}
                        user={user}
                        t={t}
                        form={form}
                        onSubmit={onSubmit}
                        generalError={generalError}
                        fieldsToHide={fieldsToHide}
                        mandatoryFields={mandatoryFields}
                        reasons={reasons}
                    />
                )}
            </Col>
            <Col
                sm={4}
                xs={0}
                style={{ backgroundImage: `url("data:image/svg+xml,${encodeURIComponent(symbols)}")` }}
                className={styles['decoration']}
            ></Col>
        </Row>
    );
}
