import classNames from 'classnames';
import styles from './styles.module.scss';
import { ReactNode } from 'react';

export function Card({ title, children }: { title: string; children: ReactNode }) {
    return (
        <div className={classNames(styles['dropdown'], 'w-100 shadow')}>
            <div className='text-caption-bold pt-3'>{title}</div>
            {children}
        </div>
    );
}
