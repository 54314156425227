import { Asset as Record } from '@/lib/api/graphcms';
import GraphImage from '@graphcms/react-image';

export type AssetProps = Partial<Record> & { className?: string };

export function Asset(props: AssetProps = {}) {
    const { handle, width, height } = props;

    return <GraphImage image={{ handle: handle || '', width: width || 0, height: height || 0 }} />;
}
