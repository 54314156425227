import graphCms, { stage } from './api/graphcms';
import { notEmpty } from './utilities';
import { GetStaticPaths } from 'next';

// todo do we need it here?
export * from './utilities';

export function isInternalPageSlug(slug: string): boolean {
    return slug?.startsWith('_');
}

export const isMarketingPageSlug = (slug: string): boolean =>
    !isInternalPageSlug(slug) &&
    !['account-create-vobahome', 'login', 'registration-success', 'global-restricted'].includes(slug);

export const getAllPagesStaticPaths: GetStaticPaths = async () => {
    const sdk = graphCms();
    const result = await sdk.AllPages(stage());
    const localized = Array.from(result.pages)
        .flatMap(({ slug, locale, preRender, localizations }) => [
            {
                slug,
                locale,
                preRender,
            },
            ...localizations.map(({ slug, locale, preRender }) => ({
                slug,
                locale,
                preRender,
            })),
        ])
        .filter(({ slug, preRender = true }) => !isInternalPageSlug(slug) && !!preRender);

    const paths = localized.map(({ slug, locale }) => (slug ? { params: { slug }, locale } : null)).filter(notEmpty);

    return {
        paths,
        fallback: 'blocking',
    };
};
