import React from 'react';
import classNames from 'classnames';
import { Calendar4, ClockHistory } from 'react-bootstrap-icons';
import { useBootstrapBreakpointValue } from '@/lib/utilities';
import { useMediaQuery } from '@mui/material';
import moment from 'moment';
import { useRouter } from 'next/router';
import { getLocalDate } from '@/lib/utilities/lib/datetime';

type SlotProps = {
    startDate: string;
    endDate: string;
};

export const DateTimeInfo = (slot: SlotProps) => {
    const { startDate, endDate } = slot;
    const router = useRouter();
    const { locale = 'de' } = router;
    const localDate = getLocalDate(startDate, locale);
    const MD_BOOTSTRAP_BREAKPOINT = useBootstrapBreakpointValue('md', []);
    const isMobile = useMediaQuery(`(max-width: ${MD_BOOTSTRAP_BREAKPOINT})`);
    return (
        <div
            className={classNames(
                isMobile ? 'body-s' : 'body-m',
                'w-100 d-flex justify-content-between align-items-center'
            )}
        >
            <span className='d-inline-flex gap-1 align-items-center'>
                <Calendar4 /> {localDate}
            </span>
            <span className='d-inline-flex gap-1 align-items-center'>
                <ClockHistory />{' '}
                {`${moment.tz(startDate, 'Europe/Berlin').format('H:mm')} - ${moment
                    .tz(endDate, 'Europe/Berlin')
                    .format('H:mm')}`}
            </span>
        </div>
    );
};
