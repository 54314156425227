import { Person as Record } from '@/lib/api/graphcms';
import Image from '@graphcms/react-image';
import React from 'react';
import avatar from './avatar.svg';

export type ButtonProps = Partial<Record> & { className?: string };

export function Person(props: ButtonProps = {}) {
    const { name, role, photo } = props || {};
    return (
        <div className='text-center'>
            {!photo ? (
                /* eslint-disable-next-line @next/next/no-img-element */
                <img src={avatar} alt={name} title={name} style={{ width: '100%' }} />
            ) : (
                <Image
                    image={{ handle: photo.handle || '', height: photo.height || 0, width: photo.width || 0 }}
                    alt={name}
                    title={name}
                />
            )}
            <h6 className='mt-2'>{name}</h6>
            <p>{role}</p>
        </div>
    );
}
