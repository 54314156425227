import { ContactForm as Form, ContactFunction, ContactResult } from '../../../contact';
import React from 'react';
import { DynamicComponentProps } from '../blocks/DynamicComponent';
import { ContactReason, ContactType, Priority, Sdk, useBff } from '@/lib/api/bff';
import { Grid, Locale, Stage, useGraphCms } from '@/lib/api/graphcms';
import useSWR from 'swr';
import { useRouter } from 'next/router';
import { Wrapper } from '../Wrapper';
import { notify } from '@/lib/utilities/logger';

export function ContactForm(props: DynamicComponentProps) {
    const { configuration } = props || {};

    const { locale = 'de', defaultLocale = 'de', isPreview } = useRouter();
    const api = useBff();
    const graphCms = useGraphCms();

    const loadGrids = () =>
        graphCms.GridsBySlug({
            slugs: ['privacy-policy', 'terms-and-conditions'],
            locale: locale as Locale,
            fallbackLocale: defaultLocale as Locale,
            stage: isPreview ? Stage.DRAFT : Stage.PUBLISHED,
        });
    const { data, error } = useSWR(['/graph-cms/grids'], loadGrids);
    const grids = data?.grids || [];
    const additionalContent = grids.reduce((acc, grid) => {
        acc[grid.slug || ''] = grid as unknown as Grid;
        return acc;
    }, {} as Record<string, Grid>);

    if (error) notify(error, 'cms/grids');
    return (
        <Form
            contact={contactFunction(api)}
            additionalContent={additionalContent}
            wrapperComponent={Wrapper}
            {...props}
            {...configuration}
        />
    );
}

function contactFunction(api: Sdk): ContactFunction {
    return ({ locale, userId, email, phone, firstName, lastName, message, reason, type, priority }) =>
        api
            .Contact({
                data: {
                    userId,
                    firstName,
                    lastName,
                    email,
                    phone: phone || null,
                    message,
                    locale,
                    reason: (reason || null) as ContactReason,
                    type: (type || null) as ContactType,
                    priority: (priority || null) as Priority,
                },
            })
            .then(({ contact }: { contact: unknown }) => contact as ContactResult);
}
