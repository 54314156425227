import React from 'react';

export default function ArrowRight(props: object) {
    return (
        <svg width='20' height='17' viewBox='0 0 20 17' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M16.6992 9.31528L1.57418e-07 9.31528L0 7.36956L16.4072 7.36956L17.4535 8.50015L16.6992 9.31528Z'
                fill='currentColor'
            />
            <path
                d='M12.1338 0.000152588L10.8605 1.37598L16.4072 7.36956L17.4535 8.50015L16.6992 9.31528L10.8605 15.6243L12.1338 17.0002L20 8.50015L12.1338 0.000152588Z'
                fill='currentColor'
            />
        </svg>
    );
}
