import { useEffect, useState } from 'react';
import styles from './tag-select.module.scss';
import classNames from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';

export type SelectItem = { label: string; key: string };

export type TagSelectProps = Partial<Pick<HTMLSelectElement, 'className' | 'multiple'>> &
    Pick<HTMLSelectElement, 'name'> & {
        placeholder?: string;
        options: SelectItem[];
        defaultValues?: string | string[];
    };

export function TagSelect(props: TagSelectProps) {
    const { name, className, options = [], defaultValues, multiple = true } = props || {};
    const { control, setValue } = useFormContext();

    const [selected, setSelected] = useState<string[]>([]);
    const isSelected = ({ key }: SelectItem) => selected.findIndex(s => s === key) >= 0;
    const addToSelection = (item: SelectItem) => {
        if (multiple) {
            if (selected.findIndex(i => i === item.key) >= 0) return;
            setSelected([...selected, item.key]);
            setValue(name, [...selected, item.key]);
        } else {
            setSelected([item.key]);
            setValue(name, [item.key]);
        }
    };
    const removeFromSelection = (item: SelectItem) => {
        const newSelected = multiple ? selected.filter(s => s !== item.key) : [];
        setSelected(newSelected);
        setValue(name, newSelected);
    };

    useEffect(() => {
        if (selected?.length > 0 || !defaultValues) return;
        const defaults = !defaultValues ? [] : Array.isArray(defaultValues) ? defaultValues : [defaultValues];
        const dflts = options.filter(i => defaults.includes(i.key)).map(({ key }) => key);
        setSelected([...dflts]);
        setValue(name, [...dflts]);
    }, [defaultValues, name, options, selected?.length, setValue]);
    return (
        <div>
            <ul className={classNames(styles['tag-list'], className)}>
                {options.map(item => {
                    if (!isSelected(item))
                        return (
                            <li key={item.key} role='button' onClick={() => addToSelection(item)}>
                                {item.label} <span className='invisible'>&times;</span>
                            </li>
                        );

                    return (
                        <li key={item.key} role='button' className='selected' onClick={() => removeFromSelection(item)}>
                            {item.label} <span className='visible'>&times;</span>
                        </li>
                    );
                })}
            </ul>
            <Controller
                name={name}
                control={control}
                defaultValue={selected}
                render={({ field }) => (
                    <select style={{ display: 'none' }} multiple={multiple} {...field}>
                        {options.map(item => (
                            <option key={item.key} value={item.key}>
                                {item.label}
                            </option>
                        ))}
                    </select>
                )}
            />
        </div>
    );
}
