import React, { useState } from 'react';
import { Button, Form, FormInput } from '@/components/design-system';
import { Textarea } from '@/components/design-system/form/inputs/Textarea';
import { useInput } from 'react-day-picker';
import { useRouter } from 'next/router';
import * as locales from 'date-fns/locale';
import { SubmitHandler, useForm } from 'react-hook-form';
import { DatePicker } from '@/components/design-system/form/inputs/DatePicker';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import * as constants from '../constants';
import { useTranslation } from 'next-i18next';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { useCreateSlot } from '../hooks/useCreateSlot';
import { useAccountId } from '@/components/users';
import { CreateSlotInput } from '@/lib/api/lease-requests/types';
import { toast, ToastContent } from 'react-toastify';
import { TOAST_OPTIONS } from '@/styles/toasts';
import { gtm } from '@/lib/utilities';
import { WidgetContainer } from '@/components/design-system/widget-container';

export type SlotFormProps = {
    startTime: string;
    duration: number;
    maxAttendees: number;
    viewingInstruction?: string;
};

export const NewSlotForm = ({ propertyId }: { propertyId: string }) => {
    const router = useRouter();
    const { t } = useTranslation('metasearch');
    const MOMENT_DATE_FORMAT = 'DD/MM/YYYY';

    const { inputProps, dayPickerProps } = useInput({
        fromDate: new Date(),
        format: 'dd/MM/yyyy',
        required: true,
        locale: router.locale === 'en' ? locales.enGB : locales.de,
    });

    const [startDateError, setStartDateError] = useState('');
    const { createSlot, isLoading: requestInProgress } = useCreateSlot();
    const accountId = useAccountId();

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
    } = useForm<SlotFormProps>({
        mode: 'all',
        defaultValues: { maxAttendees: constants.DEFAULT_MAX_ATTENDEES },
    });

    const submitHandler: SubmitHandler<SlotFormProps> = async values => {
        if (isSubmitting || requestInProgress) return;

        gtm.event(gtm.EVENTS.CREATE_APPOINTMENT_SLOT_CLICKED, { category: gtm.CATEGORIES.APPOINTMENT_SLOT });

        const [startHour, startMinute] = values.startTime.split(':');

        const startDate = moment
            .tz(inputProps.value.toString(), MOMENT_DATE_FORMAT, 'Europe/Berlin')
            .set('hours', +startHour)
            .set('minutes', +startMinute);

        const input: CreateSlotInput = {
            propertyId: propertyId,
            createdBy: accountId,
            startDate: startDate.toISOString(),
            endDate: startDate.add(values.duration, 'minute').toISOString(),
            maxNumOfParticipants: values.maxAttendees,
            viewingInstructions: values.viewingInstruction,
        };

        const response = await createSlot(input);

        if (!response || response.error) {
            if (response?.error?.message === 'ADDRESS_NOT_SET') {
                toast.error(`${t('slot_form.location_not_set') as ToastContent<string>}`, TOAST_OPTIONS);
                return;
            }

            toast.error(`${t('slot_form.something_went_wrong') as ToastContent<string>}`, TOAST_OPTIONS);
            return;
        }

        if (response.data.id) {
            toast.success(`${t('slot_form.slot_has_been_created') as ToastContent<string>}`, TOAST_OPTIONS);
        }
    };

    const validateStartDate = (value: Date | string) => {
        if (!value) {
            setStartDateError(t('errors.default.required', { fieldName: t('slot_form.date') }));
            return;
        }
        const date = moment(value, MOMENT_DATE_FORMAT);
        const now = moment();
        if (!date.isValid()) {
            setStartDateError(t('slot_form.incorrect_date_format'));
            return;
        }
        if (date.isBefore(now, 'days')) {
            setStartDateError(t('slot_form.date_in_the_past'));
            return;
        }
        setStartDateError('');
    };

    return (
        <WidgetContainer>
            <h4 className='mb-4'>{t('slot_form.header')}</h4>
            <Form onSubmit={handleSubmit(submitHandler)} className={classNames(styles['slot-form'], 'd-flex gap-6')}>
                <Row lg={12} className='d-flex justify-content-between gap-6'>
                    <Col>
                        <DatePicker
                            label={t('slot_form.date') + '*'}
                            id='startDate'
                            data-cy='startDate'
                            validate={validateStartDate}
                            error={startDateError}
                            isInvalid={!!startDateError}
                            inputProps={inputProps}
                            dayPickerProps={dayPickerProps}
                        />
                    </Col>
                    <Col>
                        <FormInput
                            placeholder={constants.DEFAULT_MAX_ATTENDEES.toString()}
                            type='number'
                            id='maxAttendees'
                            data-cy='maxAttendees'
                            label={t('slot_form.max_attendees') + '*'}
                            error={errors.maxAttendees?.message}
                            isInvalid={!!errors.maxAttendees}
                            {...register('maxAttendees', {
                                validate: value => {
                                    if (!value)
                                        return t('errors.default.required', {
                                            fieldName: t('slot_form.max_attendees'),
                                        });
                                    if (value > 100) {
                                        return t('slot_form.cannot_be_greater', { max: constants.MAX_MAX_ATTENDEES });
                                    }
                                    if (value < 1) {
                                        return t('slot_form.cannot_be_greater', { min: constants.MIN_MAX_ATTENDEES });
                                    }
                                    return true;
                                },
                                valueAsNumber: true,
                            })}
                        ></FormInput>
                    </Col>
                </Row>
                <Row lg={12} className='d-flex justify-content-between gap-5'>
                    <Col>
                        <FormInput
                            type='time'
                            id='startTime'
                            data-cy='startTime'
                            label={t('slot_form.time') + '*'}
                            error={errors.startTime?.message}
                            isInvalid={!!errors.startTime}
                            {...register('startTime', {
                                validate: value => {
                                    if (!value) return t('errors.default.required', { fieldName: t('slot_form.time') });
                                    return true;
                                },
                            })}
                        />
                    </Col>
                    <Col>
                        <FormInput
                            type='number'
                            min={constants.MIN_DURATION}
                            max={constants.MAX_DURATION}
                            placeholder={constants.DEFAULT_DURATION.toString()}
                            defaultValue={constants.DEFAULT_DURATION}
                            list='durations'
                            id='duration'
                            data-cy='duration'
                            label={t('slot_form.duration') + t('slot_form.minutes') + '*'}
                            error={errors.duration?.message}
                            isInvalid={!!errors.duration}
                            {...register('duration', {
                                validate: value => {
                                    if (!value) {
                                        return t('errors.default.required', { fieldName: t('slot_form.duration') });
                                    }
                                    if (value > constants.MAX_DURATION) {
                                        return t('slot_form.cannot_be_greater', { max: constants.MAX_DURATION });
                                    }
                                    if (value < constants.MIN_DURATION) {
                                        return t('slot_form.cannot_be_less', { min: constants.MIN_DURATION });
                                    }
                                    if (value % constants.DURATION_STEP !== 0) {
                                        return t('slot_form.chose_only_intervals', {
                                            interval: constants.DURATION_STEP,
                                        });
                                    }
                                    return true;
                                },
                                valueAsNumber: true,
                            })}
                        />
                        <datalist id='durations'>
                            {constants.DURATION_SUGGESTIONS.map(suggestion => (
                                <option key={suggestion} value={suggestion}>
                                    {suggestion}
                                </option>
                            ))}
                        </datalist>
                    </Col>
                </Row>
                <div className='mt-2'>
                    <label htmlFor='viewingInstructions' className='fw-bold mb-2'>
                        {t('slot_form.instructions_for_customer')}
                    </label>
                    <Textarea id='viewingInstructions' rows={4} {...register('viewingInstruction')} />
                </div>
                <Button isSubmitting={requestInProgress || isSubmitting} disabled={!isValid}>
                    {t('slot_form.create_new_slot')}
                </Button>
            </Form>
        </WidgetContainer>
    );
};
