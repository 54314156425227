import React from 'react';

export default function Profile(props: object) {
    return (
        <svg width='18' height='20' viewBox='0 0 18 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M9 9.52381C6.23858 9.52381 4 7.39183 4 4.7619C4 2.13198 6.23858 0 9 0C11.7614 0 14 2.13198 14 4.7619C14 7.39183 11.7614 9.52381 9 9.52381ZM9 7.61905C10.6569 7.61905 12 6.33986 12 4.7619C12 3.18395 10.6569 1.90476 9 1.90476C7.34315 1.90476 6 3.18395 6 4.7619C6 6.33986 7.34315 7.61905 9 7.61905ZM9 10.4762C13.9706 10.4762 18 14.3137 18 19.0476V20H0V19.0476C0 14.3137 4.02944 10.4762 9 10.4762ZM9 12.381C5.47353 12.381 2.55612 14.8645 2.07089 18.0952H15.9291C15.4439 14.8645 12.5265 12.381 9 12.381Z'
                fill='currentColor'
            />
        </svg>
    );
}
