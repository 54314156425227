import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import { AvailabilityPicker } from '@/components/availability-picker';
import { AttendViewingRequestButton } from './components/AttendViewingRequestButton';
import { Availability } from '@/lib/api/lease-requests/types';
import { Slots } from './components/Slots';
import { useAppointmentSlots } from '@/hooks/useAppointmentSlots';
import { getActiveSlots } from '@/lib/utilities/lib/properties';
import { StickyLabel } from '@/components/common';
import { TFunction } from 'react-i18next';
import styles from '@/components/lease-requests/view/components/AvailableViewings/styles.module.scss';
import { ExclamationCircleFill } from 'react-bootstrap-icons';
import classNames from 'classnames';

export const AvailableViewings = ({
    propertyId,
    onViewingRequestClick,
    className,
}: {
    propertyId: string;
    onViewingRequestClick: (availability?: Availability, slotId?: string) => Promise<void>;
    className?: string;
}) => {
    const { t } = useTranslation('metasearch');

    const [selectedSlotId, setSelectedSlotId] = useState(null);
    const { appointmentSlots } = useAppointmentSlots(propertyId);
    const slots = getActiveSlots(appointmentSlots);

    if (!slots || !slots.length)
        return (
            <>
                <AvailabilityPicker className={classNames(className, 'mb-4')} onSubmit={onViewingRequestClick} />
                <StickyLabel body={<ViewingRequestDisclaimerBody className={'body-s m-0 line-height-16'} t={t} />} />
            </>
        );

    return (
        <div className={className}>
            <p className='body-m'>{`${t('select_one_appointment')}:`}</p>
            <Slots slots={slots} selectedSlotId={selectedSlotId} onSlotClick={setSelectedSlotId} />
            <AttendViewingRequestButton
                onClick={() => onViewingRequestClick(undefined, selectedSlotId)}
                selectedSlotId={selectedSlotId}
                className='w-100 mt-4'
            />
        </div>
    );
};

const ViewingRequestDisclaimerBody = ({ t, className }: { t: TFunction; className?: string }) => {
    return (
        <div className={styles['disclaimer-body']}>
            <ExclamationCircleFill className='mt-1' height={17} color='var(--bs-velvet-500)' />
            <p className={className}>{t('ownr_property_disclaimer')}</p>
        </div>
    );
};
