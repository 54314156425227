import createClient from 'openapi-fetch';
import type { paths as propertiesPaths } from './schema';
import { apiGatewayConfig } from '@/lib/env';
import { useSession } from 'next-auth/react';
import { logOnError } from '../utils';
import { useRouter } from 'next/router';

export const usePropertiesClient = () => {
    const { data: session } = useSession();
    const router = useRouter();

    if (!session) {
        router.push('/login');
    }

    const propertiesClient = createClient<propertiesPaths>({
        baseUrl: apiGatewayConfig.baseUrl,
        headers: { Authorization: `Bearer ${session?.idToken}` },
    });
    propertiesClient.use(logOnError);

    return propertiesClient;
};
