import React from 'react';
import classNames from 'classnames';
import { ImageCard } from '@/components/common';
import { Container, Row, Col } from 'react-bootstrap';
import { ImageCardComponent } from '@/lib/api/graphcms';
import Slider from 'react-slick';
import styles from './ImageCardLayout.module.scss';
import commonStyles from '../../Gallery.module.scss';

type ImageCardLayoutProps = {
    items: ImageCardComponent[];
    className?: string;
};

export const ImageCardLayout = ({ items = [], className }: ImageCardLayoutProps): JSX.Element | null => {
    const sliderConfig = {
        centerMode: true,
        slidesToShow: 1,
        infinite: true,
        arrows: false,
    };

    return (
        <Container>
            <Row className={classNames(styles.container, className, 'd-none d-sm-block')}>
                {items?.map(item => (
                    <Col sm={6} md={6} lg={4} key={item.id}>
                        <ImageCard className={styles.card} key={item.id} {...item} />
                    </Col>
                ))}
            </Row>

            <Slider {...sliderConfig} className={classNames(commonStyles.slider, 'd-block d-sm-none')}>
                {items?.map(item => (
                    <ImageCard key={item.id} className={styles.card} {...item} />
                ))}
            </Slider>
        </Container>
    );
};
