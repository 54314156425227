export const LEASE_REQUEST = '/lease-requests/v2/{id}';
export const LEASE_REQUEST_TASKS = '/lease-requests/{id}/tasks';
export const LEASE_REQUEST_BY_PROPERTY = '/lease-requests/v2/properties/{id}';
export const LEASE_REQUESTS_OLD = '/lease-requests';
export const LEASE_REQUESTS = '/lease-requests/v2';
export const LEASE_REQUEST_STATUS = '/lease-requests/{id}/statuses';

export const APPOINTMENTS = '/lease-requests/{id}/appointments';
export const APPOINTMENT_STATUS = '/lease-requests/appointments/{id}/statuses';
export const APPOINTMENT_STATUS_CONFIRM = '/lease-requests/appointments/{id}/confirm';
export const APPOINTMENT_STATUS_CANCEL = '/lease-requests/appointments/{id}/cancel';

export const SLOTS_SEARCH_REQUEST = '/lease-requests/slots/search';
export const SLOTS_REQUEST = '/lease-requests/slots';
export const SLOTS_CANCEL_REQUEST = '/lease-requests/slots/{slotId}/cancel';
export const SLOTS_HIDE_REQUEST = '/lease-requests/slots/{slotId}/hide';
export const ATTENDEES = '/lease-requests/appointments/attendees';

export const GET_PROPERTY = '/property/{propertyID}';
export const METADATA = '/property/metadata/{propertyID}';
export const CREATE_PROPERTY = '/property/off-market';
export const EDIT_PROPERTY = '/property/off-market/{id}';
export const HIDE_PROPERTY = '/property/off-market/hide/{id}';
export const OFF_MARKET_PROPERTIES = '/property/v2/off-market/search';

export const FILE_STORAGE_LINKS = '/property/file-storage/links';
export const GET_PROPERTIES = '/property/search';
export const GET_PARTNERS_PROPERTIES = '/property/partners/search';

export const GET_PORTAL_PUBLISH_STATUS = '/property/off-market/publish/status';
export const POST_PORTAL_PUBLISH = '/property/off-market/publish';
