import classNames from 'classnames';
import { ToggleButton, ButtonGroup } from 'react-bootstrap';
import { ToggleButtonType } from 'react-bootstrap/esm/ToggleButton';
import styles from './styles.module.scss';

interface OptionsButtonProps {
    id: string;
    name: string;
    checked: boolean;
    variant?: string;
    value: string | number;
    children: React.ReactNode;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    type: ToggleButtonType;
    wrapperClassName?: string;
    buttonClassName?: string;
    disabled?: boolean;
}

export const OptionsButton = ({
    id,
    name,
    type,
    checked,
    variant,
    value,
    children,
    onChange,
    onClick,
    wrapperClassName,
    buttonClassName,
    disabled = false,
}: OptionsButtonProps) => {
    return (
        <ButtonGroup className={classNames(styles.optionsButtonWrapper, wrapperClassName)}>
            <ToggleButton
                id={id}
                className={classNames(
                    'px-1 py-2 btn btn-sm border-2 w-100',
                    checked ? 'fw-normal' : 'fw-light',
                    buttonClassName,
                    styles.option
                )}
                type={type}
                variant={variant ?? 'outline-velvet-100'}
                name={name}
                value={value}
                checked={checked}
                onChange={onChange}
                disabled={disabled}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onClick={!!onClick ? onClick : () => {}}
            >
                {children}
            </ToggleButton>
        </ButtonGroup>
    );
};
