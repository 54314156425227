import { Col, FloatingLabel, Form, Modal, Row } from 'react-bootstrap';
import { Button } from '@/components/common';
import { snakeCase } from '@/lib/utilities';
import { ErrorMessage } from '@hookform/error-message';
import styles from './contact.module.scss';
import { Trans } from 'next-i18next';
import { FieldValues, UseFormRegister, UseFormReturn } from 'react-hook-form';
import { TFunction } from 'react-i18next';
import React, { useId, useState } from 'react';
import isEmail from 'validator/lib/isEmail';
import { ErrorText, isMandatory, isVisible, OptionalMarker } from './util';
import { Wrapper, Grid } from '../../types';
import { User } from 'next-auth';

export type FormPartProps = {
    onSubmit: (data: FieldValues) => Promise<void>;
    wrapperComponent: Wrapper;
    additionalContent?: Record<string, Grid>;
    user?: Pick<User, 'firstName' | 'lastName' | 'email' | 'accountId'> | null;
    form: UseFormReturn;
    generalError?: string | null;
    t: TFunction;
    reasons: string[];
    fieldsToHide?: string[];
    mandatoryFields?: string[];
};

export function FormPart(props: FormPartProps) {
    const {
        user,
        additionalContent,
        form,
        t,
        generalError,
        onSubmit,
        fieldsToHide = [],
        mandatoryFields = [],
        reasons = [],
        wrapperComponent: Wrapper,
    } = props || {};
    const {
        register,
        formState: { errors, isValid, isSubmitting },
        handleSubmit,
        setValue,
    } = form;

    if (user) {
        user.firstName && setValue('firstName', user.firstName);
        user.lastName && setValue('lastName', user.lastName);
        user.email && setValue('email', user.email);
    }

    const componentId = useId();
    const formId = (...params: string[]) => snakeCase('contact', ...params) + '_' + componentId;

    const { 'terms-and-conditions': terms, 'privacy-policy': privacyPolicy } = additionalContent || {};
    const [showTermsModal, setShowTermsModal] = useState<boolean>(false);
    const [showPrivacyModal, setShowPrivacyModal] = useState<boolean>(false);

    const emailValidation = async (email: string) => {
        if (!isEmail(email)) return t(`validation.email.format`, { ns: 'newsletter' });
        return undefined;
    };

    return (
        <>
            <Form id={formId()} onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <h5>{t('h_contact_data')}</h5>
                </div>
                {generalError && (
                    <div className='mt-3 mb-3 w-100'>
                        <p className='text-danger'>{t(generalError)}</p>
                    </div>
                )}
                <div className='d-flex w-100 mb-3'>
                    <FloatingLabel controlId={formId('first_name')} label={t('first_name')} className='me-2 w-100'>
                        <Form.Control
                            type='text'
                            placeholder='Max'
                            isInvalid={!!errors['firstName']}
                            {...register('firstName', {
                                minLength: 2,
                                required: true,
                            })}
                        />
                        <ErrorMessage
                            errors={errors}
                            name='firstName'
                            render={e => <ErrorText t={t} name='first_name' {...e} />}
                        />
                    </FloatingLabel>
                    <FloatingLabel controlId={formId('last_name')} label={t('last_name')} className='ms-2 w-100'>
                        <Form.Control
                            type='text'
                            placeholder='Mustermann'
                            isInvalid={!!errors['lastName']}
                            {...register('lastName', {
                                minLength: 2,
                                required: true,
                            })}
                        />
                        <ErrorMessage
                            errors={errors}
                            name='lastName'
                            render={e => <ErrorText t={t} name='last_name' {...e} />}
                        />
                    </FloatingLabel>
                </div>
                <FloatingLabel controlId={formId('email')} label={t('email')} className='mb-3 d-grid w-100'>
                    <Form.Control
                        type='text'
                        placeholder='name@example.com'
                        isInvalid={!!errors['email']}
                        {...register('email', {
                            minLength: 6,
                            required: true,
                            validate: { format: emailValidation },
                        })}
                    />
                    <ErrorMessage errors={errors} name='email' render={e => <ErrorText t={t} name='email' {...e} />} />
                </FloatingLabel>
                {isVisible(fieldsToHide, 'phone') && (
                    <FloatingLabel
                        controlId={formId('phone')}
                        label={
                            <>
                                {t('phone')}
                                <OptionalMarker t={t} mandatoryFields={mandatoryFields} name='propertyFeatures' />
                            </>
                        }
                        className='mb-3 d-grid w-100'
                    >
                        <Form.Control
                            type='text'
                            placeholder='+494000000000'
                            isInvalid={!!errors['phone']}
                            {...register('phone', {
                                minLength: 2,
                                maxLength: 16,
                                required: isMandatory(mandatoryFields, 'phone'),
                            })}
                        />
                        <ErrorMessage
                            errors={errors}
                            name='phone'
                            render={e => <ErrorText t={t} name='phone' {...e} />}
                        />
                    </FloatingLabel>
                )}
                <div className='mt-6'>
                    <h4>{t('h_message')}</h4>
                </div>
                <ReasonSelect
                    reasons={reasons}
                    mandatoryFields={mandatoryFields}
                    formId={formId}
                    register={register}
                    t={t}
                />
                <FloatingLabel controlId={formId('message')} label={t('message')} className='mb-3 d-grid w-100'>
                    <Form.Control
                        as='textarea'
                        placeholder='Liebes OWNR Team ...'
                        isInvalid={!!errors['message']}
                        className={styles['message']}
                        {...register('message', {
                            minLength: 1,
                            required: true,
                        })}
                    />
                    <ErrorMessage
                        errors={errors}
                        name='message'
                        render={e => <ErrorText t={t} name='message' {...e} />}
                    />
                </FloatingLabel>
                <Row className='mb-4'>
                    <span>
                        <Trans i18nKey='terms' ns='contact'>
                            xxx
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a className='link-interaction' onClick={() => setShowTermsModal(true)}>
                                Terms
                            </a>
                            xxx
                            <a className='link-interaction' onClick={() => setShowPrivacyModal(true)}>
                                Privacy
                            </a>
                            .
                        </Trans>
                    </span>
                </Row>
                <Row>
                    <Col>
                        <Button buttonType='submit' disabled={!isValid && isSubmitting}>
                            &nbsp;{t('btn_next')}&nbsp;
                            {isSubmitting ? (
                                <i className='icon icon-ownr-o loader' />
                            ) : (
                                <i className='icon icon-angle-right' />
                            )}
                        </Button>
                    </Col>
                </Row>
            </Form>
            <Modal show={showTermsModal} centered={true} onHide={() => setShowTermsModal(false)}>
                <Modal.Header closeButton={true}>{terms?.title}</Modal.Header>
                <Modal.Body>
                    <Wrapper elements={terms?.columns} styleContext={{ background: 'bg-white' }} />
                </Modal.Body>
                <Modal.Footer>
                    <Button color='velvet' onClick={() => setShowTermsModal(false)}>
                        {t('modal_close')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showPrivacyModal} centered={true} onHide={() => setShowPrivacyModal(false)}>
                <Modal.Header closeButton={true}>{privacyPolicy?.title}</Modal.Header>
                <Modal.Body>
                    <Wrapper elements={privacyPolicy?.columns} styleContext={{ background: 'bg-white' }} />
                </Modal.Body>
                <Modal.Footer>
                    <Button color='velvet' onClick={() => setShowPrivacyModal(false)}>
                        {t('modal_close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

const ReasonSelect = ({
    reasons,
    mandatoryFields,
    formId,
    register,
    t,
}: {
    reasons: string[];
    mandatoryFields: string[];
    formId: (id: string) => string;
    register: UseFormRegister<FieldValues>;
    t: TFunction;
}) => {
    const options =
        reasons.length === 1 ? (
            <option selected key={reasons[0]} value={reasons[0]}>
                {t(`reasons.${snakeCase(reasons[0].toLowerCase())}`)}
            </option>
        ) : (
            ['', ...reasons].map((value, idx) => (
                <option key={value + idx} value={value}>
                    {value?.length ? t(`reasons.${snakeCase(value.toLowerCase())}`) : ''}
                </option>
            ))
        );

    return (
        <Form.Group controlId={formId('reason')} className='mb-3 w-100'>
            <Form.Label className='ps-2 text-velvet-500'>
                {t('reason')}
                <OptionalMarker t={t} mandatoryFields={mandatoryFields} name='reason' />
            </Form.Label>
            <Form.Select
                defaultValue={reasons[0]}
                aria-label={t('reason')}
                {...register('reason', {
                    required: isMandatory(mandatoryFields, 'reason'),
                    minLength: 1,
                })}
            >
                {options}
            </Form.Select>
        </Form.Group>
    );
};
