import { useLayoutEffect, useState } from 'react';

export type WindowDimensions = { width?: number; height?: number; serverSide: boolean };

function getWindowDimensions(): WindowDimensions {
    if (typeof window === 'undefined') return { serverSide: true };
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
        serverSide: false,
    };
}

export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState<WindowDimensions>(getWindowDimensions());

    useLayoutEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}
