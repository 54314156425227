import React from 'react';

export default function Share(props: object) {
    return (
        <svg width='18' height='19' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                d='M6.32546 6.6763L10.4418 4.19966C10.4281 4.06831 10.4211 3.93498 10.4211 3.8C10.4211 1.70132 12.1177 0 14.2105 0C16.3034 0 18 1.70132 18 3.8C18 5.89868 16.3034 7.6 14.2105 7.6C12.9375 7.6 11.8111 6.97051 11.1239 6.005L7.37319 8.26168C7.50654 8.64983 7.57895 9.06644 7.57895 9.5C7.57895 9.93356 7.50654 10.3502 7.37319 10.7383L11.1239 12.995C11.8111 12.0295 12.9375 11.4 14.2105 11.4C16.3034 11.4 18 13.1013 18 15.2C18 17.2987 16.3034 19 14.2105 19C12.1177 19 10.4211 17.2987 10.4211 15.2C10.4211 15.065 10.4281 14.9317 10.4418 14.8003L6.32546 12.3237C5.65388 12.9306 4.7647 13.3 3.78947 13.3C1.69661 13.3 0 11.5987 0 9.5C0 7.40132 1.69661 5.7 3.78947 5.7C4.7647 5.7 5.65388 6.06941 6.32546 6.6763ZM14.2105 5.7C15.257 5.7 16.1053 4.84934 16.1053 3.8C16.1053 2.75066 15.257 1.9 14.2105 1.9C13.1641 1.9 12.3158 2.75066 12.3158 3.8C12.3158 4.84934 13.1641 5.7 14.2105 5.7ZM14.2105 17.1C15.257 17.1 16.1053 16.2493 16.1053 15.2C16.1053 14.1507 15.257 13.3 14.2105 13.3C13.1641 13.3 12.3158 14.1507 12.3158 15.2C12.3158 16.2493 13.1641 17.1 14.2105 17.1ZM3.78947 11.4C4.83591 11.4 5.68421 10.5493 5.68421 9.5C5.68421 8.45066 4.83591 7.6 3.78947 7.6C2.74304 7.6 1.89474 8.45066 1.89474 9.5C1.89474 10.5493 2.74304 11.4 3.78947 11.4Z'
                fill='currentColor'
            />
        </svg>
    );
}
