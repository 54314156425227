import { RichText } from '@graphcms/rich-text-react-renderer';
import { Grid as Record, GridColumns } from '@/lib/api/graphcms';
import { Col, Container, Row, Stack } from 'react-bootstrap';
import classNames from 'classnames';
import { ComponentLayout, Wrapper } from '../Wrapper';
import { enumToBackground, kebabCase, splitToChunks } from '@/lib/utilities';
import { Button } from './Button';
import React, { ReactNode, useMemo } from 'react';
import { WithStyleContext } from '../../../types';
import { lockSymbols, idToVariant } from '@/components/common';
import styles from './Grid.module.scss';

export type GridProps = Partial<Record> & WithStyleContext;

export function Grid(props: GridProps) {
    const {
        id,
        slug,
        columns = [],
        components = [],
        colLayout,
        background = 'white',
        ctas = [],
        width = 1,
    } = props || {};
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const columnsMerged = [...components, ...columns];
    if (!columnsMerged?.length) return null;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const grid: GridColumns[][] = width > 0 ? splitToChunks(columnsMerged, width) : [columnsMerged];
    const columnLayout = (COLUMN_LAYOUT[colLayout || 'plain'] || DEFAULT_LAYOUT)(width);
    const backgroundClass = enumToBackground(background);
    return (
        <div id={kebabCase(slug, 'grid')} className={classNames(backgroundClass, 'pt-2 pb-3')}>
            <Container className='mt-3 mb-3'>
                <GridElements {...props}>
                    {grid.map((items, idx) => (
                        <Row
                            key={`${id}-row-${idx}`}
                            md={width}
                            sm={1}
                            xs={1}
                            // className={classNames({ 'gap-3': colLayout === 'card' })}
                        >
                            <Wrapper
                                elements={items}
                                layout={columnLayout}
                                styleContext={{ background: backgroundClass }}
                            />
                        </Row>
                    ))}
                </GridElements>
                <CtaButtons ctas={ctas} />
            </Container>
        </div>
    );
}

const CtaButtons = ({ ctas }: { ctas: { id: string }[] }) => {
    if (ctas?.length <= 0) return null;
    return (
        <Stack gap={4} direction='horizontal' className='justify-content-center mt-3'>
            {ctas.map(button => (
                <div key={button.id}>
                    <Button {...button} />
                </div>
            ))}
        </Stack>
    );
};

const GridElements = (props: GridProps & { children: ReactNode }) => {
    const { id, title, headline, subtext, layout = 'split' } = props || {};
    const { raw: subtitle } = subtext || {};
    const hasSubtitle = !!(
        (subtitle?.children || [])[0]?.children > 1 || (subtitle?.children || [])[0]?.children[0]?.text
    );
    const hasText = headline?.length || title?.length || hasSubtitle || subtitle?.children?.[0]?.text;
    const isSplit = layout === 'split';
    const isDecorated = !isSplit && layout?.indexOf('decorated') >= 0;

    const GridDecoration = (props: { [key: string]: unknown }) => {
        const symbols = useMemo(() => {
            return lockSymbols(2, 2, idToVariant(id));
        }, []);

        return (
            <Col
                {...props}
                style={{ backgroundImage: `url("data:image/svg+xml,${encodeURIComponent(symbols)}")` }}
                className={styles['decoration']}
            ></Col>
        );
    };

    if (!hasText) {
        if (isDecorated) {
            return (
                <Row>
                    <Col sm={8} xs={12}>
                        {props.children}
                    </Col>
                    <GridDecoration sm={4} xs={0} />
                </Row>
            );
        }
        return (
            <Row>
                <Col>{props.children}</Col>
            </Row>
        );
    }

    const GridSplitText = (props: { [key: string]: unknown }) => {
        return (
            <Col {...props}>
                {headline && headline?.length > 0 && <h5>{headline}</h5>}
                {title && title?.length > 0 && <h2 className={!isSplit ? 'mb-4' : ''}>{title}</h2>}
                {hasSubtitle && <RichText content={subtitle} />}
            </Col>
        );
    };

    const GridText = () => {
        return (
            hasText &&
            !isSplit && (
                <Row>
                    {headline && headline?.length > 0 && <h5>{headline}</h5>}
                    {title && title?.length > 0 && <h2 className='mb-4'>{title}</h2>}
                    {hasSubtitle && <RichText content={subtitle} />}
                </Row>
            )
        );
    };

    return (
        <Row>
            {hasText && isSplit && <GridSplitText lg={4} />}
            <Col md={hasText && (isSplit || isDecorated) ? 8 : 12}>
                <GridText />
                {props.children}
            </Col>
            {isDecorated && <GridDecoration sm={4} xs={0} />}
        </Row>
    );
};

type LayoutFactory = (columns: number) => ComponentLayout;
const DEFAULT_LAYOUT: LayoutFactory = () => () =>
    function DefaultLayout(element: JSX.Element, key: string) {
        return React.cloneElement(element, {
            key,
            className: classNames(element.props.className, 'col'),
        });
    };

const COLUMN_LAYOUT: { [key: string]: LayoutFactory } = {
    plain: DEFAULT_LAYOUT,
    card: () => () =>
        function CardLayout(element: JSX.Element, key: string) {
            //TODO i think we can do this without using the p-3 class
            return (
                <Col className={'mb-3'} key={'card-layout-' + key}>
                    <div className={'shadow h-100 p-3'}>{element}</div>
                </Col>
            );
            // return React.cloneElement(element, {
            //     key,
            //     className: classNames(element.props.className, 'col', 'shadow'),
            // });
        },
    frame: () => () =>
        function FrameLayout(element: JSX.Element, key: string) {
            return React.cloneElement(element, {
                key,
                className: classNames(element.props.className, 'col', 'border'),
            });
        },
};
