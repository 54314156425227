import React, { CSSProperties } from 'react';
import { Glyph } from '../glyph';
import styles from './button.module.scss';
import classNames from 'classnames';
import { ButtonProps } from './types';
import { computeColorStyle } from './util';
import { kebabCase, notEmpty } from '@/lib/utilities';
import { Link } from '../../../navigation';

export function Button(props: ButtonProps & { customStyles?: Partial<CSSProperties> }) {
    const {
        id,
        as: tagName,
        glyph,
        placement = 'end',
        color,
        children,
        className,
        disabled = false,
        type = 'solid',
        buttonType,
        customStyles,
        cta,
        ...rest
    } = props || {};

    const Component = (
        tagName || buttonType?.toLowerCase() === 'submit' || buttonType?.toLowerCase() === 'reset' ? 'button' : Link
    ) as React.ElementType;
    let optionals: Record<string, unknown> = {};
    if (id) {
        optionals = { ...optionals, id };
    }
    if (disabled) {
        optionals = { ...optionals, disabled: true, 'aria-disabled': true };
    }
    if (type) {
        optionals = { ...optionals, type };
    }
    const start = placement === 'start';
    let childElements: (string | JSX.Element)[] = (
        cta && cta.length ? [cta] : Array.isArray(children) ? children : [children]
    ).filter(notEmpty);
    if (glyph) {
        childElements = start
            ? [<Glyph className='me-3' key='glyph' name={glyph} />, ...childElements]
            : [...childElements, <Glyph className='ms-3' key='glyph' name={kebabCase(glyph)} />];
    }
    const { style, outline = false, text = false } = computeColorStyle(color, type, disabled);

    return (
        <Component
            role='button'
            style={customStyles}
            className={classNames(
                'text-button',
                text ? styles['link'] : styles['button'],
                styles[style],
                outline ? styles['border'] : null,
                className
            )}
            {...optionals}
            {...rest}
        >
            {childElements}
        </Component>
    );
}
