import { Divider } from './Divider';

export const InfoSegment = ({ text, value }: { text: string; value: string | number | object }) => {
    if (typeof value === 'object') {
        return null;
    }
    return (
        <div style={{ flex: 1 }}>
            <div className='d-flex flex-row align-items-center gap-4'>
                <div>{text}</div>
                <Divider />
                <div className='fs-bold text-nowrap'>{value}</div>
            </div>
        </div>
    );
};
