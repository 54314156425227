import React from 'react';
import glyphs from './glyphs';
import { GlyphProps } from './types';

export const Glyph = ({ name, className, color }: GlyphProps): JSX.Element | null => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const GlyphComponent = glyphs[name];

    if (!GlyphComponent) {
        console.error(`Glyph named ${name} was not found in the set of available glyphs`);
        return null;
    }

    return <GlyphComponent className={className} style={{ color: color || 'inherit' }} />;
};
