import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { formatCurrency } from '@/lib/utilities';
import { LeaseRate } from './LeaseRate';
import { RefurbishmentLevel } from '@/lib/api/graphcms';
import { Property } from '@/lib/api/lease-requests/types';

export const PropertyPriceInfo = ({
    property,
    refurbishmentLevel,
    leaseRate,
}: {
    property: Property;
    refurbishmentLevel?: RefurbishmentLevel | null;
    leaseRate?: number;
}) => {
    const { t } = useTranslation('metasearch');
    const { metadata } = property;
    const hidePurchasePrice = metadata?.hidePurchasePrice;
    const { locale = 'de' } = useRouter();

    return (
        <>
            {property?.price && (
                <>
                    {!hidePurchasePrice && (
                        <p data-cy='expose-price'>
                            {t('expose_price')}: {formatCurrency(locale, property?.price)}
                        </p>
                    )}
                    {refurbishmentLevel && (
                        <div className='mb-4'>
                            <p>
                                {t('refurbishment.selected')}: {refurbishmentLevel.name}
                            </p>
                            <span className='text-tag'>{refurbishmentLevel.description}</span>
                        </div>
                    )}
                </>
            )}
            {leaseRate && <LeaseRate leaseRate={leaseRate} />}
        </>
    );
};
