import { useEffect, useMemo } from 'react';
import { BrokerRegistrationFormProps } from '../User/types';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'next-i18next';
import { TFunction } from 'react-i18next';
import styles from '../User/registration.module.scss';
import { colorVariant, lockSymbols, patternVariant } from '@/components/common';
import { gtm } from '@/lib/utilities';
import { ContactForm } from './ContactForm';

const MANDATORY_BASE = ['firstName', 'lastName', 'email', 'password', 'region', 'propertyURL'];

export function BrokerRegistration(props: BrokerRegistrationFormProps) {
    const symbols = useMemo(() => {
        const date = new Date();
        return lockSymbols(2, 2, { pattern: patternVariant(date.getDay()), colors: colorVariant(date.getDate()) });
    }, []);

    const { t } = useTranslation(['register', 'common', 'properties']) as unknown as {
        t: TFunction<string>;
    };
    const mandatoryFields = [...new Set([...(props.mandatoryFields || []), ...MANDATORY_BASE])];

    const fieldsToHide = (props.fieldsToHide || []).filter(f => !mandatoryFields.includes(f));
    const { additionalContent, brokerApplication: brokerApplication } = props || {};
    const { 'terms-and-conditions': terms, 'privacy-policy': privacyPolicy } = additionalContent || {};

    useEffect(() => {
        gtm.event(gtm.EVENTS.APPLICATION_STARTED, { category: gtm.CATEGORIES.BROKER_APPLICATION });
    }, []);

    return (
        <Row className='w-100 p-0 mb-6'>
            <Col xs={12} sm={8}>
                <Row>
                    <ContactForm
                        {...props}
                        formId={params => `broker-register-${params}`}
                        mandatoryFields={mandatoryFields}
                        fieldsToHide={fieldsToHide}
                        t={t}
                        content={{ terms, privacyPolicy }}
                        brokerApplication={brokerApplication}
                    />
                </Row>
            </Col>
            <Col
                sm={4}
                xs={0}
                style={{ backgroundImage: `url("data:image/svg+xml,${encodeURIComponent(symbols)}")` }}
                className={styles['decoration']}
            ></Col>
        </Row>
    );
}
