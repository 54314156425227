import classNames from 'classnames';
import React from 'react';
import { Alert as BootstrapAlert } from 'react-bootstrap';
import { AlertProps } from 'react-bootstrap';

type InnerProps = Omit<AlertProps, 'variant'>;

export const InfoAlert = ({ className, ...rest }: InnerProps) => (
    <BootstrapAlert
        {...rest}
        variant='light'
        className={classNames(className, 'text-black bg-velvet-50 border-velvet-200 border-1')}
    />
);

export const SuccessAlert = ({ className, ...rest }: InnerProps) => (
    <BootstrapAlert
        {...rest}
        variant='light'
        className={classNames(className, 'text-black bg-success-alert border-success-alert border-1')}
    />
);
export const DangerAlert = ({ className, ...rest }: InnerProps) => (
    <BootstrapAlert
        {...rest}
        variant='light'
        className={classNames(className, 'text-black bg-coral-100 border-coral-200 border-1')}
    />
);

export const ErrorAlert = ({ children, className, ...rest }: InnerProps) => {
    return (
        <div className={classNames(className, 'bg-coral-600 rounded-8 p-2 text-white body-s text-center')} {...rest}>
            {children}
        </div>
    );
};
