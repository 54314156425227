import { notEmpty } from '@/lib/utilities';
import * as Blocks from './blocks';
import { isIterable } from 'iterall';
import { ConditionTagBlock, GridColumns, PageBlocks } from '@/lib/api/graphcms';
import { WithStyleContext } from '../../types';

export type BodyElement = Pick<PageBlocks | GridColumns | ConditionTagBlock, 'id'> & {
    __typename?: string;
    className?: string;
};
export type BodyElements = BodyElement[];

export type LayoutFunction = (
    element: JSX.Element,
    key: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props?: any
) => JSX.Element;
type ElementType = keyof typeof Blocks;
type BlockComponent = typeof Blocks[ElementType] &
    Partial<WithStyleContext> & {
        pageLayoutContainer?: LayoutFunction;
    };

export type ComponentLayout = (Component: BlockComponent) => LayoutFunction | null;
export type WrapperProps = {
    elements?: BodyElement | BodyElements | null;
    layout?: ComponentLayout;
} & WithStyleContext;

export function Wrapper(props: WrapperProps) {
    const { elements, layout, styleContext } = props || {};
    const list: BodyElement[] = !elements ? [] : isIterable(elements) ? elements.filter(notEmpty) : [elements];
    return (
        <>
            {list.map(({ __typename, id, ...record }, idx) => {
                const t: ElementType = __typename as ElementType;
                const Component: BlockComponent = Blocks[t];
                if (!Component) return null;

                const l = layout && layout(Component);
                const key = id || `wrpr-${idx}`;

                if (l) {
                    return l(<Component id={id} {...record} key={key} styleContext={styleContext} />, key, props);
                }
                return <Component id={id} {...record} key={key} styleContext={styleContext} />;
            })}
        </>
    );
}

export function pageLayout(Component: BlockComponent): LayoutFunction | null {
    if ('pageLayoutContainer' in Component) {
        return Component.pageLayoutContainer || null;
    }
    return null;
}
