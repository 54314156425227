import { ConditionTagList as Component } from '@/lib/api/graphcms';
import { Wrapper } from '../Wrapper';
import { WithStyleContext } from '../../../types';
import React, { useContext } from 'react';

export type ConditionTagListProps = Partial<Component> & WithStyleContext;
export type ConditionTagListSelected = { tags: string[] };
export const ConditionTagListContext = React.createContext({} as ConditionTagListSelected);

export function ConditionTagList(props: ConditionTagListProps) {
    const { blocks = [] } = props || {};
    const { tags = [] } = useContext(ConditionTagListContext) || {};

    const selected = blocks.filter(block => {
        const { tags: selected = [], default: isDefault = false } = block;
        const contains = tags.findIndex(t => selected.includes(t)) >= 0;
        return !isDefault && contains;
    });

    if (selected.length) return <Wrapper elements={selected} {...props} />;
    const defaults = blocks.filter(({ default: isDefault = false }) => isDefault);
    return <Wrapper elements={defaults} {...props} />;
}

export type ConditionTagBlockProps = Partial<Component> & WithStyleContext;

export function ConditionTagBlock(props: ConditionTagBlockProps) {
    const { blocks = [] } = props || {};

    return <Wrapper elements={blocks} {...props} />;
}
