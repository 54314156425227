import React, { useEffect } from 'react';
import { SupportedRegions } from '@/lib/api/bff';
import { RefurbishmentLevelGroup } from '@/lib/api/graphcms';
import { getRefurbishmentGroupByRegion } from './utils';
import { OptionsButton } from '../OptionsButton';
import { Col, Row } from 'react-bootstrap';

const minRefurbishmentValueIndex = (key: string | null) => {
    if (!key) return 0;

    switch (key) {
        case 'basis':
            return 0;
        case 'medium':
            return 1;
        case 'full':
            return 2;
        default:
            return 0;
    }
};

const refurbishmentLevelChanged = (minRefurbishmentIndex, desiredLevel, onChange) => {
    if (+desiredLevel.currentTarget.value < minRefurbishmentIndex) return;

    onChange(+desiredLevel.currentTarget.value);
};

export const RefurbishmentLevels = ({
    refurbishmentGroups,
    region,
    selectedRefurbishmentIndex,
    onChange,
    minRefurbishmentKey,
    isInfoSection = false,
}: {
    refurbishmentGroups: RefurbishmentLevelGroup[];
    region: SupportedRegions;
    selectedRefurbishmentIndex: number;
    onChange: (value: number) => void;
    minRefurbishmentKey?: string;
    isInfoSection?: boolean;
}) => {
    const minRefurbishmentIndex = minRefurbishmentValueIndex(minRefurbishmentKey);

    useEffect(() => {
        if (!isInfoSection) onChange(minRefurbishmentIndex);
    }, []);

    return (
        <Row className='d-flex gap-3 flex-wrap flex-column flex-md-row'>
            {getRefurbishmentGroupByRegion(refurbishmentGroups, region).level.map((lvl, idx) => (
                <Col key={`${lvl}-${idx}`}>
                    <OptionsButton
                        id={`refurbishment-${idx}`}
                        type='radio'
                        name={isInfoSection ? 'refurbishmentInfo' : 'refurbishment'}
                        value={idx}
                        wrapperClassName='w-100'
                        checked={selectedRefurbishmentIndex === idx}
                        onChange={desired => refurbishmentLevelChanged(minRefurbishmentIndex, desired, onChange)}
                        disabled={minRefurbishmentIndex > idx}
                    >
                        {lvl.name}
                    </OptionsButton>
                </Col>
            ))}
        </Row>
    );
};
