import React from 'react';

export default function Close(props: object) {
    return (
        <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M6.70063 8L0 14.7006L1.29937 16L8 9.29936L14.7006 16L16 14.7006L9.29937 8L16 1.29936L14.7006 0L8 6.70063L1.29937 5.67972e-08L7.14768e-06 1.29936L6.70063 8ZM6.70063 8L8 6.70063L9.29937 8L8 9.29936L6.70063 8Z'
                fill='currentColor'
            />
            <path d='M8 6.70063L6.70063 8L8 9.29936L9.29937 8L8 6.70063Z' fill='currentColor' />
        </svg>
    );
}
