/* eslint-disable-next-line */
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import styles from './dropdown.module.scss';
import { Controller, useFormContext } from 'react-hook-form';
import { DropdownProps, OptionItem } from './types';

export function Dropdown(props: DropdownProps) {
    const {
        title,
        name,
        className,
        options = [],
        defaultValues = [],
        multiple = true,
        actionPhrase,
        startCollapsed = true,
    } = props || {};
    const [collapsed, setCollapsed] = useState(startCollapsed);
    const [selected, setSelected] = useState<OptionItem[]>([]);
    const { control, setValue } = useFormContext();
    const isSelected = ({ key }: OptionItem) => selected.findIndex(i => i.key === key) >= 0;
    const addToSelection = (item: OptionItem) => {
        if (multiple) {
            if (selected.findIndex(i => i.key === item.key) >= 0) return;
            setSelected([...selected, item]);
            setValue(name, [...selected.map(({ key }) => key), item.key]);
        } else {
            setSelected([item]);
            setValue(name, [item.key]);
        }
    };
    const removeFromSelection = (item: OptionItem) => {
        const newSelected = multiple ? selected.filter(s => s.key !== item.key) : [];
        setSelected(newSelected);
        setValue(
            name,
            newSelected.map(({ key }) => key)
        );
    };

    useEffect(() => {
        if (selected?.length > 0 || !defaultValues) return;

        const dflts = options.filter(i => defaultValues.includes(i.key));
        setSelected([...dflts]);
        setValue(name, [...dflts.map(({ key }) => key)]);
    }, [defaultValues, name, options, selected, setValue]);

    return (
        <>
            <div className={classNames(styles['dropdown'], 'w-100 shadow', className)}>
                <div
                    role='button'
                    className={classNames(
                        styles['dropdown-button'],
                        'w-100 m-0 d-flex justify-content-between align-items-middle'
                    )}
                    onClick={() => setCollapsed(!collapsed)}
                >
                    <div className='text-caption-bold pt-3'>{title}</div>
                    <div
                        className={classNames(styles['icon'], collapsed ? styles['icon-closed'] : styles['icon-open'])}
                    >
                        <svg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <rect x='0.75' y='0.75' width='40.5' height='40.5' rx='20.25' fill='#725BFF' />
                            <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M31 17.2424L29.7267 16L21 24.5152L12.2733 16L11 17.2424L21 27L31 17.2424Z'
                                fill='white'
                            />
                            <rect
                                x='0.75'
                                y='0.75'
                                width='40.5'
                                height='40.5'
                                rx='20.25'
                                stroke='#725BFF'
                                strokeWidth='1.5'
                            />
                        </svg>
                    </div>
                </div>
                <div className={classNames(styles['dropdown-body'], 'collapse', { show: !collapsed })}>
                    <div>
                        <div className={classNames(styles['dropdown-tags'])}>
                            <ul className={classNames(styles['tag-list'])}>
                                {options.map(item => {
                                    if (!isSelected(item))
                                        return (
                                            <li
                                                key={item.key}
                                                role='button'
                                                className='text-tag'
                                                onClick={() => addToSelection(item)}
                                            >
                                                {item.label}
                                            </li>
                                        );

                                    return (
                                        <li
                                            key={item.key}
                                            role='button'
                                            className='selected text-tag'
                                            onClick={() => removeFromSelection(item)}
                                        >
                                            {item.label} &times;
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
                <Controller
                    name={name}
                    control={control}
                    defaultValue={selected}
                    render={({ field }) => (
                        <select style={{ display: 'none' }} multiple={multiple} {...field}>
                            {options.map(item => (
                                <option key={item.key} value={item.key}>
                                    {item.label}
                                </option>
                            ))}
                        </select>
                    )}
                />
            </div>
            {actionPhrase && <div className='text-form my-2 px-2'>{actionPhrase}</div>}
            <div className='mt-6'>
                <ul className={classNames(styles['tag-list'])}>
                    {selected.map(item => {
                        return (
                            <li
                                key={item.key}
                                role='button'
                                className='text-tag selected'
                                onClick={() => removeFromSelection(item)}
                            >
                                {item.label} &times;
                            </li>
                        );
                    })}
                </ul>
            </div>
        </>
    );
}
