import { useTranslation } from 'next-i18next';
import { gtm } from '@/lib/utilities';
import { Link } from '@/components/navigation';
import { useRouter } from 'next/router';

export const RegisterButton = () => {
    const { t } = useTranslation('common');
    const { locale = 'de' } = useRouter();

    const handleClick = () => {
        gtm.event(gtm.EVENTS.APPLY_CLICKED);
    };

    return (
        <Link
            role='button'
            locale={locale}
            target={{ slug: 'account/create' }}
            onClick={handleClick}
            className='btn btn-ternary py-1 px-4 fw-light'
            data-cy='register-link'
        >
            {t('apply')}
        </Link>
    );
};
