import React, { useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { DayPicker, InputDayPickerProps, InputProps } from 'react-day-picker';
import { FormInput, FormInputProps } from '../FormInput';
import styles from '../style.module.scss';
import classNames from 'classnames';

type DatePikerProps<T> = Omit<FormInputProps<T>, 'type'> & {
    inputProps: InputProps;
    dayPickerProps: InputDayPickerProps;
    validate: (value?: Date | string) => void;
};

export const DatePicker = <T extends HTMLInputElement>({
    inputProps,
    dayPickerProps,
    validate,
    ...rest
}: DatePikerProps<T>) => {
    const [showDatePicker, setShowDatePicker] = useState(false);

    return (
        <OverlayTrigger
            trigger='click'
            placement='bottom'
            show={showDatePicker}
            onToggle={(nextShow: boolean) => {
                setShowDatePicker(nextShow);
            }}
            rootClose
            rootCloseEvent='click'
            overlay={
                <Popover className={classNames(styles['date-picker-popover'], 'bg-body shadow')} data-cy='day-picker'>
                    <DayPicker
                        {...dayPickerProps}
                        mode='single'
                        onSelect={() => setShowDatePicker(false)}
                        onDayClick={(day, am, e) => {
                            dayPickerProps.onDayClick && dayPickerProps.onDayClick(day, am, e);
                            validate(day);
                        }}
                    />
                </Popover>
            }
        >
            <FormInput
                type='text'
                onClick={() => setShowDatePicker(true)}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                value={inputProps.value}
                {...inputProps}
                {...rest}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    inputProps.onChange && inputProps.onChange(e);
                    validate(e.target.value);
                }}
            />
        </OverlayTrigger>
    );
};
