import { RichText } from '@graphcms/rich-text-react-renderer';
import { Button as ButtonRecord, GoldenCut as Record, GoldenCutItem, Maybe } from '@/lib/api/graphcms';
import { Container } from 'react-bootstrap';
import GraphCmsImage from '../../GraphCmsImage';
import React from 'react';
import styles from './GoldenCut.module.scss';
import spacingStyles from '../Spacings.module.scss';
import classnames from 'classnames';
import { Button } from '../Button';
import { StickyLabel } from '@/components/common';

export type GoldenCutProps = Partial<Record>;

export function GoldenCut(props: GoldenCutProps) {
    const { id, goldenCutItems = [], startLeftSide, button } = props || {};

    if (goldenCutItems?.length === 0) return null;

    const side = startLeftSide ? 0 : 1;

    return (
        <Container className={styles.goldenCut} key={id}>
            {goldenCutItems.map((item, idx) => (
                <GoldenCutItemComponent key={item.id} {...item} side={(idx + side) % 2} button={button} />
            ))}
        </Container>
    );
}

const GoldenCutItemComponent = ({
    content,
    header,
    image,
    imageString,
    side,
    button,
    buttonTarget, // buttonTargetUrl
    targetReference,
    stickyButton,
    stickyButtonLabel,
}: GoldenCutItem & { side: number; button?: Maybe<ButtonRecord>; imageString?: string }) => {
    const buttonProps = targetReference
        ? { ...button, target: targetReference }
        : buttonTarget
        ? { ...button, external: buttonTarget }
        : button;

    const stickyButtonVariation = !!stickyButton && stickyButtonLabel;

    return (
        <div
            className={classnames(
                styles.marginAligner,
                side ? styles.padRight : styles.padLeft,
                stickyButtonVariation ? spacingStyles.goldenCutSpacingTight : spacingStyles.goldenCutSpacing
            )}
        >
            <div className={classnames(styles.contentContainer, stickyButtonVariation && styles.withStickyButton)}>
                <div className={classnames(styles.textContainer, side && styles.orderOne)}>
                    {header && <h2 className={'tiny-header'}>{header}</h2>}
                    <div className={styles.contentText}>
                        {content && content?.raw && <RichText content={content.raw} />}
                    </div>
                    {button && (
                        <div className={styles.buttonDiv}>
                            <Button {...buttonProps} />
                        </div>
                    )}
                    {stickyButtonVariation && stickyButtonLabel && (
                        <div>
                            <StickyLabel columnLayout title={stickyButtonLabel} ctaButton={{ ...stickyButton }} />
                        </div>
                    )}
                </div>
                <div className={styles.imageContainer}>
                    {imageString ? (
                        /* eslint-disable-next-line @next/next/no-img-element */
                        <img
                            src={imageString}
                            alt=''
                            style={{
                                maxHeight: !stickyButton ? 'clamp(300px, 70vh, 420px)' : 'clamp(300px, 70vh, 620px)',
                                height: '100%',
                            }}
                        />
                    ) : (
                        <GraphCmsImage
                            image={image}
                            className={styles.svgImg}
                            style={{
                                maxHeight: !stickyButton ? 'clamp(300px, 70vh, 420px)' : 'clamp(300px, 70vh, 620px)',
                                height: '100%',
                            }}
                            withParallax
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
