import moment from 'moment-timezone';

export const getTime = (date: string, locale: string) => {
    if (!date) {
        return '';
    }
    return moment(date).tz('Europe/Berlin').locale(locale).format('HH:mm');
};

export const getTimeInterval = (startDate: string, endDate: string, locale: string) => {
    if (!startDate && !endDate) {
        return '';
    }

    if (!endDate && startDate) {
        return getTime(startDate, locale);
    }

    if (!startDate && endDate) {
        return getTime(endDate, locale);
    }

    return `${getTime(startDate, locale)} - ${getTime(endDate, locale)}`;
};

export const checkImageExists = async (url: string) => {
    const img = new Image();
    img.src = url;
    try {
        await img.decode();
        return true;
    } catch (err) {
        return false;
    }
};
