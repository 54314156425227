import React, { MouseEventHandler, ReactNode, useEffect, useState } from 'react';
import styles from './OwnrModal.module.scss';
import classnames from 'classnames';

export const OwnrModal = ({
    className = '',
    fullscreen = false,
    onHide = () => true,
    onOpen = () => true,
    showModal = false,
    hasMoreTopSpace = false,
    modalBody,
    modalFooter,
    modalTitle,
}: OwnrModalProps) => {
    const [isOpen, setIsOpen] = useState(showModal);

    const handleClose = () => {
        setIsOpen(false);
        onHide();
    };

    const handleNoAction: MouseEventHandler = e => {
        e.stopPropagation();
    };

    const handleOpen = () => {
        setIsOpen(true);
        onOpen();
    };

    const keyboardHandler = (event: KeyboardEvent) => {
        if (isOpen && event.key === 'Escape') {
            handleClose();
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', keyboardHandler, false);
        return () => {
            document.removeEventListener('keydown', keyboardHandler, false);
        };
    });

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [isOpen]);

    useEffect(() => {
        if (showModal) {
            handleOpen();
        } else {
            handleClose();
        }
    }, [showModal, handleOpen, handleClose]);
    return (
        <div
            className={classnames(styles.modalWrap, isOpen ? styles.modalOpen : styles.modalClosed)}
            onClick={handleNoAction}
        >
            <div
                className={classnames(
                    styles.modal,
                    isOpen && styles.in,
                    hasMoreTopSpace && styles.moreTopSpace,
                    fullscreen && styles.fullscreenModal,
                    className
                )}
                onClick={handleNoAction}
            >
                <div className={styles.modalDialog}>
                    <div className={styles.closeLoginPopup} onClick={handleClose} />
                    <div className={styles.modalContent}>
                        <ModalHeader>{modalTitle}</ModalHeader>
                        <div className={styles.modalBody}>{modalBody}</div>
                        <ModalFooter>{modalFooter}</ModalFooter>
                    </div>
                </div>
            </div>

            {!fullscreen && (
                <div className={classnames(styles.modalBackdrop, isOpen && styles.in)} onClick={handleClose} />
            )}
        </div>
    );
    // ,
    // document.body
    // )
};

/* eslint-disable @typescript-eslint/no-explicit-any */
const ModalHeader = ({ children }: { children?: ReactNode | undefined }) => {
    return children ? (
        <div className='modal-header'>
            <h4 className='modal-title'>{children}</h4>
        </div>
    ) : null;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
const ModalFooter = ({ children }: { children?: ReactNode | undefined }) => {
    return children ? <div className={styles.modalFooter}>{children}</div> : null;
};

type OwnrModalProps = {
    additionalClass?: string; // Additional CSS-Class that is added to the modal
    className?: string; // Class that is added to modal
    fullscreen?: boolean; // Bool to trigger fullscreen mode
    hasMoreTopSpace?: boolean; // React element for the body of the modal
    modalBody: ReactNode; // React element for the footer of the modal
    modalFooter?: ReactNode; // React element for the title of the modal
    modalTitle?: ReactNode; // Function that is called on modal close
    onHide?: () => void; // Function that is called on modal open
    onOpen?: () => void; // Boolean to display/hide the modal
    showModal: boolean; // Boolean to determine, if modal need the class -moreTopSpace
};
