import { useState } from 'react';
import classNames from 'classnames';
import { gtm } from '@/lib/utilities';
import { Button } from '@/components/common';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { EyeSlash } from 'react-bootstrap-icons';
// todo remove interdependency, extract hook
import { ToastContent, toast } from 'react-toastify';
import { TOAST_OPTIONS } from '@/styles/toasts';
import { useHideProperty } from '@/hooks/useHideProperty';

export const EditPanel = ({
    propertyId,
    showHidePropertyButton,
    containerClassName,
    className,
}: {
    propertyId: string;
    showHidePropertyButton: boolean;
    containerClassName?: string;
    className?: string;
}) => {
    const router = useRouter();
    const [isLoading, setIsLoading] = useState(false);
    const { isLoading: isHidingProperty, hideProperty } = useHideProperty();
    const [hasBeenHidden, setHasBeenHidden] = useState(false);
    const { t } = useTranslation(['metasearch', 'properties']);

    const handleEditButtonClick = () => {
        gtm.event(gtm.EVENTS.EDIT_PROPERTY_CLICKED, {
            category: gtm.CATEGORIES.PROPERTY,
            value: propertyId,
        });
        setIsLoading(true);
        router.push({
            pathname: '/my/properties/edit/[propertyId]',
            query: { propertyId },
        });
    };

    const handleHideButtonClick = async () => {
        gtm.event(gtm.EVENTS.HIDE_PROPERTY_CLICKED, {
            category: gtm.CATEGORIES.PROPERTY,
            value: propertyId,
        });
        const { success, error } = await hideProperty(propertyId);
        if (success) {
            setHasBeenHidden(true);
            toast.success(
                `${t('property_has_been_hidden', { ns: 'properties' }) as ToastContent<string>}`,
                TOAST_OPTIONS
            );
        } else if (error) {
            toast.error(`${t('errors.SOMETHING_WENT_WRONG', { ns: 'myOwnr' }) as ToastContent<string>}`, TOAST_OPTIONS);
        }
    };

    return (
        <div className={classNames(containerClassName, 'my-3 d-flex flex-column gap-3 w-100')}>
            <Button
                disabled={isLoading}
                className={classNames('w-100 justify-content-center d-flex align-items-center', className)}
                type='outline'
                as='/my/properties/edit/[propertyId]'
                onClick={handleEditButtonClick}
            >
                <i
                    style={{ width: '18px' }}
                    className={classNames('icon icon-pencil fs-6 me-1', isLoading ? ' text-gray-200' : '')}
                />
                {t('edit_property', { ns: 'metasearch' })}
            </Button>
            {showHidePropertyButton && !hasBeenHidden && (
                <Button
                    disabled={isHidingProperty}
                    className={classNames('w-100 justify-content-center d-flex align-items-center', className)}
                    type='outline'
                    as='/my/properties/edit/[propertyId]'
                    onClick={handleHideButtonClick}
                >
                    <EyeSlash className={classNames('me-1', isHidingProperty ? 'text-gray-200' : '')} />
                    <span className=''>{t('hide_property', { ns: 'properties' })}</span>
                </Button>
            )}
        </div>
    );
};
