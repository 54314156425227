import { flip } from './util';

export type Orientation = 'north' | 'east' | 'south' | 'west';

export const BASE_PATTERN: Orientation[][] = [
    ['east', 'west', 'south', 'south'],
    ['west', 'north', 'east', 'north'],
    ['north', 'east', 'west', 'south'],
    ['south', 'west', 'east', 'north'],
];

export const ORIENTATIONS = {
    north: 0,
    east: 90,
    south: 180,
    west: 270,
};

export const PATTERN_VARIATIONS: Orientation[][][] = [
    BASE_PATTERN,
    ...Array.from({ length: BASE_PATTERN.length - 1 }).map(flip(BASE_PATTERN)),
];

export const GREY = '#32384b';
export const CORAL = '#f2695b';
export const VELVET = '#725bff';
export const DURATION = 0.75;
export const DELAY = 1;

const BASE_COLORS: string[][] = [
    [GREY, CORAL, VELVET, GREY, CORAL],
    [VELVET, GREY, CORAL, VELVET],
    [CORAL, VELVET, GREY, CORAL],
    [GREY, CORAL, VELVET, GREY],
];

export const COLORS_VARIATIONS: string[][][] = [
    BASE_COLORS,
    ...Array.from({ length: BASE_COLORS.length - 1 }).map(flip(BASE_COLORS)),
];

export const CELL_SIZE = 140;
export const WIDTH = 128;
export const HEIGHT = 118;

export type BaseConfiguration = {
    pattern?: Orientation[][];
    colors?: string[][];
    animate?: [number, number][] | number | 'all';
    initialDelay?: number;
    interval?: number;
    duration?: number;
    cellSizeMultiplier?: number;
};
