import { RichText } from '@graphcms/rich-text-react-renderer';
import { Paragraph as Record } from '@/lib/api/graphcms';
import { Container } from 'react-bootstrap';
import { Button, StickyLabel } from '@/components/common';
import { Icon } from './Icon';
import classNames from 'classnames';
import { WithStyleContext } from '../../../types';
import React from 'react';
import styles from './Spacings.module.scss';
import { AdvancedLink } from './AdvancedLink';

export type ParagraphProps = Partial<Record> &
    Partial<Pick<React.HTMLAttributes<HTMLDivElement>, 'className'>> &
    WithStyleContext;

export function Paragraph(props: ParagraphProps) {
    const {
        content: c,
        className,
        styleContext,
        headline,
        headlineStyle,
        cta,
        stickyLabel,
        attachedPosition,
    } = props || {};
    const { json: content, references } = c || {};
    if (content?.children?.lenght <= 0) return null;
    const headlineVisible = headlineStyle && headlineStyle !== 'hidden';

    return (
        <Container className={classNames(styles.paragraphSpacing, attachedPosition && styles[attachedPosition])}>
            <div className={classNames(className, styleContext?.background)}>
                {headlineVisible && React.createElement(headlineStyle || '', null, headline)}
                {content && (
                    <div className={styles.paraGraphFont}>
                        <RichText
                            content={content}
                            references={references}
                            renderers={{
                                table: ({ children, ...other }) => (
                                    <table className={classNames('table')} {...other}>
                                        {children}
                                    </table>
                                ),
                                embed: {
                                    Button: buttonProps => <Button {...buttonProps} />,
                                    Icon: iconProps => <Icon {...iconProps} />,
                                    AdvancedLink: advancedLinkProps => <AdvancedLink {...advancedLinkProps} />,
                                },
                            }}
                        />
                    </div>
                )}
                {cta && <Button {...cta} />}
                {stickyLabel && <StickyLabel title={stickyLabel?.title} ctaButton={stickyLabel?.cta} />}
            </div>
        </Container>
    );
}

Paragraph.pageLayoutContainer = (element: JSX.Element, key: string) => <div key={key}>{element}</div>;
