import { DateTimeInfo } from './DateTimeInfo';
import { Group, Item } from '@/components/design-system/list-group';
import { RadioButton } from '@/components/design-system/form/inputs/RadioButton';

export const Slots = ({ slots, selectedSlotId, onSlotClick }) => {
    return (
        <Group>
            {slots.map(slot => {
                const selected = selectedSlotId === slot.id;
                return (
                    <Item
                        className='d-flex align-items-center gap-3'
                        onClick={() => {
                            onSlotClick(slot.id);
                        }}
                        selected={selected}
                        key={slot.id}
                    >
                        <RadioButton
                            checked={selected}
                            id={slot.id}
                            onChange={e => {
                                onSlotClick(slot.id);
                                e.stopPropagation();
                            }}
                        />
                        <DateTimeInfo {...slot} />
                    </Item>
                );
            })}
        </Group>
    );
};
