import React from 'react';
import { useTranslation } from 'next-i18next';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { Button } from '@/components/common';
import styles from './styles.module.scss';
import { RefurbishmentLevel, RefurbishmentLevelGroup } from '@/lib/api/graphcms';
import { RefurbishmentLevels } from '../RefurbishmentLevels';
import { SupportedRegions } from '@/lib/api/bff';

export function RefurbishmentInfo({
    isShown,
    setIsShown,
    refurbishmentLevel,
    refurbishmentGroups,
    region,
    selectedRefurbishmentIndex,
    onChange,
    minRefurbishmentKey,
}: {
    isShown: boolean;
    setIsShown: React.Dispatch<React.SetStateAction<boolean>>;
    refurbishmentLevel: RefurbishmentLevel;
    refurbishmentGroups: RefurbishmentLevelGroup[];
    region: SupportedRegions;
    selectedRefurbishmentIndex: number;
    onChange: (value: number) => void;
    minRefurbishmentKey?: string;
}) {
    const { t } = useTranslation('metasearch');

    return (
        <Modal className={styles['refurbishment-info']} show={isShown} centered={true} onHide={() => setIsShown(false)}>
            <Modal.Header
                className={classNames('text-caption-bold border-0 pe-5', styles['refurbishment-info__header'])}
                closeButton={true}
            >
                {t('refurbishment.details')}
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col className='mb-8 mx-3'>
                            <RefurbishmentLevels
                                refurbishmentGroups={refurbishmentGroups}
                                region={region}
                                selectedRefurbishmentIndex={selectedRefurbishmentIndex}
                                onChange={onChange}
                                minRefurbishmentKey={minRefurbishmentKey}
                                isInfoSection={true}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>{refurbishmentLevel.description}</Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer className='border-0 pe-5'>
                <Button onClick={() => setIsShown(false)} type='outline' className='btn btn-sm d-block'>
                    {t('cancel')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
