import styles from './styles.module.scss';
import { BackButton } from './components/BackButton';
import { InfoSection } from './components/InfoSection';
import { Col, Container, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { ImageCarousel } from './components/ImageCarousel/index';
import { ShortDetails } from './components/ShortDetails';
import { Divider } from '../../components/Divider';
import { RefurbishmentLevel, SupportedRegions } from '@/lib/api/bff';
import { PropertyPriceInfo } from './components/PropertyPriceInfo';
import { RefurbishmentLevelGroup } from '@/lib/api/graphcms';
import { getRefurbishmentLevelByKey } from '../../components/CallToActionBlock/components/RefurbishmentLevels/utils';
import { Property } from '@/lib/api/lease-requests/types';
import { PropertyLocationMap } from '@/components/property/components/PropertyLocationMap';

interface PropertyProps {
    property: Property;
    refurbishmentGroups: RefurbishmentLevelGroup[];
    refurbishmentLevel: RefurbishmentLevel;
    leaseRate: number;
}

export function PropertyWithLeaseRequest({
    property,
    refurbishmentGroups,
    refurbishmentLevel,
    leaseRate,
}: PropertyProps) {
    const { images } = property;

    const region = property?.location?.region?.toUpperCase() as SupportedRegions;
    const refurbishment = getRefurbishmentLevelByKey(refurbishmentGroups, region, refurbishmentLevel?.key);

    return (
        <Container>
            <Row className='mb-8'>
                <BackButton className='mb-3' />
                <Col md={6} lg={7} className='d-flex flex-column gap-5'>
                    <ImageCarousel images={images ?? []} isActive={true} />
                    <ShortDetails property={property} />
                    <Divider />
                    {/*{!isActive && <ExposeNoLongerActive />}*/}
                    <InfoSection
                        // isActive={!!isActive}
                        property={property}
                        // renderCallToActionBlock={() => (
                        //     <PropertyPriceInfo property={property} refurbishmentLevel={refurbishment} />
                        // )}
                    />
                    <PropertyLocationMap
                        location={property?.location}
                        showFullAddress={true}
                        className={styles.mapContainer}
                    />
                </Col>

                <Col md={6} lg={5} className='d-flex align-content-center'>
                    <div className={classNames('container h-auto d-none d-md-block', styles['lease-rate__container'])}>
                        <PropertyPriceInfo
                            property={property}
                            leaseRate={leaseRate}
                            refurbishmentLevel={refurbishment}
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
