import { FormPartProps } from '../types';
import { Form } from 'react-bootstrap';
import { Button } from '@/components/common';
import classNames from 'classnames';
import React from 'react';
import { OptionalMarker } from '../util';
import { Control, FormProvider, useForm } from 'react-hook-form';
import { SupportedRegions } from '@/lib/api/bff';
import { Card } from './SubComponents/Card';
import { LocationSelect, FormValuesType } from '../../../../search';

export function DistrictForm(
    props: FormPartProps & {
        selectDislikedDistricts?: boolean;
        trendingDistricts?: Record<string, string[]>;
    }
) {
    const {
        formId,
        t,
        mandatoryFields = [],
        defaultValues = {},
        otherValues = { city: { region: '' } },
        activeRegions = [],
        suggestedLocationsByRegion,
        navigateForward,
    } = props || {};
    const hookForm = useForm({ mode: 'onSubmit', defaultValues });
    const {
        control,
        watch,
        handleSubmit,
        formState: { isValid },
        getValues,
        setValue,
    } = hookForm;
    const activeRegionSet = new Set(activeRegions);
    const { region } = otherValues['city'] || {};

    if (!region) return null;
    const isCurrentRegion = 'other' !== region;

    const suggestedLocations = suggestedLocationsByRegion?.[region.toUpperCase()].map(
        ({ casafariId, name }: { casafariId: number; name: string }) => ({
            label: name,
            value: casafariId,
        })
    );

    const submitBtnDisabled = !isValid || !((watch('locationsIds') || []).length > 0);

    return (
        <FormProvider {...hookForm}>
            <Form onSubmit={handleSubmit(navigateForward)}>
                {isCurrentRegion && (
                    <Form.Group
                        controlId={formId('form')}
                        className={classNames('mb-3 w-100', {
                            'text-grey': !activeRegionSet.has(region),
                        })}
                        aria-disabled={!activeRegionSet.has(region)}
                    >
                        <Form.Label>
                            {t('districts.intro')}
                            <OptionalMarker t={t} mandatoryFields={mandatoryFields} name='locationsLiked' />
                        </Form.Label>

                        <Card title={t('districts.field')}>
                            <LocationSelect
                                region={region.toUpperCase() as SupportedRegions}
                                suggestedLocations={suggestedLocations}
                                control={control as unknown as Control<FormValuesType, object>}
                                getValues={getValues}
                                setValue={setValue}
                                watch={watch}
                            />
                        </Card>
                    </Form.Group>
                )}
                <div className='mt-5'>
                    <Button buttonType='submit' disabled={submitBtnDisabled}>
                        {t('btn_next')}
                    </Button>
                </div>
            </Form>
        </FormProvider>
    );
}
