import React, { useState } from 'react';
import classNames from 'classnames';
import { Button, Glyph } from '@/components/common';
import { FlipCard as Record } from '@/lib/api/graphcms';
import { GraphCmsImage } from '@/components/graph-cms';
import styles from './flip-card.module.scss';

export type FlipCardProps = Partial<Record> & { className?: string };

export const FlipCard = ({ title, description, image, className }: FlipCardProps): JSX.Element => {
    const [flipped, setFlipped] = useState<boolean>(false);

    return (
        <div className={classNames(styles.container, className)}>
            <div className={classNames(styles.inner, flipped && styles.flipped)}>
                <div className={classNames(styles.card, styles.front)} onClick={() => setFlipped(true)}>
                    <div className={styles['image-container']}>
                        <div className={styles.overlay}>
                            <GraphCmsImage image={image} alt='Card image' />
                        </div>
                    </div>
                    <div className={classNames(styles.footer, 'text-white')}>
                        <h3 className={classNames(styles.title, styles['title-front'])}>{title}</h3>
                        <Button className={classNames(styles.button, 'btn btn-velvet')}>
                            <Glyph name='plus' color='white' />
                        </Button>
                    </div>
                </div>

                <div className={classNames(styles.card, styles.back)}>
                    <div className={styles['text-container']}>
                        <h3 className={styles.title}>{title}</h3>
                        <p className={styles.description}>{description}</p>
                    </div>
                    <div className={classNames(styles.footer, 'justify-content-end')}>
                        <Button className={classNames(styles.button)} onClick={() => setFlipped(false)}>
                            <Glyph name='minus' color='white' />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
