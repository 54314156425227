import useSWR from 'swr';
import { notify } from '@/lib/utilities/logger';
import { useAccountId } from '@/components/users';
import { useLeaseRequestsClient, usePropertiesClient } from '@/lib/api';
import { GET_PROPERTY, LEASE_REQUEST_BY_PROPERTY } from '@/lib/constants/paths';
import { PropertyDetails } from '@/lib/api/properties/types';
import { logErrorResponse } from '@/lib/api/utils';

export const useProperty = (
    propertyId: number | string
): {
    property: PropertyDetails;
    error: any;
    isLoading: boolean;
} => {
    const api = usePropertiesClient();

    if (!api) throw new Error('property service api is not initialized!');

    const { data, error, isLoading } = useSWR(
        () => (propertyId ? [GET_PROPERTY, propertyId] : null),
        async () => {
            const { data } = await api.GET(GET_PROPERTY, {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                params: { path: { propertyID: propertyId.toString() } },
            });

            return data;
        }
    );

    if (error) {
        notify(typeof error === 'object' ? JSON.stringify(error) : error, GET_PROPERTY);
    }

    return {
        property: data as unknown as PropertyDetails,
        error: error,
        isLoading,
    };
};

export const useLeaseRequestByPropertyId = (propertyId: string) => {
    const accountId = useAccountId();
    const leaseRequestsClient = useLeaseRequestsClient();

    const key = [LEASE_REQUEST_BY_PROPERTY, accountId, propertyId];

    const { data, error, isLoading, mutate } = useSWR(
        () => (accountId && propertyId ? key : null),
        async () => {
            const requestData = {
                params: { path: { id: propertyId }, query: { accountId } },
            };
            const response = await leaseRequestsClient.GET(LEASE_REQUEST_BY_PROPERTY, requestData);
            if (response.response?.status >= 400)
                logErrorResponse(response.response, accountId, requestData, response.error);
            return response.data;
        }
    );

    if (error) notify(typeof error === 'object' ? JSON.stringify(error) : error, LEASE_REQUEST_BY_PROPERTY, accountId);

    return {
        leaseRequest: data,
        error,
        isLoading,
        mutate,
    };
};

export { useRefurbishmentGroups } from '@/hooks/useRefurbishmentGroups';
