import React from 'react';
import classNames from 'classnames';
import { GraphCmsImage } from '../../../../graph-cms';
import { IllustrationCardComponent } from '@/lib/api/graphcms';
import { enumToBackground } from '@/lib/utilities';
import { RichText } from '@graphcms/rich-text-react-renderer';
import styles from './illustration-card.module.scss';

export type IllustrationCardProps = Partial<IllustrationCardComponent> & { className?: string };

export const IllustrationCard = ({ className, background, image, text }: IllustrationCardProps) => {
    return (
        <div className={classNames(styles.container, enumToBackground(background), className)}>
            <GraphCmsImage className={styles.image} image={image} alt='Illustration card' />
            <div className={styles.content}>
                <RichText content={text?.raw} />
            </div>
        </div>
    );
};
