import React from 'react';
import classNames from 'classnames';
import { FlipCard, lockSymbols } from '@/components/common';
import { Row, Col, Container } from 'react-bootstrap';
import { FlipCard as FlipCardType } from '@/lib/api/graphcms';
import Slider from 'react-slick';
import styles from '../../Gallery.module.scss';

type FlipCardLayoutProps = {
    items: FlipCardType[];
    className?: string;
};

export const FlipCardLayout = ({ items = [], className }: FlipCardLayoutProps): JSX.Element | null => {
    const sliderConfig = {
        dots: true,
        centerMode: true,
        slidesToShow: 1,
        infinite: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 380,
                settings: {
                    centerMode: false,
                },
            },
        ],
    };
    const desktopSymbols = lockSymbols(5, 1, { cellSizeMultiplier: 2 });
    const mobileSymbols = lockSymbols(3, 2, {});

    return (
        <div className={classNames(styles.galleryContainer)}>
            <div
                className={classNames(styles.backgroundContainer, styles.hideOnMobile)}
                style={{
                    backgroundImage: `url("data:image/svg+xml,${encodeURIComponent(desktopSymbols)}")`,
                }}
            >
                <div
                    className={classNames(styles.backgroundContainer, styles.hideOnDesktop)}
                    style={{
                        backgroundImage: `url("data:image/svg+xml,${encodeURIComponent(mobileSymbols)}")`,
                    }}
                >
                    <Container style={{ paddingRight: 0 }}>
                        <Row className={classNames(styles.container, className, 'd-none d-sm-block')}>
                            {items?.map(item => (
                                <Col md={6} lg={4} xl={4} xxl={3} key={item.id}>
                                    <FlipCard {...item} />
                                </Col>
                            ))}
                        </Row>

                        <div style={{ position: 'relative' }}>
                            <Slider
                                {...sliderConfig}
                                className={classNames(styles.slider, 'd-block d-sm-none', styles.symbols)}
                            >
                                {items?.map(item => (
                                    <FlipCard key={item.id} className={styles['card-space']} {...item} />
                                ))}
                            </Slider>
                        </div>
                    </Container>
                </div>
            </div>
        </div>
    );
};
