import React, { TextareaHTMLAttributes } from 'react';
import { Form, FormControlProps } from 'react-bootstrap';
import classNames from 'classnames';
import styles from '../style.module.scss';

export const Textarea = React.forwardRef(function Textarea(
    props: FormControlProps & TextareaHTMLAttributes<HTMLTextAreaElement>,
    ref: React.Ref<HTMLTextAreaElement>
) {
    const { placeholder, rows, className, ...rest } = props;
    return (
        <Form.Control
            className={classNames('design-system', className, styles['text-area'])}
            ref={ref}
            type='tex'
            as='textarea'
            placeholder={placeholder}
            rows={rows}
            {...rest}
        />
    );
});
