import { notify } from '@/lib/utilities/logger';
import { AppointmentSlot, CreateSlotInput } from '@/lib/api/lease-requests/types';
import { useLeaseRequestsClient } from '@/lib/api';
import { SLOTS_REQUEST, SLOTS_SEARCH_REQUEST } from '@/lib/constants/paths';
import { useState } from 'react';
import { mutate } from 'swr';
import { logErrorResponse } from '@/lib/api/utils';
import { useAccountId } from '@/components/users';

export const useCreateSlot = (): {
    createSlot: (slotInput: CreateSlotInput) => Promise<{
        data: AppointmentSlot;
        error: any;
    }>;
    isLoading: boolean;
} => {
    const api = useLeaseRequestsClient();
    const accountId = useAccountId();
    const [isLoading, setIsLoading] = useState(false);

    const createSlot = async (slotInput: CreateSlotInput) => {
        setIsLoading(true);

        let data, error;

        if (!slotInput) return;

        if (!slotInput.createdBy) {
            notify('No account Id!', SLOTS_REQUEST + ' create');
            return;
        }

        if (!slotInput.propertyId) {
            notify('No property Id!', SLOTS_REQUEST + ' create');
            return;
        }
        try {
            const requestData = { body: { ...slotInput } };
            const response = await api.POST(SLOTS_REQUEST, requestData);
            if (response.response?.status >= 400)
                logErrorResponse(response.response, accountId, requestData, response.error);
            data = response.data;
            error = response.error;

            if (data?.id) {
                mutate([SLOTS_SEARCH_REQUEST, slotInput.createdBy, slotInput.propertyId]);
            }
        } catch (e) {
            error = e;
            notify(e, SLOTS_REQUEST + ' create');
        }
        setIsLoading(false);
        return { data, error };
    };

    return {
        createSlot,
        isLoading,
    };
};
