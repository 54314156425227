import React from 'react';
import { Gallery as Record, GalleryLayout } from '@/lib/api/graphcms';
import { Container } from 'react-bootstrap';
import { FlipCardLayout, IllustrationCardLayout, ImageCardLayout, SliderCardLayout } from './Layouts';
import spacingStyles from '../Spacings.module.scss';

export type GalleryProps = Partial<Record>;

export const Gallery = ({
    layout,
    flipCardItems,
    imageCardItems,
    illustrationCardItems,
    sliderCardItems,
}: GalleryProps): JSX.Element | null => {
    if (layout === GalleryLayout.flip_card) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return <FlipCardLayout items={flipCardItems} />;
    }

    if (layout === GalleryLayout.image_card) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return <ImageCardLayout items={imageCardItems} />;
    }

    if (layout === GalleryLayout.illustration_card) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return <IllustrationCardLayout items={illustrationCardItems} />;
    }

    if (layout === GalleryLayout.slider_card) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return <SliderCardLayout items={sliderCardItems} />;
    }

    return null;
};

Gallery.pageLayoutContainer = (element: JSX.Element, key: string) => (
    <Container className={spacingStyles.gallerySpacing} fluid key={key}>
        {element}
    </Container>
);
