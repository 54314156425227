import { useLeaseRequestsClient } from '@/lib/api';
import { useAccountId } from '@/components/users';
import { useState } from 'react';
import { LEASE_REQUEST } from '@/lib/constants/paths';
import { notify } from '@/lib/utilities/logger';
import { logErrorResponse } from '@/lib/api/utils';

export const useCloseLeaseRequest = () => {
    const leaseRequestsClient = useLeaseRequestsClient();
    const accountId = useAccountId();
    const [isLoading, setIsLoading] = useState(false);

    const closeLeaseRequest = async (leaseRequestId: string, reason: string) => {
        setIsLoading(true);
        let data,
            error = null;

        if (accountId) {
            const requestData = { params: { path: { id: leaseRequestId }, query: { accountId, reason } } };
            try {
                const response = await leaseRequestsClient.DELETE(LEASE_REQUEST, requestData);

                if (response.response?.status >= 400)
                    logErrorResponse(response.response, accountId, requestData, response.error);

                if (response.error) {
                    error = response.error;
                } else {
                    data = response.data;
                }
            } catch (err) {
                error = err;
                notify(typeof error === 'object' ? JSON.stringify(error) : error, LEASE_REQUEST, accountId);
            }
        }

        setIsLoading(false);
        return { data, error };
    };

    return {
        closeLeaseRequest,
        isLoading,
    };
};
