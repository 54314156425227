import { useId } from 'react';
import { BaseConfiguration, CELL_SIZE, DELAY, DURATION, HEIGHT, ORIENTATIONS, WIDTH } from './types';
import { colorVariant, createList, patternVariant } from './util';

export type SymbolsProps = BaseConfiguration & {
    height: number;
    width: number;
};

export function Symbols(props: SymbolsProps) {
    const id = useId();
    const {
        width,
        height,
        pattern = patternVariant(),
        colors = colorVariant(),
        animate,
        initialDelay = DELAY,
        interval = DELAY,
        duration = DURATION,
    } = props;

    const animateList =
        animate === undefined ? [] : Array.isArray(animate) ? animate : createList(animate, width, height);

    return (
        <svg
            width={width * CELL_SIZE}
            height={height * CELL_SIZE}
            viewBox={`0 0 ${width * CELL_SIZE} ${height * CELL_SIZE}`}
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <defs>
                <path
                    id='symbol'
                    d='M63.9055 0.847992C27.6041 0.847992 0 26.7129 0 60.4497C0 102.433 40.839 117.802 46.7001 117.802V86.8769C37.6248 81.0667 32.1418 71.133 32.3309 60.4497C32.1418 43.7687 45.7548 30.274 62.3929 30.0866C62.9601 30.0866 63.3383 30.0866 63.9055 30.0866C80.7326 29.3369 94.9128 42.4567 95.6691 59.1377C95.6691 59.5126 95.6691 60.0749 95.6691 60.4497C96.0473 71.3205 90.3752 81.4415 81.1108 86.8769V117.802C86.7829 117.802 128 102.433 128 60.4497C128 26.9003 100.018 0.847992 63.9055 0.847992Z'
                />
            </defs>
            {Array.from({ length: height }, (_, y) => {
                const orientations = pattern[y % pattern.length];
                const rowColors = colors[y % colors.length];

                return (
                    <>
                        {Array.from({ length: width }, (_, x) => {
                            const animationOrder = animateList.findIndex(([x1, y1]) => x1 === x && y1 === y);
                            const animated = animationOrder >= 0;

                            const orientation = orientations[x % orientations.length];
                            const color = rowColors[x % rowColors.length] || 'var(--bs-gray-500)';
                            const rotation = ORIENTATIONS[orientation];
                            const opposite = (animationOrder % 2 ? rotation + 180 : rotation + 180) % 360;
                            const xOrigin = x * CELL_SIZE + CELL_SIZE / 2;
                            const yOrigin = y * CELL_SIZE + CELL_SIZE / 2;
                            const transform = [];
                            const optionals: { transform?: string } = {};
                            const rotationCenter = `${xOrigin} ${yOrigin}`;
                            if (rotation > 0) transform.push(`rotate(${rotation} ${rotationCenter})`);
                            // if (x > 0 || y > 0) transform.push(`translate(${x * CELL_SIZE || 0} ${y * CELL_SIZE})`);
                            if (transform) optionals.transform = transform.join(' ');
                            const startA =
                                animationOrder < 1
                                    ? `${initialDelay}s; animation-${animateList.length - 1}-b.end+${interval}s`
                                    : `animation-${animationOrder - 1}-a.end+${interval}s`;
                            const startB =
                                animationOrder < 1
                                    ? `animation-${animateList.length - 1}-a.end+${interval}s`
                                    : `animation-${animationOrder - 1}-b.end+${interval}s`;

                            return (
                                <use
                                    key={y}
                                    id={`${id}-${y}-${x}`}
                                    href='#symbol'
                                    x={x * CELL_SIZE + (CELL_SIZE - WIDTH) / 2}
                                    y={y * CELL_SIZE + (CELL_SIZE - HEIGHT) / 2}
                                    fill={color}
                                    transform={`translate(${x * CELL_SIZE})`}
                                    {...optionals}
                                >
                                    {animated && (
                                        <>
                                            <animateTransform
                                                id={`animation-${animationOrder}-a`}
                                                begin={startA}
                                                dur={`${duration}s`}
                                                attributeName='transform'
                                                type='rotate'
                                                from={`${rotation} ${rotationCenter}`}
                                                to={`${opposite} ${rotationCenter}`}
                                                repeatCount='1'
                                                fill='freeze'
                                            />
                                            <animateTransform
                                                id={`animation-${animationOrder}-b`}
                                                begin={startB}
                                                dur={`${duration}s`}
                                                attributeName='transform'
                                                type='rotate'
                                                from={`${opposite} ${rotationCenter}`}
                                                to={`${rotation} ${rotationCenter}`}
                                                repeatCount='1'
                                                fill='freeze'
                                            />
                                        </>
                                    )}
                                </use>
                            );
                        })}
                    </>
                );
            })}
        </svg>
    );
}
