import { SupportedRegions } from '@/lib/api/bff';
import { RefurbishmentLevel, RefurbishmentLevelGroup } from '@/lib/api/graphcms';

export const STEP = 1;

export const getRefurbishmentLevel = (
    refurbishmentGroups: RefurbishmentLevelGroup[],
    region: SupportedRegions,
    refurbishmentIndex: number
): RefurbishmentLevel | undefined => {
    const levelsForRegion = refurbishmentGroups.find(
        (group: RefurbishmentLevelGroup) => group.region.toUpperCase() === region
    )?.level;
    levelsForRegion?.sort((a: RefurbishmentLevel, b: RefurbishmentLevel) => a.adjustment - b.adjustment);
    return levelsForRegion?.[refurbishmentIndex];
};

export const getRefurbishmentAdjustment = (
    refurbishmentGroups: RefurbishmentLevelGroup[],
    region: SupportedRegions,
    refurbishmentIndex: number
) => {
    return getRefurbishmentLevel(refurbishmentGroups, region, refurbishmentIndex)?.adjustment || 1;
};

export const getRefurbishmentGroupByRegion = (
    refurbishmentGroups: RefurbishmentLevelGroup[],
    region: SupportedRegions
) => {
    return refurbishmentGroups.find(group => group.region.toLocaleLowerCase() === region.toLocaleLowerCase());
};

export const getRefurbishmentLevelByKey = (
    refurbishmentGroups: RefurbishmentLevelGroup[],
    region: SupportedRegions,
    key: string
) => {
    const levelsForRegion = getRefurbishmentGroupByRegion(refurbishmentGroups, region).level;

    return levelsForRegion?.find((level: RefurbishmentLevel) => level.key === key);
};
