import { RichText } from '@graphcms/rich-text-react-renderer';
import { Card as Record, RichText as CardRichText } from '@/lib/api/graphcms';
import { Container } from 'react-bootstrap';
import { Button } from './Button';
import * as rb from 'react-bootstrap';
import GraphImage from '@graphcms/react-image';
import React from 'react';
import classNames from 'classnames';
import GraphCmsImage from '../GraphCmsImage';

export type ParagraphProps = Partial<Record> & { cardContent?: CardRichText } & Partial<
        Pick<React.HTMLAttributes<HTMLDivElement>, 'className'>
    >;

export function Card(props: ParagraphProps = {}) {
    const { cardContent: { raw: content } = {}, image, title, subtitle, footer, cta, ctaPlacement } = props;
    const imagePos = props.imagePos || 'top';
    if (content?.children?.lenght <= 0) return null;

    let body: JSX.Element;
    if (isOverlay(imagePos)) {
        body = (
            <rb.Card.ImgOverlay>
                <RichText content={content} />
            </rb.Card.ImgOverlay>
        );
    } else {
        body = (
            <rb.Card.Body>
                <RichText content={content} />
            </rb.Card.Body>
        );
    }

    const imgTop = image && (imagePos === 'top' || imagePos === 'fluid');
    const imgBottom = image && imagePos === 'bottom';

    return (
        <rb.Card className={classNames(props.className)} style={{ textAlign: 'initial' }}>
            {imgTop && <GraphCmsImage className={`card-img-${imagePos}`} image={image} />}
            {image && isOverlay(imagePos) && <GraphCmsImage image={image} />}
            {title && <rb.Card.Title as='h4'>{title}</rb.Card.Title>}
            {subtitle && <rb.Card.Subtitle as='h6'>{subtitle}</rb.Card.Subtitle>}
            {body}
            {cta && (
                <div className={`d-flex justify-content-${ctaPlacement || 'center'}`}>
                    <Button {...cta} />
                </div>
            )}
            {footer && <rb.Card.Footer>${footer}</rb.Card.Footer>}
            {imgBottom && (
                <GraphImage
                    className={`card-img-bottom`}
                    image={{
                        handle: image?.handle || '',
                        width: image?.width || 0,
                        height: image?.height || 0,
                    }}
                />
            )}
        </rb.Card>
    );
}

Card.pageLayoutContainer = (element: JSX.Element, key: string) => <Container key={key}>{element}</Container>;

function isOverlay(imagePosition: string) {
    return imagePosition === 'overlay';
}
