import { RichText } from '@graphcms/rich-text-react-renderer';
import { FlipCardComponent as Record, RichText as CardRichText } from '@/lib/api/graphcms';
import { CloseButton, Container } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import React, { useState } from 'react';
import classNames from 'classnames';
import ReactCardFlip from 'react-card-flip';
import { DynamicComponent } from './DynamicComponent';
import GraphCmsImage from '../GraphCmsImage';

export type ParagraphProps = Partial<Record> & { cardContent?: CardRichText } & Partial<
        Pick<React.HTMLAttributes<HTMLDivElement>, 'className'>
    >;

export function FlipCard(props: ParagraphProps = {}) {
    const { cardContent: { raw: content } = {}, image, title, subtitle, ctaText, ctaPlacement, backside } = props;
    const [flipped, setFlipped] = useState(false);

    if (content?.children?.lenght <= 0) return null;

    return (
        <ReactCardFlip isFlipped={flipped}>
            <Card
                className={classNames(props.className, 'pb-3')}
                style={{ cursor: 'pointer' }}
                onClick={() => setFlipped(!flipped)}
            >
                <>
                    {image && (
                        <GraphCmsImage
                            className={'card-img-top'}
                            style={{ width: '100%', height: 'auto' }}
                            image={image}
                        />
                    )}
                    {title && <Card.Title as='h4'>{title}</Card.Title>}
                    {subtitle && <Card.Subtitle as='h6'>{subtitle}</Card.Subtitle>}
                    <Card.Body className='h-100'>
                        <RichText content={content} />
                    </Card.Body>
                    {ctaText && (
                        <Card.Footer
                            className={classNames(
                                // 'w-50',
                                'align-bottom',
                                'd-flex',
                                `justify-content-${ctaPlacement || 'end'}`
                            )}
                        >
                            <Button variant='velvet' onClick={() => setFlipped(!flipped)}>
                                {ctaText}
                            </Button>
                        </Card.Footer>
                    )}
                </>
            </Card>
            <Card>
                <div className='pt-2'>
                    <div className='w-100 d-flex justify-content-end'>
                        <CloseButton onClick={() => setFlipped(!flipped)} />
                    </div>
                    <DynamicComponent {...backside} styleContext={{ background: 'bg-white' }} />
                </div>
            </Card>
        </ReactCardFlip>
    );
}

FlipCard.pageLayoutContainer = (element: JSX.Element, key: string) => <Container key={key}>{element}</Container>;
