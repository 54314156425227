import { ResendCodeComponent as Form } from '../../../auth-ui';
import React from 'react';
import { resendConfirmationCode } from '@/lib/env';

export function ResendCodeForm() {
    return (
        <div className='container'>
            <Form resendConfirmationCode={resendConfirmationCode} />
        </div>
    );
}
