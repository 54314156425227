import { TFunction } from 'react-i18next';
import { Alert, Col, Row } from 'react-bootstrap';
import classNames from 'classnames';

import styles from '../User/registration.module.scss';

export function SuccessPage({ t }: { t: TFunction }) {
    return (
        <div className='mt-3 mb-5 p-0'>
            <div className='mb-6'>
                <p className='text-copy-l' style={{ fontWeight: 700, fontFamily: 'Source Serif Pro' }}>
                    {t('success.headline_qualified')}{' '}
                </p>
            </div>
            <div className='mb-6'>
                <Alert className={classNames('container p-3', styles['alert'])}>
                    <Row>
                        <Col xs='auto' className='pe-0 ps-4'>
                            <i className={classNames('icon icon-envelope', styles['alert__icon'])} />
                        </Col>
                        <Col className='me-2'>{t(`success.confirm_email`)}</Col>
                    </Row>
                </Alert>
            </div>
        </div>
    );
}
