export default function Parking({ fillColor }) {
    return (
        <svg fill='none' width='15' height='16' viewBox='0 0 15 16' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_506_3127)'>
                <path
                    fill={fillColor}
                    d='M0.00107517 8.00002C0.00107517 6.23987 0.00107517 4.48618 0.00107517 2.72896C0.00400875 1.69047 0.664657 0.832107 1.65621 0.579232C1.84985 0.529677 2.04891 0.504447 2.24879 0.504131C5.74836 0.499437 9.24812 0.499437 12.7481 0.504131C14.0283 0.504131 14.9952 1.46693 14.997 2.74421C15.0013 6.24848 15.0013 9.75294 14.997 13.2576C14.997 14.5355 14.0295 15.4983 12.7493 15.4994C9.2493 15.503 5.74855 15.503 2.24703 15.4994C0.960945 15.4994 -9.45395e-05 14.5319 -0.00244141 13.2447C-0.00244141 11.4959 -0.0012717 9.74766 0.00107517 8.00002ZM1.49955 7.98477V12.8475C1.49955 12.9842 1.49955 13.1209 1.49955 13.2582C1.51246 13.7053 1.78997 13.9834 2.23764 13.9998C2.27636 13.9998 2.31567 13.9998 2.35498 13.9998H12.6372C12.6912 13.9998 12.7451 13.9998 12.7985 13.9998C13.2128 13.9699 13.4709 13.7105 13.4985 13.2957C13.502 13.2423 13.4985 13.1884 13.4985 13.135V2.86743C13.4985 2.81345 13.4985 2.75947 13.4985 2.70608C13.4697 2.28599 13.2051 2.0249 12.7839 2.00202C12.7352 2.00202 12.6859 2.00202 12.6372 2.00202H2.35792C2.30453 2.00202 2.25055 2.00202 2.19716 2.00495C1.78998 2.03664 1.53417 2.29362 1.50483 2.7008C1.50014 2.75947 1.5019 2.81814 1.5019 2.87681L1.49955 7.98477Z'
                />
                <path
                    fill={fillColor}
                    d='M6.75829 9.49786V11.7391H5.26099V4.25731C5.30968 4.25731 5.35662 4.25027 5.40415 4.25027C6.21382 4.25027 7.02387 4.25027 7.83433 4.25027C8.2037 4.24727 8.5696 4.32167 8.90847 4.46868C9.24734 4.6157 9.55165 4.83206 9.80182 5.10383C10.052 5.37561 10.2424 5.69677 10.3609 6.04663C10.4794 6.39649 10.5233 6.7673 10.4898 7.13516C10.3995 8.26341 9.42022 9.30307 8.29079 9.46325C8.11683 9.48535 7.94163 9.49632 7.76627 9.4961C7.43243 9.50138 7.10152 9.49786 6.75829 9.49786ZM6.76239 7.9988C7.14493 7.9988 7.51574 8.00291 7.88654 7.9988C8.4539 7.99059 8.95965 7.51769 8.99661 6.96911C9.03592 6.3865 8.6217 5.83675 8.04203 5.7769C7.62311 5.73349 7.19656 5.76869 6.76239 5.76869V7.9988Z'
                />
            </g>
            <defs>
                <clipPath id='clip0_506_3127'>
                    <rect width='15.0012' height='15' fill='white' transform='translate(0 0.5)' />
                </clipPath>
            </defs>
        </svg>
    );
}
