import { BoxArrowRight } from 'react-bootstrap-icons';
import React from 'react';
import { gtm } from '@/lib/utilities';
import { signOut } from 'next-auth/react';
import { absoluteUrl } from '@/lib/env';
import { useTranslation } from 'next-i18next';
import { TFunction } from 'react-i18next';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import styles from './my-account.module.scss';

interface LogoutButtonProps {
    className?: string;
}

export const LogoutButton: React.FC<LogoutButtonProps> = ({ className }) => {
    const { t } = useTranslation(['common']) as unknown as { t: TFunction<string> };
    const router = useRouter();
    const { locale = 'de' } = router;

    const handleOnLogoutClicked = () => {
        gtm.event(gtm.EVENTS.LOGOUT);
        signOut({ callbackUrl: absoluteUrl(`${locale}${router.asPath}` ?? `/${locale}`), redirect: true });
    };

    return (
        <button
            tabIndex={0}
            className={classNames(className, 'border-0', styles['logout-button'])}
            onClick={handleOnLogoutClicked}
        >
            <BoxArrowRight className='me-2' />
            {t('logout')}
        </button>
    );
};
