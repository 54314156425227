import { Carousel as Record } from '@/lib/api/graphcms';
import classNames from 'classnames';
import { Carousel as RBCarousel } from 'react-bootstrap';
import { Wrapper } from '../Wrapper';
import { Container } from 'react-bootstrap';
import { contrastTextClass, isDarkBackground } from '@/lib/utilities';
import { WithStyleContext } from '../../../types';

export type CarouselProps = Partial<Record> &
    Partial<Pick<React.HTMLAttributes<HTMLDivElement>, 'className'>> &
    WithStyleContext;

export function Carousel(props: CarouselProps) {
    const { cards = [], minHeight, styleContext } = props || {};
    if (!cards?.length) return null;
    const background = props?.background || styleContext?.background || 'none';

    return (
        <RBCarousel
            indicators={false}
            variant={isDarkBackground(background) ? undefined : 'dark'}
            className={props.className}
        >
            {cards.map((card, idx) => {
                return (
                    <RBCarousel.Item
                        key={`${card.id}-${idx}`}
                        className={classNames(
                            'h-100',
                            background === 'none' ? '' : `bg-${background}`,
                            contrastTextClass(background)
                        )}
                        style={{ minHeight: minHeight || 0 }}
                    >
                        <Wrapper elements={card} styleContext={{ background: 'bg-white' }} />
                    </RBCarousel.Item>
                );
            })}
        </RBCarousel>
    );
}

Carousel.pageLayoutContainer = (element: JSX.Element, key: string) => <Container key={key}>{element}</Container>;
