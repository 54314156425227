import { RichText } from '@graphcms/rich-text-react-renderer';
import { Teaser as Record, TeaserContentRichText } from '@/lib/api/graphcms';
import classNames from 'classnames';
import { CSSProperties } from 'react';
import styles from './Teaser.module.scss';
import { Button } from './Button';
import { classToRgb, contrastTextClass, kebabCase } from '@/lib/utilities';
import { Link } from '../../../navigation';
import { WithStyleContext } from '../../../types';

export type TeaserProps = Partial<Record> & { teaser?: TeaserContentRichText } & Partial<
        Pick<React.HTMLAttributes<HTMLDivElement>, 'className'>
    > &
    WithStyleContext;

export function Teaser(props: TeaserProps) {
    const {
        id,
        image,
        teaser: t,
        opacity = 100,
        defaultHeight = 0,
        allClickable = true,
        buttons = [],
        buttonPlacement = 'center',
        styleContext,
    } = props || {};
    const { json: content, references } = t || {};
    const background = props.background || 'white';
    const [primaryButton] = buttons || [];

    let backgroundStyles: CSSProperties = {};
    let overlayStyles: CSSProperties = {};
    if (image && image?.url?.length > 0) {
        backgroundStyles = {
            backgroundImage: `url("${image?.url}")`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        };

        overlayStyles = {
            backgroundColor: classToRgb(background, opacity / 100),
        };
    }

    const body = (
        <>
            <div className={classNames(styles['overlay'], 'h-100')} style={overlayStyles} />
            <div
                className={classNames(
                    styles['content'],
                    'p-3',
                    'd-flex',
                    'flex-column',
                    styleContext?.background,
                    contrastTextClass(background)
                )}
                style={defaultHeight ? { height: `${defaultHeight}vh` } : {}}
            >
                <div className='h-100'>
                    <RichText
                        content={content}
                        references={references}
                        renderers={{ embed: { Button: props => <Button {...props} /> } }}
                    />
                </div>
                <div className={classNames('d-flex', `justify-content-${buttonPlacement}`)}>
                    {buttons.map((props, idx) => (
                        <Button key={`${id}-btn-${idx}`} className={BUTTON_CLASSES[buttonPlacement] || ''} {...props} />
                    ))}
                </div>
            </div>
        </>
    );

    return (
        <div
            className={classNames(styles['teaser'], 'vh-25 w-100', `bg-${kebabCase(background)}`, props.className)}
            style={backgroundStyles}
        >
            {primaryButton && (allClickable === null || allClickable) ? (
                <Link
                    role='buton'
                    className='text-decoration-none'
                    target={primaryButton?.target}
                    external={primaryButton?.external}
                    parameter={primaryButton?.parameter}
                >
                    {body}
                </Link>
            ) : (
                body
            )}
        </div>
    );
}

const BUTTON_CLASSES = {
    start: 'me-2',
    center: 'me-1 ms-1',
    end: 'ms-2',
};
