export const getPropertyFeatureIconName = (feature: string) => {
    switch (feature) {
        case 'balcony':
            return 'balcony';
        case 'terrace':
            return 'terrace';
        case 'elevator':
            return 'elevator';
        case 'garden':
            return 'garden';
        case 'parking':
            return 'parking';
        default:
            return;
    }
};
