import classNames from 'classnames';
import React from 'react';

type LabelProps = {
    containerClassName?: string;
    textClassName?: string;
    text?: string;
    icon?: React.JSX.Element;
    size?: 'xs' | 'sm' | 'md' | 'lg';
    pill?: boolean;
};

export const Badge = ({ containerClassName, textClassName, text, icon, pill, size = 'md' }: LabelProps) => {
    return (
        <div
            className={classNames(
                containerClassName,
                'd-inline-flex gap-1 justify-content-center align-items-center',
                textClassName,
                size === 'xs' && 'body-s py-1 px-2',
                size === 'sm' && 'body-s py-2 px-4',
                size === 'md' && 'body-m py-2 px-4',
                size === 'lg' && 'body-l py-2 px-6',
                pill ? 'rounded-32' : 'rounded-4'
            )}
        >
            <p className={'m-0'}>{text}</p>
            {icon}
        </div>
    );
};
