import styles from './footer.module.scss';
import headerStyles from './header.module.scss';
import { LanguageSelector, Link, LocalizedPages } from '../../navigation';
import { PartnersLogoType, Footer as Record } from '@/lib/api/graphcms';
import React from 'react';
import classNames from 'classnames';
import { Col, Container, Row } from 'react-bootstrap';
import { Glyph, Logo } from '@/components/common';
import { useTranslation } from 'next-i18next';
import { isMarketingPageSlug } from '@/lib/util';

export type FooterProps = Partial<Record> & { partnersLogo?: PartnersLogoType; localizations: LocalizedPages };

export function Footer(props: FooterProps) {
    const { t } = useTranslation('common');
    const { sections, partnersLogo, slug, claimLink, localizations } = props || {};
    const showClaim = slug && (slug === '_homepage' || isMarketingPageSlug(slug));

    return (
        <>
            <footer className={classNames(styles['footer'], 'bg-dark')}>
                {showClaim && (
                    <section
                        className={classNames(
                            styles['claim'],
                            'text-white bg-velvet px-3 px-sm-3 d-flex align-items-center'
                        )}
                    >
                        <Container className='my-7'>
                            <Row>
                                <Col>
                                    <h1 className='w-75'>{t('footer.claim_header')}</h1>
                                </Col>
                            </Row>
                            {
                                <Row>
                                    <div>
                                        <Link
                                            target={claimLink?.target}
                                            anchor={claimLink?.anchor}
                                            external={claimLink?.external}
                                            newWindow={claimLink?.newWindow || false}
                                            className='text-velvet-200 text-caption'
                                        >
                                            {t('footer.call_to_action')} <i className='icon icon-forward-arrow' />
                                        </Link>
                                    </div>
                                </Row>
                            }
                        </Container>
                    </section>
                )}
                <Container className={classNames(styles['grid'], 'my-8 d-grid gap-9 gap-lg-12')}>
                    <Row className='d-flex '>
                        <Col className={classNames('align-content-end')}>
                            <div className={classNames(headerStyles['brand-logo'])}>
                                <Logo partnersLogo={partnersLogo} />
                            </div>
                        </Col>
                        <Col
                            className={classNames(
                                styles['socials'],
                                'd-flex gap-6 justify-content-end mb-0 text-white pe-5'
                            )}
                        >
                            <a href='https://www.facebook.com/ownr.eu/' target='_blank'>
                                <Glyph name={'facebook'} color='white' />
                            </a>
                            <a href='https://www.instagram.com/ownr_leasing/' target='_blank'>
                                <Glyph name={'instagram'} color='white' />
                            </a>
                            <a href='https://www.linkedin.com/company/ownr-deutschland-ag/mycompany/' target='_blank'>
                                <Glyph name={'linkedin'} color='white' />
                            </a>
                        </Col>
                    </Row>

                    <LanguageSelector localizations={localizations} className='d-lg-none' whiteBackground />

                    <Row className={'d-flex justify-content-between'}>
                        <Col
                            lg={9}
                            className={classNames(styles['sections-container'], 'text-white d-flex flex-row flex-wrap')}
                        >
                            {sections?.map(section => (
                                <section key={section.title}>
                                    <div>
                                        <h2 className='text-title fs-2 mb-5'>{section.title}</h2>
                                        <ul className='p-0 m-0'>
                                            {section.links.map(item => (
                                                <li className='py-2' key={item.id}>
                                                    <Link
                                                        className={classNames(styles['section-link'], 'text-white')}
                                                        {...item}
                                                    >
                                                        {item.title}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </section>
                            ))}
                        </Col>
                        <Col lg={1} className={classNames('d-none d-lg-flex align-items-baseline justify-content-end')}>
                            <LanguageSelector localizations={localizations} whiteBackground />
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    );
}
