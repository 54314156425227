import { NextSeo, NextSeoProps } from 'next-seo';
import { useRouter } from 'next/router';
import { Seo as SeoSettings } from '@/lib/api/graphcms';
import { OpenGraph, OpenGraphMedia } from 'next-seo/lib/types';

export type SeoProps = NextSeoProps &
    Partial<Pick<SeoSettings, 'image' | 'keywords' | 'noIndex'>> & { absoluteUrl: (path: string) => string };
export default function Seo(props: SeoProps) {
    const { image: i, keywords, noIndex: noindex = false, ...other } = props;
    const router = useRouter();

    const image = convertImage(i);
    const openGraph: OpenGraph = {
        ...(image && {
            images: [
                {
                    alt: props.title,
                    ...image,
                },
            ],
        }),
        url: router?.asPath,
        ...props,
    };

    const seo = {
        ...(keywords && { keywords: keywords.toString() }),
        noindex,
        openGraph,
        ...other,
    };

    return <NextSeo {...seo} />;
}

function convertImage(img: SeoSettings['image']): OpenGraphMedia | undefined {
    if (!img) return undefined;

    const result: OpenGraphMedia = { url: img.url };
    if (img.mimeType) result.type = img.mimeType;
    if (img.width) result.width = img.width;
    if (img.height) result.height = img.height;

    return result;
}
