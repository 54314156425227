export function computeColorStyle(
    color: string | null | undefined,
    type: string | undefined | null,
    disabled: boolean
): { style: string; outline: boolean; text: boolean } {
    const { suffix: sfx, ...attrs } = suffix(type);
    if (disabled) return { style: `disabled${sfx}`, ...attrs };

    return { style: `${color || 'velvet'}${sfx}`, ...attrs };
}

function suffix(type: string | undefined | null): { suffix: string; outline: boolean; text: boolean } {
    switch (type) {
        case 'outline':
            return { suffix: '-outline', outline: true, text: false };
        case 'text':
            return { suffix: '-text', outline: false, text: true };
        default:
            return { suffix: '', outline: false, text: false };
    }
}
