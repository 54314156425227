import React from 'react';
import { Form, FormCheckProps } from 'react-bootstrap';
import classNames from 'classnames';

type RadioButtonProps = FormCheckProps;

export const RadioButton = React.forwardRef(function RadioInput(
    props: RadioButtonProps,
    ref: React.Ref<HTMLInputElement>
) {
    const { checked, id, onChange, className } = props;
    return (
        <Form.Check
            bsPrefix='design-system'
            bsSwitchPrefix='design-system'
            ref={ref}
            className={classNames('design-system', className)}
            checked={checked}
            id={id}
            onChange={onChange}
            type='radio'
        />
    );
});
