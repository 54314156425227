import { UpdateAccount, Account } from '../../../selfservice';
import React from 'react';
import { AccountInput, useBff } from '@/lib/api/bff';
import { isServerside } from '@/lib/env';
export function UpdateAccountForm() {
    const api = useBff();
    const loadFunction = async (accountId: string) => {
        const { account = null } = await api.Account({ accountId });
        return account;
    };
    const updateFunction = async (account: Account) => {
        const { updateAccount = { success: false, errors: [] } } = await api.UpdateAccount({
            account: account as AccountInput,
        });

        console.log('updateFunction - account', account, 'updateAccount', updateAccount);

        return updateAccount;
    };
    if (isServerside()) return null;

    return <UpdateAccount loadFunction={loadFunction} updateFunction={updateFunction} />;
}
