import React, { useState } from 'react';
import classnames from 'classnames';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import { Carousel, Modal, Ratio, Badge, Container } from 'react-bootstrap';
import { BADGE_TYPES, PropertyBadge } from '../../../PropertyBadge';
import { checkImageExists } from '../../../utils';
import styles from './styles.module.scss';
import { Maybe, PromotionType } from '@/lib/api/bff';

const IMAGE_PLACEHOLDER_URL = '/images/property_placeholder.svg';

export const ImageCarousel = ({
    images,
    isActive,
    promotionType,
}: {
    images: string[];
    isActive: boolean;
    promotionType?: Maybe<PromotionType>;
}) => {
    const [prevImages, setPrevImages] = useState<string[]>(images);
    const [imagesToShow, setImagesToShow] = useState<string[]>(() =>
        images.length ? images : [IMAGE_PLACEHOLDER_URL]
    );
    const [show, setShow] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const fetchImages = async () => {
        const imagesToShow = await Promise.all(
            images.map(async img => {
                const exists = await checkImageExists(img);
                if (exists) {
                    return img;
                }
                return '';
            })
        );
        const newImages = imagesToShow.filter(Boolean) as string[];
        setImagesToShow(newImages.length ? newImages : [IMAGE_PLACEHOLDER_URL]);
    };

    if (images.length !== prevImages.length) {
        setPrevImages(images);
        fetchImages();
    }

    const handleClose = () => {
        setShow(false);
    };
    const handleShow = (index: number) => {
        if (!imagesToShow || imagesToShow.length <= 1 || !isActive) return;

        setSelectedIndex(index);
        setShow(true);
    };

    const imageUrl = imagesToShow[selectedIndex];
    const backgroundImage = `url(${imageUrl})`;

    return (
        <div className={classnames('position-relative', styles.imageContainer, !isActive && styles.grayFilter)}>
            <div
                className={classnames('position-relative h-100 d-none d-md-block', styles.rounded, styles.mainImg)}
                onClick={() => handleShow(0)}
                style={{
                    backgroundImage,
                    backgroundSize: 'cover, 75%',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    filter: isActive ? '' : 'grey',
                }}
            />
            <Carousel
                className='d-sm-block d-md-none'
                defaultActiveIndex={0}
                controls={false}
                indicators={false}
                onSlide={index => setSelectedIndex(index)}
            >
                {imagesToShow.map((src, i) => (
                    <Carousel.Item key={src} onClick={() => handleShow(i)}>
                        <Ratio aspectRatio='4x3'>
                            <img className='d-block w-100' src={src} alt='' />
                        </Ratio>
                    </Carousel.Item>
                ))}
            </Carousel>
            <Container
                className={classnames(
                    'position-absolute d-flex justify-content-between w-100 p-md-0',
                    styles.badgesContainer
                )}
            >
                {imagesToShow.length && (
                    <Badge
                        className={classnames('p-2 rounded opacity-75 text-white text-tag', styles.countBadge)}
                        bg='black'
                    >
                        <i className='icon icon-popup pe-2' />
                        {`${selectedIndex + 1}/${imagesToShow.length}`}
                    </Badge>
                )}

                {promotionType && (
                    <PropertyBadge
                        type={promotionType as unknown as BADGE_TYPES}
                        className={classnames('d-sm-inline-flex d-md-none', styles.propertyBadge)}
                    />
                )}
            </Container>
            <Modal show={show} fullscreen onEscapeKeyDown={handleClose}>
                <Modal.Header className='border-0' closeButton={true} onClick={handleClose}></Modal.Header>
                <Modal.Body className='pb-10'>
                    <ImageGallery
                        items={
                            imagesToShow.map(v => ({
                                original: v,
                                thumbnail: v,
                                originalHeight: '600px',
                            })) as unknown as ReactImageGalleryItem[]
                        }
                        showNav
                        startIndex={selectedIndex}
                        showFullscreenButton={false}
                        useBrowserFullscreen={false}
                        showPlayButton={false}
                        showThumbnails={false}
                    />
                </Modal.Body>
            </Modal>
        </div>
    );
};
