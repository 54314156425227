import classNames from 'classnames';
import styles from './burger-button.module.scss';
import { BurgerButtonProps } from './types';

export function BurgerButton(props: BurgerButtonProps) {
    const { onClick, className, light } = props || {};
    const background = light ? 'bg-dark' : 'bg-white';
    return (
        <button
            type='button'
            className={classNames(
                className,
                styles['burger'],
                styles['navbar-toggler'],
                'd-flex d-block d-xl-none bg-transparent'
            )}
            onClick={onClick}
            data-cy={'ownr-burgerButton'}
        >
            <div className={classNames(styles['line'], background)} />
            <div className={classNames(styles['line'], background)} />
            <div className={classNames(styles['line'], background)} />
        </button>
    );
}
