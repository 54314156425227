import { useSession } from 'next-auth/react';
import createClient from 'openapi-fetch';
import type { paths as leaseRequestsPaths } from './schema';
import { apiGatewayConfig } from '@/lib/env';
import { useRouter } from 'next/router';

export const useLeaseRequestsClient = (baseUrl?: string) => {
    const { data: session } = useSession();
    const router = useRouter();

    if (!session) {
        router.push('/login');
    }

    const leaseRequestsClient = createClient<leaseRequestsPaths>({
        baseUrl: baseUrl ?? apiGatewayConfig.baseUrl,
        headers: { Authorization: `Bearer ${session?.idToken}` },
    });

    // 6 August - Temporary switching to error logging in place instead of generic middleware.
    // This will allow to log request parameters.

    // leaseRequestsClient.use(logOnError);

    return leaseRequestsClient;
};
