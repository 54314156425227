import { useEffect, useId, useMemo, useState } from 'react';
import { RegistrationFormProps, RegistrationState, Step, StepComponent } from './types';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'next-i18next';
import { FieldValues } from 'react-hook-form';
import { TFunction } from 'react-i18next';
import { CityForm } from './SubPages/CityForm';
import { DistrictForm } from './SubPages/DistrictForm';
import { PropertyFeaturesForm } from './SubPages/PropertyFeaturesForm';
import { ContactForm } from './SubPages/ContactForm';
import { SuccessPage } from './SubPages/SuccessPage';
import classNames from 'classnames';
import styles from './registration.module.scss';
import { colorVariant, lockSymbols, patternVariant } from '@/components/common';
import { gtm, snakeCase } from '@/lib/utilities';
import { headline } from './util';
import { DEFAULT_STEP_NAME } from './constants';
import { useRegistrationInitialState } from './hooks';
import { calculateNextStep, calculatePreviousStep } from './functions';

const steps: [Step, StepComponent][] = [
    ['city', CityForm],
    ['districts', DistrictForm],
    // ['urgency', UrgencyForm],
    ['features', PropertyFeaturesForm],
    ['contact', ContactForm],
    ['success', SuccessPage],
];

const STEP_NAMES = steps.map(([s]) => s as string);

const MANDATORY_BASE = [
    'firstName',
    'lastName',
    'email',
    'password',
    'region',
    'locationsLiked',
    // 'timing',
    'propertyURL',
];

const defaultState = steps.reduce((state, [step, ,]) => {
    state[step] = { valid: false, values: {} };
    return state;
}, {} as RegistrationState);

export function Registration(props: RegistrationFormProps) {
    const componentId = useId();
    const symbols = useMemo(() => {
        const date = new Date();
        return lockSymbols(2, 2, { pattern: patternVariant(date.getDay()), colors: colorVariant(date.getDate()) });
    }, []);

    const [initialStep, initialState] = useRegistrationInitialState(DEFAULT_STEP_NAME, defaultState, STEP_NAMES);
    const [step, setStep] = useState<Step>(initialStep);
    const [state, setState] = useState<RegistrationState>(initialState);

    const { t } = useTranslation(['register', 'common', 'properties']) as unknown as {
        t: TFunction<string, undefined>;
    };
    const mandatoryFields = [...new Set([...(props.mandatoryFields || []), ...MANDATORY_BASE])];

    const fieldsToHide = (props.fieldsToHide || []).filter(f => !mandatoryFields.includes(f));
    const {
        maxLivingArea = 140,
        maxRooms = 5,
        maxNetIncome = 12000,
        maxMonthlyBudget = 6000,
        additionalContent,
        selectDislikedDistricts = true,
        customerApplication,
    } = props || {};
    const { 'terms-and-conditions': terms, 'privacy-policy': privacyPolicy } = additionalContent || {};

    useEffect(() => {
        gtm.event(gtm.EVENTS.APPLICATION_STARTED, { category: gtm.CATEGORIES.APPLICATION });
    }, []);

    useEffect(() => {
        window.location.hash = window.location.hash.replace(window.location.hash, `#${step}Step`);
    }, [step]);

    const navigateTo = (current: Step, target: Step) => {
        return (data: FieldValues) => {
            setState({ ...state, [step]: { valid: true, values: data } });
            window?.scrollTo({ top: 0, behavior: 'smooth' });
            const nextStep = calculateNextStep(data, target);
            setStep(nextStep);
            gtm.event(gtm.EVENTS.NAVIGATE_FORWARD, {
                category: gtm.CATEGORIES.APPLICATION,
                label: nextStep,
            });
        };
    };

    const navigateBackwards = (current: Step, target?: Step) => {
        return () => {
            if (!target) return;
            window?.scrollTo({ top: 0, behavior: 'smooth' });
            const nextStep = calculatePreviousStep(state, current, target);
            setStep(nextStep);
            gtm.event(gtm.EVENTS.NAVIGATE_BACKWARD, {
                category: gtm.CATEGORIES.APPLICATION,
                label: nextStep,
            });
        };
    };
    const currentStep = steps.findIndex(([s]) => s === step);
    const [, CurrentStepComponent] = steps[currentStep] || [];
    const headlineFn = headline(CurrentStepComponent);
    const navigateForward =
        currentStep >= 0 && currentStep < steps.length - 1
            ? navigateTo(step, steps[currentStep + 1][0])
            : () => undefined;

    const navigateBack = currentStep >= 1 ? navigateBackwards(step, steps[currentStep - 1][0]) : undefined;
    const otherValues = Object.entries(state)
        // .filter(([, {valid = false}]) => !valid)
        .reduce((otherValues, [step, { values }]) => {
            otherValues[step as Step] = values;
            return otherValues;
        }, {} as Record<Step, FieldValues>);

    return (
        <Row className='w-100 p-0 mb-6'>
            <Col xs={12} sm={8}>
                <Row className='w-100'>
                    {currentStep < steps.length ? (
                        <a
                            role='button'
                            className={classNames(
                                'p-0 text-decoration-none',
                                currentStep > 0 ? 'text-interaction' : 'text-gray-100',
                                { invisible: step === 'success' }
                            )}
                            onClick={navigateBack}
                        >
                            <i className='icon icon-backward-arrow ' />
                        </a>
                    ) : null}
                    &nbsp;
                </Row>
                <Row className='w-100 text-copy-s-bold'>{t(`${step}.subline`)}</Row>
                <Row className={classNames(styles['progress'], 'w-100 mb-4')}>
                    {steps.map(([s], index) => {
                        if (currentStep === index || step === 'success')
                            return <div key={s} className={classNames(styles['pill'], 'bg-velvet-500')} />;
                        else if (currentStep > index)
                            return (
                                <div
                                    key={s}
                                    role='button'
                                    className={classNames(styles['pill'], 'bg-velvet-500')}
                                    onClick={navigateBackwards(step, s)}
                                />
                            );

                        return <div key={s} className={classNames(styles['pill'], 'bg-gray-200')} />;
                    })}
                </Row>
                <Row className='w-100 mb-4 text-copy-l'>{headlineFn(otherValues, step, t)}</Row>
                <Row>
                    <CurrentStepComponent
                        {...props}
                        formId={(...params) => snakeCase('register', step, ...params) + '_' + componentId}
                        selectDislikedDistricts={selectDislikedDistricts}
                        otherValues={otherValues}
                        defaultValues={state[step]?.values}
                        navigateForward={navigateForward}
                        mandatoryFields={mandatoryFields}
                        fieldsToHide={fieldsToHide}
                        maxLivingArea={maxLivingArea}
                        maxRooms={maxRooms}
                        maxNetIncome={maxNetIncome}
                        maxMonthlyBudget={maxMonthlyBudget}
                        t={t}
                        content={{ terms, privacyPolicy }}
                        customerApplication={customerApplication}
                        trendingDistricts={props.trendingDistricts}
                    />
                </Row>
            </Col>
            <Col
                sm={4}
                xs={0}
                style={{ backgroundImage: `url("data:image/svg+xml,${encodeURIComponent(symbols)}")` }}
                className={styles['decoration']}
            ></Col>
        </Row>
    );
}
