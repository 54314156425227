import React from 'react';

export default function ArrowLeft(props: object) {
    return (
        <svg width='20' height='17' viewBox='0 0 20 17' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M2.54651 8.5L3.59291 9.6307L9.13954 15.6242L7.86629 17L0 8.5L7.86629 0L9.13954 1.37583L3.30077 7.68498L2.54651 8.5Z'
                fill='currentColor'
            />
            <path d='M20 9.63069V7.68498L3.30077 7.68498L2.54651 8.5L3.59291 9.6307L20 9.63069Z' fill='currentColor' />
        </svg>
    );
}
