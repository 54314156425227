import { BrokerApplicationFunction, BrokerRegistration } from '../../../registration';
import {
    BrokerApplicationMutation,
    BrokerRegistrationData,
    BrokerRegistrationResponse,
    Sdk,
    useBff,
} from '@/lib/api/bff';
import { DynamicComponentElementProps } from '../blocks/DynamicComponent';
import { Grid } from '@/lib/api/graphcms';
import React, { useContext } from 'react';

export type BrokerRegistrationFormContent = {
    content: Record<string, Grid>;
};
export const BrokerRegistrationFormContext = React.createContext({} as BrokerRegistrationFormContent);

export function BrokerRegistrationForm(props: DynamicComponentElementProps) {
    const { id = '', configuration } = props || {};
    const { mandatoryFields = [], fieldsToHide = [] } = configuration || {};
    const api = useBff();
    const additionalContent = useContext(BrokerRegistrationFormContext);
    return (
        <BrokerRegistration
            id={id}
            brokerApplication={brokerApplication(api)}
            fieldsToHide={fieldsToHide}
            mandatoryFields={mandatoryFields}
            additionalContent={additionalContent.content}
        />
    );
}

function brokerApplication(api: Pick<Sdk, 'BrokerApplication'>): BrokerApplicationFunction {
    return (data: BrokerRegistrationData) => {
        return api
            .BrokerApplication({ data })
            .then(({ brokerRegistration }: BrokerApplicationMutation) => brokerRegistration)
            .catch(err => {
                return {
                    success: false,
                    errors: [{ code: 'invocation_failed', message: err.message }],
                } as BrokerRegistrationResponse;
            });
    };
}
