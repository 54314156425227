import React from 'react';

export default function ArrowCircleLeft(props: object) {
    return (
        <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M27.9794 34.5L29.25 33.2108L20.5412 24.375L29.25 15.5392L27.9794 14.25L18 24.375L27.9794 34.5Z'
                fill='currentColor'
            />
            <rect
                x='47'
                y='1'
                width='46'
                height='46'
                rx='23'
                transform='rotate(90 47 1)'
                stroke='currentColor'
                strokeWidth='2'
            />
        </svg>
    );
}
