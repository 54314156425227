import React, { useEffect, useState, useId } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styles from '../../styles.module.scss';
import classNames from 'classnames';
import { Trans, useTranslation } from 'next-i18next';
import { formatCurrency, gtm, useElementVisibility } from '@/lib/utilities';
import { useRouter } from 'next/router';
import { LeaseRate } from './components/LeaseRate';
import { RefurbishmentLevels } from './components/RefurbishmentLevels';
import { getRefurbishmentLevel, getRefurbishmentAdjustment } from './components/RefurbishmentLevels/utils';
import { OptionsButton } from './components/OptionsButton';
import { SupportedRegions } from '@/lib/api/bff';

// todo extract useAccountId
import { useAccountId } from '@/components/users';
import { EditPanel } from './components/EditPanel';
import { RefurbishmentLevelGroup } from '@/lib/api/graphcms';
import { RefurbishmentInfo } from './components/RefurbishmentInfo';
import { toast, ToastContent } from 'react-toastify';
import { TOAST_OPTIONS } from '@/styles/toasts';
import { notify } from '@/lib/utilities/logger';
import { PropertyDetails } from '@/lib/api/properties/types';
import { Availability, LeaseRequestCreationErrors } from '@/lib/api/lease-requests/types';
import { LeasingRates } from '@/components/types';
import { useSession } from 'next-auth/react';
import { checkIsAdmin, checkIsBroker } from '@/lib/security';
import { Link } from '@/components/design-system';
import { NewSlotForm } from '@/components/slots/new';
import { AvailableViewings } from '@/components/lease-requests/view/components/AvailableViewings';
import CallToActionBlockMobile from './CallToActionBlockMobile';
import { useLeaseRequestByPropertyId } from '@/hooks';
import { Loader } from '@/components/common';
import { useCreateLeaseRequest } from '@/components/lease-requests/hooks/useCreateLeaseRequest';
import { SlotsList } from '@/components/slots/list';

interface CallToActionBlockProps {
    property: Omit<PropertyDetails, 'leasingRate'> & {
        leasingRate?: number | null;
        leasingRates?: LeasingRates | null;
    };
    refurbishmentGroups?: RefurbishmentLevelGroup[];
}

const CONTAINER_CLASSES = 'border border-1 border-velvet-200 h-auto rounded-12 p-4';

export const CallToActionBlock = ({ property, refurbishmentGroups }: CallToActionBlockProps) => {
    const { t } = useTranslation('metasearch');
    const router = useRouter();
    const session = useSession();

    const { leaseRequest, isLoading } = useLeaseRequestByPropertyId(property.localId);
    const { createLeaseRequest } = useCreateLeaseRequest();

    const [leaseDuration, setLeaseDuration] = useState(48);
    const [leaseRate, setLeaseRate] = useState(0);
    const [refurbishmentIndex, setRefurbishmentIndex] = useState(0);
    const [targetRef, isVisible] = useElementVisibility();
    const accountId = useAccountId();
    const calculatorId = useId();
    const [isShownRefurbishmentInfo, setIsShownRefurbishmentInfo] = useState(false);

    const isBroker = checkIsBroker(session.data);
    const isOwner = property.ownerId && accountId === property.ownerId;
    const isAdmin = checkIsAdmin(session.data);

    const { locale = 'de' } = router;
    const price = property.salesPrice || 0;
    const region = property.address.region.toUpperCase() as SupportedRegions;
    const leasingRates = property.leasingRates;
    const leaseDurations = [
        { name: `24 ${t('months')}`, value: 24 },
        { name: `36 ${t('months')}`, value: 36 },
        { name: `48 ${t('months')}`, value: 48 },
    ];

    const selectedRefurbishmentLevel = getRefurbishmentLevel(refurbishmentGroups ?? [], region, refurbishmentIndex);

    const showActiveLeaseRequestInfo =
        leaseRequest &&
        leaseRequest.accountId === accountId &&
        leaseRequest.status !== 'CLOSED' &&
        leaseRequest.status !== 'CLOSED_BY_CUSTOMER';

    useEffect(() => {
        const leaseRate = leasingRates && Math.round(leasingRates[leaseDuration].base);
        const adjustment = getRefurbishmentAdjustment(refurbishmentGroups ?? [], region, refurbishmentIndex);
        const leaseRateWithRefurbishment = leaseRate * adjustment;
        setLeaseRate(leaseRateWithRefurbishment);
    }, [leaseDuration, refurbishmentIndex, leasingRates, refurbishmentGroups, region]);

    const onPeriodChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = +e.currentTarget.value;
        gtm.event(gtm.EVENTS.PROPERTY_LEASE_RATE_CLICKED, {
            category: gtm.CATEGORIES.PROPERTY,
            propertyId: property.id,
            value,
        });
        setLeaseDuration(value);
    };

    const onRefurbishmentLevelChange = (value: number) => {
        gtm.event(gtm.EVENTS.PROPERTY_REFURBISHMENT_LEVEL_CLICKED, {
            category: gtm.CATEGORIES.PROPERTY,
            propertyId: property.id,
            name: selectedRefurbishmentLevel?.name,
        });
        setRefurbishmentIndex(value);
    };

    const onRefurbishmentInfoClick = () => {
        gtm.event(gtm.EVENTS.PROPERTY_REFURBISHMENT_INFO_OPENED, {
            category: gtm.CATEGORIES.PROPERTY,
            propertyId: property.id,
        });
        setIsShownRefurbishmentInfo(true);
    };

    const handleOnViewingRequestClick = async (availability?: Availability, slotId?: string) => {
        try {
            gtm.event(slotId ? gtm.EVENTS.ATTEND_VIEWING_REQUESTED : gtm.EVENTS.PROPERTY_VIEWING_REQUESTED, {
                category: gtm.CATEGORIES.PROPERTY,
                value: { property: property.id },
            });

            // in case of Casafari property a platform id could be still empty
            // then we use casafari property id
            const propertyId = property.localId ? property.localId : property.id;

            const { error, status } = await createLeaseRequest(propertyId.toString(), {
                slotId,
                leaseRate,
                leaseDuration,
                refurbishmentKey: selectedRefurbishmentLevel?.key,
                refurbishmentAdjustment: selectedRefurbishmentLevel?.adjustment,
                availability,
            });

            if (error) {
                if (status === 400) handleBadRequestError(error.message);
                else toast.error(t('request_error') as ToastContent<string>, TOAST_OPTIONS);
                return;
            }

            toast.success(t('viewing_request.success.header') as ToastContent<string>, TOAST_OPTIONS);
            await router.push(`/my/lease-request?id=${property.localId}`);
            return;
        } catch (e) {
            toast.error(t('request_error') as ToastContent<string>, TOAST_OPTIONS);
            notify(e);
            return;
        }
    };

    const handleBadRequestError = (error: LeaseRequestCreationErrors) => {
        if (error === 'LOW_NET_INCOME') {
            toast.warn(
                <Trans i18nKey='low_net_income' ns='metasearch'>
                    xxx
                    <a target='_blank' href={`/${router.locale ?? 'de'}/account/update`} rel='noreferrer'>
                        here
                    </a>
                </Trans>,
                TOAST_OPTIONS
            );
        } else {
            toast.warn(<Trans i18nKey={error.toLowerCase()} ns='metasearch' />, TOAST_OPTIONS);
        }
    };

    if (isLoading) {
        return <Loader />;
    }

    return (
        <Container
            ref={targetRef}
            className={classNames(styles['cta-container'], 'design-system d-flex flex-column px-0 gap-5')}
        >
            <div className={CONTAINER_CLASSES}>
                <h4 className={'pt-2 fw-bold'}>{t('lease_calculator')}</h4>
                {!property.hidePurchasePrice && (
                    <Row className='border-top border-velvet-200'>
                        <Col>
                            <p className='my-2 text-gray-500' data-cy='expose-price'>
                                {t('expose_price')}: {formatCurrency(locale, price)}
                            </p>
                        </Col>
                    </Row>
                )}
                <Row className='border-top border-velvet-200 pt-4'>
                    <Col>
                        <p className=' mb-3 text-gray-500 '>{t('lease_duration')}</p>
                        <div className='d-flex gap-3 flex-wrap flex-column flex-md-row'>
                            {leaseDurations.map((duration, idx) => {
                                const checked = leaseDuration === duration.value;
                                return (
                                    <Col key={duration.value}>
                                        <OptionsButton
                                            id={`radio-${calculatorId}-${idx}`}
                                            type='radio'
                                            name='radio'
                                            value={duration.value}
                                            wrapperClassName='w-100'
                                            checked={checked}
                                            onChange={onPeriodChange}
                                        >
                                            {duration.name}
                                        </OptionsButton>
                                    </Col>
                                );
                            })}
                        </div>
                    </Col>
                </Row>
                {refurbishmentGroups?.length && (
                    <Row>
                        <Col>
                            <p className='mt-5 mb-4 text-gray-500'>
                                {t('refurbishment.options')}{' '}
                                <a className='link-interaction' onClick={onRefurbishmentInfoClick}>
                                    {t('refurbishment.info')}
                                </a>
                            </p>
                            <div className='px-4 mt-1 mb-8'>
                                <RefurbishmentLevels
                                    refurbishmentGroups={refurbishmentGroups}
                                    region={region}
                                    selectedRefurbishmentIndex={refurbishmentIndex}
                                    onChange={onRefurbishmentLevelChange}
                                    minRefurbishmentKey={property.minRefurbishmentKey}
                                />
                            </div>
                            <p className='text-tag mb-0'>{selectedRefurbishmentLevel?.short}</p>
                        </Col>
                    </Row>
                )}
                <Row className='mt-6'>
                    <Col xs={12}>
                        <LeaseRate leaseRate={leaseRate} />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} className='mt-4'>
                        {property.ownerId && (isOwner || isAdmin) && (
                            <EditPanel
                                propertyId={property.localId ?? ''}
                                showHidePropertyButton={!property.isPrivate}
                            />
                        )}
                    </Col>
                </Row>
            </div>

            {showActiveLeaseRequestInfo && (
                <div className={classNames('border border-1 border-velvet-200 h-auto rounded-12 p-4')}>
                    <h4 className='mb-4'>{t('available_viewing_date')}</h4>
                    <p className='m-0 body-l fw-bold'>{t('viewing_requested')}</p>
                    <span className='body-l'>
                        <Trans i18nKey='active_lease_request' ns='metasearch'>
                            xxx
                            <Link className='fw-bold' href={`/my/lease-request?id=${property.localId}`}>
                                active lease request
                            </Link>
                        </Trans>
                    </span>
                </div>
            )}

            {(isOwner || isAdmin) && (
                <>
                    <SlotsList propertyId={property.localId} />
                    <NewSlotForm propertyId={property?.localId} />
                </>
            )}

            {!showActiveLeaseRequestInfo && !isBroker && (
                <div className={CONTAINER_CLASSES}>
                    <h4 className='mb-4'>{t('available_viewing_date')}</h4>
                    <AvailableViewings
                        propertyId={property?.localId}
                        onViewingRequestClick={handleOnViewingRequestClick}
                    />
                </div>
            )}

            <CallToActionBlockMobile
                onViewingRequestClick={handleOnViewingRequestClick}
                leaseRate={leaseRate}
                leaseDuration={leaseDuration}
                refurbishmentLevel={selectedRefurbishmentLevel}
                visible={!isVisible}
                property={property}
                activeLeaseRequestId={leaseRequest?.status === 'CLOSED' ? null : leaseRequest?.requestId}
            />

            {selectedRefurbishmentLevel && refurbishmentGroups && (
                <RefurbishmentInfo
                    isShown={isShownRefurbishmentInfo}
                    setIsShown={setIsShownRefurbishmentInfo}
                    refurbishmentLevel={selectedRefurbishmentLevel}
                    refurbishmentGroups={refurbishmentGroups}
                    region={region}
                    selectedRefurbishmentIndex={refurbishmentIndex}
                    onChange={onRefurbishmentLevelChange}
                    minRefurbishmentKey={property.minRefurbishmentKey}
                />
            )}
        </Container>
    );
};
