import React, { ReactElement, useState } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { Button, Dropdown } from 'react-bootstrap';

export type ContextMenuOption = {
    icon?: ReactElement | string;
    text: string;
    onClick: () => void;
    disabled?: boolean;
};

type MenuToggleProps = {
    children: ReactElement;
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
    disabled: boolean;
};

const MenuToggle = React.forwardRef<HTMLButtonElement, MenuToggleProps>(({ children, onClick, disabled }, ref) => (
    <Button
        ref={ref}
        disabled={disabled}
        className={classNames(styles.contextMenuToggle, 'rounded-circle p-0 px-2 btn btn-white text-velvet')}
        size='lg'
        variant='outline-velvet-200'
        onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            onClick(e);
        }}
    >
        {children}
        ···
    </Button>
));
MenuToggle.displayName = 'MenuToggle';

export const WithContextMenu = ({
    children,
    options,
    disabled,
}: {
    children: ReactElement;
    options: Array<ContextMenuOption>;
    disabled?: boolean;
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(prev => !prev);
    };

    return (
        <div className={styles.contextMenu}>
            <Dropdown show={isOpen} onToggle={handleToggle}>
                <Dropdown.Toggle disabled={disabled} as={MenuToggle} id='dropdown-custom-components' />

                <Dropdown.Menu className='border-velvet rounded-3 p-0'>
                    {options.map((option: ContextMenuOption, index: number) => {
                        return (
                            <Dropdown.Item
                                className={classNames(
                                    styles.dropdownItem,
                                    'py-2',
                                    option.disabled ? 'bg-gray-100' : ''
                                )}
                                as='button'
                                disabled={option.disabled}
                                key={index}
                                onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    option.onClick();
                                    setIsOpen(false);
                                }}
                            >
                                <div className='d-flex gap-3 align-items-center text-footnote'>
                                    {option.icon}
                                    {option.text}
                                </div>
                            </Dropdown.Item>
                        );
                    })}
                </Dropdown.Menu>
            </Dropdown>
            {children}
        </div>
    );
};
