import classNames from 'classnames';
import { useEffect, useId, useState } from 'react';
import styles from './dropdown.module.scss';
import useSWR from 'swr';
import { Controller, useFormContext } from 'react-hook-form';
import { notEmpty } from '@/lib/utilities';
import { DropdownWithSearchProps, OptionItem } from './types';

export function DropdownWithSearch(props: DropdownWithSearchProps) {
    const {
        title,
        actionPhrase,
        startCollapsed = true,
        name,
        className,
        placeholder,
        defaultValues,
        multiple = true,
        tabDefinitions,
    } = props || {};

    const componentId = useId();
    const [collapsed, setCollapsed] = useState(startCollapsed);
    const [search, setSearch] = useState('');
    const [selected, setSelected] = useState<OptionItem[]>([]);
    const { control, setValue } = useFormContext();
    const isSelected = ({ key }: OptionItem) => selected.findIndex(i => i.key === key) >= 0;
    const addToSelection = (item: OptionItem) => {
        if (multiple) {
            if (selected.findIndex(i => i.key === item.key) >= 0) return;
            setSelected([...selected, item]);
            setValue(name, [...selected.map(({ key }) => key), item.key]);
        } else {
            setSelected([item]);
            setValue(name, [item.key]);
        }
    };
    const removeFromSelection = (item: OptionItem) => {
        const newSelected = multiple ? selected.filter(s => s.key !== item.key) : [];
        setSelected(newSelected);
        setValue(
            name,
            newSelected.map(({ key }) => key)
        );
    };
    const { list: tabList, order } = {
        list: [{ key: 'default', label: '', loader: props.loader }],
        order: ['default'],
        ...tabDefinitions,
    };
    tabList.sort((a, b) => {
        const aIndex = order.indexOf(a.key);
        const bIndex = order.indexOf(b.key);
        return aIndex - bIndex;
    });

    useEffect(() => {
        if (selected?.length > 0 || !defaultValues) return;

        Promise.all(
            tabList.map(({ loader }) => {
                if (!loader) return Promise.resolve([]);
                return loader('').then(items => items.filter(i => defaultValues.includes(i.key)));
            })
        ).then(results => {
            const keys = new Set<string>();
            const dflts: OptionItem[] = results
                .flat()
                .filter(notEmpty)
                .filter(({ key }) => {
                    if (keys.has(key)) return false;
                    keys.add(key);
                    return true;
                });
            setSelected([...dflts]);
            setValue(name, [...dflts.map(({ key }) => key)]);
        });
    }, [defaultValues, name, selected?.length, setValue, tabList]);

    const [activeTab, setActiveTab] = useState(tabList[0].key);

    const fetcher: ([url, active, term]: [url: string, active: string, term: string]) => Promise<OptionItem[]> = ([
        ,
        active,
        term,
    ]) => {
        const loadFn = tabList.find(t => t.key === active)?.loader;
        if (!loadFn) throw new Error('Unable to determine loader for tab ' + activeTab + '.');

        return loadFn(term);
    };
    const { data = [] } = useSWR([`/dropdown/${componentId}`, activeTab, search], fetcher);
    data.sort((a, b) => a.label.localeCompare(b.label));

    return (
        <>
            <div className={classNames(styles['dropdown'], 'w-100 shadow', className)}>
                <div
                    role='button'
                    className={classNames(
                        styles['dropdown-button'],
                        'w-100 m-0 d-flex justify-content-between align-items-middle'
                    )}
                    onClick={() => setCollapsed(!collapsed)}
                >
                    <div className='text-caption-bold pt-3'>{title}</div>
                    <div
                        className={classNames(styles['icon'], collapsed ? styles['icon-closed'] : styles['icon-open'])}
                    >
                        <svg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <rect x='0.75' y='0.75' width='40.5' height='40.5' rx='20.25' fill='#725BFF' />
                            <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M31 17.2424L29.7267 16L21 24.5152L12.2733 16L11 17.2424L21 27L31 17.2424Z'
                                fill='white'
                            />
                            <rect
                                x='0.75'
                                y='0.75'
                                width='40.5'
                                height='40.5'
                                rx='20.25'
                                stroke='#725BFF'
                                strokeWidth='1.5'
                            />
                        </svg>
                    </div>
                </div>
                <div className={classNames(styles['dropdown-body'], 'collapse', { show: !collapsed })}>
                    <div className='input-group flex-nowrap mb-2'>
                        <span className='input-group-text bg-white border-end-0' id='addon-wrapping'>
                            <i className='icon icon-search-thin text-secondary' />
                        </span>
                        <input
                            type='search'
                            className='form-control border-start-0 ps-1'
                            placeholder={placeholder}
                            aria-label={placeholder}
                            value={search}
                            onChange={e => setSearch(e.target.value)}
                            aria-describedby='addon-wrapping'
                        />
                    </div>
                    <div>
                        {tabList && tabList.length > 1 && (
                            <div>
                                <ul className={classNames(styles['tab-list'], 'align-items-middle')}>
                                    {tabList.map(({ key, label }) => {
                                        if (activeTab === key)
                                            return (
                                                <li key={key} className='active'>
                                                    <span className='text-caption-bold text-velvet-500'>{label}</span>
                                                </li>
                                            );
                                        return (
                                            <li
                                                key={key}
                                                role='button'
                                                className='text-caption'
                                                onClick={() => setActiveTab(key)}
                                            >
                                                <span>{label}</span>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        )}
                        <div className={classNames(styles['dropdown-tags'])}>
                            <ul className={classNames(styles['tag-list'])}>
                                {data.map(item => {
                                    if (!isSelected(item))
                                        return (
                                            <li
                                                key={item.key}
                                                role='button'
                                                className='text-tag'
                                                onClick={() => addToSelection(item)}
                                            >
                                                {item.label}
                                            </li>
                                        );

                                    return (
                                        <li
                                            key={item.key}
                                            role='button'
                                            className='text-tag selected'
                                            onClick={() => removeFromSelection(item)}
                                        >
                                            {item.label} &times;
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
                <Controller
                    name={name}
                    control={control}
                    defaultValue={selected}
                    render={({ field }) => (
                        <select style={{ display: 'none' }} multiple={multiple} {...field}>
                            {data.map(item => (
                                <option key={item.key} value={item.key}>
                                    {item.label}
                                </option>
                            ))}
                        </select>
                    )}
                />
            </div>
            {actionPhrase && <div className='text-form my-2 px-2'>{actionPhrase}</div>}
            <div className='p-0'>
                <ul className={classNames(styles['tag-list'])}>
                    {selected.map(item => {
                        return (
                            <li
                                key={item.key}
                                role='button'
                                className='text-tag selected'
                                onClick={() => removeFromSelection(item)}
                            >
                                {item.label} &times;
                            </li>
                        );
                    })}
                </ul>
            </div>
        </>
    );
}
