import { Button, Link } from '@/components/design-system';
import styles from './my-account.module.scss';
import classNames from 'classnames';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'next-i18next';
import { TFunction } from 'react-i18next';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { getPrivateLinks, isCurrentPage } from '@/components/navigation/utils';
import { LogoutButton } from '@/components/navigation/components/LogoutButton';

interface CustomToggleProps {
    children: React.ReactNode;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const MyAccountToggle = React.forwardRef<HTMLButtonElement, CustomToggleProps>(({ children, onClick }, ref) => (
    <Button
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
        size='sm'
        variant='secondary'
        // className={classNames('pt-1 px-2 bg-transparent')}
        className={classNames('pt-1 px-2', styles['toggle-button'])}
        data-cy='my-account-toggle'
    >
        {children}
    </Button>
));
MyAccountToggle.displayName = 'MyAccountToggle';

interface CustomMenuProps {
    children: React.ReactNode;
    style: React.CSSProperties;
    className: string;
    'aria-labelledby': string;
    onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const MyAccountMenu = React.forwardRef<HTMLDivElement, CustomMenuProps>(
    ({ children, style, className, 'aria-labelledby': labeledBy, onClick }, ref) => {
        return (
            <div
                ref={ref}
                style={style}
                className={classNames(className, 'rounded-8 mt-1', styles['menu'])}
                aria-labelledby={labeledBy}
                onClick={onClick}
                data-cy='my-account-menu'
            >
                <ul className='list-unstyled'>{children}</ul>
            </div>
        );
    }
);
MyAccountMenu.displayName = 'MyAccountMenu';

const MenuItem = ({ children }: { children: React.ReactNode }) => {
    return <li className={classNames('text-nowrap')}>{children}</li>;
};

export const MyAccountButton = () => {
    const { data } = useSession();
    const router = useRouter();
    const { asPath } = router;
    const { t } = useTranslation(['common']) as unknown as { t: TFunction<string> };

    const links = getPrivateLinks(data, t);

    return (
        <Dropdown>
            <Dropdown.Toggle as={MyAccountToggle} id='dropdown-custom-components'>
                <i className='icon icon-menu-profile me-1' />
                {t('navigation.my_account')}
                <i className='icon icon-angle-down ms-1' />
            </Dropdown.Toggle>

            <Dropdown.Menu as={MyAccountMenu}>
                {links
                    .map(link => ({ ...link, selected: isCurrentPage(router, link.href) }))
                    .map(link => (
                        <Dropdown.Item as='div' key={link.href} className={styles['menu-item']}>
                            <MenuItem key={link.href}>
                                <Link
                                    href={link.href}
                                    className={classNames(
                                        'd-block py-1',
                                        styles['link'],
                                        link.selected ? styles['selected'] : 'text-dark'
                                    )}
                                >
                                    {link.text}
                                </Link>
                            </MenuItem>
                        </Dropdown.Item>
                    ))}
                <MenuItem>
                    <LogoutButton
                        className={classNames('d-block ps-4 pe-6 py-2 w-100 text-start text-dark', styles['menu-item'])}
                    />
                </MenuItem>
            </Dropdown.Menu>
        </Dropdown>
    );
};
