export type PropertyType = 'HOUSE' | 'APARTMENT';
export const PropertyTypes: Record<string, PropertyType> = {
    HOUSE: 'HOUSE',
    APARTMENT: 'APARTMENT',
};

export class PropertySubType {
    constructor(readonly type: string, readonly propertyType: PropertyType) {}

    isHouseType() {
        return this.propertyType === 'HOUSE';
    }

    isApartmentType() {
        return this.propertyType === 'APARTMENT';
    }
}

export const PropertySubTypes: Record<string, PropertySubType> = {
    ERDGESCHOSSWOHNUNG: new PropertySubType('erdgeschosswohnung', PropertyTypes.APARTMENT),
    HOCHPATERRE: new PropertySubType('hochparterre', PropertyTypes.APARTMENT),
    SOUTERRAIN: new PropertySubType('souterrain', PropertyTypes.APARTMENT),
    ETAGENWOHNUNG: new PropertySubType('etagenwohnung', PropertyTypes.APARTMENT),
    LOFT: new PropertySubType('loft', PropertyTypes.APARTMENT),
    MAISONETTE: new PropertySubType('maisonette', PropertyTypes.APARTMENT),
    PENTHOUSE: new PropertySubType('penthouse', PropertyTypes.APARTMENT),
    STUDIO: new PropertySubType('studio', PropertyTypes.APARTMENT),

    EINFAMILIENHAUS: new PropertySubType('einfamilienhaus', PropertyTypes.HOUSE),
    BUNGALOW: new PropertySubType('bungalow', PropertyTypes.HOUSE),
    DOPPELHAUSHAELFTE: new PropertySubType('doppelhaushälfte', PropertyTypes.HOUSE),
    ZWEIFAMILIENHAUS: new PropertySubType('zweifamilienhaus', PropertyTypes.HOUSE),
    REIHENENDHAUS: new PropertySubType('reihenendhaus', PropertyTypes.HOUSE),
    REIHENMITTLELHAUS: new PropertySubType('reihenmittlelhaus', PropertyTypes.HOUSE),
    STADTHAUS: new PropertySubType('stadthaus', PropertyTypes.HOUSE),
};

export type PropertyAgeType = 'OLD_BUILDING' | 'NEW_BUILDING' | 'FIRST_OCCUPATION';
export const PropertyAgeTypes: Record<string, PropertyAgeType> = {
    OLD_BUILDING: 'OLD_BUILDING',
    NEW_BUILDING: 'NEW_BUILDING',
    FIRST_OCCUPATION: 'FIRST_OCCUPATION',
};

export const Urgencies = ['asap', '1month', '3months', '4months', 'dontknow'];
export const DEFAULT_STEP_NAME = 'city';
