import { notify } from '@/lib/utilities/logger';
import { AppointmentSlot } from '@/lib/api/lease-requests/types';
import { useLeaseRequestsClient } from '@/lib/api';
import { SLOTS_CANCEL_REQUEST, SLOTS_SEARCH_REQUEST } from '@/lib/constants/paths';
import { useState } from 'react';
import { mutate } from 'swr';
import { useAccountId } from '@/components/users';
import { logErrorResponse } from '@/lib/api/utils';

export const useCancelSlot = (): {
    cancelSlot: (
        slotId: string,
        propertyId: string
    ) => Promise<{
        data: AppointmentSlot;
        error: any;
    }>;

    isLoading: boolean;
} => {
    const api = useLeaseRequestsClient();
    const accountId = useAccountId();
    const [isLoading, setIsLoading] = useState(false);

    const cancelSlot = async (slotId: string, propertyId: string) => {
        setIsLoading(true);

        let data, error;

        try {
            const requestData = { params: { path: { slotId } } };
            const response = await api.POST(SLOTS_CANCEL_REQUEST, requestData);
            if (response.response?.status >= 400)
                logErrorResponse(response.response, accountId, requestData, response.error);
            data = response.data;
            await mutate([SLOTS_SEARCH_REQUEST, accountId, propertyId]);
        } catch (err) {
            error = err;
            notify(typeof error === 'object' ? JSON.stringify(error) : error, SLOTS_CANCEL_REQUEST, accountId);
        }
        setIsLoading(false);
        return { data, error };
    };

    return {
        cancelSlot,
        isLoading,
    };
};
