import React, { useEffect, useId, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { TFunction } from 'react-i18next';
import { useRouter } from 'next/router';
import { createFormIdFn, createUsernameValidation, getErrorMessageByName, queryParameter } from '../util';
import { ConfirmForgotPasswordForm } from './confirm-forgot-password-form';
import styles from '../login-form/login-form.module.scss';
import classNames from 'classnames';
import { ResultWithAttempts } from '@/lib/api/cognito';
import { Button, Form, FormInput } from '@/components/design-system';

/* eslint-disable-next-line */
export interface ForgotPasswordComponentProps {
    username?: string | null;
    step?: Step | null;
    confirmForgotPassword: (username: string, code: string, password: string) => Promise<ResultWithAttempts>;
    forgotPassword: (username: string) => Promise<ResultWithAttempts>;
}

export type Step = 'send' | 'confirm' | 'success';
export type StepComponent = typeof ForgotPasswordForm | typeof ConfirmForgotPasswordForm | typeof PasswordResetSuccess;
const steps: [Step, StepComponent][] = [
    ['send', ForgotPasswordForm],
    ['confirm', ConfirmForgotPasswordForm],
    ['success', PasswordResetSuccess],
];

type State = { step: number; username: string };

export type NavigateForward = (username: string) => Promise<boolean>;

export function ForgotPasswordComponent(props: ForgotPasswordComponentProps) {
    const { username: defaultUsername = queryParameter('username') ?? '' } = props || {};
    const componentId = useId();
    const [username] = useState(defaultUsername);
    const formId = createFormIdFn(componentId);
    const router = useRouter();
    const { t } = useTranslation('common') as unknown as {
        t: TFunction<string, undefined>;
    };
    const tNamespacePrefix = 'forgot_password_form';
    const {
        username: initialUsername = queryParameter('username', router.query),
        step: current = queryParameter('step', router.query),
    } = props;
    const [state, setState] = useState<State>({
        username: (initialUsername || '').trim(),
        step: Math.max(
            0,
            steps.findIndex(([step]) => step === current)
        ),
    });

    useEffect(() => {
        setState({
            username: queryParameter('username', router.query) || props.username || '',
            step: Math.max(
                0,
                steps.findIndex(([step]) => step === current)
            ),
        });
    }, [router.query, current, props.username]);

    const createForward = (currentStep: number) =>
        function (username: string) {
            const nextIdx = currentStep >= steps.length - 1 ? steps.length - 1 : currentStep + 1;
            return router.push(
                { pathname: router.pathname, query: { ...router.query, username, step: steps[nextIdx][0] } },
                undefined,
                {
                    shallow: true,
                }
            );
        };

    const [, CurrentStepComponent] = steps[state.step] || [];

    return (
        <div className={classNames('px-4 pb-5', styles['login-form'])}>
            <CurrentStepComponent
                formId={formId}
                t={t}
                username={username}
                forward={createForward(state.step)}
                tNamespacePrefix={tNamespacePrefix}
                {...props}
            />
        </div>
    );
}

function ForgotPasswordForm(
    props: ForgotPasswordComponentProps & {
        formId: (...params: string[]) => string;
        t: TFunction;
        forgotPassword: (username: string) => Promise<ResultWithAttempts>;
        username: string | null;
        forward: NavigateForward;
        tNamespacePrefix: string;
    }
) {
    const { formId, forgotPassword, username, forward, t, tNamespacePrefix } = props || {};
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors, isSubmitting, isValid },
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            username,
        },
    });

    const submit = async ({ username: uname }: { username?: string | null }) => {
        const username = uname?.trim();
        if (!username) {
            setError('username', { message: t(`${tNamespacePrefix}.validation.username.required`) });
            return;
        }
        const { success, error } = await forgotPassword(username);
        if (!success || !!error) {
            setError('username', {
                message: t(`common:${tNamespacePrefix}.error.${error || 'unexpected_error'}`, { username }),
            });
        } else {
            await forward(username);
        }
    };

    return (
        <div className='d-flex flex-column gap-3 design-system'>
            <h1 className='text-center text-velvet'>{t(`${tNamespacePrefix}.headline`)}</h1>
            <p className='body-l bold px-8 text-center'>{t(`${tNamespacePrefix}.intro`)}</p>
            <Form className='gap-7' id={formId()} onSubmit={handleSubmit(submit)}>
                <div>
                    <FormInput
                        type='email'
                        id={formId('Username')}
                        data-cy='email'
                        placeholder='name@example.com'
                        isInvalid={!!errors['username']}
                        label={t(`${tNamespacePrefix}.username`)}
                        error={getErrorMessageByName(t, tNamespacePrefix, errors, 'username')}
                        {...register('username', {
                            minLength: 8,
                            maxLength: 254,
                            required: true,
                            validate: { username: createUsernameValidation(t, tNamespacePrefix) },
                        })}
                    />
                </div>
                <Button
                    className='w-100'
                    type='submit'
                    data-cy='reset-password'
                    tabIndex={3}
                    disabled={!isValid || isSubmitting}
                    isSubmitting={isSubmitting}
                >
                    {isSubmitting ? t(`${tNamespacePrefix}.button.sending`) : t(`${tNamespacePrefix}.button.send`)}
                </Button>
            </Form>
        </div>
    );
}

export function PasswordResetSuccess(props: { t: TFunction; tNamespacePrefix: string }) {
    const { t, tNamespacePrefix } = props;
    const router = useRouter();
    setTimeout(
        () =>
            router.push({ pathname: '/login', query: { ...router.query } }, undefined, {
                shallow: true,
            }),
        4000
    );
    return <p className='body-l text-center'>{t(`${tNamespacePrefix}.success`)}</p>;
}
