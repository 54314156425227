import React from 'react';
import { Form as BootstrapForm, FormProps as BootstrapFormProps } from 'react-bootstrap';
import styles from './style.module.scss';
import classNames from 'classnames';

type FormProps = BootstrapFormProps;

export const Form = ({ children, className, ...rest }: FormProps) => {
    return (
        <BootstrapForm className={classNames('design-system', [styles['form'], className])} {...rest}>
            {children}
        </BootstrapForm>
    );
};
