import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { Button } from 'react-bootstrap';
import classNames from 'classnames';
import { getPartnerNameByUrl } from '../utils';

import { gtm } from '@/lib/utilities';

export const LoginButton = ({ light }: { light: boolean }) => {
    const router = useRouter();

    const { t } = useTranslation('common');
    const colorClasses = light ? ['btn-outline-interaction'] : ['btn-outline-white'];

    const partner = getPartnerNameByUrl(router.asPath);

    const handleClick = () => {
        gtm.event(gtm.EVENTS.LOGIN);
        router.push({
            pathname: '/login',
            query: partner ? { partner } : undefined,
        });
    };

    return (
        <Button
            role='button'
            variant='outline'
            onClick={handleClick}
            className={classNames('btn d-sm-block ms-2 py-1 px-4', ...colorClasses)}
            data-cy='login-link'
        >
            {t('login')}
        </Button>
    );
};
