import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import styles from '../../styles.module.scss';
import { LeaseRateFormatted } from './components/LeaseRate';
import { useSession } from 'next-auth/react';
import { checkIsAdmin, checkIsBroker } from '@/lib/security';
import { EditPanel } from './components/EditPanel';
import { RefurbishmentLevel } from '@/lib/api/graphcms';
import { PropertyDetails } from '@/lib/api/properties/types';
import { Button } from '@/components/design-system';
import Modal from '@/components/design-system/modal';
import { useAccountId } from '@/components/users';
import { Availability } from '@/lib/api/lease-requests/types';
import { AvailableViewings } from '@/components/lease-requests/view/components/AvailableViewings';

const CallToActionBlockMobile = ({
    visible,
    onViewingRequestClick,
    leaseRate,
    leaseDuration,
    refurbishmentLevel,
    property,
    activeLeaseRequestId,
}: {
    visible: boolean;
    onViewingRequestClick: (availability: Availability) => Promise<void>;
    leaseRate: number;
    leaseDuration: number;
    refurbishmentLevel?: RefurbishmentLevel;
    property: Omit<PropertyDetails, 'leasingRate'>;
    activeLeaseRequestId?: string;
}) => {
    const { t } = useTranslation('metasearch');
    const router = useRouter();
    const { locale = 'de' } = router;
    const [isShown, setIsShown] = useState(false);
    const accountId = useAccountId();

    const { data: session } = useSession();
    const isBroker = checkIsBroker(session);
    const isAdmin = checkIsAdmin(session);
    const showEditButton = property.ownerId && (accountId === property.ownerId || isAdmin);
    if (isBroker && !showEditButton) return null;

    const hasActiveLeaseRequest =
        activeLeaseRequestId !== null && activeLeaseRequestId !== undefined && activeLeaseRequestId !== '';

    if (!showEditButton && hasActiveLeaseRequest) return null;

    return (
        <>
            <Modal
                title={t('available_viewing_date')}
                centered={true}
                show={isShown}
                handleClose={() => setIsShown(false)}
            >
                <AvailableViewings propertyId={property.localId} onViewingRequestClick={onViewingRequestClick} />
            </Modal>
            <div
                className={classNames(
                    'bg-body position-fixed bottom-0 start-0 w-100 h-auto py-4',
                    styles.container,
                    isShown && 'd-none'
                )}
                style={{ visibility: visible ? 'initial' : 'hidden' }}
            >
                {showEditButton && (
                    <EditPanel
                        containerClassName='px-4'
                        className='text-footnote'
                        propertyId={property.localId ?? ''}
                        showHidePropertyButton={!property.isPrivate}
                    />
                )}
                <div className='design-system d-flex flex-row h-100 justify-content-between w-100 px-4'>
                    <div className='d-flex justify-content-center flex-column text-gray-400'>
                        <LeaseRateFormatted
                            leaseRate={leaseRate}
                            locale={locale}
                            className='d-block m-0 my-auto fw-bold p-0'
                        />
                        <div className='d-flex gap-1 text-tag'>
                            <p className='m-0'>{`${leaseDuration} ${t('months')}`}</p>
                            <p className='m-0'>{`(${t('excluding_charges')})`}</p>
                        </div>
                        {refurbishmentLevel && (
                            <div className='d-flex gap-1 text-tag'>
                                <p className='m-0'>{`${refurbishmentLevel?.name} ${t('refurbishment.name')}`}</p>
                            </div>
                        )}
                    </div>
                    {!hasActiveLeaseRequest && (
                        <Button
                            onClick={() => setIsShown(prev => !prev)}
                            className='body-m align-self-center'
                            size='md'
                        >
                            {t('send_viewing_request')}
                        </Button>
                    )}
                </div>
            </div>
        </>
    );
};

export default CallToActionBlockMobile;
