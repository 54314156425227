import { PropertyDetails, PropertySearchOrderBy, SupportedRegions, WithRegions } from '@/lib/api/bff';
import { UseFormRegister, UseFormSetValue, UseFormWatch, Control } from 'react-hook-form';
import React from 'react';
import { OffMarketPropertiesFilter, SearchPropertyFilter } from '@/lib/api/properties/types';

export enum FormPropertyType {
    APARTMENT = 'APARTMENT',
    HOUSE = 'HOUSE',
    ALL = 'ALL',
}

export type SearchPropertiesResultPropsType = {
    onGetMorePropertiesClick: () => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getValues: () => any;
    page: number;
    searchParams: (SearchPropertyFilter & { propertyType: string }) | null;
};
export type SearchPropertiesGridPropsType = {
    casafariProperties?: PropertyDetails[];
    onPropertyClick?: (property: PropertyDetails) => void;
    onGetMorePropertiesClick: () => void;
    withLeasingRequest?: boolean;
    page: number;
    currentlyOpenPages: number;
    searchParams: SearchPropertyFilter & { propertyType: string };
};
export type LocationType = {
    label: string;
    value: number;
};
export type FormValuesType = {
    propertyType: FormPropertyType;
    region: SupportedRegions;
    locationsIds: LocationType[] | null;
    minRooms: number | undefined;
    minSpace: number | undefined;
    minLeaseRate: string;
    maxLeaseRate: string;
    limit: number;
    offset: number;
    orderBy: PropertySearchOrderBy | 'DEFAULT';
};

export enum SearchQueryParams {
    PropertyType = 'propertyType',
    Region = 'region',
    LocationsIds = 'locationsIds',
    MinRooms = 'minRooms',
    MinSpace = 'minSpace',
    MinLeaseRate = 'minLeaseRate',
    MaxLeaseRate = 'maxLeaseRate',
    Offset = 'offset',
    OrderBy = 'orderBy',
}

export type SearchFiltersProps = WithRegions & {
    register: UseFormRegister<FormValuesType>;
    watch: UseFormWatch<FormValuesType>;
    setValue: UseFormSetValue<FormValuesType>;
    control: Control<FormValuesType, object>;
    onSearchBtnClick: () => void;
    onOrderOptionClick: (option: PropertySearchOrderBy) => void;
    isValid: boolean;
    isSubmitting: boolean;
    getValues: () => FormValuesType;
    onFieldTouched: (fieldName: string) => void;
    errors: Record<string, unknown>;
};

export type SearchPageFilterType =
    | SearchPropertyFilter
    | (OffMarketPropertiesFilter & { offset: number | null; orderBy: string | null })
    | null
    | undefined;

export enum EmailIntervalType {
    NEVER = 'never',
    DAILY = 'daily',
    EVERY_OTHER_DAY = 'every_other_day',
    WEEKLY = 'weekly',
    MONTHLY = 'monthly',
}

export interface ToggleProps {
    onClick: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

export interface MenuProps {
    style: React.CSSProperties;
    className: string;
    'aria-labelledby': string;
}
