import React from 'react';

export default function ArrowCircleRight(props: object) {
    return (
        <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M20.0206 34.5L18.75 33.2108L27.4588 24.375L18.75 15.5392L20.0206 14.25L30 24.375L20.0206 34.5Z'
                fill='currentColor'
            />
            <rect
                x='1'
                y='47'
                width='46'
                height='46'
                rx='23'
                transform='rotate(-90 1 47)'
                stroke='currentColor'
                strokeWidth='2'
            />
        </svg>
    );
}
