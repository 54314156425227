import React, { KeyboardEvent, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { FieldValues, useForm } from 'react-hook-form';
import classNames from 'classnames';

import { notify } from '@/lib/utilities/logger';
import { Button } from '@/components/common';
import { getErrorMessageByCode } from '../util';

import styles from './change-password.module.scss';
import { NotifiableError } from '@bugsnag/js';

const DEFAULT_RETURN_PAGE = 'my';

export const ChangePasswordForm = ({
    absoluteUrl,
    changeTemporaryPassword,
}: {
    absoluteUrl: (pathname: string) => string;
    changeTemporaryPassword: (
        username: string,
        temporaryPassword: string,
        newPassword: string
    ) => Promise<{ success: boolean; message?: string; code?: string }>;
}) => {
    const router = useRouter();
    const { locale = 'de' } = router;

    const { t } = useTranslation('common');

    const [error, setError] = useState('');

    const {
        register,
        handleSubmit,
        formState: { errors, isValid, isSubmitting },
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            username: router.query.email ? router.query.email + '' : null,
            tempPassword: '',
            newPassword: '',
        },
    });

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.code === 'Enter') {
            handleSubmit(onSubmit)();
        }
    };

    const onSubmit = async (fields: FieldValues) => {
        if (isSubmitting) return;

        setError('');
        try {
            const username = fields['username'];
            const tempPassword = fields['tempPassword'];
            const newPassword = fields['newPassword'];

            const { success, code } = await changeTemporaryPassword(username, tempPassword, newPassword);

            const errorMsg = getErrorMessageByCode(t, success, code);
            if (errorMsg) {
                setError(errorMsg);
                return;
            }

            window.location.href = absoluteUrl(`${locale}/${DEFAULT_RETURN_PAGE}`);
        } catch (e: unknown) {
            setError(t('login_form.errors.something_went_wrong'));
            notify(e as NotifiableError);
        }
    };

    return (
        <div className={classNames('mt-4 p-4 pb-5 ', styles['login-form'])}>
            <div className='text-center mt-4 mb-6'>{t('change_password_form.header')}</div>
            <Form>
                <Form.Group className='mb-5' controlId='email'>
                    <Form.Label>{t('change_password_form.email')}</Form.Label>
                    <Form.Control
                        type='email'
                        className='mb-3'
                        autoFocus
                        isInvalid={!!errors['username']}
                        {...register('username', {
                            required: true,
                        })}
                    />
                </Form.Group>
                <Form.Group className='mb-3' controlId='tempPassword'>
                    <Form.Label>{t('change_password_form.temp_password')}</Form.Label>
                    <Form.Control
                        type='password'
                        onKeyDown={handleKeyDown}
                        isInvalid={!!errors['tempPassword']}
                        {...register('tempPassword', {
                            required: true,
                        })}
                    />
                </Form.Group>
                <Form.Group className='mb-3' controlId='newPassword'>
                    <Form.Label>{t('change_password_form.new_password')}</Form.Label>
                    <Form.Control
                        type='password'
                        onKeyDown={handleKeyDown}
                        isInvalid={!!errors['newPassword']}
                        {...register('newPassword', {
                            required: true,
                            pattern:
                                /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/,
                        })}
                    />
                    <p className={classNames(styles['description'], 'text-form mt-2')}>
                        {t('change_password_form.password_rules')}
                    </p>
                </Form.Group>

                {error && (
                    <Alert className='mt-6 mb-6' variant='danger'>
                        {error}
                    </Alert>
                )}
            </Form>
            <Button
                className='float-end mt-4'
                onClick={handleSubmit(onSubmit)}
                color='velvet'
                type='submit'
                disabled={!isValid || isSubmitting}
            >
                {t('submit')}
                {isSubmitting ? <i className='icon icon-ownr-o loader' /> : ''}
            </Button>
        </div>
    );
};
