import { useTranslation } from 'next-i18next';
import React from 'react';

export const ExposeNoLongerActive = () => {
    const { t } = useTranslation('metasearch');
    return (
        <div className={'container'}>
            <div className={'container'}>
                <h3 className={'tiny-header'}>{t('This expose has expired')}</h3>
            </div>
        </div>
    );
};
