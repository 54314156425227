import React, { useEffect, useMemo, useState } from 'react';
import Select, { GroupBase } from 'react-select';
import { Controller, FieldValues, UseFormSetValue, UseFormGetValues, Control, UseFormWatch } from 'react-hook-form';
import { TFunction } from 'react-i18next';
import { useLocations, useSearchLocationDebounce } from '../hooks';
import { SupportedRegions } from '@/lib/api/bff';
import { FormValuesType } from '../types';
import { getOptions, getSelectStyles } from '../utils';
import { useTranslation } from 'next-i18next';
import { OptionItem } from '@/components/common';
import { TagsCloud } from '@/components/design-system/tags-cloud';

type OptionsGroupType = {
    label: string;
    options: {
        label: string;
        value: number;
    }[];
};
export type GroupedOptionsArray = Array<OptionsGroupType>;

export function LocationSelect({
    region = SupportedRegions.BERLIN,
    suggestedLocations,
    control,
    isMulti = true,
    required = false,
    postCodesOnly = false,
    hasError = false,
    getValues,
    setValue,
    watch,
}: {
    region: SupportedRegions;
    showSuggestedLocations?: boolean;
    suggestedLocations?: { label: string; value: number }[];
    control: Control<FormValuesType>;
    isMulti?: boolean;
    required?: boolean;
    postCodesOnly?: boolean;
    hasError?: boolean;
    getValues: UseFormGetValues<FieldValues>;
    setValue: UseFormSetValue<FieldValues>;
    watch: UseFormWatch<FieldValues>;
}) {
    const selectStyles = getSelectStyles(hasError);

    const [locationSearchKeyword, setLocationSearchKeyword] = useState<string>('');
    const { data, isLoading } = useLocations(region, locationSearchKeyword);
    const searchLocations = useSearchLocationDebounce(setLocationSearchKeyword);
    const { t } = useTranslation(['myOwnr', 'properties']) as unknown as { t: TFunction<string, undefined> };

    useEffect(() => {
        setLocationSearchKeyword('');
    }, [region]);

    let selectOptions = useMemo(() => getOptions(data, t), [data, t]);

    if (postCodesOnly) {
        selectOptions = selectOptions?.filter(option => option.label === t('PLZ', { ns: 'properties' }));
    }

    const selectedLocations = (getValues('locationsIds') || []) as { label: string; value: number }[];

    const handleSuggestedLocationSelect = async (location: OptionItem) => {
        if (!selectedLocations.find(selectedLocation => selectedLocation.value === +location.key)) {
            setValue('locationsIds', [...selectedLocations, { label: location.label, value: +location.key }]);
        }

        setValue('suggestedLocations', [
            ...(watch('suggestedLocations') || []),
            { label: location.label, value: +location.key },
        ]);
    };

    const handleSuggestedLocationRemove = async (location: OptionItem) => {
        setValue(
            'locationsIds',
            selectedLocations.filter(selectedLocation => selectedLocation.value !== +location.key)
        );

        setValue(
            'suggestedLocations',
            watch('suggestedLocations').filter(suggestedLocation => suggestedLocation.value !== +location.key)
        );
    };

    const placeholder = postCodesOnly
        ? t('PLZ', { ns: 'properties' })
        : t('locations_placeholder', { ns: 'properties' });

    return (
        <>
            <Controller
                name={'locationsIds'}
                control={control}
                rules={{ required }}
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                    <Select<
                        {
                            label: string;
                            value: number;
                        },
                        true,
                        GroupBase<{
                            label: string;
                            value: number;
                        }>
                    >
                        filterOption={() => {
                            return true;
                        }}
                        styles={selectStyles}
                        placeholder={required ? placeholder + ' *' : placeholder}
                        options={selectOptions}
                        closeMenuOnSelect={!isMulti}
                        noOptionsMessage={() => t('locations_instructions', { ns: 'properties' })}
                        isLoading={isLoading}
                        loadingMessage={() => t('loading', { ns: 'properties' })}
                        onInputChange={e => {
                            if (e.length > 2) {
                                searchLocations(e);
                            }
                        }}
                        components={{ IndicatorSeparator }}
                        onChange={value => {
                            if (Array.isArray(value)) {
                                setValue('locationsIds', [...value]);

                                const selectedLocations = [...value];
                                const selectedSuggestedLocations = watch('suggestedLocations') || [];
                                const newSuggestedLocations = selectedLocations.filter(
                                    location =>
                                        suggestedLocations?.find(
                                            suggestedLocation => suggestedLocation.value === location.value
                                        ) &&
                                        !selectedSuggestedLocations.find(
                                            suggestedLocation => suggestedLocation.value === location.value
                                        )
                                );
                                setValue('suggestedLocations', [
                                    ...selectedSuggestedLocations,
                                    ...newSuggestedLocations,
                                ]);
                            } else {
                                setValue('locationsIds', value);
                            }
                            onChange(value);
                        }}
                        isMulti={isMulti ? isMulti : undefined}
                        onBlur={onBlur}
                        value={value}
                        name={name}
                        ref={ref}
                    />
                )}
            />
            {!!suggestedLocations?.length && (
                <TagsCloud
                    options={suggestedLocations?.map(location => ({
                        label: location.label,
                        key: location.value.toString(),
                    }))}
                    selectedOptions={watch('suggestedLocations')?.map(location => ({
                        label: location.label,
                        key: location.value.toString(),
                    }))}
                    onAdd={handleSuggestedLocationSelect}
                    onRemove={handleSuggestedLocationRemove}
                    className='mt-3'
                />
            )}
        </>
    );
}

const IndicatorSeparator = () => {
    return <span />;
};

export default LocationSelect;
