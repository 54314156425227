import { PageLayout } from '../page-layout';
import { absoluteUrl } from '@/lib/env';
import { NextPageWithLayout } from '@/pages/_app';
import { GetStaticProps, GetStaticPropsContext } from 'next';
import { pageLayout, Wrapper } from '../graph-cms';
import { createNavigationItems, localizedPages, LocalizedPages, NavigationItem } from '../navigation';
import { getPage, getPartnersData, Locale, Page, PageBlockTypes, Partners, PartnersLogoType } from '@/lib/api/graphcms';
import { ReactElement } from 'react';
import { GetStaticPropsResult } from 'next/types';
import { isInternalPageSlug } from '@/lib/util';
import graphCms, { stage } from '@/lib/api/graphcms';
import { ParsedUrlQuery } from 'querystring';
import { SSRConfig } from 'next-i18next';
import { Sdk } from '@/lib/api/bff';
import api from '../../lib/api/api';
import { SWRConfig } from 'swr';
import { useRouter } from 'next/router';
import { SpeedInsights } from '@vercel/speed-insights/react';

export type StandardPageProps = {
    page: Page;
    navigationItems: NavigationItem[];
    partnersNavigationItems: NavigationItem[];
    partnersLogo: PartnersLogoType;
    locale: string;
    preview?: boolean;
    localizations: LocalizedPages;
    children?: ReactElement;
    fallback?: unknown;
    footer: any;
} & Partial<SSRConfig>;

const StandardPage: NextPageWithLayout = (props: StandardPageProps) => {
    const { page, fallback } = props || {};
    const { blocks = [] } = page || {};
    const router = useRouter();
    return (
        <SWRConfig value={{ fallback }}>
            <Wrapper elements={blocks} layout={pageLayout} styleContext={{ background: 'bg-white' }} />
            <SpeedInsights route={router.pathname} />
        </SWRConfig>
    );
};
export default StandardPage;

export function standardPageLayout() {
    return function getLayout(page) {
        const {
            page: p,
            navigationItems = [],
            partnersNavigationItems,
            preview,
            localizations,
            partnersLogo,
            locale = 'de',
            footer,
        } = page.props || {};
        const { title, header, slug } = p || {};
        return (
            <PageLayout
                title={title}
                preview={preview}
                locale={locale}
                navigationItems={navigationItems}
                partnersNavigationItems={partnersNavigationItems}
                localizations={localizations}
                header={header}
                partnersLogo={partnersLogo}
                absoluteUrl={absoluteUrl}
                footer={footer}
                slug={slug}
            >
                {page}
            </PageLayout>
        );
    };
}

export type GetStandardStaticProps = {
    slugFunction?: (params: ParsedUrlQuery) => string | undefined;
    revalidate?: number;
    ssrConfig?: (locale: string) => Promise<SSRConfig>;
    partner?: Partners;
};

export function locales(context: GetStaticPropsContext) {
    const { locale = 'de', locales = ['de'] } = context || {};
    const [isoLocale] = (locale || locales[0]).split('-');
    const fallbackLocale = locale === 'de' ? 'en' : 'de';
    return { locale: isoLocale as Locale, fallbackLocale: fallbackLocale as Locale };
}

export function getStandardStaticProps(props?: GetStandardStaticProps): GetStaticProps<StandardPageProps> {
    const {
        slugFunction = params => {
            const { slug: s } = params || {};
            const slug = Array.isArray(s) ? s[0] : s;
            return slug && !isInternalPageSlug(slug) ? slug : undefined;
        },
        revalidate,
        ssrConfig,
    } = props || {};

    return async function getStaticProps(
        context: GetStaticPropsContext
    ): Promise<GetStaticPropsResult<StandardPageProps>> {
        const { params, preview = false } = context || {};
        const slug = slugFunction(params);
        if (!slug) return { notFound: true };
        const { locale, fallbackLocale } = locales(context);
        const graphCmsSdk = graphCms();

        const response = await getPage(
            {
                slug,
                locale,
                fallbackLocale,
                ...stage(preview),
            },
            graphCmsSdk
        );

        const {
            pages: [page = null],
            collected = {},
            footer,
        } = response;

        if (!page) return { notFound: true };

        const partnersData = await getPartnersData(graphCmsSdk, { locale, ...stage(preview) });
        const partnersLogo = partnersData.assets;
        const partnersNavigationItems = partnersData.navigationItems;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const navigationItems = await createNavigationItems(response);
        const localizations = localizedPages(page);

        const apiSdk = api();
        const preLoaded = await Promise.all(
            Object.entries(collected).map(async ([key, value]) => {
                const populator = PREPOPS[key];
                if (!populator) return null;
                const pairs = await populator(apiSdk, value, {
                    locale,
                    fallbackLocale,
                });
                return [...pairs];
            })
        );

        const fallback = preLoaded.reduce((acc, pairs) => {
            const r = pairs.reduce((a, [url, item]) => {
                a[url] = item;
                return a;
            }, acc);
            return r;
        }, {});

        const result: GetStaticPropsResult<StandardPageProps> = {
            props: {
                preview,
                page: (page as Page) || null,
                navigationItems,
                partnersNavigationItems,
                partnersLogo,
                locale,
                localizations,
                fallback,
                footer,
            },
        };

        if (revalidate) result.revalidate = revalidate;
        if (ssrConfig) result.props = { ...result.props, ...(await ssrConfig(locale)) };
        return result;
    };
}

const PREPOPS: Partial<
    Record<PageBlockTypes, (api: Sdk, ids: string[], ctx: { locale: string }) => Promise<[string, unknown][]>>
> = {
    // Accordion: async (api, ids, { locale = 'de' }) => {
    //     if (!ids || !ids.length) return [];
    //     const { knowledgeArticleList: articles = [] } = await api.KnowledgeArticleList({ urlNames: ids, locale });
    //     const r: [string, unknown][] = articles.map(article => [
    //         `/bff/knowledge/articles/${locale}/${article.urlName}`,
    //         article,
    //     ]);
    //     return r;
    // },
};
