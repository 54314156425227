import React from 'react';
import { SliderCardComponent } from '@/lib/api/graphcms';
import { Row } from 'react-bootstrap';
import Slider from 'react-slick';
import { Glyph, SliderCard } from '@/components/common';
import styles from './SliderCardLayout.module.scss';

type SliderCardLayoutProps = {
    items: SliderCardComponent[];
    className?: string;
};

export const SliderCardLayout = ({ className, items }: SliderCardLayoutProps): JSX.Element | null => {
    const sliderConfig = {
        dots: true,
        centerMode: true,
        slidesToShow: 1,
        infinite: true,
        arrows: false,
        cssEase: 'linear',
        variableWidth: true,
        prevArrow: <Glyph name='arrow-circle-left' color='#725BFF' />,
        nextArrow: <Glyph name='arrow-circle-right' color='#725BFF' />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    variableWidth: true,
                    arrows: false,
                },
            },
        ],
    };

    return (
        <Row className={className}>
            <Slider {...sliderConfig} className={styles.slider}>
                {items?.map(item => (
                    <SliderCard key={item.id} {...item} />
                ))}
            </Slider>
        </Row>
    );
};
