import { useEffect } from 'react';
import { RegistrationFormQuickProps } from '../User/types';
import { useTranslation } from 'next-i18next';
import { TFunction } from 'react-i18next';
import { gtm } from '@/lib/utilities';
import { ContactForm } from './ContactForm';

const MANDATORY_BASE = ['firstName', 'lastName', 'email', 'password'];

export function RegistrationVobahome(props: RegistrationFormQuickProps) {
    const { t } = useTranslation(['register', 'common', 'properties']) as unknown as {
        t: TFunction<string>;
    };
    const mandatoryFields = [...new Set([...(props.mandatoryFields || []), ...MANDATORY_BASE])];

    const { additionalContent, register } = props || {};
    const { 'terms-and-conditions': terms, 'privacy-policy': privacyPolicy } = additionalContent || {};

    useEffect(() => {
        gtm.event(gtm.EVENTS.APPLICATION_STARTED, { category: gtm.CATEGORIES.VOBAHOME_APPLICATION });
    }, []);

    return (
        <ContactForm
            {...props}
            formId={params => `register-${params}`}
            mandatoryFields={mandatoryFields}
            fieldsToHide={[]}
            t={t}
            content={{ terms, privacyPolicy }}
            register={register}
        />
    );
}
