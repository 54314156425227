import React from 'react';

export default function ChevronUp(props: object) {
    return (
        <svg width='20' height='11' viewBox='0 0 20 11' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M20 9.75761L18.7267 11L10 2.48478L1.27325 11L-2.17317e-06 9.75761L10 1.18652e-06L20 9.75761Z'
                fill='currentColor'
            />
        </svg>
    );
}
