import { useSession } from 'next-auth/react';
import { useState } from 'react';
import { getUserByEmail } from '@/lib/api/cognito';
import { notify } from '@/lib/utilities/logger';

export function useAccountId(): string | undefined {
    const { data: session } = useSession();
    const { user } = session || {};
    const { accountId, impersonating = undefined } = user || {};
    return impersonating ? impersonating.accountId : accountId;
}

export function useImpersonateUser() {
    const [isLoading, setIsLoading] = useState(false);
    const { data } = useSession();
    return {
        findUser: async (email: string) => {
            try {
                setIsLoading(true);
                const user = await getUserByEmail(email, data);

                if (!user) return { isLoading: false, user: null };

                return { isLoading, user };
            } catch (error) {
                setIsLoading(false);
                notify(error, 'bff/findUserToImpersonate');
                console.error('Error finding user to impersonate', error);
                return { isLoading: false, user: null };
            }
        },
    };
}
