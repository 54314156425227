import * as Components from '../dynamic';
import { DynamicComponent as Component } from '@/lib/api/graphcms';
import { Container } from 'react-bootstrap';
import { WithStyleContext } from '../../../types';

type ElementType = keyof typeof Components;

export type DynamicComponentElementProps = Partial<Component> & WithStyleContext;

export type DynamicComponentProps = DynamicComponentElementProps & {
    componentType?: ElementType;
};

export function DynamicComponent(props: DynamicComponentProps) {
    const { id, componentType } = props || {};
    if (!componentType) return null;
    const Component = Components[componentType];
    return <Component id={id} {...props} />;
}

DynamicComponent.pageLayoutContainer = (element: JSX.Element, key: string) => (
    <Container key={key}>{element}</Container>
);
