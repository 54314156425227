import React from 'react';

export default function Attention(props: object) {
    return (
        <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M9 16.2C12.9764 16.2 16.2 12.9764 16.2 9C16.2 5.02355 12.9764 1.8 9 1.8C5.02355 1.8 1.8 5.02355 1.8 9C1.8 12.9764 5.02355 16.2 9 16.2ZM9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z'
                fill='currentColor'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M9.87324 4.1V10.4H8.07324V4.1H9.87324ZM8.09506 12.7002C8.09506 12.2031 8.498 11.8002 8.99506 11.8002H9.00406C9.50111 11.8002 9.90406 12.2031 9.90406 12.7002C9.90406 13.1972 9.50111 13.6002 9.00406 13.6002H8.99506C8.498 13.6002 8.09506 13.1972 8.09506 12.7002Z'
                fill='currentColor'
            />
        </svg>
    );
}
