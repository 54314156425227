import { getPartnerFromSession, getPartnerNameByUrl } from '@/components/navigation/utils';
import { Partners, PartnersLogoType } from '@/lib/api/graphcms';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';

const usePartnerLogo = (partnersLogo?: PartnersLogoType): string | null | undefined => {
    const { data } = useSession();
    const { asPath, query } = useRouter();
    if (!partnersLogo || !partnersLogo.length) return;
    const partnerFromSession = getPartnerFromSession(data);
    if (partnerFromSession) {
        // currently only Vobahome is supported
        return partnersLogo.find(asset => asset.partner === Partners.VOBAHOME && asset.fileName.includes('private'))
            ?.url;
    }
    if (asPath.includes(Partners.VOBAHOME.toLowerCase())) {
        return partnersLogo.find(asset => asset.partner === Partners.VOBAHOME && !asset.fileName.includes('private'))
            ?.url;
    }
    if (asPath.includes('/partners')) {
        const partnerNameFromPath = getPartnerNameByUrl(asPath);
        return partnersLogo.find(asset => asset.partner === partnerNameFromPath)?.url;
    }
    if (query?.partner) {
        return partnersLogo.find(asset => asset.partner === query.partner)?.url;
    }
    return null;
};

export default usePartnerLogo;
