import React from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { Link } from '../navigation';
import { useTranslation } from 'next-i18next';
import { TFunction } from 'react-i18next';

export function NotFound() {
    const { t } = useTranslation(['metasearch']) as unknown as { t: TFunction<string> };

    return (
        <Container>
            <Row className='justify-content-md-center m-6'>
                <Col md='auto'>
                    <Card>
                        <Card.Body className='justify-content-md-center'>
                            <Card.Text>
                                <h2>{t('not_found')}</h2>
                                <p>{t('not_found_subheading')}</p>
                            </Card.Text>
                            <Row className='justify-content-md-center'>
                                <Col md='auto'>
                                    <Link target={{ slug: 'searches' }}>
                                        <Button variant='btn btn-ternary p-4'>{t('go_to_search')}</Button>
                                    </Link>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}
