import React from 'react';

export default function Play(props: object) {
    return (
        <svg width='18' height='22' viewBox='0 0 18 22' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M0 0L18 11L0 22V0ZM1.90609 3.36264V18.6374L14.4036 11L1.90609 3.36264Z'
                fill='currentColor'
            />
        </svg>
    );
}
