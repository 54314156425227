import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { TFunction } from 'react-i18next';
import { createCodeValidation, getErrorMessageByName } from '../util';
import { NavigateForward } from './forgot-password-form';
import { ResultWithAttempts } from '@/lib/env';
import { ErrorAlert, Button, Form, FormInput } from '@/components/design-system';

export function ConfirmForgotPasswordForm(props: {
    username?: string | null;
    formId: (...params: string[]) => string;
    t: TFunction;
    confirmForgotPassword: (username: string, code: string, password: string) => Promise<ResultWithAttempts>;
    forward: NavigateForward;
    tNamespacePrefix: string;
}) {
    const { formId, username, confirmForgotPassword, forward, t, tNamespacePrefix } = props || {};
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors, isSubmitting, isValid },
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            username,
            code: null,
            newPassword: null,
            passwordConfirm: null,
        },
    });

    useEffect(() => {
        if (errors?.username?.message) window?.scrollTo({ top: 0, behavior: 'smooth' });
    }, [errors]);

    const submit = async ({
        username,
        code,
        newPassword,
        passwordConfirm,
    }: {
        username?: string | null;
        code?: string | null;
        newPassword?: string | null;
        passwordConfirm?: string | null;
    }) => {
        if (!username) {
            setError('username', {
                message: t(`${tNamespacePrefix}.validation.username.required`),
            });
            return;
        }
        if (!code) {
            setError('code', {
                message: t(`${tNamespacePrefix}.validation.code.required`),
            });
            return;
        }
        if (!newPassword) {
            setError('newPassword', {
                message: t(`${tNamespacePrefix}.validation.newPassword.required`),
            });
            return;
        }
        if (!passwordConfirm) {
            setError('newPassword', {
                message: t(`${tNamespacePrefix}.validation.passwordConfirm.required`),
            });
            return;
        }
        if (newPassword !== passwordConfirm) {
            setError('newPassword', {
                message: t(`${tNamespacePrefix}.validation.passwordConfirm.equal`),
            });
            return;
        }
        const { success, error } = await confirmForgotPassword(username, code, newPassword);
        if (!success || !!error) {
            setError('username', {
                message: t(`${tNamespacePrefix}.error.${error || 'unexpected_error'}`, {
                    username,
                }),
            });
        } else {
            forward(username);
        }
    };

    return (
        <div className='design-system'>
            {errors['username']?.message && <ErrorAlert className='mb-3'>{errors['username']?.message}</ErrorAlert>}
            <h1 className='text-center text-velvet'>{t(`${tNamespacePrefix}.headline`)}</h1>
            <p className='body-l bold text-center px-6 mb-5'>{t(`${tNamespacePrefix}.code_sent`)}</p>
            <Form className='gap-5' id={formId()} onSubmit={handleSubmit(submit)}>
                <div>
                    <FormInput
                        type='text'
                        id={formId('Code')}
                        data-cy='code'
                        placeholder={t(`${tNamespacePrefix}.code`)}
                        label={t(`${tNamespacePrefix}.code`) + '*'}
                        error={getErrorMessageByName(t, tNamespacePrefix, errors, 'code')}
                        isInvalid={!!errors['code']}
                        {...register('code', {
                            minLength: 6,
                            maxLength: 6,
                            required: true,
                            pattern: /[0-9]{6}/,
                            validate: { code: createCodeValidation(t) },
                        })}
                    />
                </div>
                <div className='mb-7'>
                    <FormInput
                        type='password'
                        id={formId('newPassword')}
                        data-cy='password'
                        isInvalid={!!errors['newPassword']}
                        placeholder={t(`${tNamespacePrefix}.newPassword`)}
                        label={t(`${tNamespacePrefix}.newPassword`) + '*'}
                        error={getErrorMessageByName(t, tNamespacePrefix, errors, 'newPassword')}
                        hint={t(`${tNamespacePrefix}.password_rules`)}
                        {...register('newPassword', {
                            minLength: 8,
                            maxLength: 254,
                            required: true,
                            pattern:
                                /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/,
                        })}
                    />
                </div>
                <div className='mb-7'>
                    <FormInput
                        type='password'
                        id={formId('passwordConfirm')}
                        data-cy='confirm-password'
                        isInvalid={!!errors['passwordConfirm']}
                        placeholder={t(`${tNamespacePrefix}.passwordConfirm`)}
                        label={t(`${tNamespacePrefix}.passwordConfirm`) + '*'}
                        error={getErrorMessageByName(t, tNamespacePrefix, errors, 'passwordConfirm')}
                        hint={t(`${tNamespacePrefix}.password_rules`)}
                        {...register('passwordConfirm', {
                            minLength: 8,
                            maxLength: 254,
                            required: true,
                        })}
                    />
                </div>
                <div className='mt-2 d-grid w-100 d-flex justify-content-end'>
                    <Button
                        className='w-100'
                        type='submit'
                        data-cy='reset-password'
                        tabIndex={3}
                        disabled={!isValid || isSubmitting}
                        isSubmitting={isSubmitting}
                    >
                        {isSubmitting ? t(`${tNamespacePrefix}.button.sending`) : t(`${tNamespacePrefix}.button.send`)}
                    </Button>
                </div>
            </Form>
        </div>
    );
}
