import React from 'react';
import classNames from 'classnames';
import { GraphCmsImage } from '../../../../graph-cms';
import { SliderCardComponent } from '@/lib/api/graphcms';
import { enumToBackground } from '@/lib/utilities';
import styles from './slider-card.module.scss';

export type SliderCardProps = Partial<SliderCardComponent> & { className?: string };

export const SliderCard = ({ className, background, image, title, text }: SliderCardProps): JSX.Element | null => {
    return (
        <div className={classNames(styles.container, enumToBackground(background), className)}>
            <div className={styles['image-container']}>
                <GraphCmsImage className={styles.image} image={image} alt='Slider card' />
            </div>
            <div className={styles.content}>
                <h3 className={styles.title}>{title}</h3>
                <h4 className={styles.description}>{text}</h4>
            </div>
        </div>
    );
};
