import { Col, Container, Form, Row } from 'react-bootstrap';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { TFunction } from 'react-i18next';
import { Button } from '@/components/common';
import { hasRole, UserRoles } from '@/lib/security';
import { useImpersonateUser } from '@/components/users';
type UserImpersonateProps = {
    email: string;
};

export const Users = () => {
    const { data, update } = useSession();
    const router = useRouter();
    const { t } = useTranslation(['common']) as unknown as { t: TFunction<string> };
    const { findUser } = useImpersonateUser();
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        setError,
    } = useForm<UserImpersonateProps>({ mode: 'all' });

    if (!hasRole(data, UserRoles.Impersonator)) {
        router.push(`/my/lease-requests`);
        return;
    }

    const onSubmit: SubmitHandler<UserImpersonateProps> = async data => {
        const { user: userData } = await findUser(data.email);

        if (!userData) {
            setError('email', {
                message: 'User not found!',
            });
            return;
        }

        update({
            ...data,
            action: 'impersonate',
            impersonating: userData,
        });
    };

    return (
        <Container>
            <Form onSubmit={handleSubmit(onSubmit)} style={{ maxWidth: '700px' }}>
                <Row className='justify-content-center align-items-center'>
                    <p className='text-caption-bold mt-5 mb-3'>{t('impersonate.header')}</p>
                    <Col className='pb-3'>
                        <Form.Control
                            type='email'
                            autoFocus
                            isInvalid={!!errors.email}
                            {...register('email', {
                                required: true,
                                pattern: {
                                    value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,9}$/,
                                    message: t('impersonate.errors.incorrect_email'),
                                },
                            })}
                        />
                    </Col>
                    <Col sm='auto' className='pb-3'>
                        <Button buttonType='submit' disabled={isSubmitting || !!errors.email} className='float-end'>
                            {t('impersonate.action')}
                            {isSubmitting ? <i className='icon icon-ownr-o loader' /> : ''}
                        </Button>
                    </Col>
                </Row>
                {errors.email && <span className='text-error'>{errors.email.message}</span>}
            </Form>
        </Container>
    );
};
