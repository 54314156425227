import { FC } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

type OptionItem = { label: string; key: string; Icon?: FC<{ fillColor?: string }> };

type TagsCloudProps = Partial<Pick<HTMLSelectElement, 'className'>> & {
    options: OptionItem[];
    selectedOptions?: OptionItem[];
    onAdd?: (value: OptionItem) => void;
    onRemove?: (value: OptionItem) => void;
};

export function TagsCloud(props: TagsCloudProps) {
    const { className, onAdd, onRemove, options = [], selectedOptions = [] } = props || {};

    const isSelected = ({ key }: OptionItem) => selectedOptions.find(selectedOption => selectedOption.key === key);

    const addToSelection = (item: OptionItem) => {
        onAdd?.(item);
    };
    const removeFromSelection = (item: OptionItem) => {
        onRemove?.(item);
    };

    return (
        <div className={classNames('w-100', className, 'design-system')}>
            <ul className={classNames(styles['tag-list'], 'px-0')} data-cy='tag-list'>
                {options.map(item => {
                    const isSelectedItem = isSelected(item);
                    return (
                        <li
                            key={item.key}
                            data-cy={`tag-${item.key}`}
                            role='button'
                            className={classNames(
                                'body-l rounded-32 px-4 py-1 text-nowrap',
                                isSelectedItem
                                    ? 'bg-velvet-500 border-velvet-500 text-white'
                                    : 'bg-gray-50 border-gray-50 text-gray-200'
                            )}
                            onClick={() => (isSelectedItem ? removeFromSelection(item) : addToSelection(item))}
                        >
                            {item.Icon && (
                                <span className='me-2'>
                                    {<item.Icon fillColor={isSelectedItem ? '#fff' : '#adafb7'} />}
                                </span>
                            )}
                            {item.label}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}
