import React, { useState } from 'react';
import { FormInputProps } from '../FormInput';
import { Form, InputGroup } from 'react-bootstrap';
import { Eye, EyeFill } from 'react-bootstrap-icons';
import classNames from 'classnames';
import styles from '../style.module.scss';

export const PasswordInput = React.forwardRef(function PasswordInput<T>(props: FormInputProps<T>, ref) {
    const { placeholder, inputClassName, id, isInvalid, ...rest } = props;
    // not working!
    const [passwordVisible, setPasswordVisible] = useState(false);
    return (
        <InputGroup>
            <Form.Control
                ref={ref}
                placeholder={placeholder}
                id={id}
                className={classNames(inputClassName, styles['form-input'])}
                isInvalid={isInvalid}
                {...rest}
                type={passwordVisible ? 'text' : 'password'}
            ></Form.Control>
            {!isInvalid && (
                <InputGroup.Text
                    className={classNames([isInvalid ? 'text-error' : 'text-velvet'])}
                    onClick={() => setPasswordVisible(!passwordVisible)}
                >
                    {passwordVisible ? <EyeFill /> : <Eye />}
                </InputGroup.Text>
            )}
        </InputGroup>
    );
});
