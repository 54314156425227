import { Accordion as Record, AccordionInfoItem } from '@/lib/api/graphcms';
import { Accordion, AccordionContext, Col, Row } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { WithStyleContext } from '../../../types';
import React, { useContext, useMemo } from 'react';
import styles from './Accordion.module.scss';
import classNames from 'classnames';
import classnames from 'classnames';
import { RichText } from '@graphcms/rich-text-react-renderer';
import { Button, colorVariant, lockSymbols, patternVariant } from '@/components/common';

export type AccordionProps = Partial<Record> &
    Partial<Pick<React.HTMLAttributes<HTMLDivElement>, 'className'>> &
    WithStyleContext;

export function AccordionComponent(props: AccordionProps) {
    const { title, items = [], cta, idTag } = props || {};

    const symbols = useMemo(() => {
        const date = new Date();
        return lockSymbols(3, 5, { pattern: patternVariant(date.getDay()), colors: colorVariant(date.getDate()) });
    }, []);
    return (
        <>
            <Row id={idTag ? idTag : ''} className={classnames(styles.accordionRow, 'container')}>
                <Col lg={6} className={classNames(styles['content'])}>
                    <h1 className='mb-3'>{title}</h1>
                    <Accordion className='mb-6' alwaysOpen={false}>
                        {items.map((item, idx) => {
                            switch (item.__typename) {
                                case 'AccordionInfoItem': {
                                    return <AccordionInfoComponent key={`${item.id}-${idx}`} {...item} />;
                                }
                                default:
                                    return null;
                            }
                        })}
                    </Accordion>
                    {cta && (
                        <div>
                            <Button {...props.cta} />
                        </div>
                    )}
                </Col>
                <Col className={styles.notMobile} />
            </Row>
            <Col
                style={{ backgroundImage: `url("data:image/svg+xml,${encodeURIComponent(symbols)}")` }}
                className={classnames(styles['decoration'], styles.onlyMobile)}
            />
            <Row className={classnames('position-absolute w-100 h-100 top-0 m-0', styles.notMobile)}>
                <Col lg={6} />
                <Col
                    style={{ backgroundImage: `url("data:image/svg+xml,${encodeURIComponent(symbols)}")` }}
                    className={classnames('p-0', styles['decoration'])}
                >
                    &nbsp;
                </Col>
            </Row>
        </>
    );
}

AccordionComponent.pageLayoutContainer = (element: JSX.Element, key: string) => (
    <div className={classnames(styles.accordion, 'container', 'bg-velvet-50', 'position-relative')} key={key}>
        {element}
    </div>
);

export function AccordionInfoComponent({ id, header, body }: AccordionInfoItem) {
    const { activeEventKey } = useContext(AccordionContext);
    if (!header || !body) return null;
    const isOpen = activeEventKey === id;
    if (!body || !body.raw) return null;
    return (
        <ContextAwareToggle eventKey={id}>
            <div className='d-flex mb-2 justify-content-between'>
                <div>
                    <h3>{header}</h3>
                </div>
                <div className='align-top'>
                    <AccordionIcon isOpen={isOpen} />
                </div>
            </div>
            <Accordion.Collapse eventKey={id}>
                <RichText content={body.raw} />
            </Accordion.Collapse>
        </ContextAwareToggle>
    );
}

function ContextAwareToggle({
    children,
    eventKey,
    callback,
}: {
    children: React.ReactNode;
    eventKey: string;
    callback?: (eventKey: string) => void;
}) {
    const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));

    return (
        <div role='button' className={classNames('p-3 mb-4 shadow', styles['item'])} onClick={decoratedOnClick}>
            {children}
        </div>
    );
}

function AccordionIcon({ isOpen }: { isOpen: boolean }) {
    if (isOpen) {
        return (
            <svg
                className={classNames(styles['icon'], { collapsed: !isOpen })}
                width='42'
                height='42'
                viewBox='0 0 42 42'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <rect x='0.75' y='0.75' width='40.5' height='40.5' rx='20.25' fill='var(--bs-velvet-500)' />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M20.0812 21.9188L21.9188 21.9188L31.3949 21.9188L31.3949 20.0812L21.9188 20.0812L20.0812 20.0812L10.6051 20.0812L10.6051 21.9188L20.0812 21.9188ZM20.0812 21.9188L20.0812 20.0812L21.9188 20.0812L21.9188 21.9188L20.0812 21.9188Z'
                    fill='white'
                />
                <path
                    d='M20.0812 20.0812L20.0812 21.9188L21.9188 21.9188L21.9188 20.0812L20.0812 20.0812Z'
                    fill='white'
                />
                <rect x='0.75' y='0.75' width='40.5' height='40.5' rx='20.25' stroke='#725BFF' strokeWidth='1.5' />
            </svg>
        );
    } else {
        return (
            <svg
                className={classNames(styles['icon'], { collapsed: !isOpen })}
                width='42'
                height='42'
                viewBox='0 0 42 42'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <rect x='0.75' y='0.75' width='40.5' height='40.5' rx='20.25' fill='var(--bs-velvet-500)' />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M20.0812 21.9188L20.0812 31.3949L21.9188 31.3949L21.9188 21.9188L31.3949 21.9188L31.3949 20.0812L21.9188 20.0812L21.9188 10.6051L20.0812 10.6051L20.0812 20.0812L10.6051 20.0812L10.6051 21.9188L20.0812 21.9188ZM20.0812 21.9188L20.0812 20.0812L21.9188 20.0812L21.9188 21.9188L20.0812 21.9188Z'
                    fill='white'
                />
                <path
                    d='M20.0812 20.0812L20.0812 21.9188L21.9188 21.9188L21.9188 20.0812L20.0812 20.0812Z'
                    fill='white'
                />
                <rect x='0.75' y='0.75' width='40.5' height='40.5' rx='20.25' stroke='#725BFF' strokeWidth='1.5' />
            </svg>
        );
    }
}
