import React from 'react';

export default function ChevronLeft(props: object) {
    return (
        <svg width='11' height='20' viewBox='0 0 11 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M9.75761 20L11 18.7267L2.48478 10L11 1.27325L9.75761 -1.85304e-06L1.47023e-06 10L9.75761 20Z'
                fill='currentColor'
            />
        </svg>
    );
}
