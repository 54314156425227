import { FormPartProps } from '../types';
import { PropertyTypes } from '../constants';
import { Form } from 'react-bootstrap';
import { Button, TagSelect } from '@/components/common';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import Slider, { createSliderWithTooltip } from 'rc-slider';
import { isVisible } from '../util';
import { PropertyFeatures } from '@/components/property-features';
import React from 'react';

const LIVING_AREA_STEP = 50;
const ROOMS_TOTAL_STEP = 1;
const Range = createSliderWithTooltip(Slider.Range);

export function PropertyFeaturesForm(props: FormPartProps & { maxRooms?: number; maxLivingArea?: number }) {
    const {
        formId,
        t,
        navigateForward = () => ({}),
        fieldsToHide = [],
        defaultValues = {},
        maxRooms = 10,
        maxLivingArea = 1000,
    } = props || {};
    const hookForm = useForm({ mode: 'onChange', defaultValues });
    const {
        watch,
        control,
        handleSubmit,
        setValue,
        formState: { isValid },
    } = hookForm;

    const selectedPropertyType: Set<string> = !watch('propertyType')
        ? new Set()
        : Array.isArray(watch('propertyType'))
        ? new Set(watch('propertyType'))
        : new Set([watch('propertyType')]);

    const livingAreaMarks = Object.fromEntries(
        Array.from({ length: Math.max(Math.floor(maxLivingArea / LIVING_AREA_STEP), 0) }).map((v, idx) => {
            const value = (1 + idx) * LIVING_AREA_STEP;
            return [value.toString(), `${value}m²`];
        })
    );
    livingAreaMarks[`${maxLivingArea}`] = t('more', { ns: 'common' });

    const roomsTotalMarks = Object.fromEntries(
        Array.from({ length: Math.max(Math.floor(maxRooms / ROOMS_TOTAL_STEP) - 2, 0) }).map((v, idx) => {
            const value = ((2 + idx) * ROOMS_TOTAL_STEP).toString();
            return [value, value];
        })
    );
    roomsTotalMarks[`${maxRooms}`] = t('more', { ns: 'common' });

    return (
        <FormProvider {...hookForm}>
            <Form id={formId()} onSubmit={handleSubmit(navigateForward)} className='p-0'>
                <div className='mt-2 mb-3 d-grid w-100 '>
                    <div className='text-form'>{t('features.h_property_type_intro')}</div>
                    <TagSelect
                        name='propertyType'
                        defaultValues={defaultValues['propertyType']}
                        options={Object.keys(PropertyTypes).map(key => ({ key, label: t(key) }))}
                    />
                </div>

                {isVisible(fieldsToHide, 'propertyFeatures') && (
                    <div className='mb-3'>
                        <div className='text-form mb-2'>{t('features.other')}</div>
                        <PropertyFeatures
                            selectedOptions={watch('propertyFeatures')?.map(feature => {
                                return { key: feature, label: t(`features.${feature}`, { ns: 'common' }) };
                            })}
                            onAdd={option => {
                                setValue('propertyFeatures', [...(watch('propertyFeatures') || []), option.key]);
                            }}
                            onRemove={option => {
                                setValue(
                                    'propertyFeatures',
                                    watch('propertyFeatures').filter(v => v !== option.key)
                                );
                            }}
                        />
                    </div>
                )}

                {isVisible(fieldsToHide, 'livingArea') && (
                    <div className='mb-8 w-100'>
                        <div className='text-form mb-2'>{t('features.h_living_area')}</div>
                        <Controller
                            name='livingArea'
                            control={control}
                            defaultValue={[50, 90]}
                            render={({ field: { value, onChange, onBlur } }) => (
                                <Range
                                    className='pb-5'
                                    min={40}
                                    max={maxLivingArea}
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    marks={livingAreaMarks}
                                    tipFormatter={value => (value >= maxLivingArea ? 'größer' : `${value}m²`)}
                                    step={10}
                                    trackStyle={[{ backgroundColor: `var(--bs-velvet-700)` }]}
                                    handleStyle={[
                                        {
                                            backgroundColor: `var(--bs-velvet-500)`,
                                            borderColor: `var(--bs-velvet-500)`,
                                        },
                                    ]}
                                    activeDotStyle={{
                                        borderColor: `var(--bs-velvet-700)`,
                                        backgroundColor: `var(--bs-velvet-700)`,
                                    }}
                                />
                            )}
                        />
                    </div>
                )}

                {isVisible(fieldsToHide, 'roomsTotal') && (
                    <div className='mb-8 w-100'>
                        <div className='text-form mb-2'>{t('features.h_rooms_total')}</div>
                        <Controller
                            name='roomsTotal'
                            control={control}
                            defaultValue={[2, 3]}
                            render={({ field: { value, onChange, onBlur } }) => (
                                <Range
                                    min={1.5}
                                    max={maxRooms}
                                    className='pb-5'
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    marks={roomsTotalMarks}
                                    tipFormatter={value => (value >= maxRooms ? `mehr` : `${value}`)}
                                    step={0.5}
                                    trackStyle={[{ backgroundColor: `var(--bs-velvet-700)` }]}
                                    handleStyle={[
                                        {
                                            backgroundColor: `var(--bs-velvet-500)`,
                                            borderColor: `var(--bs-velvet-500)`,
                                        },
                                    ]}
                                    activeDotStyle={{
                                        borderColor: `var(--bs-velvet-700)`,
                                        backgroundColor: `var(--bs-velvet-700)`,
                                    }}
                                />
                            )}
                        />
                    </div>
                )}
                <div className='mt-5'>
                    <Button buttonType='submit' disabled={!isValid || selectedPropertyType.size <= 0}>
                        {t('btn_next')}
                    </Button>
                </div>
            </Form>
        </FormProvider>
    );
}
