import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { PropertySearchOrderBy } from '@/lib/api/bff';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { TFunction } from 'react-i18next';

import styles from '../styles.module.scss';

function OrderByComponent({
    selectedOrderOption,
    onOrderOptionClick,
}: {
    selectedOrderOption: PropertySearchOrderBy;
    onOrderOptionClick: (option: PropertySearchOrderBy) => void;
}) {
    const { t } = useTranslation('properties') as unknown as { t: TFunction<string> };

    return (
        <div className={'d-flex'}>
            <Dropdown className={styles['sorting-control']}>
                <Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components' className='dropdown-toggle'>
                    {`${t('order_by')} (${selectedOrderOption === 'bedrooms' ? t('rooms') : t(selectedOrderOption)})`}
                </Dropdown.Toggle>

                <Dropdown.Menu as={CustomMenu}>
                    {Object.keys(PropertySearchOrderBy).map(option => (
                        <Dropdown.Item
                            active={selectedOrderOption === option}
                            onClick={() => onOrderOptionClick(option as PropertySearchOrderBy)}
                            key={option}
                            eventKey={option}
                            className={styles['dropdown-item']}
                        >
                            {option === 'bedrooms' ? t('rooms') : t(option)}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}

interface ToggleProps {
    onClick: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

interface MenuProps {
    style: React.CSSProperties;
    className: string;
    'aria-labelledby': string;
}

const CustomToggle = React.forwardRef<HTMLAnchorElement, React.PropsWithChildren<ToggleProps>>(
    ({ children, onClick }, ref) => (
        <a
            href=''
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
        >
            <i className='icon icon-sort'></i>
            {'  '}
            {children}
        </a>
    )
);
CustomToggle.displayName = 'CustomToggle';

const CustomMenu = React.forwardRef<HTMLDivElement, React.PropsWithChildren<MenuProps>>(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
        return (
            <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
                <ul className={classNames('list-unstyled', styles['dropdown-items'])}>
                    {React.Children.toArray(children)}
                </ul>
            </div>
        );
    }
);
CustomMenu.displayName = 'CustomMenu';

export default OrderByComponent;
