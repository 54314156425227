import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { TFunction } from 'react-i18next';
import { Fire, EyeSlash } from 'react-bootstrap-icons';
import { Badge } from '@/components/design-system';

interface PropertyBadgeProps {
    type: BADGE_TYPES;
    className?: string;
    size?: 'xs' | 'sm';
}

export enum BADGE_TYPES {
    HOT = 'HOT',
    EXCLUSIVE = 'EXCLUSIVE',
    NORMAL = 'NORMAL',
    HIDDEN = 'HIDDEN',
    APPROVED_TO_PUBLISH = 'APPROVED_TO_PUBLISH',
}

export const PropertyBadge = ({ type, className, size = 'sm' }: PropertyBadgeProps): ReactElement | null => {
    const { t } = useTranslation(['properties']) as unknown as {
        t: TFunction<string>;
    };

    const badgeText = getBadgeText(type, t);
    const badgeIcon = getBadgeIcon(type);
    const bgColor = getBgColor(type);
    const showBadge = !!(badgeText && badgeIcon);

    return showBadge ? (
        <div className='design-system'>
            <Badge
                containerClassName={classNames(`bg-${bgColor}`)}
                textClassName={classNames(className, type === BADGE_TYPES.HIDDEN ? 'text-gray-300' : 'text-white')}
                size={size}
                data-cy='property-badge'
                text={badgeText}
                icon={badgeIcon}
                pill
            ></Badge>
        </div>
    ) : null;
};

function getBadgeText(badgeType: BADGE_TYPES, t: TFunction<string>) {
    switch (badgeType) {
        case BADGE_TYPES.HOT:
            return t('hot');
        case BADGE_TYPES.EXCLUSIVE:
            return t('exclusive');
        case BADGE_TYPES.HIDDEN:
            return t('non_visible');
        case BADGE_TYPES.APPROVED_TO_PUBLISH:
            return t('approved_to_publish');
        default:
            return null;
    }
}

function getBadgeIcon(badgeType: BADGE_TYPES) {
    switch (badgeType) {
        case BADGE_TYPES.HOT:
            return <Fire />;
        case BADGE_TYPES.EXCLUSIVE:
            return <i className='icon icon-thumbs-up-alt' />;
        case BADGE_TYPES.HIDDEN:
            return <EyeSlash />;
        case BADGE_TYPES.APPROVED_TO_PUBLISH:
            return <i className='icon icon-check-circle' />;
        default:
            return null;
    }
}

function getBgColor(badgeType: BADGE_TYPES) {
    switch (badgeType) {
        case BADGE_TYPES.HOT:
            return 'coral-600';
        case BADGE_TYPES.HIDDEN:
            return 'gray-100';
        case BADGE_TYPES.APPROVED_TO_PUBLISH:
            return 'coral-300';
        default:
            return 'velvet-500';
    }
}
