import NextAuth, { AuthOptions, CustomAuthUser } from 'next-auth';
import { createJwt, createUser, mapSession } from '@/lib/nextauth';
import CredentialsProvider from 'next-auth/providers/credentials';
import {
    Stage,
    authBaseUrl,
    cognitoClientId,
    cognitoClientSecret,
    initiateAuth,
    isLocal,
    stage,
    websiteBaseUrl,
} from '@/lib/env';
import moment from 'moment';
import api from '../../../lib/api/api';
import { issuer } from '@/lib/api/cognito';
import { Provider } from 'next-auth/providers/index';

const credentialProvider = CredentialsProvider({
    // The name to display on the sign in form (e.g. "Sign in with...")
    name: 'UserPool',
    // `credentials` is used to generate a form on the sign in page.
    // You can specify which fields should be submitted, by adding keys to the `credentials` object.
    // e.g. domain, username, password, 2FA token, etc.
    // You can pass any HTML attribute to the <input> tag through the object.
    credentials: {
        username: { label: 'Username', type: 'text', placeholder: 'jsmith' },
        password: { label: 'Password', type: 'password' },
    },
    async authorize(credentials): Promise<CustomAuthUser> {
        // Add logic here to look up the user from the credentials supplied
        const { user, idToken, refreshToken, accessToken, expiresIn } = await initiateAuth(
            credentials.username,
            credentials.password
        );

        const { account } = user.referrer
            ? { account: { referrer: user.referrer } }
            : await api().getAccountContext({ accountId: user.accountId });

        return {
            ...user,
            referrer: account?.referrer,
            data: {
                accessTokenExpiry: moment().add(expiresIn, 'seconds').valueOf(),
                idToken,
                accessToken,
                refreshToken,
            },
        };
    },
});

type TProvider = 'Microsoft' | 'Vobahome';

function getProvider(provider: TProvider): Provider {
    /*
       Provider generation function to avoid repeating ourselved when
       declaring providers in the authOptions below.
    */
    return {
        // e.g. cognito_google | cognito_facebook
        id: `cognito_${provider.toLowerCase()}`,

        // e.g. CognitoGoogle | CognitoFacebook
        name: `Cognito${provider}`,

        type: 'oauth',

        // The id of the app client configured in the user pool.
        clientId: cognitoClientId,

        // The app client secret.
        clientSecret: cognitoClientSecret,
        checks: 'nonce',

        wellKnown: `${issuer()}/.well-known/openid-configuration`,

        // Authorization endpoint configuration
        authorization: {
            url: `${authBaseUrl}/oauth2/authorize`,
            params: {
                response_type: 'code',
                client_id: cognitoClientId,
                identity_provider: provider.toLowerCase(),
                redirect_uri: `${websiteBaseUrl}/api/auth/callback/cognito_${provider.toLowerCase()}`,
            },
        },

        // Token endpoint configuration
        token: {
            url: `${authBaseUrl}/oauth2/token`,
            params: {
                grant_type: 'authorization_code',
                client_id: cognitoClientId,
                client_secret: cognitoClientSecret,
                redirect_uri: `${websiteBaseUrl}/api/auth/callback/cognito_${provider.toLowerCase()}`,
            },
        },

        // userInfo endpoint configuration
        userinfo: {
            url: `${authBaseUrl}/oauth2/userInfo`,
        },

        // Profile to return after successcul auth.
        // You can do some transformation on your profile object here.
        profile: createUser,
    };
}

export const authOptions: AuthOptions = {
    // Configure one or more authentication providers
    providers: [credentialProvider, ...['Vobahome'].map((p: TProvider) => getProvider(p))],
    useSecureCookies: !isLocal,
    session: { strategy: 'jwt', maxAge: 14 * 24 * 60 * 60 },
    callbacks: {
        jwt: createJwt,
        session: mapSession,
    },
    pages: {
        signIn: '/login',
        error: '/login',
    },
};
export default NextAuth(authOptions);
