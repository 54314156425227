import { notEmpty } from '@/lib/utilities';

export const extractCasafariImageUrl = (urls?: (string | null)[] | null): string => {
    if (!urls || urls.length < 1) return 'https://static.ownr.eu/img/placeholder/property.svg';

    return urls.filter(notEmpty)[0];
};

export const extractBaseLeasingRate = (leasingRatesStr: string | null): number | undefined => {
    if (!leasingRatesStr) {
        return;
    }

    try {
        const leasingRates = JSON.parse(leasingRatesStr);
        return leasingRates['48']?.max ?? leasingRates['48']?.base ?? undefined;
    } catch {
        return;
    }
};

export const getAddressHeader = property => {
    const address =
        'address' in property
            ? property.address
            : {
                  zip: property.postalCode,
                  city: property.city,
              };

    if (!address) {
        return '';
    }

    const { district, zip, city } = address;
    const zipLine = [zip, city].filter(Boolean).join(' ');
    return [district, zipLine].filter(Boolean).join(', ');
};
