import { TagsCloud } from '@/components/design-system/tags-cloud';
import React from 'react';
import { useTranslation } from 'next-i18next';
import { TFunction } from 'react-i18next';
import { PROPERTY_FEATURES } from '@/lib/constants';
import { getPropertyFeatureIconName } from './utils';

import BalconyIcon from './icons/balcony';
import ElevatorIcon from './icons/elevator';
import GardenIcon from './icons/garden';
import ParkingIcon from './icons/parking';
import TerraceIcon from './icons/terrace';
import { OptionItem } from '@/components/common';

const icons = {
    balcony: BalconyIcon,
    terrace: TerraceIcon,
    elevator: ElevatorIcon,
    garden: GardenIcon,
    parking: ParkingIcon,
};

export function PropertyFeatures(props: {
    selectedOptions?: OptionItem[];
    onAdd?: (value: OptionItem) => void;
    onRemove?: (value: OptionItem) => void;
}) {
    const { t } = useTranslation(['common']) as unknown as { t: TFunction<string> };
    return (
        <TagsCloud
            options={Object.keys(PROPERTY_FEATURES).map(a => {
                const key = PROPERTY_FEATURES[a];
                return {
                    key,
                    label: t(`features.${key}`),
                    Icon: icons[getPropertyFeatureIconName(key)],
                };
            })}
            {...props}
        />
    );
}
