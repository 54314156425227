import { TFunction } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { FieldErrors, FieldError, get, useFormContext } from 'react-hook-form';

export function ErrorText(props: { error: FieldError; t: TFunction; name: string; prefix?: string }) {
    const { error, t, name, prefix } = props;
    if (!error) return null;
    let key = prefix ? `${prefix}.${name}` : name;
    const fieldName = t(`${key}`, { defaultValue: t(`${key}`) });
    key = `${key}.${error.type}`;
    const defaultValue = t(`errors.default.${error.type}`, { fieldName });
    return <Form.Text className='invalid-feedback'>{t(key, { fieldName, defaultValue })}</Form.Text>;
}

export function ErrorMessage<TFieldErrors extends FieldErrors>(props: {
    errors: TFieldErrors;
    name: string;
    t: TFunction;
}) {
    const { errors, name } = props || {};
    const methods = useFormContext();
    const error = get(errors || methods.formState.errors, name);

    if (!error) return null;
    return <ErrorText error={error} name={name} t={props.t} />;
}
