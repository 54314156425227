import classNames from 'classnames';
import _Link, { LinkProps as _LinkProps } from 'next/link';
import React, { ReactNode } from 'react';

type AnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement>;

type LinkProps = _LinkProps & {
    children?: ReactNode | undefined;
    className?: string | undefined;
} & AnchorProps;

export const Link = ({ children, className, href, ...rest }: LinkProps) => {
    const isExternal = href && (href.startsWith('http://') || href.startsWith('https://'));

    if (isExternal) {
        return (
            <a
                className={classNames(className, 'text-decoration-none', 'design-system')}
                href={href}
                target='_blank'
                rel='noopener noreferrer'
                {...rest}
            >
                {children}
            </a>
        );
    }

    return (
        <_Link className={classNames(className, 'text-decoration-none', 'design-system')} href={href} {...rest}>
            {children}
        </_Link>
    );
};
