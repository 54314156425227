import { Button as Record } from '@/lib/api/graphcms';
import { Button as CommonButton } from '@/components/common';
import { GlyphName } from '@/components/common/lib/glyph/types';

export type ButtonProps = Partial<Record> & { className?: string };

const ICON_RENAME = new Map<string, string>([
    ['forward_arrow', 'arrow-right'],
    ['back_arrow', 'arrow-left'],
    ['angle_right', 'chevron-right'],
    ['angle_left', 'chevron-left'],
]);

export function Button(props: ButtonProps = {}) {
    const { icon, ...other } = props;

    const gylph: undefined | GlyphName =
        icon && ICON_RENAME.has(icon)
            ? (ICON_RENAME.get(icon) as GlyphName)
            : (icon as unknown as GlyphName | undefined);

    return <CommonButton glyph={gylph} {...other} />;
}
