import {
    PropertyOrderOptions,
    PropertySearchOrderBy,
    PropertyType,
    SearchFilter,
    SupportedRegions,
} from '@/lib/api/bff';
import { GroupedOptionsArray } from '@/components/search';
import { GroupBase, StylesConfig } from 'react-select';
import { TFunction } from 'react-i18next';
import { getDefaultLocation, parameterAsObject, parameterAsString, serializedParameter } from '@/lib/utilities';
import { FormPropertyType, FormValuesType, LocationType, SearchPageFilterType, SearchQueryParams } from './types';
import { NextRouter } from 'next/router';
import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { SEARCH_LIMIT } from './constants';
import { OffMarketPropertiesFilter, SearchPropertyFilter } from '@/lib/api/properties/types';
import { ParsedUrlQueryInput } from 'node:querystring';

const LOCATION_TYPES_ORDER: [string, RegExp[]][] = [
    ['Bundesland', [/^bundesland$/gis]],
    ['Kreis', [/^kreis$/gis]],
    ['Stadt', [/^(kreisfreie )stadt$/gis]],
    ['Gemeinde', [/^gemeinde$/gis]],
    ['Bezirk', [/^bezirk$/gis]],
    ['Stadtteil', [/^stadtteil$/gis]],
    ['Ortsteil', [/^ortsteil$/gis]],
    ['top', [/^top$/gis]],
];
export function getOptions(
    locations: Record<string, [{ name: string; locationId: number; subLocationIds?: string[]; zipCodes: string[] }]>,
    t: TFunction
): GroupedOptionsArray | undefined {
    if (!locations) {
        return undefined;
    }

    const entries = Object.entries(locations);

    return LOCATION_TYPES_ORDER.reduce((opts, [type, regexes]) => {
        const locs = entries.filter(([key]) => regexes.some(regex => regex.test(key))).flatMap(([, value]) => value);
        const label = type !== 'top' ? t(type, { ns: 'properties' }) : t('PLZ', { ns: 'properties' });
        const options = locs.map(location => ({
            label: location?.name,
            value: location?.locationId,
            subLocationIds: location?.subLocationIds,
            zipCodes: location?.zipCodes,
        }));

        return [...opts, { label, options }];
    }, [] as GroupedOptionsArray);
}

export function getOptionsV2(
    locations: Record<string, [{ name: string; locationId: number; subLocationIds?: string[]; zipCodes: string[] }]>,
    t: TFunction
): GroupedOptionsArray | undefined {
    if (!locations) {
        return undefined;
    }

    const locationsTypeOrder = ['Bundesland', 'Bezirk', 'Stadtteil', 'Gemeinde', 'top', 'Ortsteil', 'Kreis'];

    const options: GroupedOptionsArray = [];

    for (let i = 0; i < locationsTypeOrder.length; i++) {
        const locationType = locationsTypeOrder[i];

        if (locations[locationType] && locations[locationType].length > 0) {
            options.push({
                label: locationType !== 'top' ? t(locationType, { ns: 'properties' }) : t('PLZ', { ns: 'properties' }),
                options: locations[locationType].map(location => ({
                    label: location?.name,
                    value: location?.locationId,
                    subLocationIds: location?.subLocationIds,
                    zipCodes: location?.zipCodes,
                    locationType,
                })),
            });
        }
    }

    return options;
}

export function getLocationsIds(
    region: SupportedRegions,
    locationsOptions: { label: string; value: number }[] | null
): number[] {
    if (!locationsOptions || locationsOptions.length === 0) {
        return [getDefaultLocation(region).id];
    }
    return locationsOptions?.map(({ value }: { value: number }) => value);
}

export function getSelectStyles(hasError?: boolean) {
    const selectStyles: StylesConfig<
        {
            label: string;
            value: number;
        },
        true,
        GroupBase<{
            label: string;
            value: number;
        }>
    > = {
        placeholder: defaultStyles => {
            return {
                ...defaultStyles,
                color: '#725bff',
            };
        },
        dropdownIndicator: defaultStyles => ({
            ...defaultStyles,
            color: '#725bff',
        }),
        indicatorSeparator: defaultStyles => ({
            ...defaultStyles,
            color: '#725bff',
            borderColor: '#725bff',
        }),
        control: defaultStyles => ({
            ...defaultStyles,
            '&:hover': {
                border: !hasError ? '1.5px solid #725bff' : '1.5px solid #dc3545',
            },
            border: !hasError ? '1.5px solid #725bff' : '1.5px solid #dc3545',
            borderRadius: '12px',
            fontFamily: 'Inter, sans-serif',
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: 1,
            padding: '8px 4px 4px 4px',
        }),
        multiValueLabel: defaultStyles => ({
            ...defaultStyles,
            color: '#5644bf',
            backgroundColor: '#e3deff',
            fontFamily: 'Inter, sans-serif',
            fontSize: '1rem',
            fontWeight: 400,
        }),
        multiValueRemove: defaultStyles => ({
            ...defaultStyles,
            color: '#5644bf',
            backgroundColor: '#e3deff',
            '&:hover': {
                color: '#5644bf',
                backgroundColor: '#c7bdff',
            },
        }),
        clearIndicator: defaultStyles => ({
            ...defaultStyles,
            color: '#5644bf',
            '&:hover': {
                color: '#5644bf',
            },
        }),
        loadingIndicator: defaultStyles => ({
            ...defaultStyles,
            color: '#5644bf',
            '&:hover': {
                color: '#5644bf',
            },
        }),
    };

    return selectStyles;
}

export function asQuery(fields: FieldValues): ParsedUrlQueryInput {
    const { propertyType, region, locationsIds, minRooms, minLeaseRate, maxLeaseRate, minSpace, orderBy, offset } =
        fields;

    const query: ParsedUrlQueryInput = {};
    if (propertyType) query.propertyType = propertyType;
    if (region) query.region = region;
    const serializedLocationsIds = serializedParameter(locationsIds);
    if (serializedLocationsIds) query.locationsIds = serializedLocationsIds;
    if (minRooms && minRooms > 0) query.minRooms = minRooms;
    if (minLeaseRate) query.minLeaseRate = minLeaseRate;
    if (maxLeaseRate) query.maxLeaseRate = maxLeaseRate;
    if (minSpace) query.minSpace = minSpace.toString();
    if (orderBy && orderBy !== 'DEFAULT') query.orderBy = orderBy;
    if (offset && offset !== 0) query.offset = offset.toString();
    return query;
}

export function pointerToOffset(pagination: never) {
    const pointerString = Buffer.from(pagination, 'base64').toString('utf-8');
    const pointerObject = JSON.parse(pointerString);
    return pointerObject.offset;
}

export function areSearchQueryParamsEmpty(router: NextRouter) {
    const searchQueryParamsNames = Object.values(SearchQueryParams);
    let areSearchQueryParamsEmpty = true;
    for (let i = 0; i < searchQueryParamsNames.length; i++) {
        const param = searchQueryParamsNames[i];
        const paramAsString = parameterAsString(router, param);
        if (paramAsString) {
            areSearchQueryParamsEmpty = false;
            break;
        }
    }

    return areSearchQueryParamsEmpty;
}

export function getDefaultSearch(router: NextRouter) {
    const defaultValues: FieldValues = {
        propertyType: parameterAsString(router, 'propertyType') ?? PropertyType.APARTMENT,
        region: parameterAsString(router, 'region')?.toUpperCase() ?? SupportedRegions.BERLIN,
        locationsIds: parameterAsObject<LocationType[]>(router, 'locationsIds') ?? null,
        minRooms: parseInt(parameterAsString(router, 'minRooms') || '0') ?? 0,
        minSpace: parseInt(parameterAsString(router, 'minSpace') || '1') ?? 1,
        minLeaseRate: parameterAsString(router, 'minLeaseRate') ?? '',
        maxLeaseRate: parameterAsString(router, 'maxLeaseRate') ?? '',
        limit: SEARCH_LIMIT,
        offset: 0,
        orderBy: parameterAsString(router, 'orderBy') ?? PropertySearchOrderBy.time_on_market,
    };

    return defaultValues;
}

export const getPropertyType = (propertyType: FormPropertyType): PropertyType[] => {
    switch (propertyType) {
        case FormPropertyType.APARTMENT:
            return [PropertyType.APARTMENT];
        case FormPropertyType.HOUSE:
            return [PropertyType.HOUSE];
        case FormPropertyType.ALL:
        default:
            return [PropertyType.APARTMENT, PropertyType.HOUSE];
    }
};

const getFormPropertyType = (propertyType: PropertyType[]): FormPropertyType => {
    if (propertyType.length === 1) {
        switch (propertyType[0]) {
            case PropertyType.APARTMENT:
                return FormPropertyType.APARTMENT;
            case PropertyType.HOUSE:
                return FormPropertyType.HOUSE;
        }
    }

    return FormPropertyType.ALL;
};

export function getSearchParamsFromFields(fields: FieldValues): NonNullable<SearchPageFilterType> {
    return {
        region: fields.region,
        propertyType: getPropertyType(fields.propertyType),
        locationsIds: getLocationsIds(fields.region, fields.locationsIds),
        minLeaseRate: fields.minLeaseRate === '' ? null : parseFloat(fields.minLeaseRate),
        maxLeaseRate: fields.maxLeaseRate === '' ? null : parseFloat(fields.maxLeaseRate),
        maxPrice: null,
        minSpace: fields.minSpace && Number.isFinite(+fields.minSpace) ? +fields.minSpace : 10,
        maxSpace: 3000,
        minRooms: fields.minRooms && Number.isFinite(+fields.minRooms) ? fields.minRooms : 1,
        maxRooms: 20,
        orderBy: fields.orderBy as PropertySearchOrderBy,
        limit: SEARCH_LIMIT,
        offset: fields.offset,
        order: PropertyOrderOptions.asc,
    };
}

export function setSearchFields(
    setValue: UseFormSetValue<FormValuesType>,
    savedSearch: Omit<SearchFilter, 'maxPrice'>
) {
    setValue(
        'propertyType',
        getFormPropertyType((savedSearch.propertyType as PropertyType[]) || [PropertyType.APARTMENT])
    );
    if (savedSearch.minLeaseRate) setValue('minLeaseRate', savedSearch.minLeaseRate + '');
    if (savedSearch.maxLeaseRate) setValue('maxLeaseRate', savedSearch.maxLeaseRate + '');
    setValue('minSpace', savedSearch.minSpace || 10);
    setValue('minRooms', savedSearch.minRooms || 1);
    setValue('region', savedSearch?.region as SupportedRegions);
    setValue(
        'locationsIds',
        savedSearch.locations?.map((location: { name: string; id: number }) => ({
            label: location.name,
            value: location.id,
        })) ?? null
    );
}

export const mapSearchFilterToOffMarketFilter = (searchParams: SearchPageFilterType, accountId: string | undefined) => {
    const offMarketFilter: OffMarketPropertiesFilter | SearchPropertyFilter = {
        region: searchParams?.region ?? SupportedRegions.BERLIN,
        accountId,
        propertyType: searchParams?.propertyType ?? [],
        maxLeaseRate: searchParams?.maxLeaseRate ?? undefined,
        minLeaseRate: searchParams?.minLeaseRate ?? undefined,
        minRooms: searchParams?.minRooms ? +searchParams?.minRooms : undefined,
        minSpace: searchParams?.minSpace ?? undefined,
        locationsIds:
            searchParams?.locationsIds && searchParams?.locationsIds.length > 0
                ? searchParams?.locationsIds
                : undefined,
        order: 'asc',
        orderBy: searchParams.orderBy ?? undefined,
    };

    return offMarketFilter;
};
