import React from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';
import styles from '../styles.module.scss';
import { gtm } from '@/lib/utilities';
import { useSession } from 'next-auth/react';
import { checkIsBroker } from '@/lib/security';

export const BackButton = ({ toSearchPage = false, className }: { toSearchPage?: boolean; className?: string }) => {
    const router = useRouter();

    const { data: session } = useSession();

    const handleClick = () => {
        gtm.event(gtm.EVENTS.PROPERTY_BACK_BUTTON_CLICKED, { category: gtm.CATEGORIES.PROPERTY });

        const isBroker = checkIsBroker(session);

        if (isBroker) {
            router.push('/my/properties');
            return;
        }

        if (toSearchPage) {
            router.push('/searches');
        } else {
            router.back();
        }
    };

    return (
        <div className={classnames('container', className, styles.header, styles.backButton)}>
            <a
                role='button'
                className={classnames('p-0 text-decoration-none', 'text-interaction')}
                onClick={handleClick}
            >
                <i className='icon icon-backward-arrow ' />
            </a>
        </div>
    );
};
