import { RichText } from '@graphcms/rich-text-react-renderer';
import { TextAndImage as Record, TextAndImageItem } from '@/lib/api/graphcms';
import { Container } from 'react-bootstrap';
import Collapse from 'react-bootstrap/Collapse';
import GraphCmsImage from '../../GraphCmsImage';
import React, { useState } from 'react';
import styles from './TextAndImage.module.scss';
import classnames from 'classnames';
import { Link } from '../../../../navigation';

export type TextAndImage = Partial<Record>;

export function TextAndImage(props: TextAndImage) {
    const { id, textAndImageItems = [], startLeftSide, moreButtonTarget: defaultMoreButtonTarget } = props || {};

    if (textAndImageItems?.length === 0) return null;

    const side = startLeftSide ? 0 : 1;

    return (
        <Container key={id}>
            <ol>
                {textAndImageItems.map((item, idx) => (
                    <TextAndImageItemsComponent
                        defaultMoreButtonTarget={defaultMoreButtonTarget}
                        key={item.id}
                        {...item}
                        side={(idx + side) % 2}
                    />
                ))}
            </ol>
        </Container>
    );
}

const TextAndImageItemsComponent = ({
    lowerContent,
    upperContent,
    header,
    image,
    side,
    moreButtonTarget,
    defaultMoreButtonTarget,
}: TextAndImageItem & { side: number; defaultMoreButtonTarget?: { slug?: string; __typename?: string } | null }) => {
    const [expandLowerContent, setExpandLowerContent] = useState(false);
    const hasPageLink = !!(defaultMoreButtonTarget || moreButtonTarget);

    return (
        <div>
            <div className={styles.contentContainer}>
                <div className={classnames(styles.textContainer, side && styles.orderOne)}>
                    <li className={styles.listStyle}>
                        {header && <h2 className={'tiny-header'}>{header}</h2>}
                        <div className={styles.contentText}>
                            {upperContent?.raw && <RichText content={upperContent.raw} />}
                        </div>
                        {hasPageLink && (
                            <Link target={moreButtonTarget || defaultMoreButtonTarget}>
                                <span className={classnames(styles.moreSpan)}>{'more...'}</span>
                            </Link>
                        )}
                        {lowerContent && !hasPageLink && (
                            <div>
                                <span
                                    className={classnames(styles.moreSpan, expandLowerContent && styles.expanded)}
                                    onClick={() => !expandLowerContent && setExpandLowerContent(!expandLowerContent)}
                                >
                                    {'more...'}
                                </span>
                            </div>
                        )}
                    </li>
                </div>
                <div className={classnames(styles.imageContainer)}>
                    <GraphCmsImage
                        className={styles.svgImg}
                        image={image}
                        style={{ maxHeight: 'clamp(300px, 70vh, 420px)', minHeight: '220px', height: '100%' }}
                        withParallax
                    />
                </div>
            </div>
            {lowerContent && (
                <Collapse in={expandLowerContent}>
                    <div>
                        <div>
                            <RichText content={lowerContent?.raw} />
                        </div>
                        <div>
                            <span
                                className={styles.moreSpan}
                                onClick={() => setExpandLowerContent(!expandLowerContent)}
                            >
                                {'less...'}
                            </span>
                        </div>
                    </div>
                </Collapse>
            )}
        </div>
    );
};
