import React from 'react';

export default function Hide(props: object) {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M24 12C24 12 19.6364 3 12 3C4.36364 3 0 12 0 12C0 12 4.36364 21 12 21C19.6364 21 24 12 24 12ZM2.49617 11.6553C2.41971 11.7774 2.34936 11.8926 2.28518 12C2.34936 12.1074 2.41971 12.2226 2.49617 12.3447C2.97961 13.1167 3.69366 14.1398 4.61624 15.1547C6.50254 17.2296 9.00527 19 12 19C14.9947 19 17.4975 17.2296 19.3838 15.1547C20.3063 14.1398 21.0204 13.1167 21.5038 12.3447C21.5803 12.2226 21.6506 12.1074 21.7148 12C21.6506 11.8926 21.5803 11.7774 21.5038 11.6553C21.0204 10.8833 20.3063 9.86018 19.3838 8.84535C17.4975 6.77043 14.9947 5 12 5C9.00527 5 6.50254 6.77043 4.61624 8.84535C3.69366 9.86018 2.97961 10.8833 2.49617 11.6553ZM22.202 12.8758C22.2022 12.8763 22.2022 12.8763 22.2019 12.8758L22.202 12.8758ZM22.2019 11.1242C22.2022 11.1237 22.2022 11.1237 22.202 11.1242L22.2019 11.1242Z'
                fill='currentColor'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10ZM8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12Z'
                fill='currentColor'
            />
            <rect
                x='1.11328'
                y='2.39868'
                width='1.995'
                height='28.9672'
                transform='rotate(-45 1.11328 2.39868)'
                fill='currentColor'
            />
        </svg>
    );
}
