import React from 'react';
import { Video as Record } from '@/lib/api/graphcms';
import { Container } from 'react-bootstrap';
import { Video as VideoComponent } from '@/components/design-system';
import spacingStyles from './Spacings.module.scss';

export type VideoProps = Partial<Record> & Partial<Pick<React.HTMLAttributes<HTMLDivElement>, 'className'>>;

export function Video(props: VideoProps = {}) {
    const { url, type, videoPreviewImage, videoheaderStyle, localizations, title } = props;
    const previewImageUrl = videoPreviewImage?.url || (localizations && localizations[0]?.videoPreviewImage?.url);

    if (!url) return null;

    return (
        <Container className={spacingStyles.videoSpacing}>
            <h2 className={videoheaderStyle}>{title}</h2>
            <VideoComponent url={url} mimeType={type} previewImageUrl={previewImageUrl} title={title} />
        </Container>
    );
}
