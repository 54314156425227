import { FormPartProps } from '../types';
import { Alert, Form, Row } from 'react-bootstrap';
import { Button } from '@/components/common';
import { isMandatory, OptionalMarker } from '../util';
import { useForm, ValidateResult } from 'react-hook-form';
import isURL from 'validator/lib/isURL';
import classNames from 'classnames';
import styles from '../registration.module.scss';
import React from 'react';
import { ExclamationCircleFill } from 'react-bootstrap-icons';

export function CityForm(props: FormPartProps) {
    const {
        defaultValues,
        formId,
        t,
        mandatoryFields = [],
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        navigateForward = () => {},
        activeRegions = [],
    } = props || {};
    const {
        handleSubmit,
        register,
        formState: { isValid },
        watch,
        resetField,
    } = useForm({ mode: 'onChange', defaultValues });
    const selectedRegion = watch('region') ?? null;
    const particularProperty: boolean = watch('particularProperty') ?? false;
    const urlValidation = async (url: string): Promise<ValidateResult> => {
        if (!url) return true;

        return isURL(url);
    };
    return (
        <Form id={formId()} onSubmit={handleSubmit(navigateForward)} className='p-0'>
            <div className='p-2 border border-1 border-velvet' style={{ borderRadius: '16px' }}>
                <Form.Group controlId={formId('region_group')} className='mb-3 w-100 p-2 mt-2'>
                    <Form.Label>
                        <h3>{t('city.searching_in')}</h3>
                        <OptionalMarker t={t} mandatoryFields={mandatoryFields} name='region' />
                    </Form.Label>
                    {activeRegions.map(region => {
                        return (
                            <div className='form-check' key={region}>
                                <input
                                    className='form-check-input'
                                    type='radio'
                                    value={region}
                                    id={formId(region)}
                                    {...register('region', { required: isMandatory(mandatoryFields, 'region') })}
                                />
                                <label className='form-check-label' htmlFor={formId(region)}>
                                    {t(`region.${region.toLowerCase()}`)}
                                </label>
                            </div>
                        );
                    })}
                    {selectedRegion && (
                        <Alert className={classNames('container p-3 mt-4', styles['alert'])}>
                            <div className='flex justify-content-start mb-2'>
                                <ExclamationCircleFill className={styles['alert__icon']} />
                            </div>
                            <Row className='px-4'>{t('city.url_explain')}</Row>
                        </Alert>
                    )}
                </Form.Group>
            </div>
            <div
                className={classNames('mt-4 border border-1', selectedRegion ? 'border-velvet' : 'border-gray-200')}
                style={{ borderRadius: '16px' }}
            >
                <Form.Group controlId={formId('url_group')} className='mb-3 w-100 my-3'>
                    <div className='row  pe-1'>
                        <div className='col mx-4'>
                            <Form.Check
                                type='checkbox'
                                label={t(
                                    selectedRegion ? 'city.particular_property_city' : 'city.particular_property',
                                    {
                                        city: t(`region.${selectedRegion?.toLowerCase()}`),
                                    }
                                )}
                                disabled={!selectedRegion}
                                {...register('particularProperty', {
                                    required: false,
                                    onChange: e => {
                                        if (!e.target.checked) {
                                            resetField('propertyURL');
                                        }
                                    },
                                })}
                            />
                        </div>
                    </div>

                    {particularProperty && (
                        <div className='p-2'>
                            <Form.Control
                                type='text'
                                placeholder={t('city.url')}
                                className={classNames(
                                    'mt-4 bg-white',
                                    particularProperty ? 'border-velvet-500' : 'text-gray-200 border-gray-200'
                                )}
                                disabled={!particularProperty}
                                {...register('propertyURL', {
                                    required: particularProperty,
                                    validate: particularProperty
                                        ? {
                                              checkURL: urlValidation,
                                          }
                                        : {},
                                })}
                            />
                        </div>
                    )}
                </Form.Group>
            </div>

            <div className='mt-5'>
                <Button buttonType='submit' disabled={!isValid}>
                    {t('btn_next')}
                </Button>
            </div>
        </Form>
    );
}
