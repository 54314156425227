import Head from 'next/head';
import dynamic from 'next/dynamic';
import React, { Suspense, lazy } from 'react';
import { LocalizedPages, NavigationItem } from '../../navigation';
import { Header as HeaderType, PartnersLogoType } from '@/lib/api/graphcms';
import { NextSeoProps } from 'next-seo';
import Seo, { SeoProps } from './seo';
import { FooterProps, Footer } from './footer';
import { User } from 'next-auth';
import { Loader } from '@/components/common';

export type PageLayoutProps = {
    title: string;
    slug: string;
    description?: string;
    navigationItems: NavigationItem[];
    partnersNavigationItems?: NavigationItem[];
    partnersLogo?: PartnersLogoType;
    localizations?: LocalizedPages;
    children?: React.ReactNode | React.ReactNode[];
    preview?: boolean;
    locale: string;
    header?: HeaderType;
    absoluteUrl: (path: string) => string;
    seo?: (NextSeoProps & SeoProps) | null;
    impersonatedUser?: User;
    footer?: FooterProps;
};

const DynamicHeader = lazy(() => import('./header'));

export function PageLayout(props: PageLayoutProps) {
    const {
        title,
        slug,
        description,
        children = [],
        preview = false,
        locale,
        navigationItems,
        partnersNavigationItems,
        partnersLogo,
        localizations,
        header,
        footer,
        absoluteUrl,
        seo,
        impersonatedUser,
    } = props || {};
    return (
        <Suspense fallback={<Loader />}>
            <Head>
                <title>{title}</title>
                <meta name='description' content={description || title} />
            </Head>
            <Seo absoluteUrl={absoluteUrl} {...seo} />
            <DynamicHeader
                preview={preview}
                locale={locale}
                navigationItems={navigationItems}
                partnersNavigationItems={partnersNavigationItems}
                localizations={localizations}
                partnersLogo={partnersLogo}
                absoluteUrl={absoluteUrl}
                hero={header?.hero}
                impersonatedUser={impersonatedUser}
            />
            <main id='content'>{children}</main>
            <Footer {...footer} partnersLogo={partnersLogo} slug={slug} localizations={localizations} />
        </Suspense>
    );
}
