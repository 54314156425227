import { TFunction } from 'react-i18next';
import { Session } from 'next-auth';
import { checkIsBroker, hasRole, UserRoles } from '@/lib/security';
import { Page, pageFragmentFragment, Partners } from '@/lib/api/graphcms';
import { LocalizedPages, NavigationItem } from '@/components/navigation/components';
import { Link } from './components/types';
import { NextRouter } from 'next/router';

export const getPrivateLinks = (session: Session, t: TFunction): Link[] => {
    if (!session) return [];

    const links = [{ href: '/account/update', text: t('navigation.profile') }];

    const isBroker = checkIsBroker(session);

    if (isBroker) {
        links.push({ href: '/my/properties', text: t('navigation.uploaded_properties') });
    } else {
        links.push({ href: '/my/lease-requests', text: t('navigation.lease_requests') });
        links.push({ href: '/my/properties', text: t('navigation.uploaded_properties') });
        links.push({ href: '/documents', text: t('navigation.documents') });
    }

    if (hasRole(session, UserRoles.Impersonator)) {
        links.push({ href: '/users', text: t('navigation.users') });
        links.push({ href: '/properties', text: t('navigation.properties') });
    }

    return links;
};

export const getPublicLinks = (
    navigationItems: NavigationItem[],
    session?: Session,
    partner?: Partners,
    isBroker?: boolean
): Link[] => {
    return navigationItems.map(ni => ({
        href:
            ni.external ??
            `/${partner && !isBroker ? `partners/${partner.toLowerCase()}/` : ''}${getHrefBasedOnSession(ni, session)}`,
        text: ni.title,
    }));
};

export const isCurrentPage = (router: NextRouter, href: string): boolean => {
    if (!router || !href) return false;
    const { asPath } = router;
    return asPath.includes('?')
        ? router.pathname.toLowerCase() === href.toLowerCase()
        : asPath.toLowerCase() === href.toLowerCase();
};

export function normalizeSlug(slug: string | undefined | null): string {
    if (!slug?.length) return '';
    switch (slug) {
        case '_homepage':
            return '';
        case '_account_create':
            return 'account/create';
        case '_broker_account_create':
            return 'broker/create';
        case '_account_update':
            return 'account/update';
        case '_account_confirm_failed':
            return 'account/confirm/failed';
        case '_account_confirm_success':
            return 'account/confirm/success';
        case 'account-create-vobahome':
            return 'account/create';
        default:
            break;
    }

    return slug;
}

export function targetLinkComponents(slug?: string | null, prefix?: string): [string, string] {
    return [prefix || '', normalizeSlug(slug)];
}

export function targetUrl(
    target: {
        __typename?: string | null;
        slug?: string | null;
    },
    partnerUrlPath?: string
): string {
    const { slug: s } = target || {};

    const removeLeadingSlash = (s: string) => s.replace(/^\/+/, '');
    let [prefix, slug] = targetLinkComponents(s, partnerUrlPath);
    prefix = removeLeadingSlash(prefix);
    slug = removeLeadingSlash(slug);
    return `/${!prefix?.length ? '' : `${prefix}/`}${slug}`;
}

export function getPartner(session: Session, url: string) {
    return getPartnerFromSession(session) ?? getPartnerNameByUrl(url);
}

export function getPartnerFromSession(session: Session): Partners | undefined {
    return hasRole(session, UserRoles.Vobahome) ? Partners.VOBAHOME : undefined;
}

export function getPartnerNameByUrl(url: string): Partners | undefined {
    const partnerValue = Object.values(Partners)
        .filter(p => p !== Partners.OWNR)
        .find(partner => url.toLowerCase().includes(`/${partner.toLowerCase()}/`));
    return partnerValue ? Partners[partnerValue] : undefined;
}

export function getPartnerPathFrom(url: string): string | undefined {
    const partner = getPartnerNameByUrl(url);
    if (!partner) {
        return undefined;
    }
    return `partners/${partner.toLowerCase()}`;
}

export function getHomepageUrl(partner?: Partners | undefined) {
    return partner ? `/partners/${partner.toLowerCase()}/${partner.toLowerCase()}` : '/';
}

export const createNavigationItems = async (pageData: {
    pages: Page[];
    navigationItem: { subNavigation: NavigationItem[] };
}): Promise<NavigationItem[]> => {
    // const [page] = pageData.pages;
    // if (page.navigation) {
    //     publicNavigationItems = page.navigation.subNavigation as NavigationItem[];
    // }

    return pageData.navigationItem.subNavigation;
};

export const getPartnerNavigationItems = (
    partner: Partners,
    partnersNavigationItems: NavigationItem[]
): NavigationItem[] => {
    if (!partnersNavigationItems) {
        return null;
    }
    const partnerNavigation = partnersNavigationItems.find(({ partners }) => partners?.includes(partner));
    return partnerNavigation?.subNavigation;
};

export const getDefaultPageAfterLogin = (session: Session): string => {
    if (!session) return '/';
    const isBroker = checkIsBroker(session);
    if (isBroker) {
        return 'my/properties';
    }
    return 'my/lease-requests';
};

export function localizedPages(page: pageFragmentFragment | null): LocalizedPages {
    if (!page) return {};
    const locs = page.localizations || [];
    const i: LocalizedPages = {};
    i[page.locale] = '/' + normalizeSlug(page.slug);

    return locs.reduce((lps, { locale, slug }) => {
        lps[locale.toString()] = '/' + normalizeSlug(slug);
        return lps;
    }, i);
}

const getHrefBasedOnSession = (item: NavigationItem, session?: Session) => {
    if (item.publicPlaceholder && !session?.user) return removeLeadingSlash(normalizeSlug(item.publicPlaceholder.slug));
    else if (item && item.target) return removeLeadingSlash(normalizeSlug(item.target.slug));
};

const removeLeadingSlash = (s: string) => s.replace(/^\/+/, '');
