import classNames from 'classnames';
import { Container } from 'react-bootstrap';
import { Calendar4, ClockHistory, Person } from 'react-bootstrap-icons';
import moment from 'moment';
import React, { useState } from 'react';
import { AppointmentSlot, Attendee } from '@/lib/api/lease-requests/types';
import { getLocalDate } from '@/lib/utilities/lib/datetime';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { TFunction } from 'react-i18next';
import { AttendeesModal } from '../AttendeesModal';
import styles from '../../styles.module.scss';
import { Badge, Button } from '@/components/design-system';
import Dropdown from '@/components/design-system/dropdown';
import { gtm } from '@/lib/utilities';
import { useCancelSlot } from '@/components/slots/hooks/useCancelSlot';
import { useHideSlot } from '@/components/slots/hooks/useHideSlot';
import { toast } from 'react-toastify';
import { TOAST_OPTIONS } from '@/styles/toasts';

export const Slot = ({
    slot,
    attendees,
    propertyId,
    isPast,
}: {
    slot: AppointmentSlot;
    attendees: Attendee[];
    propertyId: string;
    isPast: boolean;
}) => {
    const { t } = useTranslation(['metasearch']) as unknown as { t: TFunction<string> };
    const { id, status } = slot;
    const [isLoading, setIsLoading] = useState(false);
    const [isAttendeesModalShown, setIsAttendeesModalShown] = useState(false);
    const [cancellationWarningIsShown, setCancellationWarningIsShown] = useState(false);
    const { cancelSlot } = useCancelSlot();
    const { hideSlot } = useHideSlot();

    const interactive = slot.status !== 'CANCELED' && attendees.length && !cancellationWarningIsShown;

    const bgColor = cancellationWarningIsShown
        ? 'bg-white border-1 border-coral-500'
        : status === 'CANCELED'
        ? 'bg-white border-gray-200'
        : status === 'HIDDEN'
        ? 'bg-velvet-50 border-velvet-200'
        : 'bg-velvet-100 border-velvet-500';

    const onHideClick = async () => {
        setIsLoading(true);

        gtm.event(gtm.EVENTS.HIDE_APPOINTMENT_SLOT_CLICKED, { category: gtm.CATEGORIES.APPOINTMENT_SLOT });

        const response = await hideSlot(slot.id, propertyId);

        if (!response || response.error) {
            setIsLoading(false);
            toast.error(t('slot_form.something_went_wrong'), TOAST_OPTIONS);
            return;
        }

        setIsLoading(false);

        toast.success(t('slot_form.slot_has_been_updated'), TOAST_OPTIONS);
    };

    const handleCancel = async () => {
        setIsLoading(true);

        gtm.event(gtm.EVENTS.CANCEL_APPOINTMENT_SLOT_CLICKED, { category: gtm.CATEGORIES.APPOINTMENT_SLOT });

        const response = await cancelSlot(slot.id, propertyId);

        if (!response || response.error) {
            setIsLoading(false);
            toast.error(t('slot_form.something_went_wrong'), TOAST_OPTIONS);
            return;
        }

        setIsLoading(false);

        toast.success(t('slot_form.slot_has_been_updated'), TOAST_OPTIONS);
    };

    const onCancelClick = () => {
        if (attendees.length) setCancellationWarningIsShown(true);
        else handleCancel();
    };

    const showDetailsModal = () => {
        gtm.event(gtm.EVENTS.SHOW_APPOINTMENT_SLOT_DETAILS_CLICKED, { category: gtm.CATEGORIES.APPOINTMENT_SLOT });
        setIsAttendeesModalShown(true);
    };

    let options = [];
    if (slot.status === 'ACTIVE') {
        options = [
            {
                text: t('slot_form.hide'),
                onClick: onHideClick,
            },
            {
                text: t('slot_form.cancel'),
                onClick: onCancelClick,
            },
        ];
    } else if (slot.status === 'HIDDEN') {
        options = [
            {
                text: t('slot_form.cancel'),
                onClick: onCancelClick,
            },
        ];
    }
    return (
        <Container
            data-cy='slot'
            className={classNames(
                interactive && styles['interactive-slot'],
                interactive && 'cursor-pointer',
                'border rounded-8 px-4 py-3 mb-2',
                bgColor
            )}
            key={id}
        >
            {cancellationWarningIsShown ? (
                <CancellationWarningView
                    onNoClick={() => setCancellationWarningIsShown(false)}
                    onYesClick={() => {
                        handleCancel();
                        setCancellationWarningIsShown(false);
                    }}
                    amountOfPeople={attendees.length}
                />
            ) : (
                <SlotView
                    isPast={isPast}
                    isLoading={isLoading}
                    interactive={interactive}
                    onCardClick={showDetailsModal}
                    slot={slot}
                    attendees={attendees}
                    options={options}
                />
            )}

            <AttendeesModal
                attendees={attendees}
                isAttendeesModalShown={isAttendeesModalShown}
                setIsAttendeesModalShown={setIsAttendeesModalShown}
            />
        </Container>
    );
};

const CancellationWarningView = ({ onNoClick, onYesClick, amountOfPeople }) => {
    const { t } = useTranslation(['metasearch', 'common']) as unknown as { t: TFunction<string> };
    return (
        <div>
            <span className='bold text-coral-500 body-m mb-4'>
                {t('slot_form.cancellation_warning_headline', { ns: 'metasearch' })}
            </span>
            <p className='body-s my-1'>
                {t('slot_form.cancellation_warning_text', { ns: 'metasearch', amountOfPeople })}
            </p>
            <div className='d-flex w-100 gap-3 justify-content-end'>
                <Button variant='secondary' className='body-s' size='xs' onClick={onNoClick}>
                    {t('no', { ns: 'common' })}
                </Button>
                <Button className='body-s' size='xs' onClick={onYesClick}>
                    {t('yes', { ns: 'common' })}
                </Button>
            </div>
        </div>
    );
};

const SlotView = ({ isPast, isLoading, interactive, onCardClick, slot, attendees, options }) => {
    const router = useRouter();
    const { locale = 'de' } = router;
    const { t } = useTranslation(['metasearch']) as unknown as { t: TFunction<string> };
    const { startDate, endDate, status, id } = slot;
    const localDate = getLocalDate(startDate, locale);
    const opaque = status !== 'ACTIVE';

    return (
        <div
            onClick={(e: any) => {
                if (e.target.type === 'button' || !interactive) return;
                onCardClick();
            }}
            key={id}
            className='body-m d-flex flex-column gap-3'
        >
            <div className={classNames(styles['slot-card-line'], 'align-items-end')}>
                <div className={classNames(opaque && 'opacity-50', 'text-nowrap d-flex align-items-center gap-1')}>
                    <Calendar4 className='text-velvet-500' /> {localDate}
                </div>
                <div className={classNames(opaque && 'opacity-50', 'd-flex align-items-center gap-1')}>
                    <Person className='text-velvet-500' /> {attendees.length} / {slot.maxNumOfParticipants}
                </div>
            </div>

            <div className={classNames(styles['slot-card-line'], 'align-items-end')}>
                <div className={classNames(opaque && 'opacity-50', 'text-nowrap d-flex align-items-center gap-1')}>
                    <ClockHistory className='text-velvet-500' />{' '}
                    {moment.utc(endDate).diff(moment.utc(startDate), 'minutes')} min
                </div>
                <div className={classNames(opaque && 'opacity-50', 'text-nowrap d-flex align-items-center gap-1')}>
                    <ClockHistory className='text-velvet-500' /> {moment.tz(startDate, 'Europe/Berlin').format('H:mm')}
                </div>

                {slot.status === 'CANCELED' ? (
                    <Badge
                        size='xs'
                        textClassName={classNames('text-gray-400')}
                        containerClassName={classNames('text-end bg-gray-50')}
                        text={t(`slot_form.statuses.${slot.status}`)}
                    />
                ) : (
                    <Dropdown
                        disabled={isLoading || isPast}
                        itemsAs='button'
                        containerClassName='ms-auto'
                        items={options}
                        variant={slot.status === 'ACTIVE' ? 'primary' : 'secondary'}
                    >
                        {t(`slot_form.statuses.${slot.status}`)}
                    </Dropdown>
                )}
            </div>
        </div>
    );
};
