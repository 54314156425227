import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { TFunction } from 'react-i18next';
import { Card, Ratio } from 'react-bootstrap';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { extractBaseLeasingRate, extractCasafariImageUrl, getAddressHeader } from './utils';
import { BADGE_TYPES, PropertyBadge } from '../../property';
import { formatCurrency, gtm } from '@/lib/utilities';
import { absoluteUrl } from '@/lib/env';
import { LeaseRequestStatus, PromotionType } from '@/lib/api/bff';
import { Property } from '@/components/types';

export const PropertyCard = ({
    property,
    withLeasingRequest,
}: {
    property: Property;
    withLeasingRequest?: boolean;
}) => {
    const { locale = 'de', pathname } = useRouter();
    const { t } = useTranslation(['properties']) as unknown as {
        t: TFunction<string>;
    };

    const isMyOwnrPage = pathname === '/my/lease-requests';

    let leasingRate;

    if (typeof property.leasingRate === 'number') {
        leasingRate = Math.round(property.leasingRate);
    } else {
        leasingRate = withLeasingRequest
            ? property.leasingRate
            : extractBaseLeasingRate(property.leasingRate as unknown as string);
    }

    const href = getPropertyHref(property.id, !!withLeasingRequest, locale);

    const handlePropertyClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();
        if (!withLeasingRequest) {
            gtm.event(gtm.EVENTS.SEARCH_PROPERTY_CLICKED, { category: gtm.CATEGORIES.SEARCH, value: property.id });
        }

        window.open(href, withLeasingRequest ? '_self' : '_blank');
    };

    const isPrivate = 'isPrivate' in property ? property.isPrivate : false;

    const isPromotionProperty =
        property.promotionType === PromotionType.HOT || property.promotionType === PromotionType.EXCLUSIVE;

    const badges = withLeasingRequest ? null : (
        <>
            {isPromotionProperty && (
                <PropertyBadge
                    type={property.promotionType === PromotionType.HOT ? BADGE_TYPES.HOT : BADGE_TYPES.EXCLUSIVE}
                    className='mb-4 me-2'
                />
            )}
            {isMyOwnrPage && isPrivate && <PropertyBadge type={BADGE_TYPES.HIDDEN} className='mb-4 me-2' />}
            {isMyOwnrPage && property.isThirdPartyPubApproved && (
                <PropertyBadge type={BADGE_TYPES.APPROVED_TO_PUBLISH} className='mb-4' />
            )}
        </>
    );

    return (
        <a
            href={href}
            className='position-relative'
            onClick={handlePropertyClick}
            target={withLeasingRequest ? '_self' : '_blank'}
            data-cy='property-card'
            rel='noreferrer'
        >
            <Card className={classNames(styles.card, 'bg-white h-100')} id={property.id as unknown as string}>
                <Ratio aspectRatio='4x3'>
                    <Card.Img
                        variant='top'
                        style={{ width: '100%' }}
                        className={classNames(isPropertyInactive(property) && styles['grayFilter'])}
                        onError={({ currentTarget }) => {
                            if (!currentTarget) return;
                            currentTarget.onerror = null;
                            currentTarget.src = '/images/property_placeholder.svg';
                        }}
                        src={extractCasafariImageUrl(property.images)}
                        loading='lazy'
                    />
                </Ratio>

                <div
                    className={classNames(
                        styles['trafficLightBar'],
                        isPropertyInactive(property) ? 'bg-grey-100' : 'bg-velvet-200'
                    )}
                ></div>

                <Card.Body className='p-2'>
                    <Card.Text className={styles['card-text']} as='div'>
                        <div className={classNames(isPropertyInactive(property) && styles['grayFilter'])}>
                            {badges}
                            <h4 className={styles['headline']} data-cy='property-address'>
                                {getAddressHeader(property)}
                            </h4>

                            <p className={styles['subline']}>
                                {'propertyType' in property
                                    ? t(property.propertyType as string)
                                    : property.type && t(property.type)}
                                <span className={styles['bullet']} />
                                {'rooms' in property ? (property.rooms as number) : property.features?.rooms}{' '}
                                {t('rooms')}
                                <span className={styles['bullet']} />
                                {'space' in property ? (property.space as number) : property.features?.area}m
                                <sup>2</sup>
                            </p>

                            {!withLeasingRequest && !property.hidePurchasePrice && (
                                <p data-cy='expose-price' className={styles['sales_price']}>
                                    {t('salesPrice')}{' '}
                                    {property.salesPrice && formatCurrency(locale, property.salesPrice)}
                                </p>
                            )}

                            {isPropertyInactive(property) ? (
                                <p>
                                    {property.closedByUser === false ? (
                                        <strong>{t('closed_lease_request.ownr_canceled')} </strong>
                                    ) : (
                                        <>
                                            <strong>{t('closed_lease_request.user_canceled')} </strong>
                                            <br />
                                            {property?.reason && (
                                                <>
                                                    <strong>{t('closed_lease_request.reason')} </strong>
                                                    <span>{t(`closed_lease_request.${property.reason}`)}</span>
                                                </>
                                            )}
                                        </>
                                    )}
                                </p>
                            ) : (
                                <p>
                                    {t('expectedRate')}{' '}
                                    {leasingRate ? (
                                        <strong>
                                            {formatCurrency(locale, leasingRate as unknown as number)}{' '}
                                            <span>{t('perMonth')}</span>
                                        </strong>
                                    ) : (
                                        <strong>{'N/A'}</strong>
                                    )}
                                </p>
                            )}
                        </div>
                    </Card.Text>
                </Card.Body>
            </Card>
        </a>
    );
};

function getPropertyHref(propertyId: number | string, withLeasingRequest: boolean, locale: string) {
    if (withLeasingRequest) {
        if (locale === 'de') return absoluteUrl(`/my/lease-request?id=${propertyId}`);
        return absoluteUrl(`/${locale}/my/lease-request?id=${propertyId}`);
    }

    if (locale === 'de') return absoluteUrl(`/searches/property?id=${propertyId}`);
    return absoluteUrl(`/${locale}/searches/property?id=${propertyId}`);
}

function isPropertyInactive(property: {
    isActive?: boolean | null;
    status?: LeaseRequestStatus | string | null;
    closedByUser?: boolean | null;
}): boolean {
    return (
        !property.isActive ||
        property.status === LeaseRequestStatus.CLOSED ||
        typeof property.closedByUser === 'boolean'
    );
}
