import React, { useState } from 'react';
import { Button, Dropdown, Form, Spinner } from 'react-bootstrap';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { TFunction } from 'react-i18next';
import styles from '../styles.module.scss';
import { useUpdateDefaultSearch } from '../hooks';
import { EmailIntervalType, MenuProps, ToggleProps, FormValuesType } from '../types';
import { TOAST_OPTIONS } from '@/styles/toasts';
import { gtm } from '@/lib/utilities';

function SaveDefaultSearchButton({ getValues }: { getValues: () => FormValuesType }) {
    const { t } = useTranslation('properties') as unknown as { t: TFunction<string> };
    const [notificationPeriod, setNotificationPeriod] = useState<EmailIntervalType>(EmailIntervalType.EVERY_OTHER_DAY);
    const { updateDefaultSearch, isLoading } = useUpdateDefaultSearch(TOAST_OPTIONS);

    const handleOnButtonClick = () => {
        const values = getValues();
        const valuesWithNotificationPeriod = { ...values, notificationPeriod };
        gtm.event(gtm.EVENTS.SEARCH_SAVE, { category: gtm.CATEGORIES.SEARCH, value: valuesWithNotificationPeriod });
        updateDefaultSearch(valuesWithNotificationPeriod, t);
    };

    return (
        <div className={classNames('d-flex', 'justify-content-end')}>
            <Dropdown className={classNames(styles['sorting-control'], styles.saveButtonDropDown)}>
                <Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components' className='dropdown-toggle'>
                    <span className={styles.hideOnXsMobile}>
                        {`${t('Set_as_favorite_search')}`} <i className='icon icon-heart-empty'></i>
                    </span>
                    <span className={styles.showOnXsMobile}>
                        {`${t('Save')}`} <i className='icon icon-heart-empty'></i>
                    </span>
                </Dropdown.Toggle>

                <Dropdown.Menu as={CustomMenu}>
                    <div className={styles.dropdownSeperator}>
                        <NotifcationPeriodSelecter setNotificationPeriod={setNotificationPeriod} />
                        <div className={'d-flex'}>
                            <Button onClick={handleOnButtonClick} className={styles.saveSearchButton}>
                                {isLoading ? <Spinner variant='coral' animation={'border'} /> : `${t('Save')}`}
                            </Button>
                        </div>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}

function NotifcationPeriodSelecter({
    setNotificationPeriod,
}: {
    setNotificationPeriod: (value: EmailIntervalType) => void;
}) {
    const { t } = useTranslation('properties') as unknown as { t: TFunction<string> };
    const [showNotificationPeriodSelect, setShowNotificationPeriodSelect] = React.useState(false);
    return (
        <>
            <a
                style={{ whiteSpace: 'nowrap' }}
                href=''
                onClick={e => {
                    e.preventDefault();
                    setShowNotificationPeriodSelect(!showNotificationPeriodSelect);
                }}
            >
                {`${t('Set_notification_interval')}`}
                <i className='icon icon-edit'></i>
            </a>

            <div
                className={classNames(
                    showNotificationPeriodSelect && styles.showNotificationSelect,
                    styles.notificationSelect
                )}
            >
                <Form.Select
                    onChange={event => {
                        setNotificationPeriod(event.target.value as EmailIntervalType);
                    }}
                    defaultValue={EmailIntervalType.EVERY_OTHER_DAY}
                >
                    {Object.keys(EmailIntervalType).map(option => {
                        return (
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            <option key={option} value={EmailIntervalType[option]}>
                                {t(option)}
                            </option>
                        );
                    })}
                </Form.Select>
            </div>
        </>
    );
}

const CustomToggle = React.forwardRef<HTMLAnchorElement, React.PropsWithChildren<ToggleProps>>(
    ({ children, onClick }, ref) => (
        <a
            href=''
            ref={ref}
            onClick={e => {
                e.preventDefault();
                gtm.event(gtm.EVENTS.SEARCH_SAVE_OPTIONS_CLICKED, { category: gtm.CATEGORIES.SEARCH });
                onClick(e);
            }}
        >
            {'  '}
            {children}
            <i className='icon icon-heart-thin'></i>
        </a>
    )
);
CustomToggle.displayName = 'CustomToggle';

const CustomMenu = React.forwardRef<HTMLDivElement, React.PropsWithChildren<MenuProps>>(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
        return (
            <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
                <ul className={classNames('list-unstyled', styles['dropdown-items'])}>
                    {React.Children.toArray(children)}
                </ul>
            </div>
        );
    }
);
CustomMenu.displayName = 'CustomMenu';

export default SaveDefaultSearchButton;
