import dynamic from 'next/dynamic';
import { FinancingExample } from '@/lib/api/graphcms';

const Financing = dynamic(() => import('../../../financing'), {
    ssr: false,
});

export function FinancingTable(props: FinancingExample) {
    return <Financing {...props} />;
}
