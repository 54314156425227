import { PropertyAddress } from '@/lib/api/bff';
import { useTranslation } from 'next-i18next';
import { Row } from 'react-bootstrap';
import { SignTurnLeft } from 'react-bootstrap-icons';
import { PropertyAddress as PropertyAddressPS } from '../../../lib/api/properties/types';

interface AddressProps {
    address: Partial<PropertyAddress> & Partial<PropertyAddressPS>;
    className?: string;
    withIcon?: boolean;
}

export const Address = ({ address, className, withIcon }: AddressProps) => {
    const { t } = useTranslation(['properties', 'common']);
    const { zip, postalCode } = address;
    const city = 'city' in address ? address.city : undefined;
    const zipLine = [postalCode ?? zip, city].filter(Boolean).join(' ');

    return (
        <Row className={className} data-cy='address'>
            {withIcon && (
                <div className='d-flex gap-2 align-items-baseline w-auto'>
                    <SignTurnLeft className='' />
                    <span>{t('address')}:</span>
                </div>
            )}
            <div className='d-flex flex-wrap w-auto align-self-end gap-2'>{zipLine}</div>
        </Row>
    );
};
