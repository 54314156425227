import React from 'react';
import classNames from 'classnames';
import { GraphCmsImage } from '../../../../graph-cms';
import { ImageCardComponent } from '@/lib/api/graphcms';
import styles from './image-card.module.scss';

export type ImageCardProps = Partial<ImageCardComponent> & { className?: string };

export const ImageCard = ({ className, title, image }: ImageCardProps) => (
    <div className={classNames(styles.container, className)}>
        <h3 className={styles.title}>{title}</h3>
        <div className={styles['image-container']}>
            <GraphCmsImage image={image} alt='Image Card' />
        </div>
        <div className={styles.overlay} />
    </div>
);
