import { InfoSection } from './components/InfoSection';
import { ExposeNoLongerActive } from './components/ExposeNoLongerActive';
import { BackButton } from './components/BackButton';
import { Col, Container, Row } from 'react-bootstrap';
import { ShortDetails } from './components/ShortDetails';
import { CallToActionBlock } from './components/CallToActionBlock';
import { Divider } from './components/Divider';
import { ImageCarousel } from './components/ImageCarousel/index';
import { Maybe, PromotionType } from '@/lib/api/bff';
import { RefurbishmentLevelGroup } from '@/lib/api/graphcms';
import { PropertyDetails } from '@/lib/api/properties/types';
import { LeasingRates } from '@/components/types';

interface PropertyProps {
    property: Omit<PropertyDetails, 'leasingRate'> & {
        leasingRate?: number | null;
        leasingRates?: LeasingRates | null;
    };
    refurbishmentGroups?: RefurbishmentLevelGroup[];
}

export function Property({ property, refurbishmentGroups }: PropertyProps) {
    const { isActive, images } = property;

    const callToActionBlock = <CallToActionBlock property={property} refurbishmentGroups={refurbishmentGroups} />;

    return (
        <Container>
            <Row className='mb-8'>
                <BackButton toSearchPage className='mb-3' />
                <Col md={6} lg={7} className='d-flex flex-column gap-5'>
                    <ImageCarousel
                        images={images ?? []}
                        isActive={!!isActive}
                        promotionType={property.promotionType as Maybe<PromotionType>}
                    />
                    <ShortDetails features={property.features} promotionType={property.promotionType} />
                    <Divider />
                    {!isActive && <ExposeNoLongerActive />}
                    <InfoSection
                        isActive={!!isActive}
                        property={property}
                        renderCallToActionBlock={() => callToActionBlock}
                    />
                </Col>

                <Col md={6} lg={5} className='d-flex align-content-center'>
                    <div className='h-auto d-none d-md-block w-100'>{callToActionBlock}</div>
                </Col>
            </Row>
        </Container>
    );
}

export default Property;
