import { RegistrationState, Step } from './types';
import { useRouter } from 'next/router';
import { getStateFromParams, isValidStep } from './util';

export function useRegistrationInitialState(
    defaultStep: Step,
    defaultState: RegistrationState,
    stepNames: string[]
): [Step, RegistrationState] {
    const router = useRouter();
    const initialStep = isValidStep(router.query.step, stepNames) ? router.query.step : defaultStep;

    const stateFromParams = getStateFromParams(router.query.params);
    const initialState = stateFromParams ? { ...defaultState, ...stateFromParams } : defaultState;

    return [initialStep as Step, initialState];
}
