import { useState } from 'react';
import { useRouter } from 'next/router';
import useSWR, { mutate } from 'swr';
import useSWRMutation from 'swr/mutation';
import { toast, ToastContent, ToastOptions } from 'react-toastify';
import { LocationInput, NotificationPeriod, SupportedRegions, useBff, SearchFilter } from '@/lib/api/bff';
import { areSearchQueryParamsEmpty, getPropertyType } from './utils';
import { FormValuesType } from './types';
import { TFunction } from 'react-i18next';
import { EmailIntervalType } from './types';
import { notify } from '@/lib/utilities/logger';
import { useSession } from 'next-auth/react';
import { useAccountId } from '../../users';
import { getDefaultLocation } from '@/lib/utilities';
import { usePropertiesClient } from '@/lib/api';
import {
    OffMarketPropertiesFilter,
    OffMarketPropertyResponseDto,
    SearchPropertyFilter,
} from '@/lib/api/properties/types';
import { GET_PARTNERS_PROPERTIES, GET_PROPERTIES } from '@/lib/constants/paths';

export function useSavedSearch() {
    const api = useBff();
    const router = useRouter();
    const { data: session } = useSession();
    const { user } = session || {};
    let accountId = useAccountId();
    const { groups = [] } = user || {};

    if (router.query['forAccount']) {
        if ((groups as [string]).includes('OWNR')) accountId = router.query['forAccount'] as string;
    }

    const shouldLoadSavedSearch = areSearchQueryParamsEmpty(router);

    const {
        data: { defaultSearch } = {},
        error,
        isLoading,
    } = useSWR(
        () => (accountId && shouldLoadSavedSearch ? ['bff/storedSearch', accountId] : null),
        () => api.DefaultSearch({ accountId: accountId as string })
    );

    if (error) notify(error, 'bff/storedSearch');
    return {
        savedSearch: defaultSearch as SearchFilter,
        error,
        isLoading,
    };
}

export function useProperties() {
    const propertyServiceApi = usePropertiesClient();
    if (!propertyServiceApi) throw new Error('property service api must be provided');

    const {
        data: { data } = {},
        error: propertyServiceError,
        isMutating: propertyServiceIsLoading,
        trigger,
    } = useSWRMutation(GET_PARTNERS_PROPERTIES, (key, { arg }: { arg: SearchPropertyFilter }) =>
        propertyServiceApi.POST(key, { body: arg })
    );

    if (propertyServiceError) notify(propertyServiceError, GET_PARTNERS_PROPERTIES);

    return {
        properties: data?.properties as unknown as OffMarketPropertyResponseDto[],
        nextOffset: data?.nextOffset,
        error: propertyServiceError,
        isLoading: propertyServiceIsLoading,
        trigger,
    };
}

export function useOffMarketProperties() {
    const propertyServiceApi = usePropertiesClient();
    if (!propertyServiceApi) throw new Error('property service api must be provided');

    const {
        data: { data: offMarketProperties } = {},
        error: propertyServiceError,
        isMutating: propertyServiceIsLoading,
        trigger,
    } = useSWRMutation(GET_PROPERTIES, (key, { arg }: { arg: OffMarketPropertiesFilter }) =>
        propertyServiceApi.POST(key, { body: arg })
    );

    if (propertyServiceError) notify(propertyServiceError, GET_PROPERTIES);

    return {
        offMarket: offMarketProperties,
        error: propertyServiceError,
        isLoading: propertyServiceIsLoading,
        trigger,
    };
}

export function useLocations(region: SupportedRegions, locationSearchKeyword: string | null) {
    const api = useBff();

    const { data, error, isLoading, mutate } = useSWR(
        locationSearchKeyword ? ['bff/locations', locationSearchKeyword, region] : null,
        () => api.Locations({ region, keyword: locationSearchKeyword })
    );

    if (error) notify(error, 'bff/locations');
    return {
        data: data?.getLocations ? JSON.parse(data?.getLocations) : {},
        error,
        isLoading,
        mutate,
    };
}

export function useSearchLocationDebounce(setLocationSearchKeyword: React.Dispatch<React.SetStateAction<string>>) {
    let filterTimeout: NodeJS.Timeout;

    return (keyword: string) => {
        clearTimeout(filterTimeout);

        filterTimeout = setTimeout(() => {
            setLocationSearchKeyword(keyword);
        }, 500);
    };
}

export function useUpdateDefaultSearch(TOAST_OPTIONS: ToastOptions) {
    const api = useBff();
    const accountId = useAccountId();
    const [isLoading, setIsLoading] = useState(false);

    return {
        isLoading,
        updateDefaultSearch: (
            search: FormValuesType & { notificationPeriod: EmailIntervalType },
            t: TFunction<string>
        ) => {
            const { propertyType, locationsIds, ...rest } = search;
            const notificationEnabled = search?.notificationPeriod !== EmailIntervalType.NEVER;
            const notificationPeriodValue = notificationEnabled ? search?.notificationPeriod : undefined;
            const propertyTypeValue = getPropertyType(propertyType);

            const locations =
                locationsIds && locationsIds.length > 0
                    ? locationsIds.map(({ value, label }) => {
                          return { id: value, name: label };
                      })
                    : [getDefaultLocation(search.region)];

            for (const [key, value] of Object.entries(rest)) {
                if (value === '') {
                    delete rest[key as keyof typeof rest];
                }
            }

            setIsLoading(true);

            const filter = {
                locations: locations as unknown as LocationInput[],
                propertyType: propertyTypeValue,
                ...rest,
                ...(rest.maxLeaseRate ? { maxLeaseRate: parseInt(rest.maxLeaseRate) } : {}),
                ...(rest.minLeaseRate ? { minLeaseRate: parseInt(rest.minLeaseRate) } : {}),
                limit: undefined,
                notificationPeriod: undefined,
                offset: undefined,
            };
            return api
                .UpdateDefaultSearch({
                    accountId: accountId as string,
                    search: filter as unknown as SearchFilter,
                    notificationEnabled,
                    notificationPeriod: notificationPeriodValue as unknown as NotificationPeriod,
                })
                .then(response => {
                    if (typeof response?.upsertDefaultSearch === 'boolean') {
                        mutate(['bff/storedSearch', accountId], { defaultSearch: filter });
                        toast.success(
                            t('update_default_search_request_success') as ToastContent<string>,
                            TOAST_OPTIONS
                        );
                    } else {
                        toast.error(t('update_default_search_request_error') as ToastContent<string>, TOAST_OPTIONS);
                    }
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false);
                    toast.error(t('update_default_search_request_error') as ToastContent<string>, TOAST_OPTIONS);
                });
        },
    };
}
