export function RevealButton({ isRevealed = false }: { isRevealed: boolean }) {
    if (isRevealed)
        return (
            <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M16 8.5C16 8.5 13.0909 2.5 8 2.5C2.90909 2.5 0 8.5 0 8.5C0 8.5 2.90909 14.5 8 14.5C13.0909 14.5 16 8.5 16 8.5ZM1.66411 8.27019C1.61314 8.35158 1.56624 8.42842 1.52345 8.5C1.56624 8.57157 1.61314 8.64842 1.66411 8.72981C1.98641 9.24444 2.46244 9.92655 3.0775 10.6031C4.33502 11.9864 6.00351 13.1667 8 13.1667C9.99649 13.1667 11.665 11.9864 12.9225 10.6031C13.5376 9.92655 14.0136 9.24444 14.3359 8.72981C14.3869 8.64842 14.4338 8.57157 14.4765 8.5C14.4338 8.42842 14.3869 8.35158 14.3359 8.27019C14.0136 7.75556 13.5376 7.07346 12.9225 6.3969C11.665 5.01362 9.99649 3.83333 8 3.83333C6.00351 3.83333 4.33502 5.01362 3.0775 6.3969C2.46244 7.07346 1.98641 7.75556 1.66411 8.27019ZM14.8013 9.08387C14.8015 9.08421 14.8015 9.0842 14.8013 9.08384L14.8013 9.08387ZM14.8013 7.91616C14.8015 7.9158 14.8015 7.91579 14.8013 7.91614L14.8013 7.91616Z'
                    fill='#725BFF'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M7.99967 7.16659C7.26329 7.16659 6.66634 7.76354 6.66634 8.49992C6.66634 9.2363 7.26329 9.83325 7.99967 9.83325C8.73605 9.83325 9.33301 9.2363 9.33301 8.49992C9.33301 7.76354 8.73605 7.16659 7.99967 7.16659ZM5.33301 8.49992C5.33301 7.02716 6.52692 5.83325 7.99967 5.83325C9.47243 5.83325 10.6663 7.02716 10.6663 8.49992C10.6663 9.97268 9.47243 11.1666 7.99967 11.1666C6.52692 11.1666 5.33301 9.97268 5.33301 8.49992Z'
                    fill='#725BFF'
                />
                <rect
                    x='0.742188'
                    y='2.09912'
                    width='1.33'
                    height='19.3115'
                    transform='rotate(-45 0.742188 2.09912)'
                    fill='#725BFF'
                />
            </svg>
        );

    return (
        <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M16 8.5C16 8.5 13.0909 2.5 8 2.5C2.90909 2.5 0 8.5 0 8.5C0 8.5 2.90909 14.5 8 14.5C13.0909 14.5 16 8.5 16 8.5ZM1.66411 8.27019C1.61314 8.35158 1.56624 8.42843 1.52345 8.5C1.56624 8.57157 1.61314 8.64842 1.66411 8.72981C1.98641 9.24444 2.46244 9.92655 3.0775 10.6031C4.33502 11.9864 6.00351 13.1667 8 13.1667C9.99649 13.1667 11.665 11.9864 12.9225 10.6031C13.5376 9.92655 14.0136 9.24444 14.3359 8.72981C14.3869 8.64842 14.4338 8.57157 14.4765 8.5C14.4338 8.42843 14.3869 8.35158 14.3359 8.27019C14.0136 7.75556 13.5376 7.07346 12.9225 6.3969C11.665 5.01362 9.99649 3.83333 8 3.83333C6.00351 3.83333 4.33502 5.01362 3.0775 6.3969C2.46244 7.07346 1.98641 7.75556 1.66411 8.27019ZM14.8013 9.08387C14.8015 9.08421 14.8015 9.0842 14.8013 9.08384L14.8013 9.08387ZM14.8013 7.91616C14.8015 7.9158 14.8015 7.91579 14.8013 7.91614L14.8013 7.91616Z'
                fill='#725BFF'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M7.99967 7.16671C7.26329 7.16671 6.66634 7.76366 6.66634 8.50004C6.66634 9.23642 7.26329 9.83337 7.99967 9.83337C8.73605 9.83337 9.33301 9.23642 9.33301 8.50004C9.33301 7.76366 8.73605 7.16671 7.99967 7.16671ZM5.33301 8.50004C5.33301 7.02728 6.52692 5.83337 7.99967 5.83337C9.47243 5.83337 10.6663 7.02728 10.6663 8.50004C10.6663 9.9728 9.47243 11.1667 7.99967 11.1667C6.52692 11.1667 5.33301 9.9728 5.33301 8.50004Z'
                fill='#725BFF'
            />
        </svg>
    );
}
