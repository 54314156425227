import React from 'react';
import classNames from 'classnames';
import { IllustrationCard } from '@/components/common';
import { Col, Container, Row } from 'react-bootstrap';
import { IllustrationCardComponent } from '@/lib/api/graphcms';
import Slider from 'react-slick';
import styles from './IllustrationCardLayout.module.scss';
import commonStyles from '../../Gallery.module.scss';

type IllustrationCardLayoutProps = {
    items: IllustrationCardComponent[];
    className?: string;
};

export const IllustrationCardLayout = ({ items = [], className }: IllustrationCardLayoutProps): JSX.Element | null => {
    const sliderConfig = {
        dots: true,
        centerMode: true,
        slidesToShow: 1,
        infinite: true,
        arrows: false,
    };

    return (
        <Container>
            <Row className={classNames(commonStyles.container, className, 'd-none d-sm-flex')}>
                {items?.map(item => (
                    <Col key={item.id} sm={6} md={4} lg={3}>
                        <IllustrationCard className={styles.card} {...item} />
                    </Col>
                ))}
            </Row>

            <Slider {...sliderConfig} className={classNames(commonStyles.slider, 'd-flex d-sm-none')}>
                {items?.map(item => (
                    <IllustrationCard key={item.id} className={styles.card} {...item} />
                ))}
            </Slider>
        </Container>
    );
};
