import { Testimonial as Record } from '@/lib/api/graphcms';
import classNames from 'classnames';
import { Container, Stack } from 'react-bootstrap';
import Image from '@graphcms/react-image';
import React from 'react';

export type TestimonialProps = Partial<Record>;

export function Testimonial(props: TestimonialProps) {
    const { statement, person } = props || {};
    const hasPersonPhoto = !!person?.photo;
    // const hasCompanyLogo = !!person?.company?.logo;

    return (
        <Container
            className='d-flex justify-content-center align-items-center w-75 pt-3'
            style={{ minHeight: 'inherit' }}
        >
            <Stack className='justify-content-center'>
                <div className={'mx-auto'}>
                    {person && hasPersonPhoto && (
                        <Image
                            image={{
                                handle: person.photo?.handle || '',
                                height: person.photo?.height || 0,
                                width: person.photo?.width || 0,
                            }}
                            alt={person.name}
                            style={{
                                width: 'clamp(45px, 10vw, 100px)',
                                height: 'clamp(45px, 10vw, 100px)',
                                borderRadius: '50%',
                            }}
                        />
                    )}
                </div>
                <div className={classNames('text-center')}>
                    <i>&raquo; {statement} &laquo;</i>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'end',
                        gap: '1rem',
                        paddingRight: 'clamp(0,15%,20vw)',
                        fontSize: '1.1rem',
                    }}
                >
                    <div className={classNames('text-end fs-italic')}>
                        <div>{person && person.name}</div>
                    </div>
                    <div>{person && person.company && <div>{person.company?.name}</div>}</div>
                </div>
            </Stack>
        </Container>
    );
}
