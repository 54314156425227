import { useAccountId } from '@/components/users';
import useSWR from 'swr';
import { notify } from '@/lib/utilities/logger';
import { AppointmentSlot } from '@/lib/api/lease-requests/types';
import { useLeaseRequestsClient } from '@/lib/api';
import { SLOTS_SEARCH_REQUEST } from '@/lib/constants/paths';
import moment from 'moment';
import { logErrorResponse } from '@/lib/api/utils';

const sortAppointmentSlots = (slots: AppointmentSlot[]) => {
    return slots?.sort((a, b) => moment(a.startDate).diff(moment(b.startDate)));
};

export const useAppointmentSlots = (
    propertyId: string
): {
    appointmentSlots: AppointmentSlot[];
    error: unknown;
    isLoading: boolean;
} => {
    const api = useLeaseRequestsClient();
    const accountId = useAccountId();

    const requestData = {
        body: { propertyId },
    };
    const {
        data: { response, data: slotsResponse, error: responseError } = {},
        error,
        isLoading,
    } = useSWR(
        () => (accountId && propertyId ? [SLOTS_SEARCH_REQUEST, accountId, propertyId] : null),
        () => api.POST(SLOTS_SEARCH_REQUEST, requestData)
    );

    if (response?.status >= 400) logErrorResponse(response, accountId, requestData, responseError);

    if (error) {
        notify(error, SLOTS_SEARCH_REQUEST);
    }

    const appointmentSlots = sortAppointmentSlots(slotsResponse);

    return {
        appointmentSlots,
        error,
        isLoading,
    };
};
