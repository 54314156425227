import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { TFunction } from 'react-i18next';
import { BADGE_TYPES, PropertyBadge } from '../../../PropertyBadge';
import styles from './styles.module.scss';
import { Property } from '@/lib/api/lease-requests/types';

export const ShortDetails = ({ property }: { property: Property }) => {
    const { rooms, bedRooms, bathRooms, area, metadata } = property;
    const { promotionType } = metadata;
    const { t } = useTranslation(['metasearch']) as unknown as { t: TFunction<string> };

    return (
        <div
            className={`d-flex
                justify-content-between
                align-content-center
                text-gray-300
                text-tag
                flex-wrap gap-3`}
            data-cy='short-details'
        >
            <div className='d-flex'>
                {renderShortDetail(rooms, t('rooms'))}
                {renderShortDetail(bedRooms, t('bedrooms'))}
                {renderShortDetail(bathRooms, t('bathrooms'))}
                {renderShortDetail(area, 'm²')}
            </div>

            {promotionType && (
                <PropertyBadge
                    type={promotionType as unknown as BADGE_TYPES}
                    className='mb-0 d-none d-md-inline-flex'
                />
            )}
        </div>
    );
};

const renderShortDetail = (detail: unknown, translatedText: string) => {
    if (detail) {
        return (
            <div className={classNames('d-flex align-items-center gap-1 ms-1', styles.detailWrapper)}>
                <span>{`${detail} ${translatedText}`}</span>
                <span>{`•`}</span>
            </div>
        );
    }
    return null;
};
