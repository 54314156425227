import { FieldErrors } from 'react-hook-form';
import { TFunction } from 'react-i18next';
import { Form } from 'react-bootstrap';

export type ErrorMessageProps = {
    name: string;
    errors: FieldErrors;
    t: TFunction;
    tNamespacePrefix: string;
};

export function ErrorMessage({ name, errors, t, tNamespacePrefix }: ErrorMessageProps) {
    const error = errors[name];
    if (!error) return null;

    if (error.message) return <Form.Control.Feedback type='invalid'>{error.message + ''}</Form.Control.Feedback>;
    const message =
        t(`${tNamespacePrefix}.validation.${name}.${error.type}`) || t(`${tNamespacePrefix}.validation.${error.type}`);
    return <Form.Control.Feedback type='invalid'>{message}</Form.Control.Feedback>;
}
