import { TFunction } from 'react-i18next';
import styles from './registration.module.scss';
import { Form } from 'react-bootstrap';
import { MultipleFieldErrors } from 'react-hook-form';
import { notify } from '@/lib/utilities/logger';
import { HeadlineFunction, Preference, PreferenceType, RegistrationState, StepComponent } from './types';
import { notEmpty } from '@/lib/utilities';
import { NotifiableError } from '@bugsnag/js';

export type OptionalMarkerProps = {
    t: TFunction;
    mandatoryFields?: string[];
    name: string;
};

export function OptionalMarker(props: OptionalMarkerProps) {
    const { mandatoryFields = [], name, t } = props;
    if (mandatoryFields.includes(name)) return null;
    return (
        <>
            {' '}
            <span className={styles['optional']}>({t('optional')})</span>
        </>
    );
}

export function isOptional(mandatoryFields: string[], name: string) {
    return !mandatoryFields.includes(name);
}

export function isMandatory(mandatoryFields: string[], name: string) {
    return mandatoryFields.includes(name);
}
export function addStarWhenMandatory(mandatoryFields: string[], name: string, value: string) {
    return isMandatory(mandatoryFields, name) ? `${value} *` : value;
}

export function isHidden(fieldsToHide: string[], name: string) {
    return fieldsToHide.includes(name);
}

export function isVisible(fieldsToHide: string[], name: string) {
    return !fieldsToHide.includes(name);
}

export function ErrorText(props: { messages?: MultipleFieldErrors; t: TFunction; name: string; prefix?: string }) {
    const { messages, t, name, prefix } = props;
    if (!messages) return null;
    const [message] = Object.keys(messages).sort();
    let key = prefix ? `${prefix}.${name}` : name;
    const fieldName = t(`${key}`, { defaultValue: t(`${key}.label`) });
    key = `${key}.${message}`;
    const defaultValue = t(`errors.default.${message}`, { fieldName });
    return <Form.Text className='invalid-feedback'>{t(key, { fieldName, defaultValue })}</Form.Text>;
}

export function createPreferences(list?: string[]): Preference[] {
    if (!list?.length) return [];
    return Array.from(list).reduce((result, key) => {
        if (key.startsWith('-')) {
            result.push({ key: key.slice(1), type: PreferenceType.DISLIKE });
            return result;
        } else {
            result.push({ key, type: PreferenceType.LIKE });
            return result;
        }
    }, [] as Preference[]);
}

export function seperateDisAndLikes(list?: string[]): [string[], string[]] {
    if (!list?.length) return [[], []];

    return Array.from(list).reduce(
        ([likes, dislikes], value) => {
            if (value.startsWith('-')) {
                return [likes, [...dislikes, value.slice(1)]];
            } else {
                return [[...likes, value], dislikes];
            }
        },
        [[] as string[], [] as string[]]
    );
}

export function createOpenEndInterval(interval: [number, number], maxValue: number): [number] | [number, number] {
    const [min, max] = interval || [];
    if (max >= maxValue) return [min];
    return interval;
}

export function createTitle(t: TFunction, value?: string | string[] | null): string | null {
    if (!value) return null;
    const titles = (Array.isArray(value) ? value : [value]).filter(notEmpty);
    titles.sort((a, b) => a.localeCompare(b) * -1);
    return titles.map(title => t(`title.${title}`)).join(' ');
}

export function isValidStep(step: string | string[] | undefined, steps: string[]) {
    return step && !Array.isArray(step) && steps.includes(step);
}

export function getStateFromParams(params: string | string[] | undefined): RegistrationState | undefined {
    if (!params || Array.isArray(params)) {
        return;
    }

    let obj;
    try {
        const utfParams = Buffer.from(params, 'base64').toString('utf-8');
        obj = JSON.parse(utfParams);
    } catch (e: unknown) {
        notify(e as NotifiableError);
        return;
    }

    let state = {};

    if (obj.city?.values?.region) {
        state = { ...state, city: { valid: true, values: obj.city?.values } };
    }

    if (obj.districts?.values?.districts) {
        state = { ...state, districts: { valid: true, values: obj.districts?.values } };
    }

    // if (obj.urgency?.values?.timing) {
    //     state = { ...state, urgency: { valid: true, values: obj.urgency?.values } };
    // }

    if (obj.property?.values?.propertyType) {
        state = { ...state, property: { valid: true, values: obj.property?.values } };
    }

    if (obj.features?.values) {
        state = { ...state, features: { valid: true, values: obj.features?.values } };
    }

    if (obj.contact?.values) {
        state = { ...state, contact: { values: obj.contact?.values } };
    }

    return state;
}

export function headline(Component: StepComponent): HeadlineFunction {
    if ('headlineFunction' in Component) {
        const fn = Component.headlineFunction;
        if (fn) return fn;
    }
    return (values, step, t) => t(`${step}.headline`);
}
